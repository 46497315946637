import {
  ACC_BRIDGE_STATUS,
  AccBridgeDataForIconsWithTooltip,
  AccBridgeProject,
  AccBridgeProjectsData,
  AccBridgeStatus,
  MIDAccBridgeFolder,
} from 'mid-types';

interface GetProjectNameSyncedArgs {
  bridgeProjectsList: AccBridgeProject[] | undefined;
  incomingACCBridgeFolder?: MIDAccBridgeFolder;
  outgoingACCBridgeFolder?: MIDAccBridgeFolder;
}
export const getACCBridgeProjectNameSynced = ({
  bridgeProjectsList,
  incomingACCBridgeFolder,
  outgoingACCBridgeFolder,
}: GetProjectNameSyncedArgs): string | undefined => {
  let projectNameSynced;

  if (bridgeProjectsList?.length) {
    if (incomingACCBridgeFolder) {
      projectNameSynced = bridgeProjectsList.find(
        (project) => project.projectId === incomingACCBridgeFolder.source_project_uid,
      )?.projectName;
    } else if (outgoingACCBridgeFolder) {
      projectNameSynced = bridgeProjectsList.find(
        (project) => project.projectId === outgoingACCBridgeFolder.target_project_uid,
      )?.projectName;
    }
  }
  return projectNameSynced;
};

export const getACCBridgeStatus = (
  incomingACCBridgeFolder: MIDAccBridgeFolder | undefined,
  outgoingACCBridgeFolders: MIDAccBridgeFolder[] | undefined,
): AccBridgeStatus => {
  if (incomingACCBridgeFolder && incomingACCBridgeFolder.isSyncAutomationActive) {
    return ACC_BRIDGE_STATUS.INCOMING;
  }
  if (outgoingACCBridgeFolders && outgoingACCBridgeFolders.some((bridgeFolder) => bridgeFolder.isSyncAutomationActive)) {
    return ACC_BRIDGE_STATUS.OUTGOING;
  }
  return ACC_BRIDGE_STATUS.NONE;
};

interface GetACCBridgeProjectsDataArgs {
  folderUrn: string;
  incomingBridgeFoldersMap: Map<string, MIDAccBridgeFolder> | undefined;
  outgoingBridgeFoldersMap: Map<string, MIDAccBridgeFolder[]> | undefined;
  bridgeProjectsList: AccBridgeProject[] | undefined;
}

// This data is used to display the ACC Bridge status icon and
// the projects synced with the ACC Bridge in its tooltip
export const getACCBridgeProjectsData = ({
  folderUrn,
  incomingBridgeFoldersMap,
  outgoingBridgeFoldersMap,
  bridgeProjectsList,
}: GetACCBridgeProjectsDataArgs): AccBridgeDataForIconsWithTooltip => {
  const incomingACCBridgeFolder = incomingBridgeFoldersMap?.get(folderUrn);
  const outgoingACCBridgeFolders = outgoingBridgeFoldersMap?.get(folderUrn);

  // accBridgeStatus will be mainly used for the acc bridge icons in folders
  // If at least one of the folders has sync automation active, the status is active
  // Otherwise, if all folders have sync automation inactive, the status is none
  const accBridgeStatus = getACCBridgeStatus(incomingACCBridgeFolder, outgoingACCBridgeFolders);

  const projectNameSynced = getACCBridgeProjectNameSynced({
    bridgeProjectsList,
    incomingACCBridgeFolder,
  });
  const accBridgeProjectsData =
    incomingACCBridgeFolder && projectNameSynced
      ? [
          {
            projectNameSynced,
            sourceProjectId: incomingACCBridgeFolder.source_project_uid,
            sourceFolderId: incomingACCBridgeFolder.source_key_uid,
          },
        ]
      : (outgoingACCBridgeFolders
          ?.map((outgoingACCBridgeFolder) => {
            if (!outgoingACCBridgeFolder.isSyncAutomationActive) {
              return;
            }

            const projectNameSynced = getACCBridgeProjectNameSynced({
              bridgeProjectsList,
              outgoingACCBridgeFolder,
            });

            return {
              projectNameSynced,
              targetProjectId: outgoingACCBridgeFolder.target_project_uid,
              targetFolderId: outgoingACCBridgeFolder.target_key_uid,
            };
          })
          .filter(
            (accBridgeProjectData) => accBridgeProjectData && accBridgeProjectData.projectNameSynced,
          ) as AccBridgeProjectsData[]);

  const accBridgeDataForTreeItem: AccBridgeDataForIconsWithTooltip = {
    accBridgeStatus,
    accBridgeProjectsData: accBridgeProjectsData || [],
  };

  return accBridgeDataForTreeItem;
};
