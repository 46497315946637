import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import text from 'global/text.json';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { licensingTestIds } from 'tests/helpers/dataTestIds';
import { BodyWrapper, HeaderWrapper, PageWrapper, PrintWrapper } from './Licensing.styles';
import LicensingContent from './LicensingContent';
import LicensingLegend from './LicensingLegend';

const licensingText = text.licensing;

const Licensing: React.FC = () => {
  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: '',
  });
  return (
    <PageWrapper>
      <HeaderWrapper className="mid-bg-shadow">
        <Typography variant="h1" fontSize="32px">
          {licensingText.licensingAttributions}
        </Typography>
      </HeaderWrapper>
      <BodyWrapper>
        <LicensingLegend />
        <LicensingContent ref={printRef} />
        <PrintWrapper>
          <Button
            variant="outlined"
            startIcon={<PrintOutlinedIcon />}
            onClick={handlePrint}
            data-testid={licensingTestIds.buttonPrint}
          >
            {licensingText.print}
          </Button>
        </PrintWrapper>
      </BodyWrapper>
    </PageWrapper>
  );
};

export default Licensing;
