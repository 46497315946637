import { Spacing } from '@mid-react-common/common';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { Section } from '../../RFOModal.styles';

const NUMBER_OF_SKELETONS = 7;

const SkeletonRow: React.FC<{ leftColumnLength: number; height?: number; noSpacing?: boolean }> = ({
  leftColumnLength,
  height,
  noSpacing,
}) => {
  const theme = useTheme();
  return (
    <>
      <Box display="flex" gap={`${theme.var.gapBase * 3}px`}>
        <Box flex="5" display="flex" flexDirection="column" gap={`${theme.var.gapBase / 5}px`}>
          {Array.from({ length: leftColumnLength }).map((_, index) => (
            <Skeleton key={index} variant="rectangular" width={`${getRandomWidthInRange(75, 100)}%`} height={height} />
          ))}
        </Box>
        <Box flex="1">
          <Skeleton variant="rectangular" height={height} />
        </Box>
        <Box flex="2">
          <Skeleton variant="rectangular" height={height} />
        </Box>
      </Box>
      {noSpacing ? null : <Spacing />}
    </>
  );
};
const getRandomWidthInRange = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

const CheckoutLoadingSkeleton: React.FC = () => {
  const theme = useTheme();

  return (
    <Section>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Skeleton variant="rectangular" width={`${getRandomWidthInRange(20, 35)}%`} height={theme.var.rfoSkeletonHeight} />
        <Skeleton variant="rectangular" width={`${getRandomWidthInRange(20, 35)}%`} height={theme.var.rfoSkeletonHeight} />
      </Box>
      <Spacing />
      <SkeletonRow leftColumnLength={1} />
      {Array.from({ length: NUMBER_OF_SKELETONS }).map((_, index) => (
        <SkeletonRow key={index} leftColumnLength={2} />
      ))}
      <SkeletonRow leftColumnLength={1} height={theme.var.rfoSkeletonHeight} noSpacing />
    </Section>
  );
};

export default CheckoutLoadingSkeleton;
