import { HigSearch, StateSetter } from '@mid-react-common/common';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { BIM360Document } from 'mid-types';
import React from 'react';
import { modelFolderContentIds } from 'tests/helpers/dataTestIds';
import { dataGridDefaultSettings } from '../../../global/constants/products';
import text from '../../../global/text.json';
import {
  FolderContentDatagrid,
  ModelsFolderContentContainer,
  ModelsFolderContentDataGrid,
  ModelsFolderContentHeader,
  ModelsSearchTextField,
} from './ModelsFolderContent.style';
import { FolderContentRow } from './ModelsFolderContent.types';
import useModelFolderContent from './useModelsFolderContent';
import { columns } from './ModelsFolderContent.utils';

const modelsFolderText = text.modelsFolderContent;

export interface ModelsFolderContentProps {
  folderUrn: string;
  lmvModelFiles: BIM360Document[] | null;
  selectedModelFolder: FolderContentRow | null;
  setSelectedModelFolder: StateSetter<FolderContentRow | null>;
  setIsPreviewLoading: StateSetter<boolean>;
}

const ModelsFolderContent: React.FC<ModelsFolderContentProps> = (props) => {
  const {
    handleOpenFoldersInDocsClick,
    handleFilterFilesByName,
    filteredProductFoldersContent,
    handleRowClick,
    selectedModelId,
  } = useModelFolderContent(props);

  return (
    <ModelsFolderContentContainer>
      <ModelsFolderContentHeader>
        <ButtonGroup variant="outlined">
          <Button onClick={handleOpenFoldersInDocsClick}>
            <Typography variant="body2" noWrap>
              {modelsFolderText.openInDocs}
            </Typography>
          </Button>
        </ButtonGroup>
        <ModelsSearchTextField
          data-testid={modelFolderContentIds.modelsFolderContentSearch}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <HigSearch />
              </InputAdornment>
            ),
          }}
          size="small"
          placeholder={modelsFolderText.searchFiles}
          onChange={handleFilterFilesByName}
        />
      </ModelsFolderContentHeader>
      <ModelsFolderContentDataGrid>
        <FolderContentDatagrid
          rows={filteredProductFoldersContent}
          columns={columns}
          pageSizeOptions={dataGridDefaultSettings.ROWS_PER_PAGE}
          rowHeight={dataGridDefaultSettings.ROW_HEIGHT}
          columnHeaderHeight={dataGridDefaultSettings.HEADER_HEIGHT}
          onRowClick={handleRowClick}
          rowSelectionModel={selectedModelId}
        />
      </ModelsFolderContentDataGrid>
    </ModelsFolderContentContainer>
  );
};

export default ModelsFolderContent;
