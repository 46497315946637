import { MIDEmptyState } from '@mid-react-common/common';
import Link from '@mui/material/Link';
import FoldersTree from 'components/Commons/FoldersTree/FoldersTree';
import useFoldersTree from 'components/Commons/FoldersTree/useFoldersTree';
import OutputProjectSelector from 'components/Commons/OutputProjectSelector/OutputProjectSelector';
import { useOutputProjectSelector } from 'components/Commons/OutputProjectSelector/useOutputProjectSelector';
import { PERMISSION_REQUIREMENT_LINK } from 'global/constants/links';
import text from 'global/text.json';
import { AccBridgeProject, MIDAccBridgeFolder } from 'mid-types';
import React from 'react';
import { UploadLocationProject } from 'types/uploadLocation';
import { FolderTreeDivider, UploadLocationStepWrapper } from './UploadLocationStep.styles';
import { useUploadLocationStep } from './useUploadLocationStep';

const rfoModalText = text.rfoModal;

interface UploadLocationStepProps {
  incomingBridgeFoldersMap: Map<string, MIDAccBridgeFolder> | undefined;
  outgoingBridgeFoldersMap: Map<string, MIDAccBridgeFolder[]> | undefined;
  bridgeProjectsList?: AccBridgeProject[];
}

const UploadLocationStep: React.FC<UploadLocationStepProps> = ({
  outgoingBridgeFoldersMap,
  incomingBridgeFoldersMap,
  bridgeProjectsList,
}) => {
  const {
    uploadLocationProject,
    uploadFolderUrn,
    isCreatingSubfolder,
    uploadLocationTree,
    expandedUploadLocationTreeNodeIdsSelection,
    editPermissions,
    handleProjectSelection,
    setUploadLocationTree,
    handleFolderSelection,
    dismissSubfolderCreationDisplay,
    setExpandedUploadLocationTreeNodeIdsSelection,
    addEditPermissions,
  } = useUploadLocationStep();

  const { projects, loading, anchorElPopover, handleProjectMenuOpen, handleProjectMenuClose, handleSearchProjects } =
    useOutputProjectSelector();

  const {
    errorReason,
    hasEmptyRootNodes,
    handleFolderToggle,
    handleSubFolderCreation,
    subFolderCreationError,
    subFolderCreationRequestLoading,
    resetSubFolderCreationError,
  } = useFoldersTree({
    accountId: uploadLocationProject?.accountId || null,
    projectId: uploadLocationProject?.id || null,
    foldersTree: uploadLocationTree,
    folderUrn: uploadFolderUrn,
    vendor: uploadLocationProject?.vendor,
    isSubfolderCreationInputVisible: isCreatingSubfolder,
    setFoldersTree: setUploadLocationTree,
    setExpandedTreeNodeIds: setExpandedUploadLocationTreeNodeIdsSelection,
    selectFolder: handleFolderSelection,
    handleEditPermissions: addEditPermissions,
  });

  const handleProjectSelectionAndClosePopover = (project: UploadLocationProject) => {
    handleProjectSelection(project);
    handleProjectMenuClose();
  };

  const handleSubFolderCreationInModal = async (folderName: string) => {
    const folderCreated = await handleSubFolderCreation(folderName);
    // Only dismiss sub folder creation display on success
    // (show display while loading & if there is an error)
    if (folderCreated) {
      dismissSubfolderCreationDisplay();
    }
  };

  const permissionLink = (
    <Link href={PERMISSION_REQUIREMENT_LINK} target="_blank" rel="noopener noreferrer">
      {rfoModalText.permissionRequirements}
    </Link>
  );

  return (
    <UploadLocationStepWrapper>
      <OutputProjectSelector
        anchorElPopover={anchorElPopover}
        selectedProject={uploadLocationProject}
        projects={projects}
        loading={loading}
        handleProjectMenuOpen={handleProjectMenuOpen}
        handleProjectMenuClose={handleProjectMenuClose}
        handleSearchProjects={handleSearchProjects}
        handleProjectSelection={handleProjectSelectionAndClosePopover}
      />
      <FolderTreeDivider />
      {errorReason ? (
        <MIDEmptyState title={rfoModalText.projectPermissionError} description={permissionLink} />
      ) : hasEmptyRootNodes ? (
        <MIDEmptyState title={rfoModalText.emptyRootNodeError} description={permissionLink} />
      ) : (
        <FoldersTree
          folderUrn={uploadFolderUrn}
          foldersTree={uploadLocationTree}
          expandedTreeNodeIds={expandedUploadLocationTreeNodeIdsSelection}
          showNewFolderCreation={isCreatingSubfolder}
          handleFolderToggle={handleFolderToggle}
          handleFolderClick={handleFolderSelection}
          folderCreationRequestLoading={subFolderCreationRequestLoading}
          folderCreationError={subFolderCreationError}
          handleNewFolderNameCancellation={dismissSubfolderCreationDisplay}
          handleNewFolderNameConfirmation={handleSubFolderCreationInModal}
          resetSubFolderCreationError={resetSubFolderCreationError}
          editPermissionLookup={editPermissions}
          bridgeIncomingProjectFoldersMap={incomingBridgeFoldersMap}
          bridgeOutgoingProjectFoldersMap={outgoingBridgeFoldersMap}
          bridgeProjectsList={bridgeProjectsList}
          selectedProjectId={uploadLocationProject?.id}
        />
      )}
    </UploadLocationStepWrapper>
  );
};

export default UploadLocationStep;
