import { OutputStatus, UploadOutputStatus } from '@adsk/offsite-dc-sdk';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { GridRenderCellParams } from '@weave-mui/data-grid';
import { HUB_PROJECT_FOLDER_KEY, HUB_PROJECT_KEY } from 'global/constants/reactQueryKeys';
import { getForgeApiServiceInstance } from 'mid-api-services';
import React from 'react';
import text from '../../../global/text.json';
import { ProjectIcon } from '@mid-react-common/common';
import { UploadLocationErrorContainer, UploadLocationIconContainer } from './ReviewPanel.styles';
import { OutputsReviewTableDataModel } from './Types';
import { handleGetHubProjectFolderQuery, handleGetHubProjectQuery } from './UploadLocationCell.utils';
import { ProjectFolderPermissionSection } from 'components/Commons/ProjectFolderPermissionSection/ProjectFolderPermissionSection';
import { outputsReviewPanel } from 'tests/helpers/dataTestIds';

const reviewPanel = text.reviewPanel;

const UploadLocationCell: React.FC<GridRenderCellParams<OutputsReviewTableDataModel>> = ({ row }) => {
  const forgeApiService = getForgeApiServiceInstance();

  const hubProjectResult = useQuery({
    queryKey: [HUB_PROJECT_KEY, row.outputLocationAccountId, row.outputLocationProjectId],
    queryFn: async ({ signal }) =>
      handleGetHubProjectQuery(
        forgeApiService,
        row.outputLocationAccountId,
        row.outputLocationProjectId,
        row.outputProjectVendor,
        signal,
      ),
    enabled: Boolean(row.outputLocationAccountId && row.outputLocationProjectId && row.outputProjectVendor),
  });

  const hubProjectFolderResult = useQuery({
    queryKey: [HUB_PROJECT_FOLDER_KEY, row.outputLocationProjectId, row.outputLocation],
    queryFn: async ({ signal }) =>
      handleGetHubProjectFolderQuery(
        forgeApiService,
        row.outputLocationProjectId,
        row.outputLocation,
        row.outputProjectVendor,
        signal,
      ),
    enabled: Boolean(row.outputLocationProjectId && row.outputLocation && row.outputProjectVendor),
  });

  const isUploadFailed =
    row.outputUploadStatus === UploadOutputStatus.FAILED ||
    row.outputUploadStatus === UploadOutputStatus.WONT_UPLOAD ||
    row.status === OutputStatus.FAILED;

  const isProcessingToGetProjectFileInfo =
    row.outputUploadStatus === UploadOutputStatus.PROCESSING ||
    hubProjectResult.isLoading ||
    hubProjectFolderResult.isLoading;

  const isSuccessfulWithUpload = Boolean(
    hubProjectResult.data?.data &&
      hubProjectFolderResult.data?.data &&
      row.outputUploadStatus === UploadOutputStatus.SUCCESS &&
      row.outputProjectVendor,
  );

  const hasPermissionErrorFetchingUploadProjectFileName = Boolean(
    (hubProjectResult.data?.showPermissionError || hubProjectFolderResult.data?.showPermissionError) &&
      row.outputProjectVendor,
  );

  if (isUploadFailed) {
    return reviewPanel.failedUploadStatus;
  }

  if (row.outputUploadStatus === UploadOutputStatus.PENDING) {
    return reviewPanel.pending;
  }

  if (isProcessingToGetProjectFileInfo) {
    return reviewPanel.processing;
  }

  if (isSuccessfulWithUpload) {
    return (
      <>
        <UploadLocationIconContainer>
          <ProjectIcon vendor={row.outputProjectVendor!} />
        </UploadLocationIconContainer>
        <Typography variant="body2" noWrap>{`${hubProjectResult.data!.data!.attributes.name} / ${
          hubProjectFolderResult.data!.data!.attributes.displayName
        }`}</Typography>
      </>
    );
  }

  if (hasPermissionErrorFetchingUploadProjectFileName) {
    return (
      <UploadLocationErrorContainer>
        <Tooltip
          title={
            <ProjectFolderPermissionSection
              summary={reviewPanel.hubProjectPermssionError}
              vendor={row.outputProjectVendor!}
            />
          }
          placement="bottom"
          arrow
        >
          <WarningRoundedIcon color="error" data-testid={outputsReviewPanel.permissionErrorIcon} />
        </Tooltip>
        <Typography variant="body2">{reviewPanel.cantViewFolder}</Typography>
      </UploadLocationErrorContainer>
    );
  }

  return reviewPanel.notAvailable;
};

export default UploadLocationCell;
