import { OutputItem } from './SettingsPanel';
import text from '../../../global/text.json';
import { generateModelStateInfo, transformOutputItemToPostVariantOutput } from './outputSettings.utils';
import { SettingsOutputTitle } from './SettingsPanel.styles';
import { OutputSettingsLabel } from './Components/OutputSettingsLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckboxForOutputLabel from './Components/CheckboxForOutputLabel/CheckboxForOutputLabel';
import { DCOutputWithVirtualTypes, PostVariantOutputWithVirtualTypes } from 'mid-types';

interface OutputSettingsItemProps {
  output: DCOutputWithVirtualTypes;
  outputSectionTitle: string;
  selectedOutputs: PostVariantOutputWithVirtualTypes[];
  addOutput: (newOutput: PostVariantOutputWithVirtualTypes) => void;
  removeOutput: (outputToRemove: PostVariantOutputWithVirtualTypes) => void;
}

const settingsPanelText = text.settingsPanel;

export const OutputSettingsItem: React.FC<OutputSettingsItemProps> = ({
  output,
  selectedOutputs,
  outputSectionTitle,
  addOutput,
  removeOutput,
}) => {
  const handleCheckboxUpdate = (
    event: React.ChangeEvent<HTMLInputElement>,
    label: OutputItem,
    drawingTemplatePath?: string,
  ): void => {
    const { outputType } = label;
    if (!outputType) {
      throw new Error(`${settingsPanelText.failToSelectOutputType} ${outputType}`);
    }

    const output = transformOutputItemToPostVariantOutput(label, drawingTemplatePath);
    if (!output) {
      return;
    }

    if (event.target.checked) {
      return addOutput(output);
    }

    removeOutput(output);
  };

  const outputLabelsItem = generateModelStateInfo(output, selectedOutputs);
  if (!outputLabelsItem) {
    return null;
  }

  return (
    <FormGroup>
      {outputSectionTitle && <SettingsOutputTitle>{outputSectionTitle}</SettingsOutputTitle>}

      <FormControlLabel
        control={
          <CheckboxForOutputLabel
            {...(outputLabelsItem.drawingTemplatePath && {
              drawingTemplatePath: outputLabelsItem.drawingTemplatePath,
            })}
            label={outputLabelsItem}
            handleCheckboxUpdate={handleCheckboxUpdate}
          />
        }
        label={<OutputSettingsLabel outputLabel={outputLabelsItem} />}
      />
    </FormGroup>
  );
};
