import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';

export const AboutInfoMenuContainer = styled('div')`
  display: flex;
`;

export const StyledIconButton = styled(IconButton)`
  align-self: center;
`;

export const ListItemIconWrapper = styled('div')`
  display: flex;
  padding-right: ${({ theme }) => `${theme.var.paddingBase / 2}px`};
`;

export const BorderlessTableCell = styled(TableCell)`
  border: 0;
  padding: 0 0 ${({ theme }) => `${theme.var.paddingBase}px`} 0;
`;
