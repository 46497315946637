import { useState, useContext } from 'react';
import AccountProjectContext from 'context/AccountProjectStore/AccountProject.context';
import { type ViewIn3dProps } from './ViewIn3d';

type UseViewIn3dProps = {
  productRelease: ViewIn3dProps['productRelease'];
};

type UseViewIn3dReturn = {
  dialogOpen: boolean;
  handleViewIn3dClick: () => void;
  handleDialogClose: () => void;
  accountName: string | undefined;
  accountId: string | null;
  projectName: string | null;
  productReleaseName: string;
  productReleaseVersion: number;
};

export const useViewIn3d = ({ productRelease }: UseViewIn3dProps): UseViewIn3dReturn => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { accountDisplayName, projectName, accountId } = useContext(AccountProjectContext);

  const handleViewIn3dClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return {
    dialogOpen,
    handleViewIn3dClick,
    handleDialogClose,
    accountName: accountDisplayName,
    accountId,
    projectName,
    productReleaseName: productRelease.name,
    productReleaseVersion: productRelease.release,
  };
};
