import { ReactNode } from 'react';
import { detailsSidebarIds } from 'tests/helpers/dataTestIds';
import { DetailsSidebarContentWrapper } from './DetailsSidebar.style';

interface DetailsSidebarContentProps {
  children: ReactNode;
}

const DetailsSidebarContent: React.FC<DetailsSidebarContentProps> = ({ children }) => (
  <DetailsSidebarContentWrapper data-testid={detailsSidebarIds.detailsSidebarContent}>
    {children}
  </DetailsSidebarContentWrapper>
);

export default DetailsSidebarContent;
