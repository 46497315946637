import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ModalContext from '../../context/ModalStore/Modal.context';
import { DialogContentTextWrapper } from './ConfirmationModal.styles';
import { confirmationDialogTestIds } from '../../global/dataTestIds';
import CustomDialog from '../CustomDialog/CustomDialog';

interface ConfirmationModalProps {
  isAddin?: boolean;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isAddin = false }) => {
  const {
    isOpen,
    message,
    title,
    dontShowAgainMessage,
    dontShowAgain,
    cancelButtonLabel,
    confirmButtonLabel,
    disableConfirmButton,
    secondaryConfirmButtonLabel,
    disableSecondaryConfirmButton,
    handleConfirm,
    handleSecondaryConfirm,
    handleCancel,
    handleDontShowAgainClick,
  } = useContext(ModalContext);

  const callToActions: JSX.Element[] = [
    <Button
      key={confirmButtonLabel}
      variant="contained"
      onClick={handleConfirm}
      autoFocus
      disabled={disableConfirmButton}
      data-testid={confirmationDialogTestIds.confirmButton}
    >
      {confirmButtonLabel}
    </Button>,
    <Button
      key={cancelButtonLabel}
      variant="outlined"
      onClick={handleCancel}
      data-testid={confirmationDialogTestIds.cancelButton}
    >
      {cancelButtonLabel}
    </Button>,
  ];

  if (secondaryConfirmButtonLabel && handleSecondaryConfirm) {
    const secondaryConfirmButton = (
      <Button
        key={secondaryConfirmButtonLabel}
        variant="outlined"
        onClick={handleSecondaryConfirm}
        disabled={disableSecondaryConfirmButton}
        data-testid={confirmationDialogTestIds.secondaryConfirmButton}
      >
        {secondaryConfirmButtonLabel}
      </Button>
    );
    callToActions.splice(1, 0, secondaryConfirmButton);
  }

  return (
    <CustomDialog open={isOpen} title={title} customActions={callToActions} isAddin={isAddin}>
      <DialogContentTextWrapper>{message}</DialogContentTextWrapper>
      {dontShowAgainMessage && (
        <FormControlLabel
          control={<Checkbox checked={dontShowAgain} onChange={handleDontShowAgainClick} />}
          label={dontShowAgainMessage}
        />
      )}
    </CustomDialog>
  );
};
