import { ReactElement } from 'react';
import { createRoot } from 'react-dom/client';

import { useThemeStore } from 'stores/ThemeStore';
import themeConsts from 'theme/consts';
import { wrapAllReactContexts } from 'utils/common';

type Options = {
  title: string;
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  handleClose?: () => void;
};

export class DockingPanel extends Autodesk.Viewing.UI.DockingPanel {
  _viewer: Autodesk.Viewing.GuiViewer3D;

  constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: Options) {
    super(viewer.container, options.id, options.title);

    this._viewer = viewer;

    this._setContainerStyles(options);

    if (options.handleClose) {
      this.addVisibilityListener((show: boolean) => {
        if (!show) {
          options.handleClose!();
        }
      });
    }

    this._initialize();
  }

  _setContainerStyles(options: Options): void {
    this.container.style.height = options.height + 'px';
    this.container.style.width = options.width + 'px';
    this.container.style.resize = 'none';
    this.container.style.left = options.x + 'px';
    this.container.style.top = options.y + 'px';
    this.container.style.backgroundColor =
      useThemeStore.getState().theme === 'dark-theme'
        ? themeConsts.darkThemePanelBackground
        : themeConsts.lightThemePanelBackground;
  }

  _initialize(): void {
    this.content = document.createElement('div');

    this.container.appendChild(this.content);
    this.initializeMoveHandlers(this.container);
  }

  renderReactComponent(component: ReactElement): void {
    const reactRoot = createRoot(this.content);
    reactRoot.render(wrapAllReactContexts(component, this._viewer.container));
  }
}
