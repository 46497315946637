import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';

type ProductSelectionBoxProps = {
  expanded: boolean;
  disabled?: boolean;
};

export const ProductSelectDropDownContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.var.marginBase}px;
  align-items: center;
`;

export const ProductSelectionBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<ProductSelectionBoxProps>`
  width: ${({ theme, expanded }) => (expanded ? 'auto' : `${theme.var.productSelectDropdownWidth}px`)};
  min-width: ${({ theme }) => theme.var.productSelectDropdownWidth}px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.surface.lightGray[35]};
  border-style: solid;
  padding: ${({ theme }) => theme.var.paddingBase}px;
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  :hover {
    border-color: ${({ theme, disabled }) => (disabled ? theme.colors.surface.lightGray[30] : theme.colors.primary.black)};
  }
  display: flex;
  justify-content: ${({ disabled }) => (disabled ? 'flex-end' : 'space-between')};
`;

export const ProductSelectionMenuItem = styled(MenuItem)<{ minWidth?: number }>`
  min-width: ${({ minWidth }) => minWidth}px;
`;

export const ProductSelectionTypography = styled(Typography)`
  margin-top: auto;
  margin-bottom: auto;
`;

export const EventlessArrowDropDown = styled(ArrowDropDown)`
  pointer-events: none;
`;

export const EventlessArrowDropUp = styled(ArrowDropUp)`
  pointer-events: none;
`;

export const DisabledArrowDropDown = styled(ArrowDropDown)`
  color: ${({ theme }) => theme.colors.surface.lightGray[35]};
`;
