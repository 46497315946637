import { Spacing } from '@mid-react-common/common';
import Typography from '@mui/material/Typography';
import text from 'global/text.json';
import React from 'react';
import { LicensingTextWrapper, ThirdPartyComponentsList, ThirdPartyLicenseBlock } from '../../Licensing.styles';
import attributions from './ThirdPartyAttributionsText';

const licensingText = text.licensing;

const ThirdPartyAttributions: React.FC = () => (
  <>
    {Object.entries(attributions).map(([key, value]) => (
      <ThirdPartyLicenseBlock key={key}>
        <Typography gutterBottom>
          {licensingText.licensedUnder}{' '}
          <Typography component="span" fontWeight="bold">
            {value.licenseName}
          </Typography>{' '}
          :
        </Typography>
        <ThirdPartyComponentsList>
          {Object.entries(value.components).map(([key, value]) => (
            <>
              <Typography>{key}</Typography>
              <Typography whiteSpace="pre-line" variant="caption">
                {value}
              </Typography>
              <Spacing />
            </>
          ))}
          <LicensingTextWrapper className="mid-border-shadow mid-bg-shadow">
            <Typography whiteSpace="pre-line" fontFamily="monospace">
              {value.licenseText}
            </Typography>
          </LicensingTextWrapper>
        </ThirdPartyComponentsList>
      </ThirdPartyLicenseBlock>
    ))}
  </>
);

export default ThirdPartyAttributions;
