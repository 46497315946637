export class EmptyViewerModelLoader {
  private viewer: any;
  private readonly emptyModel: any;

  constructor(viewer: any) {
    this.viewer = viewer;
    this.emptyModel = new Autodesk.Viewing.Model({
      bbox: new THREE.Box3(new THREE.Vector3(-100, -100, -100), new THREE.Vector3(100, 100, 100)),
      fragments: {
        length: 1,
        fragId2dbId: {},
      },
      is2d: false,
      loadOptions: {},
    });
  }

  // @ts-ignore: noinspection JSUnusedGlobalSymbols
  public loadFile = (url: string, options: any, onDone: any, onWorkerStart: any): void => {
    if (onWorkerStart) {
      onWorkerStart();
    }
    this.emptyModel.initialize();
    onDone(null, this.emptyModel);
    this.viewer.api.fireEvent({
      type: Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT,
      model: this.emptyModel,
    });
    this.viewer.onLoadComplete(this.emptyModel);
  };

  // noinspection JSUnusedGlobalSymbols
  public is3d(): boolean {
    return true;
  }
}
