import { StateSetter } from '@mid-react-common/common';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import ProductContext from 'context/ProductStore/Product.context';
import { useContext } from 'react';
import { SelectionFilterIds } from 'tests/helpers/dataTestIds';
import { Instance } from 'types/product';
import { instancesVisibilityFilter } from '../../../../../global/constants/common';
import text from '../../../../../global/text.json';

const instancePanelText = text.instancePanel;

interface SelectionFilterProps {
  setSelectionFilter: StateSetter<string>;
  selectionFilter: string;
  selectedInstances?: Instance[];
}

const SelectionFilter: React.FC<SelectionFilterProps> = ({ setSelectionFilter, selectedInstances, selectionFilter }) => {
  const { dataGridInstances } = useContext(ProductContext);

  const filterInstancesByProductRelease = () => {
    const filteredInstances = selectedInstances?.filter((selectedInstance) =>
      dataGridInstances.some(
        (dataGridInstance) =>
          dataGridInstance.contentId === selectedInstance.contentId && dataGridInstance.release === selectedInstance.release,
      ),
    );
    return filteredInstances || [];
  };

  const countSelected = filterInstancesByProductRelease().length || 0;
  const allInstancesCount = dataGridInstances?.length || 0;
  const countUnselected = allInstancesCount - countSelected;

  const handleOnSelectionFilterChange = (_event: React.MouseEvent<HTMLElement>, value: string) => {
    setSelectionFilter(value);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={selectionFilter}
      exclusive
      onChange={handleOnSelectionFilterChange}
      size="small"
    >
      <ToggleButton
        value={instancesVisibilityFilter.SELECTED}
        data-testid={SelectionFilterIds.selectionFilterToggleSelected}
      >
        <Typography variant="body2" noWrap>
          {`${instancePanelText.selected} (${countSelected})`}
        </Typography>
      </ToggleButton>
      <ToggleButton
        value={instancesVisibilityFilter.NOT_SELECTED}
        data-testid={SelectionFilterIds.selectionFilterToggleNotSelected}
      >
        <Typography variant="body2" noWrap>
          {`${instancePanelText.notSelected} (${countUnselected})`}
        </Typography>
      </ToggleButton>
      <ToggleButton value={instancesVisibilityFilter.ALL} data-testid={SelectionFilterIds.selectionFilterToggleAll}>
        <Typography variant="body2" noWrap>
          {`${instancePanelText.all} (${allInstancesCount})`}
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default SelectionFilter;
