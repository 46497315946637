import { styled, css } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';

export const DialogWrapper = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'isAddin',
})<{ height?: number; isAddin?: boolean }>`
  ${({ height }) =>
    height &&
    css(`
      & .MuiPaper-root {
        height: ${height}px;
      }
    `)}
  ${({ isAddin }) =>
    isAddin &&
    css(`
      user-select: none;
    `)}
`;
