import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import Check from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export const RFOStepIconRoot = styled(Box)<{ ownerState: { active?: boolean; error?: boolean; completed?: boolean } }>`
  width: ${({ theme }) => theme.var.rfoContainerSize}px;
  height: ${({ theme }) => theme.var.rfoContainerSize}px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  z-index: 1;

  border: ${({ theme }) => theme.var.rfoStepperLineThickness}px solid ${({ theme }) => theme.palette.text.secondary};

  ${({ ownerState, theme }) =>
    ownerState.active &&
    `
    color: ${theme.palette.primary.main};
    background-color: ${theme.palette.background.default};
    border:  ${theme.var.rfoStepperLineThickness}px solid ${theme.palette.primary.main};
  `}
  ${({ ownerState, theme }) =>
    ownerState.error &&
    `
    color: ${theme.palette.error.main};
    background-color: ${theme.palette.background.default};
    border: ${theme.var.rfoStepperLineThickness}px solid ${theme.palette.error.main};
  `}
  ${({ ownerState, theme }) =>
    ownerState.completed &&
    `
    background-color: ${theme.palette.primary.main};
    border: ${theme.var.rfoStepperLineThickness}px solid ${theme.palette.primary.main};
  `}
`;

export const RFOCompletedIcon = styled(Check)`
  width: ${({ theme }) => theme.var.rfoIconSize}px;
  color: ${({ theme }) => theme.palette.background.default};
`;
export const RFOErrorIcon = styled(PriorityHighIcon)`
  width: ${({ theme }) => theme.var.rfoIconSize}px;
  color: ${({ theme }) => theme.palette.error.main};
`;

export const StepperConnector = styled(StepConnector)`
  &.${stepConnectorClasses.alternativeLabel} {
    top: ${({ theme }) => theme.var.marginBase}px;
    left: ${({ theme }) => `calc(-50% + ${theme.var.marginBase}px)`};
    right: ${({ theme }) => `calc(50% + ${theme.var.marginBase}px)`};
  }
  &.${stepConnectorClasses.active} {
    & .${stepConnectorClasses.line} {
      border-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
  &.${stepConnectorClasses.completed} {
    & .${stepConnectorClasses.line} {
      border-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
  & .${stepConnectorClasses.line} {
    border-color: ${({ theme }) => theme.palette.text.secondary};
    border-top-width: ${({ theme }) => theme.var.rfoStepperLineThickness}px;
  }
`;

export const StepperLabel = styled(StepLabel)`
  & .${stepLabelClasses.alternativeLabel} {
    & .${stepLabelClasses.active} {
      font-weight: bolder;
    }
  }
`;
