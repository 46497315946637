import { LogLevel } from '../../interfaces/log';
import { browserApiService, webview2BrowserApiService } from '../../services';

export const getAssemblyVersion = (): Promise<string> => browserApiService?.getAssemblyVersion();

export const openMidWebapp = async (): Promise<boolean> => {
  const result = await browserApiService?.openExternalUrl(await browserApiService?.getMIDWebAppUrl());
  if (!result.value) {
    throw new Error(`${result.errorMessage}`);
  }
  return result.value;
};

export const getApplicationVersionNumber = (): Promise<string> => browserApiService?.getApplicationVersionNumber();

export const saveToFile = async (content: string, fileName: string, fileExtension: string): Promise<string> => {
  const result = await browserApiService?.saveToFile(content, fileName, fileExtension);
  if (!result.value) {
    throw new Error(`${result.errorMessage}`);
  }
  return result.value;
};

export const logToFile = async (message: string, logLevel: LogLevel): Promise<void> => {
  await browserApiService.logToFile(message, logLevel);
};

export const resizeHostWindow = (width: number, height: number): Promise<void> =>
  webview2BrowserApiService.resizeWindow(width, height);

export const encodeToBase64 = (stringToEncode: string): string => {
  const encoder = new TextEncoder();
  const data = encoder.encode(stringToEncode);
  return window.btoa(String.fromCharCode(...data));
};
