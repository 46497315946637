import { useTheme } from '@mui/material/styles';
import {
  HigFolder,
  HigFolderOpen,
  FilledFolderOpen,
  FilledFolder,
  LockedFolderOpen,
  LockedFolder,
} from '@mid-react-common/common';
import { foldersTreeTestIds } from 'tests/helpers/dataTestIds';
interface FolderIconProps {
  isExpanded: boolean;
  folderUrn: string;
  editPermissionLookup?: Record<string, boolean>;
}

const FolderIcon: React.FC<FolderIconProps> = ({ isExpanded, folderUrn, editPermissionLookup }) => {
  const theme = useTheme();

  if (!editPermissionLookup) {
    return isExpanded ? (
      <HigFolderOpen data-testid={foldersTreeTestIds.folderOpenIcon} />
    ) : (
      <HigFolder data-testid={foldersTreeTestIds.folderIcon} />
    );
  }

  if (editPermissionLookup[folderUrn]) {
    return isExpanded ? (
      <FilledFolderOpen fill={theme.colors.primary.charcoal[600]} data-testid={foldersTreeTestIds.filledFolderOpenIcon} />
    ) : (
      <FilledFolder fill={theme.colors.primary.charcoal[600]} data-testid={foldersTreeTestIds.filledFolderIcon} />
    );
  }

  return isExpanded ? (
    <LockedFolderOpen fill={theme.colors.primary.charcoal[600]} data-testid={foldersTreeTestIds.lockedFolderOpenIcon} />
  ) : (
    <LockedFolder fill={theme.colors.primary.charcoal[600]} data-testid={foldersTreeTestIds.lockedFolderIcon} />
  );
};

export default FolderIcon;
