import ProductContext from 'context/ProductStore/Product.context';
import useRFOModalStore from 'context/RFOModalStore/useRFOModalStore';
import BillOfMaterials from './BillOfMaterials/BillOfMaterial';
import Drawing2D from './Drawing2D/Drawing2D';
import Model3D from './Model3D/Model3D';
import NeutralFormat from './NeutralFormat/NeutralFormat';
import { useContext, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { isBomBasedOutput, isDrawingTemplateBasedOutput, isModelBasedOutput, isNeutralFormatBasedOutput } from 'mid-utils';
import { getAllOutputs } from './FileTypesStep.utils';

const FileTypesStep: React.FC = () => {
  const { selectedProductRelease, selectedInstances } = useContext(ProductContext);
  const { localSelectedProductRelease, resetAllOutputs, setLocalSelectedProductRelease } = useRFOModalStore(
    useShallow((state) => ({
      allOutputs: state.allOutputs,
      localSelectedProductRelease: state.selectedProductRelease,
      setLocalSelectedProductRelease: state.setSelectedProductRelease,
      resetAllOutputs: state.resetAllOutputs,
    })),
  );

  const outputs = getAllOutputs(selectedProductRelease, selectedInstances);
  const outputsTypeModel3D = outputs.filter(isModelBasedOutput);
  const outputsTypeDrawing = outputs.filter(isDrawingTemplateBasedOutput);
  const outputsTypeBom = outputs.filter(isBomBasedOutput);
  const outputsTypeNeutralFormat = outputs.filter(isNeutralFormatBasedOutput);

  // We need to reset all outputs when the selected product release changes
  // because outputs can be different for between product releases
  useEffect(() => {
    if (
      selectedProductRelease?.contentId !== localSelectedProductRelease?.contentId ||
      selectedProductRelease?.release !== localSelectedProductRelease?.release
    ) {
      resetAllOutputs();
      setLocalSelectedProductRelease(selectedProductRelease);
    }
  }, [localSelectedProductRelease, resetAllOutputs, selectedProductRelease, setLocalSelectedProductRelease]);

  return (
    <>
      {outputsTypeModel3D.length > 0 && <Model3D />}
      {outputsTypeDrawing.length > 0 && <Drawing2D outputsTypeDrawing={outputsTypeDrawing} />}
      {outputsTypeBom.length > 0 && <BillOfMaterials outputsTypeBom={outputsTypeBom} />}
      {outputsTypeNeutralFormat.length > 0 && <NeutralFormat outputsTypeNeutralFormat={outputsTypeNeutralFormat} />}
    </>
  );
};

export default FileTypesStep;
