import { NotificationContext } from '@mid-react-common/common';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import GatekeeperContext from 'context/GatekeeperStore/Gatekeeper.context';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import UserContext from '../../../context/UserStore/User.context';
import text from '../../../global/text.json';
import {
  UserInfoContainer,
  UserLogoutIcon,
  UserMenuContainer,
  UserSettingsAvatar,
  UserSettingsContents,
  UserSettingsHeaderAvatar,
  UserSettingsHeaderContainer,
  UserSettingsIcon,
  UserSettingsPopoverContainer,
} from './UserSettingsPanel.style';

const userSettingsText = text.userSettingsPanel;

function UserSettingsPanel(): JSX.Element {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { handleLogout } = useContext(GatekeeperContext);
  const { userInfoLoading, userInfo, setProfile, handleProfileSettingClick } = useContext(UserContext);

  const { logAndShowNotification } = useContext(NotificationContext);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const setProfileCallback = useCallback(async () => {
    try {
      await setProfile();
    } catch (error) {
      logAndShowNotification({
        message: userSettingsText.error,
        error,
      });
    }
  }, [logAndShowNotification, setProfile]);

  useEffect(() => {
    setProfileCallback();
  }, [setProfileCallback]);

  return userInfoLoading ? (
    <CircularProgress />
  ) : (
    <>
      <UserSettingsHeaderContainer onClick={handleOpenUserMenu} data-testid="user-settings">
        <UserSettingsHeaderAvatar src={userInfo?.profileImg50} />
      </UserSettingsHeaderContainer>
      <Popover
        open={Boolean(anchorElUser)}
        anchorEl={anchorElUser}
        onClose={handleCloseUserMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <UserSettingsPopoverContainer>
          <UserInfoContainer>
            <UserSettingsAvatar alt={userInfo?.name} src={userInfo?.profileImg50} />
            <UserSettingsContents>
              <Typography variant="h6">{userInfo?.name}</Typography>
              <Typography variant="body2">{userInfo?.email}</Typography>
            </UserSettingsContents>
          </UserInfoContainer>
        </UserSettingsPopoverContainer>
        <UserMenuContainer>
          <Button onClick={handleProfileSettingClick}>
            <UserSettingsIcon />
            {userSettingsText.profileSettings}
          </Button>
          <Button onClick={handleLogout}>
            <UserLogoutIcon />
            {userSettingsText.logout}
          </Button>
        </UserMenuContainer>
      </Popover>
    </>
  );
}

export default UserSettingsPanel;
