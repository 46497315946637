// TODO: remove this file completely in a separate refactoring ticket TRADES-5849
// https://jira.autodesk.com/browse/TRADES-5849
// this types file should be live close to the Notification component
export type HandleCloseReason = 'timeout' | 'clickaway' | 'escapeKeyDown';

export enum NOTIFICATION_STATUSES {
  NONE = 'none',
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}
