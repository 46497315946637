import { Instance } from 'types/product';
import { ProductInstanceAccBridgeMetadata, VariantInstanceAccBridgeMetadata } from 'types/bridge';

export interface AccBridgeData {
  targetProjectId: string;
  sourceProjectId?: string;
  sourceFolderUrn?: string;
}

export const getBridgeDataFromInstance = (
  instanceParameters: VariantInstanceAccBridgeMetadata | ProductInstanceAccBridgeMetadata | Instance,
): AccBridgeData => {
  if (
    instanceParameters.targetProjectId &&
    instanceParameters.projectId &&
    instanceParameters.folderUrn &&
    instanceParameters.targetProjectId !== instanceParameters.projectId
  ) {
    // If targetProjectId is not same as projectId, then it is a bridged variant
    // We need to have sourceFolderUrn and sourceProjectId to look up variant from bridge automation
    // folderUrn might come in the format of 'urn-1/urn-2/urn-3'
    return {
      targetProjectId: instanceParameters.targetProjectId,
      sourceFolderUrn: instanceParameters.folderUrn,
      sourceProjectId: instanceParameters.projectId,
    };
  }

  // If targetProjectId is the same as projectId, then we don't need additional params to look up bridge automation
  // This is also backwards compatible for the models that do not have targetProjectId and folderUrn
  return {
    targetProjectId: instanceParameters.targetProjectId || instanceParameters.projectId,
  };
};

export const transformInstancesToVariantBridgeMap = (
  instances: Instance[],
): Map<string, VariantInstanceAccBridgeMetadata> => {
  const variantMap = new Map<string, VariantInstanceAccBridgeMetadata>();

  instances.forEach((instance) => {
    if (!variantMap.has(instance.variantId)) {
      variantMap.set(instance.variantId, {
        variantId: instance.variantId,
        projectId: instance.projectId,
        targetProjectId: instance.targetProjectId,
        folderUrn: instance.folderUrn,
      });
    }
  });

  return variantMap;
};

export const getBridgeAutomationFromVariantId = (
  variantId: string,
  VariantInstanceAccBridgeMetadataMap?: Map<string, VariantInstanceAccBridgeMetadata>,
): AccBridgeData | undefined => {
  const instanceBridgeMetadata = VariantInstanceAccBridgeMetadataMap?.get(variantId);
  return instanceBridgeMetadata ? getBridgeDataFromInstance(instanceBridgeMetadata) : undefined;
};
