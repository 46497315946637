import { Vendors } from '@adsk/offsite-dc-sdk';
import { AccProjectPlatform, accProjectPlatform } from 'mid-types';

export const vendorPlatforms: Record<AccProjectPlatform, Vendors> = {
  [accProjectPlatform.acc]: Vendors.ACC,
  [accProjectPlatform.bim360]: Vendors.BIM360,
};

export const BIM360LocalStorageKeys = {
  SELECTED_PROJECT_ID: 'selected-project-id',
};
