import { useEffect, useState } from 'react';
import { AlertColor } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useTheme, Theme } from '@mui/material/styles';
import { notificationTestIds } from '../../global/dataTestIds';
import { NotificationTitle, StyledSnackbar } from './Notication.styles';
import { AnchorHorizontalPosition, AnchorVerticalPosition } from './notification.constants';
import { HandleCloseReason } from './notification.types';

// Calculate the top offset for the snackbar based on the sequence number (to stack the notifications)
function calculateTopOffset(theme: Theme, seqNumber: number) {
  const { notificationSnackBarHeight, notificationSnackBarInitialTopOffset, notificationSnackBarsGap } = theme.var;

  return notificationSnackBarInitialTopOffset + seqNumber * (notificationSnackBarHeight + notificationSnackBarsGap);
}

interface NotificationItemProps {
  id: string;
  seqNumber: number;
  message: string;
  severity: AlertColor;
  messageBody?: string | JSX.Element | null;
  anchor: { vertical: AnchorVerticalPosition; horizontal: AnchorHorizontalPosition };
  handleClose: (event?: React.SyntheticEvent | Event, reason?: HandleCloseReason) => void;
}

const MIDNotification: React.FC<NotificationItemProps> = ({
  seqNumber,
  handleClose,
  anchor,
  severity,
  message,
  messageBody,
}) => {
  const theme = useTheme();

  const [topOffset, setTopOffset] = useState(calculateTopOffset(theme, seqNumber));

  useEffect(() => {
    setTopOffset(calculateTopOffset(theme, seqNumber));
  }, [seqNumber, theme]);

  return (
    <StyledSnackbar
      // the current implementation supports only top right position of the snackbar
      // add support for other positions if needed
      topOffset={topOffset}
      open
      data-testid={notificationTestIds.notificationSnackbar}
      onClose={handleClose}
      anchorOrigin={anchor}
      key={message}
      // remove transition to avoid flickering when multiple notifications are shown
      TransitionProps={{ appear: false }}
    >
      <Alert onClose={handleClose} severity={severity}>
        {messageBody ? (
          <>
            <NotificationTitle>{message}</NotificationTitle>
            {messageBody}
          </>
        ) : (
          message
        )}
      </Alert>
    </StyledSnackbar>
  );
};
export default MIDNotification;
