import { CustomError } from '../customError';
import { ErrorCode } from '../errorCode';

export class BIM360AccountsFetchError extends CustomError {
  errorCode = ErrorCode.BIM360AccountsFetchError;

  constructor(message: string) {
    super(message);
  }
}
