import { inversifyContainer, DcApiService, InversifyTypes } from 'mid-api-services';
import { DCProductUIExtension, GetAllProductReleasesResult } from 'mid-types';
import { processAllSettledResults } from 'mid-utils';
import { ProductRelease } from '@adsk/offsite-dc-sdk';
import { getBridgeDataFromInstance } from 'utils/bridge';
import { ProductInstanceAccBridgeMetadata } from 'types/bridge';

export const getAllProductReleases = async (
  productInstanceList: ProductInstanceAccBridgeMetadata[],
): Promise<GetAllProductReleasesResult | null> => {
  if (productInstanceList.length === 0) {
    return null;
  }

  const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
  //loop though all products and get all releases
  const resultsPromise = productInstanceList.map((params): Promise<DCProductUIExtension<ProductRelease>[]> => {
    const incomingAccBridgeData = getBridgeDataFromInstance(params);

    return dcApiService.getProductReleasesList({
      projectId: params.projectId,
      productId: params.productId,
      incomingAccBridgeData,
    });
  });

  const allSettledResult = await Promise.allSettled<DCProductUIExtension<ProductRelease>[]>(resultsPromise);
  return processAllSettledResults(allSettledResult);
};
