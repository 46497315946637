import { Spacing } from '@mid-react-common/common';
import Divider from '@mui/material/Divider';
import OutputsTable from './OutputsTable/OutputsTable';
import UploadLocationInfo from './UploadLocationInfo';
import CheckoutLoadingSkeleton from './CheckoutSkeletons/CheckoutLoadingSkeleton';
import useRFOModalStore from '../../../../../context/RFOModalStore/useRFOModalStore';

const CheckoutStep: React.FC = () => {
  const isCheckoutStepLoading = useRFOModalStore((state) => state.isCheckoutStepLoading);
  return (
    <>
      {isCheckoutStepLoading ? (
        <CheckoutLoadingSkeleton />
      ) : (
        <>
          <UploadLocationInfo />
          <Spacing />
          <Divider />
          <Spacing />
          <OutputsTable />
        </>
      )}
    </>
  );
};

export default CheckoutStep;
