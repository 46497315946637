import Stack from '@mui/material/Stack';
import { KeyValueStackItem, KeyValueStackLabel, KeyValueStackValue } from './KeyValueStack.styles';

export interface KeyValueStackItem {
  label: string | JSX.Element;
  value?: any;
}

export interface KeyValueStackProps {
  data: KeyValueStackItem[];
}

export const KeyValueStack = ({ data }: KeyValueStackProps): JSX.Element => (
  <Stack>
    {data.map((row, idx) => (
      <KeyValueStackItem key={`stack-item-${row.label}-${idx}`}>
        <KeyValueStackLabel>{row.label}</KeyValueStackLabel>

        <KeyValueStackValue>{row.value}</KeyValueStackValue>
      </KeyValueStackItem>
    ))}
  </Stack>
);

export default KeyValueStack;
