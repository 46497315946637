import { logError } from 'mid-utils';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { NOTIFICATION_STATUSES } from '../components/Notification/notification.types';
import NotificationContext from '../context/NotificationStore/Notification.context';

export const useLogAndShowNotification = (error: any, notificationMessage: string): void => {
  const { showNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (!error) {
      return;
    }
    logError(error);
    showNotification({
      message: notificationMessage,
      severity: NOTIFICATION_STATUSES.ERROR,
    });
  }, [error, notificationMessage, showNotification]);
};

type CancellablePromise<T = any> = {
  promise: Promise<T>;
  cancel: () => void;
};

function makeCancellable<T = any>(promise: Promise<T>): CancellablePromise<T> {
  let isCancelled = false;
  const wrappedPromise = new Promise<T>((resolve, reject) => {
    // Suppress resolution and rejection if canceled
    // Propagate promise rejection only if the reason is not cancelling
    promise.then((val) => !isCancelled && resolve(val)).catch((error) => !isCancelled && reject(error));
  });
  return {
    promise: wrappedPromise,
    cancel() {
      isCancelled = true;
    },
  };
}

type CancellablePromiseHookReturnType = <T = any>(p: Promise<T>) => Promise<T>;

export function useCancellablePromise(): CancellablePromiseHookReturnType {
  // think of useRef as member variables inside a hook
  // you cannot define promises here as an array because
  // they will get initialized at every render refresh
  const promises = useRef<CancellablePromise[]>();
  // useEffect initializes the promises array
  // and cleans up by calling cancel on every stored
  // promise.
  // Empty array as input to useEffect ensures that the hook is
  // called once during mount and the cancel() function called
  // once during unmount
  useEffect(() => {
    promises.current = promises.current || [];

    return function cancel() {
      promises.current!.forEach((p) => p.cancel());
      promises.current = [];
    };
  }, []);

  // cancellablePromise remembers the promises that you
  // have called so far. It returns a wrapped cancellable
  // promise
  return useCallback((p) => {
    const cPromise = makeCancellable(p);
    promises.current!.push(cPromise);
    return cPromise.promise;
  }, []);
}
