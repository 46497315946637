import { ErrorCode } from './errorCode';
import { CustomError } from './customError';

export class EnvError extends CustomError {
  errorCode = ErrorCode.EnvError;

  constructor(message: string) {
    super(message);
  }
}
