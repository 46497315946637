import { styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

export const BreadcrumbsWrapper = styled(Breadcrumbs)`
  width: calc(100% + ${({ theme }) => theme.var.paddingBase}px);
  position: relative;
  left: -${({ theme }) => theme.var.paddingBase}px;
  padding: ${({ theme }) => theme.var.paddingBase}px;
  margin-bottom: ${({ theme }) => theme.var.marginBase}px;
`;

export const LinkWithPointerCursor = styled(Link)`
  cursor: pointer;
`;
