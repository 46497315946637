import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Cancel';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import text from 'global/text.json';
import { Spacing } from '@mid-react-common/common';
import { rfoModalTestIds } from 'tests/helpers/dataTestIds';
import { useTheme } from '@mui/material/styles';
const fileTypesStepText = text.rfoModal.fileTypesStep;

interface NeutralFormatDropdownProps {
  label: string;
  modelStates: string[];
  selectedModelStates: string[];
  handleOnChange: (event: SelectChangeEvent<string[]>) => void;
  handleDelete: (event: any, value: string) => void;
}

const NeutralFormatDropdown: React.FC<NeutralFormatDropdownProps> = ({
  label,
  modelStates,
  selectedModelStates,
  handleOnChange,
  handleDelete,
}) => {
  const theme = useTheme();
  return (
    <FormControl fullWidth size="small">
      <InputLabel>{label}</InputLabel>
      <Select
        data-testid={`${rfoModalTestIds.fileTypesStep.neutralFormatDropdowns}-${label}`}
        label={label}
        variant="outlined"
        size="small"
        fullWidth
        multiple
        value={selectedModelStates}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: `${theme.var.gapBase / 2}px` }}>
            {selected.map((value) => (
              <Chip
                data-testid={`${label}-${rfoModalTestIds.fileTypesStep.neutralFormatOutputChip}-${value}`}
                key={value}
                label={value}
                deleteIcon={
                  <CancelIcon
                    data-testid={`${label}-${rfoModalTestIds.fileTypesStep.chipRemoveButton}-${value}`}
                    onMouseDown={(e) => e.stopPropagation()}
                  />
                }
                onDelete={(e) => handleDelete(e, value)}
              />
            ))}
          </Box>
        )}
        onChange={handleOnChange}
      >
        {modelStates.length > 1 && (
          <MenuItem value={fileTypesStepText.all} data-testid={rfoModalTestIds.fileTypesStep.allNeutralFormatMenuItem}>
            <Checkbox
              checked={modelStates.length === selectedModelStates.length}
              indeterminate={selectedModelStates.length > 0 && selectedModelStates.length < modelStates.length}
            />
            <ListItemText primary={fileTypesStepText.allRepresentations} />
          </MenuItem>
        )}
        {modelStates.length > 1 && <Divider />}
        {modelStates.map((modelState, index) => (
          <MenuItem
            data-testid={`${rfoModalTestIds.fileTypesStep.neutralFormatOutputMenuItem}-${modelState}`}
            key={index}
            value={modelState}
          >
            <Checkbox checked={selectedModelStates.includes(modelState)} />
            <ListItemText primary={modelState} />
          </MenuItem>
        ))}
      </Select>
      <Spacing />
    </FormControl>
  );
};

export default NeutralFormatDropdown;
