import { authService } from '@mid-react-common/common';
import { getReactAppEnvironment, UserInfoError, ServiceConfigMap } from 'mid-utils';
import { useCallback, useState } from 'react';
import text from '../../global/text.json';
import { ExtendedUserInfo } from 'mid-types';

export interface UserStore {
  userInfoLoading: boolean;
  userInfo?: ExtendedUserInfo;
  setProfile: () => Promise<void>;
  handleProfileSettingClick: () => void;
}

export const useUserStore = (): UserStore => {
  const [userInfoLoading, setUserInfoLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<ExtendedUserInfo | undefined>(undefined);
  const userStoreText = text.userStore;

  const setProfile = useCallback(async (): Promise<void> => {
    try {
      setUserInfoLoading(true);
      const res = await authService.getUserInfo();
      setUserInfo(res);
      setUserInfoLoading(false);
    } catch (e) {
      throw new UserInfoError(userStoreText.failedToSetProfile, { error: e });
    }
  }, [userStoreText.failedToSetProfile]);

  const handleProfileSettingClick = (): void => {
    const currentEnv = getReactAppEnvironment();
    window.location.href = ServiceConfigMap.PROFILE_SETTING_API[currentEnv].api;
  };

  return {
    userInfoLoading,
    userInfo,
    setProfile,
    handleProfileSettingClick,
  };
};
