import { InputType } from '@adsk/offsite-dc-sdk';
import { InventorProperties } from '../../interfaces/inventorProperties';
import { ProductDefinitionInputParameter, ProductDefinitionInputProperties } from '../../interfaces/productDefinitions';
import { toProductDefinitionInputParameter } from '../../utils/sharedServices/productDefinitionUtils';

export const mockProperties: InventorProperties = {
  parameters: [
    {
      name: 'StudSpacing',
      parameterType: 'User',
      unitType: 'ft',
      value: '1.3333333333333333',
      isKey: false,
    },
    {
      name: 'StudCountRight',
      parameterType: 'User',
      unitType: 'ul',
      value: '9',
      isKey: false,
    },
    {
      name: 'WallHeight',
      parameterType: 'User',
      unitType: 'ft',
      value: '8',
      isKey: true,
    },
    {
      name: 'WallWidth',
      parameterType: 'User',
      unitType: 'ft',
      value: '15',
      isKey: true,
    },
    {
      name: 'EqualCheck',
      parameterType: 'User',
      unitType: 'Boolean',
      value: 'True',
      isKey: false,
      options: ['True', 'False'],
    },
    {
      name: 'MV_Text',
      parameterType: 'User',
      unitType: 'Text',
      value: '"Red"',
      isKey: true,
      options: ['"Blue"', '"Green"', '"Red"'],
    },
    {
      name: 'd22',
      parameterType: 'Model',
      unitType: 'in',
      value: '2',
      isKey: false,
    },

    {
      name: 'd24',
      parameterType: 'Model',
      unitType: 'ul',
      value: '1',
      isKey: false,
    },

    {
      name: 'd50',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd51',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd52',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd53',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd54',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd55',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd56',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd57',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd58',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'Stock',
      parameterType: 'User',
      unitType: 'Text',
      value: '"2x6"',
      isKey: true,
    },

    {
      name: 'd59',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd60',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd61',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'StudSpacingMax',
      parameterType: 'User',
      unitType: 'in',
      value: '16',
      isKey: false,
    },

    {
      name: 'ProjectName',
      parameterType: 'User',
      unitType: 'Text',
      value: '"Office Building"',
      isKey: false,
    },
    {
      name: 'RevitElementID',
      parameterType: 'User',
      unitType: 'Text',
      value: '"W01679"',
      isKey: false,
    },
    {
      name: 'MV_Numeric',
      parameterType: 'User',
      unitType: 'ft',
      value: '1',
      isKey: true,
      options: [1, 2, 3],
    },
  ],
};

export const mockPropertiesString = JSON.stringify(mockProperties);

export const mockPropertiesInventor1: InventorProperties = {
  parameters: [
    {
      name: 'StudSpacing',
      parameterType: 'User',
      unitType: 'ft',
      value: '1.3333333333333333',
      isKey: false,
    },
    {
      name: 'WallHeight',
      parameterType: 'User',
      unitType: 'ft',
      value: '10',
      isKey: true,
    },
    {
      name: 'WallWidth',
      parameterType: 'User',
      unitType: 'ft',
      value: '11',
      isKey: true,
    },
    {
      name: 'EqualCheck',
      parameterType: 'User',
      unitType: 'Boolean',
      value: 'True',
      isKey: false,
      options: ['True', 'False'],
    },
    {
      name: 'MV_Text',
      parameterType: 'User',
      unitType: 'Text',
      value: '"Red"',
      isKey: true,
      options: ['"Blue"', '"Green"', '"Red"'],
    },
  ],
};

export const mockPropertiesProductDefinition1: ProductDefinitionInputProperties = {
  parameters: mockProperties.parameters.slice(3, 5).map(toProductDefinitionInputParameter),
};
export const mockPropertiesProductDefinition1Inputs: ProductDefinitionInputParameter[] = [
  ...mockPropertiesProductDefinition1.parameters,
];

export const mockPropertiesProductDefinition2: ProductDefinitionInputProperties = {
  parameters: mockProperties.parameters.slice(4, 9).map(toProductDefinitionInputParameter),
};

// Below is the mockProducts[0] inputs data (from MockProducts.ts) transformed into ProductDefinition inputs data
// Used for testing DownloadProductDefinition feature
export const mockPropertiesProductDefinition3: ProductDefinitionInputProperties = {
  parameters: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      readOnly: false,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Has Door - Controlling Input',
      name: 'HasDoorControllingInput',
      readOnly: false,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
      onChange: [
        {
          dependsOn: {
            bindings: {
              dependentValue: 'Width.visible',
              dependsOn: 'HasDoorControllingInput.value',
            },
          },
        },
      ],
    },
    {
      label: 'Has Door - Read Only',
      name: 'HasDoorReadOnly',
      readOnly: true,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      increment: 6,
      label: 'Door Width',
      max: 48,
      min: 32,
      name: 'Width',
      readOnly: false,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
      allowCustomValue: true,
    },
    {
      increment: 6,
      label: 'Door Width - Read Only',
      max: 48,
      min: 32,
      name: 'WidthReadOnly',
      readOnly: true,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Numeric',
      name: 'MockMultiValueNumeric',
      readOnly: false,
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      values: [1, 2, 3],
      value: 2,
      onChange: [],
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Numeric - Read Only',
      name: 'MockMultiValueNumericReadOnly',
      readOnly: true,
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      values: [1, 2, 3],
      value: 2,
      onChange: [],
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Text',
      name: 'MockMultiValueText',
      readOnly: false,
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text2',
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Text - Read Only',
      name: 'MockMultiValueTextReadOnly',
      readOnly: true,
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text2',
      allowCustomValue: true,
    },
  ],
};

export const mockPropertiesInventor2: InventorProperties = {
  parameters: [
    {
      isKey: true,
      name: 'Height',
      options: ['30', '42', '54', '66'],
      parameterType: 'User',
      unitType: 'in',
      value: '54',
    },
    {
      isKey: true,
      name: 'Width',
      parameterType: 'User',
      unitType: 'in',
      value: '30',
    },
    {
      isKey: true,
      name: 'Depth',
      options: ['10', '11', '12', '13.000000000000002', '14', '15', '16', '17', '18'],
      parameterType: 'User',
      unitType: 'in',
      value: '14',
    },
    {
      isKey: false,
      name: 'Thickness',
      parameterType: 'User',
      unitType: 'in',
      value: '1',
    },
    {
      isKey: true,
      name: 'Material',
      options: [
        '"Laminated Veneer Lumber"',
        '"Particleboard"',
        '"Ash"',
        '"Birch"',
        '"Mahogany"',
        '"Maple"',
        '"Oak, Red"',
        '"Pine"',
        '"Plywood, Finish"',
        '"Spruce"',
        '"Walnut"',
      ],
      parameterType: 'User',
      unitType: 'Text',
      value: '"Oak, Red"',
    },
    {
      isKey: true,
      name: 'Shelves_Qty',
      options: ['1', '2', '3'],
      parameterType: 'User',
      unitType: 'ul',
      value: '3',
    },
    {
      isKey: false,
      name: 'd0',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd1',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd2',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd3',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd4',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd5',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd6',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd7',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd8',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd9',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd10',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd11',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd12',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd13',
      parameterType: 'Model',
      unitType: 'deg',
      value: '0',
    },
    {
      isKey: false,
      name: 'd14',
      parameterType: 'Model',
      unitType: 'deg',
      value: '0',
    },
    {
      isKey: false,
      name: 'd15',
      parameterType: 'Model',
      unitType: 'deg',
      value: '0',
    },
    {
      isKey: false,
      name: 'd16',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd17',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd18',
      parameterType: 'Model',
      unitType: 'in',
      value: '2',
    },
    {
      isKey: false,
      name: 'd19',
      parameterType: 'Model',
      unitType: 'in',
      value: '12',
    },
    {
      isKey: false,
      name: 'd20',
      parameterType: 'Model',
      unitType: 'ul',
      value: '1',
    },
    {
      isKey: false,
      name: 'MID_Publish_Mode',
      options: ['True', 'False'],
      parameterType: 'User',
      unitType: 'Boolean',
      value: 'True',
    },
  ],
};
