export default {
  // Surface colors - https://weave.autodesk.com/web/basics/colors-surfaces
  surface: {
    lightGray: {
      10: '#ffffff',
      20: '#f5f5f5',
      25: '#eeeeee',
      30: '#d9d9d9',
      35: '#cccccc',
    },
    darkBlue: {
      10: '#454f61',
      20: '#3b4453',
      25: '#2e3440',
      30: '#222933',
      35: '#1a1f26',
    },
    darkGray: {
      10: '#535353',
      20: '#474747',
      25: '#373737',
      30: '#2a2a2a',
      35: '#202020',
    },
  },
  // Mains Colors - https://weave.autodesk.com/web/basics/colors-main
  primary: {
    charcoal: {
      100: '#eeeeee',
      200: '#dcdcdc',
      300: '#cccccc',
      400: '#bbbbbb',
      500: '#999999',
      600: '#808080',
      700: '#666666',
      800: '#4d4d4d',
      900: '#3c3c3c',
    },
    autodeskBlue: {
      100: '#cdeaf7',
      200: '#9bd5ef',
      300: '#6ac0e7',
      400: '#38abdf',
      500: '#0696d7',
      600: '#007fc6',
      700: '#006EAF',
      800: '#074b78',
      900: '#0a324d',
    },
    black: '#000000',
    white: '#ffffff',
  },
  secondary: {
    darkBlue: {
      100: '#e1ebf7',
      200: '#bcd3ee',
      300: '#8babdc',
      400: '#5f8bcb',
      500: '#3970b9',
      600: '#1858a8',
      700: '#064285',
      800: '#003163',
      900: '#0c2c54',
    },
    turquoise: {
      100: '#d6f2ef',
      200: '#ade4de',
      300: '#84d7ce',
      400: '#5bc9bd',
      500: '#32bcad',
      600: '#23a597',
      700: '#168576',
      800: '#0c665b',
      900: '#04403d',
    },
    green: {
      100: '#e7f2d9',
      200: '#cfe4b3',
      300: '#b7d78c',
      400: '#9fc966',
      500: '#87b340',
      600: '#6a9728',
      700: '#507b16',
      800: '#385e08',
      900: '#234200',
    },
    yellowOrange: {
      100: '#feecd1',
      200: '#fddaa4',
      300: '#fcc776',
      400: '#fbb549',
      500: '#faa21b',
      600: '#ed8d16',
      700: '#d9730b',
      800: '#bf5808',
      900: '#8c3401',
    },
    red: {
      100: '#faeded',
      200: '#f7d6d6',
      300: '#f9b4b4',
      400: '#f48686',
      500: '#eb5555',
      600: '#dd2222',
      700: '#af1b1b',
      800: '#800f0f',
      900: '#520404',
    },
  },
  statusColors: {
    lightGray: {
      success: '#6a9728',
      warning: '#faa21b',
      error: '#dd2222',
      new: '#fe8d55',
    },
    dark: {
      success: '#87b340',
      warning: '#fbb549',
      error: '#eb5555',
      new: '#fe8d55',
    },
  },
  // Brand Accent Colors: https://brand.autodesk.com/brand-system/color/
  brand: {
    black: '#000000',
    clay: '#d74e26',
    darkSlate: '#666666',
    gold: '#ffc21a',
    iris: '#5f60ff',
    lightSlate: '#cccccc',
    plant: '#2bc275',
    white: '#ffffff',
  },
};
