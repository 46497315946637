import {
  Vendors,
  OutputVendorContextMap,
  ProductUserConfiguration,
  PutProductUserConfigurationPayload,
} from '@adsk/offsite-dc-sdk';

export const mockOutputLocations: OutputVendorContextMap = {
  RFA: {
    vendor: Vendors.ACC,
    accountId: 'mock-account-id',
    projectId: 'mock-project-id',
    outputLocation: 'mock-rfa-output-location',
  },
  BOM: {
    vendor: Vendors.BIM360,
    accountId: 'mock-account-id',
    projectId: 'mock-project-id',
    outputLocation: 'mock-bom-output-location',
  },
  THUMBNAIL: {
    vendor: Vendors.ACC,
    accountId: 'mock-account-id',
    projectId: 'mock-project-id',
    outputLocation: 'mock-thumbnail-output-location',
  },
};

export const mockProductUserConfiguration: ProductUserConfiguration = {
  tenancyId: 'mock-project-id',
  contentId: 'mock-product-id',
  outputLocations: mockOutputLocations,
  createdAt: '2017-11-27 11:53:54',
  updatedAt: '2017-11-27 14:53:54',
};

export const mockProductUserConfigurationPayload: PutProductUserConfigurationPayload = {
  outputLocations: mockOutputLocations,
};
