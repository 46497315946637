import { styled } from '@mui/material/styles';

export const SummaryTableWrapper = styled('table')`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => `${theme.typography.fontSize}px`};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  border-collapse: collapse;
  width: 100%;

  td {
    text-align: left;
    padding: ${({ theme }) => `${theme.var.paddingBase}px`};
  }
`;

export const StyledTitle = styled('td')<{ width?: string }>`
  width: ${({ width }) => width || '100%'};
  padding: ${({ theme }) => `${theme.var.summaryTableCellPadding}px`};
`;

export const StyledTableRow = styled('tr')`
  display: flex;
`;
