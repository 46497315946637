import { Box } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import InputBase from '@mui/material/InputBase';

export const MainContainer = styled('div')`
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.var.newFolderInputHeight}px;
`;

export const FolderNodeContainer = styled(Box)`
  display: flex;
  margin: ${({ theme }) => theme.var.folderNodeMargin / 2}px 0;
`;

export const FolderNode = styled(Box)`
  margin-right: ${({ theme }) => theme.var.folderNodeMargin}px;
  display: flex;
  align-items: center;
`;

export const NewFolderInputContainer = styled(Box)`
  margin-left: ${({ theme }) => theme.var.marginBase}px;
  display: flex;
  align-items: center;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.primary.autodeskBlue[500]};
  padding: 0 ${({ theme }) => theme.var.marginBase}px;
`;

export const InputButton = styled(ButtonBase)`
  padding: 0;
  border-radius: ${({ theme }) => theme.var.newFolderInputRadius}px;
  margin-left: ${({ theme }) => theme.var.newFolderInputMargin}px;
  ${({ disabled, theme }) =>
    disabled
      ? css`
          border: 1px solid ${theme.colors.primary.charcoal[100]};
          color: ${theme.colors.primary.charcoal[500]};
          background-color: ${theme.colors.primary.charcoal[100]};
        `
      : css`
          border: 1px solid ${theme.colors.primary.charcoal[400]};
          color: ${theme.colors.primary.autodeskBlue[400]};
        `}
`;

export const CreateFolderInput = styled(InputBase)`
  flex: 1;
`;

export const SubFolderCreationInfoContainer = styled('div')`
  width: 10%;
`;
