import { ACC_BRIDGE_STATUS, AccBridgeStatus } from 'mid-types';
import text from '../../common.text.json';

const { accBridge: accBridgeText } = text;

interface GetTooltipTextArgs {
  accBridgeStatus: AccBridgeStatus;
  hasProjectNameSynced: boolean;
}

export const getTooltipText = ({ accBridgeStatus, hasProjectNameSynced }: GetTooltipTextArgs): string => {
  let tooltipText = '';

  if (accBridgeStatus === ACC_BRIDGE_STATUS.OUTGOING) {
    tooltipText = hasProjectNameSynced ? accBridgeText.tooltipSenderAdmin : accBridgeText.tooltipSenderNotAdmin;
  } else if (accBridgeStatus === ACC_BRIDGE_STATUS.INCOMING) {
    tooltipText = hasProjectNameSynced ? accBridgeText.tooltipRecipientAdmin : accBridgeText.tooltipRecipientNotAdmin;
  }

  return tooltipText;
};
