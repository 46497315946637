import { NotificationContext, useCancellablePromise } from '@mid-react-common/common';
import { escapeRegExp } from 'lodash';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { AccProject, BIMAccount } from 'mid-types';
import { useCallback, useContext, useEffect, useState } from 'react';

interface UseAccountProjectSelectorProps {
  accountId: string | null;
}

interface UseAccountProjectSelectorState {
  accounts: BIMAccount[] | undefined;
  projects: AccProject[] | undefined;
  anchorElPopover: null | HTMLElement;
  loading: boolean;
  handleProjectMenuOpen: (event: React.MouseEvent<HTMLElement>) => Promise<void>;
  handleProjectMenuClose: () => void;
  handleSearchProjects: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const useAccountProjectSelector = ({ accountId }: UseAccountProjectSelectorProps): UseAccountProjectSelectorState => {
  const [anchorElPopover, setAnchorElPopover] = useState<null | HTMLElement>(null);

  const [accounts, setAccounts] = useState<BIMAccount[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [allProjects, setAllProjects] = useState<AccProject[] | undefined>(undefined);
  const [filteredProjects, setFilteredProjects] = useState<AccProject[] | undefined>(undefined);

  const cancellablePromise = useCancellablePromise();
  const { logAndShowNotification } = useContext(NotificationContext);

  const forgeApiService = getForgeApiServiceInstance();

  const fetchAccounts = useCallback(async () => {
    try {
      setLoading(true);

      const accounts = await cancellablePromise(forgeApiService.getAccounts());
      setAccounts(accounts);
    } catch (error) {
      logAndShowNotification({ error });
    } finally {
      setLoading(false);
    }
  }, [forgeApiService, logAndShowNotification, cancellablePromise]);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  useEffect(() => {
    if (!accountId) {
      return;
    }

    const fetchProjects = async () => {
      setLoading(true);

      const accountProjects = await cancellablePromise(forgeApiService.getProjects(accountId));

      setLoading(false);

      setAllProjects(accountProjects);
      setFilteredProjects(accountProjects);
    };

    fetchProjects();
  }, [forgeApiService, accountId, cancellablePromise]);

  const handleProjectMenuClose = () => {
    setAnchorElPopover(null);
  };

  const handleProjectMenuOpen = async (event: React.MouseEvent<HTMLElement>) => {
    // Clear up previous project search results
    setFilteredProjects(allProjects);
    setAnchorElPopover(event.currentTarget);
  };

  const handleSearchProjects = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const { value } = event.target;
      const regex = new RegExp(escapeRegExp(value), 'i');

      const filteredProjectList = allProjects?.filter((project) => {
        const titleLowercase = project.name.toLowerCase();
        return regex.test(titleLowercase);
      });

      setFilteredProjects(filteredProjectList);
    },
    [allProjects, setFilteredProjects],
  );

  return {
    accounts,
    projects: filteredProjects,
    anchorElPopover,
    loading,
    handleProjectMenuOpen,
    handleProjectMenuClose,
    handleSearchProjects,
  };
};
