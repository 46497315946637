type ACSFolder = 'acs_folder';

export type AccBridgeAutomationResponse<T> = {
  data: T[];
  more_data: boolean;
  next_sync_token?: string;
};

export type AccBridgeFolder = {
  asset_type: ACSFolder;
  automation_uid: string;
  history_uid: null;
  name: string;
  owner: string | null;
  sender_user_oid: string;
  source_collection_uid: string | null;
  source_deactivated_by: string | null;
  source_key_uid: string;
  source_project_uid: string;
  target_collection_uid: string | null;
  target_deactivated_by: string | null;
  target_key_uid: string;
  target_project_uid: string;
};

export interface MIDAccBridgeFolder extends AccBridgeFolder {
  targetFolderUrn: string;
  sourceFolderUrn: string;
  isSyncAutomationActive: boolean;
}

export const ACC_BRIDGE_STATUS = {
  OUTGOING: 'OUTGOING',
  INCOMING: 'INCOMING',
  NONE: 'NONE',
} as const;

export type AccBridgeStatus = (typeof ACC_BRIDGE_STATUS)[keyof typeof ACC_BRIDGE_STATUS];

export interface AccBridgeProject {
  accountName: string;
  id: string;
  projectId: string;
  projectName: string;
  status: string;
}

export interface AccBridgeProjectsResponse {
  bridges: AccBridgeProject[];
  pagination: { limit: number; nextUrl: string | undefined };
}

export interface AccBridgeProjectsData {
  projectNameSynced: string | undefined;
  sourceProjectId?: string;
  sourceFolderId?: string;
  targetProjectId?: string;
  targetFolderId?: string;
}

export interface AccBridgeDataForIconsWithTooltip {
  accBridgeStatus: AccBridgeStatus;
  accBridgeProjectsData: AccBridgeProjectsData[];
}
