import { OutputStatus, UploadOutputStatus, UploadOutputStatuses, Vendors } from '@adsk/offsite-dc-sdk';
import { FlexContainer, midTheme } from '@mid-react-common/common';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ServiceConfigMap, getReactAppEnvironment } from 'mid-utils';
import React, { useCallback } from 'react';
import text from '../../../../global/text.json';
import { outputsReviewPanel } from '../../../../tests/helpers/dataTestIds';
import LogPreview from '../LogPreview/LogPreview';
import DownloadOutputsFile from './DownloadOutputsFile';
import { getFusionTeamUrl } from './fusion.utils';

interface SuccessActionsProps {
  status: string;
  objectKey?: string;
  currentProjectId: string | null;
  outputUploadStatus: UploadOutputStatuses | null;
  outputLocationProjectId: string | null;
  outputLocation: string | null;
  outputProjectVendor: Vendors | null;
  productId?: string;
  variantId?: string;
}

const reviewPanelText = text.reviewPanel;

const currentEnv = getReactAppEnvironment();

const ActionsPanel: React.FC<SuccessActionsProps> = ({
  status,
  objectKey,
  currentProjectId,
  outputLocationProjectId,
  outputLocation,
  productId,
  variantId,
  outputProjectVendor,
  outputUploadStatus,
}) => {
  const { enableOutputsDownloadFile } = useFlags();

  const handleOutputLocationClick = useCallback(() => {
    if (!outputLocationProjectId || !outputLocation || !outputProjectVendor) {
      return;
    }

    const bim360OrAccUrl =
      ServiceConfigMap.DOCUMENTS_MANAGER_API[currentEnv].api + '/' + outputLocationProjectId + '/folders/' + outputLocation;

    const url =
      outputProjectVendor === Vendors.FUSIONTEAM
        ? getFusionTeamUrl(outputLocationProjectId, outputLocation, currentEnv)
        : bim360OrAccUrl;

    window.open(url, '_blank', 'noopener noreferrer');
  }, [outputLocationProjectId, outputLocation, outputProjectVendor]);

  return (
    <FlexContainer gap={midTheme.var.paddingBase}>
      {(status === reviewPanelText.all || status === OutputStatus.SUCCESS) && (
        <>
          <Button
            startIcon={<OpenInNewIcon />}
            data-testid={outputsReviewPanel.openOutputLocationButton}
            variant="contained"
            onClick={handleOutputLocationClick}
            disabled={
              !outputLocationProjectId ||
              !outputLocation ||
              !outputProjectVendor ||
              outputUploadStatus !== UploadOutputStatus.SUCCESS
            }
          >
            {reviewPanelText.openInUploadLocation}
          </Button>
          {enableOutputsDownloadFile && <DownloadOutputsFile projectId={currentProjectId} objectKey={objectKey} />}
        </>
      )}
      {(status === OutputStatus.FAILED || outputUploadStatus === UploadOutputStatus.FAILED) && (
        <LogPreview projectId={currentProjectId} productId={productId} variantId={variantId} />
      )}
    </FlexContainer>
  );
};

export default ActionsPanel;
