import { ProductRelease } from '@adsk/offsite-dc-sdk';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Instance } from 'types/product';
import ProductContext from '../../../../context/ProductStore/Product.context';

interface UseInstancePanelState {
  hasDataGridInstancesBeenInitialized: boolean;
  selectedInstancesIds: string[] | undefined;
}

const useInstancePanelData = ({
  localSelectedInstances,
}: {
  localSelectedInstances: Instance[] | undefined;
}): UseInstancePanelState => {
  const { instances, selectedProductRelease, dataGridInstances, setDataGridInstances } = useContext(ProductContext);
  const [hasDataGridInstancesBeenInitialized, setInitializeDataGridInstances] = useState<boolean>(false);

  // Instances to show in the dataGrid
  const selectedInstancesIds: string[] | undefined = localSelectedInstances
    ?.filter((instance) => dataGridInstances.some((dataGridInstance) => dataGridInstance.id === instance.id))
    .map((instance) => instance.id);

  const filterDataGridInstancesByProductId = useCallback(
    (selectedProductProductRelease: ProductRelease) => {
      if (instances) {
        const filteredInstancesByProduct = instances.filter(
          (instance) =>
            instance.contentId === selectedProductProductRelease.contentId &&
            instance.release === selectedProductProductRelease.release,
        );
        setDataGridInstances(filteredInstancesByProduct);
        setInitializeDataGridInstances(true);
      }
    },
    [setDataGridInstances, instances],
  );

  // Filtering instances by selected product and finalize data grid instances
  useEffect(() => {
    //If there are not instances in model
    if (instances && instances.length === 0) {
      setInitializeDataGridInstances(true);
    }

    // If instances has been initialized and selectedProductRelease is not empty
    if (instances && instances?.length > 0 && selectedProductRelease) {
      filterDataGridInstancesByProductId(selectedProductRelease);
    }
  }, [filterDataGridInstancesByProductId, selectedProductRelease, instances]);

  return {
    hasDataGridInstancesBeenInitialized,
    selectedInstancesIds,
  };
};

export default useInstancePanelData;
