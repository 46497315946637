import { SidebarThumbnailContainer, SidebarThumbnailImage } from './SidebarThumbnail.style';

interface SidebarThumbnailProps {
  src: string;
  alt?: string;
}

const SidebarThumbnail = ({ src, alt }: SidebarThumbnailProps): JSX.Element => (
  <SidebarThumbnailContainer className="mid-border-shadow">
    <SidebarThumbnailImage src={src} alt={alt || ''} />
  </SidebarThumbnailContainer>
);

export default SidebarThumbnail;
