export const mockManifest = {
  urn: 'mock urn',
  derivatives: [
    {
      extractorVersion: '2024.1.2022.1124',
      hasThumbnail: 'true',
      overrideOutputType: 'svf2',
      children: [
        {
          urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/model.sdb',
          role: 'Autodesk.CloudPlatform.PropertyDatabase',
          mime: 'application/autodesk-db',
          guid: '6fac95cb-af5d-3e4f-b943-8a7f55847ff1',
          type: 'resource',
          status: 'success',
        },
        {
          urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/AECModelData.json',
          role: 'Autodesk.AEC.ModelData',
          mime: 'application/json',
          guid: 'a4aac952-a3f4-031c-4113-b2d9ac2d0de6',
          type: 'resource',
          status: 'success',
        },
        {
          guid: '6d6ee121-36ce-cc80-19eb-1bd5ff0085fa',
          type: 'geometry',
          role: '3d',
          name: '{3D}',
          viewableID: '0b04bea8-5792-464d-9ded-8b1577a44d9c-001631ac',
          phaseNames: 'New Construction',
          status: 'success',
          hasThumbnail: 'true',
          progress: 'complete',
          children: [
            {
              guid: '0b04bea8-5792-464d-9ded-8b1577a44d9c-001631ac',
              type: 'view',
              role: '3d',
              name: '{3D}',
              status: 'success',
              progress: 'complete',
              camera: [
                119.07340240478516, -126.30424499511719, 104.5318374633789, 17.317893981933594, -24.54873275756836,
                2.7763290405273438, -0.40824830532073975, 0.40824830532073975, 0.8164966106414795, 1.148780345916748, 0, 1,
                1,
              ],
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/3D View/{3D} 1454508/{3D}.svf',
              role: 'graphics',
              mime: 'application/autodesk-svf',
              guid: '2b8b1cf8-31bf-7e71-dfb5-e1d4342ddb82',
              type: 'resource',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/3D View/{3D} 1454508/{3D}1.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: 'ee7bd440-5ebb-fd98-f53e-f134d6255df3',
              type: 'resource',
              resolution: [100, 100],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/3D View/{3D} 1454508/{3D}2.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: 'cb33efba-d63a-76c6-ed7f-0c5e245860ea',
              type: 'resource',
              resolution: [200, 200],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/3D View/{3D} 1454508/{3D}4.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: '30e3bbf5-195e-65ad-bdf0-77872dc490a6',
              type: 'resource',
              resolution: [400, 400],
              status: 'success',
            },
          ],
        },
        {
          isMasterView: true,
          phaseNames: 'New Construction',
          role: '3d',
          hasThumbnail: 'true',
          children: [
            {
              guid: 'c884ae1b-61e7-4f9d-0001-719e20b22d0b-00169d09',
              type: 'view',
              role: '3d',
              name: 'New Construction',
              status: 'success',
              progress: 'complete',
              camera: [
                119.07340240478516, -126.30424499511719, 104.5318374633789, 17.317893981933594, -24.54873275756836,
                2.7763290405273438, -0.40824830532073975, 0.40824830532073975, 0.8164966106414795, 1.148780345916748, 0, 1,
                1,
              ],
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/3D View/08f99ae5-b8be-4f8d-881b-128675723c10/New Construction/New Construction.svf',
              role: 'graphics',
              mime: 'application/autodesk-svf',
              guid: '34e5bb8b-869c-1d41-5c0f-97b1b9554691',
              type: 'resource',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/3D View/08f99ae5-b8be-4f8d-881b-128675723c10/New Construction/New Construction1.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: 'a3c19573-8948-7ae0-fb5c-75cab1d0e87a',
              type: 'resource',
              resolution: [100, 100],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/3D View/08f99ae5-b8be-4f8d-881b-128675723c10/New Construction/New Construction2.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: '86d53dd2-82c8-1948-5997-00ebf6078ed7',
              type: 'resource',
              resolution: [200, 200],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/3D View/08f99ae5-b8be-4f8d-881b-128675723c10/New Construction/New Construction4.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: '7e36c074-8997-d941-4dc3-6a72fcdf3b9b',
              type: 'resource',
              resolution: [400, 400],
              status: 'success',
            },
          ],
          name: 'New Construction',
          guid: '88e3b6d6-6e37-17c0-b50a-700e88560571',
          progress: 'complete',
          type: 'geometry',
          viewableID: 'c884ae1b-61e7-4f9d-0001-719e20b22d0b-00169d09',
          status: 'success',
        },
        {
          guid: 'a9d9fe86-88f5-4836-b02e-9316a01bd745-00163b51',
          type: 'geometry',
          role: '2d',
          name: 'S001 - Title Sheet',
          viewableID: 'a9d9fe86-88f5-4836-b02e-9316a01bd745-00163b51',
          phaseNames: 'New Construction',
          status: 'success',
          progress: 'complete',
          isVectorPDF: true,
          properties: {
            'Print Setting': {
              Layout: 'Landscape',
              'Paper size': 'ISO A4, 210 x 297 mm',
            },
          },
          units: 'mm',
          hasThumbnail: 'true',
          children: [
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S001 - Title Sheet 1456977/pdf/S001 - Title Sheet1.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: 'ba2c5232-3672-c149-cc66-7bb2006e9db6',
              type: 'resource',
              resolution: [100, 100],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S001 - Title Sheet 1456977/pdf/S001 - Title Sheet2.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: 'd5132fe9-5d81-04da-f27a-02f1f610f320',
              type: 'resource',
              resolution: [200, 200],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S001 - Title Sheet 1456977/pdf/S001 - Title Sheet4.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: '2022014a-c981-67ec-7bb5-4a6715f53be5',
              type: 'resource',
              resolution: [400, 400],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S001 - Title Sheet 1456977/pdf/S001 - Title Sheet.pdf',
              role: 'pdf-page',
              mime: 'application/pdf',
              guid: '6f44527a-f611-40df-8dea-890ec9dac42a',
              type: 'resource',
              status: 'success',
            },
            {
              guid: '1029a69f-40c7-4c6e-ad12-a0d4acb24052',
              type: 'view',
              role: '2d',
              name: 'S001 - Title Sheet',
              viewbox: [0, 0, 839.978, 593.979],
            },
          ],
        },
        {
          guid: '505917d5-31be-41b4-bed7-c50d88f9b35f-00163b84',
          type: 'geometry',
          role: '2d',
          name: 'S101 - Framing Plans',
          viewableID: '505917d5-31be-41b4-bed7-c50d88f9b35f-00163b84',
          phaseNames: 'New Construction',
          status: 'success',
          progress: 'complete',
          isVectorPDF: true,
          properties: {
            'Print Setting': {
              Layout: 'Landscape',
              'Paper size': 'ISO A4, 210 x 297 mm',
            },
          },
          units: 'mm',
          hasThumbnail: 'true',
          children: [
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S101 - Framing Plans 1457028/pdf/S101 - Framing Plans1.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: 'ad9d8753-0b5d-a74c-9fe8-7110ee1c089e',
              type: 'resource',
              resolution: [100, 100],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S101 - Framing Plans 1457028/pdf/S101 - Framing Plans2.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: '0bd663e7-fb6b-1620-0db8-7de131e08b55',
              type: 'resource',
              resolution: [200, 200],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S101 - Framing Plans 1457028/pdf/S101 - Framing Plans4.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: 'dee0d687-920c-6986-4c23-fd84b608fd25',
              type: 'resource',
              resolution: [400, 400],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S101 - Framing Plans 1457028/pdf/S101 - Framing Plans.pdf',
              role: 'pdf-page',
              mime: 'application/pdf',
              guid: '6cdd9149-5a4d-4b2b-9e64-ddf6b554c179',
              type: 'resource',
              status: 'success',
            },
            {
              guid: 'a48a8fa5-58c0-45a7-af38-8653738652d8',
              type: 'view',
              role: '2d',
              name: 'S101 - Framing Plans',
              viewbox: [0, 0, 839.978, 593.979],
            },
          ],
        },
        {
          guid: '505917d5-31be-41b4-bed7-c50d88f9b35f-00163b95',
          type: 'geometry',
          role: '2d',
          name: 'S201 - Upper House Framing',
          viewableID: '505917d5-31be-41b4-bed7-c50d88f9b35f-00163b95',
          phaseNames: 'New Construction',
          status: 'success',
          progress: 'complete',
          isVectorPDF: true,
          properties: {
            'Print Setting': {
              Layout: 'Landscape',
              'Paper size': 'ISO A4, 210 x 297 mm',
            },
          },
          units: 'mm',
          hasThumbnail: 'true',
          children: [
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S201 - Upper House Framing 1457045/pdf/S201 - Upper House Framing1.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: '58e3082e-adf7-f5f2-9fe8-4c1433a68681',
              type: 'resource',
              resolution: [100, 100],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S201 - Upper House Framing 1457045/pdf/S201 - Upper House Framing2.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: '00c841af-5209-d21d-759a-acd75c0bad43',
              type: 'resource',
              resolution: [200, 200],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S201 - Upper House Framing 1457045/pdf/S201 - Upper House Framing4.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: 'dff9efd7-27e4-88c2-3f51-230ea231a8b7',
              type: 'resource',
              resolution: [400, 400],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S201 - Upper House Framing 1457045/pdf/S201 - Upper House Framing.pdf',
              role: 'pdf-page',
              mime: 'application/pdf',
              guid: 'df41c2c5-20e0-45e2-8515-ad323e871aa5',
              type: 'resource',
              status: 'success',
            },
            {
              guid: 'e9f15eca-9cae-486b-9e0d-ad90941cd14f',
              type: 'view',
              role: '2d',
              name: 'S201 - Upper House Framing',
              viewbox: [0, 0, 839.978, 593.979],
            },
          ],
        },
        {
          guid: '505917d5-31be-41b4-bed7-c50d88f9b35f-00163ba8',
          type: 'geometry',
          role: '2d',
          name: 'S202 - Wall Section',
          viewableID: '505917d5-31be-41b4-bed7-c50d88f9b35f-00163ba8',
          phaseNames: 'New Construction',
          status: 'success',
          progress: 'complete',
          isVectorPDF: true,
          properties: {
            'Print Setting': {
              Layout: 'Landscape',
              'Paper size': 'ISO A4, 210 x 297 mm',
            },
          },
          units: 'mm',
          hasThumbnail: 'true',
          children: [
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S202 - Wall Section 1457064/pdf/S202 - Wall Section1.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: '0703c828-4bf3-20a4-e674-35778db693c0',
              type: 'resource',
              resolution: [100, 100],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S202 - Wall Section 1457064/pdf/S202 - Wall Section2.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: '65c96e00-2d89-5c66-92be-575eb42227b2',
              type: 'resource',
              resolution: [200, 200],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S202 - Wall Section 1457064/pdf/S202 - Wall Section4.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: '0ae949d6-1eee-deef-6eb3-50e56660000e',
              type: 'resource',
              resolution: [400, 400],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S202 - Wall Section 1457064/pdf/S202 - Wall Section.pdf',
              role: 'pdf-page',
              mime: 'application/pdf',
              guid: '7b791695-7600-4eb9-9556-e0fd2e06c3c9',
              type: 'resource',
              status: 'success',
            },
            {
              guid: 'e1e524e1-f1a7-4208-9291-cdbd11dfc97d',
              type: 'view',
              role: '2d',
              name: 'S202 - Wall Section',
              viewbox: [0, 0, 839.978, 593.979],
            },
          ],
        },
        {
          guid: '505917d5-31be-41b4-bed7-c50d88f9b35f-00163bff',
          type: 'geometry',
          role: '2d',
          name: 'S203 - Central Pile Section',
          viewableID: '505917d5-31be-41b4-bed7-c50d88f9b35f-00163bff',
          phaseNames: 'New Construction',
          status: 'success',
          progress: 'complete',
          isVectorPDF: true,
          properties: {
            'Print Setting': {
              Layout: 'Landscape',
              'Paper size': 'ISO A4, 210 x 297 mm',
            },
          },
          units: 'mm',
          hasThumbnail: 'true',
          children: [
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S203 - Central Pile Section 1457151/pdf/S203 - Central Pile Section1.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: '48927904-1676-62b8-cbaa-7d5dd137be2a',
              type: 'resource',
              resolution: [100, 100],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S203 - Central Pile Section 1457151/pdf/S203 - Central Pile Section2.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: '539e4507-3f9c-744e-6dad-ebaec712f6aa',
              type: 'resource',
              resolution: [200, 200],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S203 - Central Pile Section 1457151/pdf/S203 - Central Pile Section4.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: '90f726df-c340-d107-0435-aa7f22497e8a',
              type: 'resource',
              resolution: [400, 400],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S203 - Central Pile Section 1457151/pdf/S203 - Central Pile Section.pdf',
              role: 'pdf-page',
              mime: 'application/pdf',
              guid: 'd2b6f99c-fcb4-4482-ae77-7a86a3ac0bfe',
              type: 'resource',
              status: 'success',
            },
            {
              guid: 'a12ab67c-8bbd-4baa-9096-30ffc3d283ab',
              type: 'view',
              role: '2d',
              name: 'S203 - Central Pile Section',
              viewbox: [0, 0, 839.978, 593.979],
            },
          ],
        },
        {
          guid: '505917d5-31be-41b4-bed7-c50d88f9b35f-00163c1d',
          type: 'geometry',
          role: '2d',
          name: 'S204 - Footing Detail',
          viewableID: '505917d5-31be-41b4-bed7-c50d88f9b35f-00163c1d',
          status: 'success',
          progress: 'complete',
          isVectorPDF: true,
          properties: {
            'Print Setting': {
              Layout: 'Landscape',
              'Paper size': 'ISO A4, 210 x 297 mm',
            },
          },
          units: 'mm',
          hasThumbnail: 'true',
          children: [
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S204 - Footing Detail 1457181/pdf/S204 - Footing Detail1.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: 'b08b8260-f0c3-3b0f-8fec-e781e5b6ec17',
              type: 'resource',
              resolution: [100, 100],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S204 - Footing Detail 1457181/pdf/S204 - Footing Detail2.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: '8db70aac-ea62-3675-6235-12e38a540aeb',
              type: 'resource',
              resolution: [200, 200],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S204 - Footing Detail 1457181/pdf/S204 - Footing Detail4.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: 'ef635b9c-e30a-9ac5-47ad-f3ff7a610d1f',
              type: 'resource',
              resolution: [400, 400],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S204 - Footing Detail 1457181/pdf/S204 - Footing Detail.pdf',
              role: 'pdf-page',
              mime: 'application/pdf',
              guid: 'd77fdaaf-9999-4080-9c9f-b98401609c75',
              type: 'resource',
              status: 'success',
            },
            {
              guid: 'cfb4b01b-c90f-472f-b165-4f4f4681cc2b',
              type: 'view',
              role: '2d',
              name: 'S204 - Footing Detail',
              viewbox: [0, 0, 839.978, 593.979],
            },
          ],
        },
        {
          guid: '505917d5-31be-41b4-bed7-c50d88f9b35f-00163c2f',
          type: 'geometry',
          role: '2d',
          name: 'S205 - Column Schedules',
          viewableID: '505917d5-31be-41b4-bed7-c50d88f9b35f-00163c2f',
          phaseNames: 'New Construction',
          status: 'success',
          progress: 'complete',
          isVectorPDF: true,
          properties: {
            'Print Setting': {
              Layout: 'Landscape',
              'Paper size': 'ISO A4, 210 x 297 mm',
            },
          },
          units: 'mm',
          hasThumbnail: 'true',
          children: [
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S205 - Column Schedules 1457199/pdf/S205 - Column Schedules1.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: '5f12832d-1eb4-7911-1732-1ed7ad75c695',
              type: 'resource',
              resolution: [100, 100],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S205 - Column Schedules 1457199/pdf/S205 - Column Schedules2.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: 'b1f11075-7a55-87ee-8bd5-74ce5e61233e',
              type: 'resource',
              resolution: [200, 200],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S205 - Column Schedules 1457199/pdf/S205 - Column Schedules4.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: '5a9500a4-e9a1-a24a-ffcb-43b3d89f401f',
              type: 'resource',
              resolution: [400, 400],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S205 - Column Schedules 1457199/pdf/S205 - Column Schedules.pdf',
              role: 'pdf-page',
              mime: 'application/pdf',
              guid: '6118d26e-89c2-4274-8048-cd8260dba5a9',
              type: 'resource',
              status: 'success',
            },
            {
              guid: 'db449036-cefc-4ad8-a752-3e6ef2016e5e',
              type: 'view',
              role: '2d',
              name: 'S205 - Column Schedules',
              viewbox: [0, 0, 839.978, 593.979],
            },
          ],
        },
        {
          guid: '6ae0816c-a18d-4426-945d-98376a9e9057-00163c4c',
          type: 'geometry',
          role: '2d',
          name: 'S206 - Elevations',
          viewableID: '6ae0816c-a18d-4426-945d-98376a9e9057-00163c4c',
          phaseNames: 'New Construction',
          status: 'success',
          progress: 'complete',
          isVectorPDF: true,
          properties: {
            'Print Setting': {
              Layout: 'Landscape',
              'Paper size': 'ISO A4, 210 x 297 mm',
            },
          },
          units: 'mm',
          hasThumbnail: 'true',
          children: [
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S206 - Elevations 1457228/pdf/S206 - Elevations1.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: '7809a03b-1802-8988-758e-3c4903d6e1c0',
              type: 'resource',
              resolution: [100, 100],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S206 - Elevations 1457228/pdf/S206 - Elevations2.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: '961c83bf-48bc-5a0b-1548-346e9b4b65b9',
              type: 'resource',
              resolution: [200, 200],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S206 - Elevations 1457228/pdf/S206 - Elevations4.png',
              role: 'thumbnail',
              mime: 'image/png',
              guid: 'fba0aa84-c4fb-179b-c1b2-33d1e446faea',
              type: 'resource',
              resolution: [400, 400],
              status: 'success',
            },
            {
              urn: 'urn:adsk.viewing:fs.file:mock urn/output/Resource/Sheet/S206 - Elevations 1457228/pdf/S206 - Elevations.pdf',
              role: 'pdf-page',
              mime: 'application/pdf',
              guid: 'aaf52c15-b42f-4c22-97fb-abf52222e16c',
              type: 'resource',
              status: 'success',
            },
            {
              guid: '47d1717c-207b-4e5b-af79-88cd12b344f1',
              type: 'view',
              role: '2d',
              name: 'S206 - Elevations',
              viewbox: [0, 0, 839.978, 593.979],
            },
          ],
        },
      ],
      name: 'rst_basic_sample_project_with_mid_stairs.rvt',
      progress: 'complete',
      outputType: 'svf',
      properties: {
        'Document Information': {
          RVTVersion: '2023',
          'Project Name': 'Sample House',
          'Project Number': '001-00',
          Author: '',
          'Project Address': 'Enter address here',
          'Project Issue Date': 'Issue Date',
          'Project Status': 'Project Status',
          'Building Name': '',
          'Client Name': 'Autodesk',
          'Organization Name': '',
          'Organization Description': '',
        },
      },
      status: 'success',
    },
    {
      children: [
        {
          urn: 'urn:adsk.viewing:fs.file:mock urn/output/preview1.png',
          role: 'thumbnail',
          mime: 'image/png',
          guid: 'db899ab5-939f-e250-d79d-2d1637ce4565',
          type: 'resource',
          resolution: [100, 100],
          status: 'success',
        },
        {
          urn: 'urn:adsk.viewing:fs.file:mock urn/output/preview2.png',
          role: 'thumbnail',
          mime: 'image/png',
          guid: '3f6c118d-f551-7bf0-03c9-8548d26c9772',
          type: 'resource',
          resolution: [200, 200],
          status: 'success',
        },
        {
          urn: 'urn:adsk.viewing:fs.file:mock urn/output/preview4.png',
          role: 'thumbnail',
          mime: 'image/png',
          guid: '4e751806-0920-ce32-e9fd-47c3cec21536',
          type: 'resource',
          resolution: [400, 400],
          status: 'success',
        },
      ],
      progress: 'complete',
      outputType: 'thumbnail',
      status: 'success',
    },
  ],
  hasThumbnail: 'true',
  progress: 'complete',
  type: 'manifest',
  region: 'US',
  version: '1.0',
  status: 'success',
};
