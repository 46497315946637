import { StateCreator } from 'zustand';

export interface CheckoutStepSlice {
  totalTokenCost: number;
  setTotalTokenCost: (totalTokenCost: number) => void;
  hasCheckoutError: boolean;
  setHasCheckoutError: (hasTokenError: boolean) => void;
  isGeneratingOutputs: boolean;
  setIsGeneratingOutputs: (isGeneratingOutputs: boolean) => void;
}

export const createCheckoutStepSlice: StateCreator<CheckoutStepSlice> = (set) => ({
  totalTokenCost: 0,
  setTotalTokenCost: (totalTokenCost: number) => set({ totalTokenCost }),
  hasCheckoutError: false,
  setHasCheckoutError: (hasCheckoutError: boolean) => set({ hasCheckoutError }),
  isGeneratingOutputs: false,
  setIsGeneratingOutputs: (isGeneratingOutputs: boolean) => set({ isGeneratingOutputs }),
});
