import { OutputTypeWithVirtualTypes } from 'mid-types';
import useRFOModalStore from 'context/RFOModalStore/useRFOModalStore';
import { useShallow } from 'zustand/react/shallow';

export interface UseModel3DState {
  isIamSelected: boolean;
  handleInventorDataSetChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const useModel3D = (): UseModel3DState => {
  const { allOutputs, isIamSelected, setAllOutputs, setIsIamSelected } = useRFOModalStore(
    useShallow((state) => ({
      isIamSelected: state.isIamSelected,
      setIsIamSelected: state.setIsIamSelected,
      allOutputs: state.allOutputs,
      setAllOutputs: state.setAllOutputs,
    })),
  );

  const handleInventorDataSetChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const otherOutputs = allOutputs.filter((output) => output.type !== OutputTypeWithVirtualTypes.IAM);

    setIsIamSelected(checked);
    if (checked) {
      setAllOutputs([
        ...otherOutputs,
        {
          type: OutputTypeWithVirtualTypes.IAM,
        },
      ]);
    } else {
      setAllOutputs([...otherOutputs]);
    }
  };

  return {
    isIamSelected,
    handleInventorDataSetChange,
  };
};

export default useModel3D;
