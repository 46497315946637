import { ReleaseStatus, ReleaseStatusValue } from '@adsk/offsite-dc-sdk';
import { ReleaseStatusWithColorBar } from '@mid-react-common/common';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import React from 'react';
import { releaseUpdateIds } from '../../../tests/helpers/dataTestIds';

interface ReleaseStatusOptionsProps {
  selectedReleaseStatus: ReleaseStatusValue;
  releaseStatusList: ReleaseStatusValue[];
  changeReleaseStatus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  activeMessage?: string;
  defaultMessage?: string;
  obsoleteMessage?: string;
}
const ReleaseStatusOptions: React.FC<ReleaseStatusOptionsProps> = ({
  selectedReleaseStatus,
  changeReleaseStatus,
  releaseStatusList,
  activeMessage,
  defaultMessage,
  obsoleteMessage,
}) => {
  // If there is only one option, we don't need to show the radio buttons
  if (releaseStatusList.length === 1) {
    return (
      <ReleaseStatusWithColorBar
        data-testid={releaseUpdateIds.releaseUpdateOptions + selectedReleaseStatus}
        status={releaseStatusList[0]}
      >
        <Typography>{defaultMessage || obsoleteMessage}</Typography>
      </ReleaseStatusWithColorBar>
    );
  }

  return (
    <RadioGroup value={selectedReleaseStatus} onChange={changeReleaseStatus}>
      {releaseStatusList.includes(ReleaseStatus.DEFAULT) && (
        <ReleaseStatusRadioWithColorBar
          status={ReleaseStatus.DEFAULT}
          selectedReleaseStatus={selectedReleaseStatus}
          message={defaultMessage}
        />
      )}
      {releaseStatusList.includes(ReleaseStatus.ACTIVE) && (
        <ReleaseStatusRadioWithColorBar
          status={ReleaseStatus.ACTIVE}
          selectedReleaseStatus={selectedReleaseStatus}
          message={activeMessage}
        />
      )}
      {releaseStatusList.includes(ReleaseStatus.OBSOLETE) && (
        <ReleaseStatusRadioWithColorBar
          status={ReleaseStatus.OBSOLETE}
          selectedReleaseStatus={selectedReleaseStatus}
          message={obsoleteMessage}
        />
      )}
    </RadioGroup>
  );
};

const ReleaseStatusRadioWithColorBar: React.FC<{
  status: ReleaseStatusValue;
  selectedReleaseStatus: ReleaseStatusValue;
  message?: string;
}> = ({ status, selectedReleaseStatus, message }) => (
  <FormControlLabel
    data-testid={releaseUpdateIds.releaseUpdateOptions + status}
    key={releaseUpdateIds.releaseUpdateOptions + status}
    value={status}
    control={<Radio />}
    label={
      <ReleaseStatusWithColorBar status={status}>
        {message && selectedReleaseStatus === status && <Typography>{message}</Typography>}
      </ReleaseStatusWithColorBar>
    }
  />
);

export default ReleaseStatusOptions;
