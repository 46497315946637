import { BIM360Document } from './bim360.types';

// For more info: https://wiki.autodesk.com/display/MID/Projects+and+Folder+Permissions
export const FOLDERS_PERMISSION_FILTER_OPTIONS: { [key in FolderDMPermissionAction]: FolderDMPermissionAction } = {
  view: 'view',
  collaborate: 'collaborate',
  download: 'download',
  publish: 'publish',
  publish_markup: 'publish_markup',
  edit: 'edit',
  control: 'control',
};

export type FolderTreeResponse = {
  folder_tree: ForgeDMProjectFolder[];
};

export type FolderResponse = {
  attributes: ForgeDMProjectFolder;
};

export type FoldersResponse = {
  folders: ForgeDMProjectFolder[];
};

export type FolderContentResponse = {
  documents: BIM360Document[];
};

export interface ProjectFolder {
  projectId: string;
  parentUrn: string;
  urn: string;
  title: string;
  deleted: boolean;
  folderType: string;
  isRoot: boolean;
  path: string;
  hidden: boolean;
  viewOption: string;
  hasSubfolders: boolean;
  permissionType: FolderDMPermissionType;
  permissionActions: FolderDMPermissionAction[];
  isSystemFolder: boolean;
}

export interface ForgeDMProjectFoldersResponse {
  folders: ForgeDMProjectFolder[];
  attributes?: ForgeDMProjectFolder;
  has_next_page?: boolean;
}
// For more info: https://wiki.autodesk.com/display/MID/Projects+and+Folder+Permissions
type FolderDMPermissionType = 'view_only' | 'view' | 'publish' | 'edit' | 'control';
export type FolderDMPermissionAction =
  | 'view'
  | 'collaborate'
  | 'download'
  | 'publish'
  | 'edit'
  | 'control'
  | 'publish_markup';

export interface ForgeDMProjectFolder {
  project_id: string;
  parent_urn: string;
  title: string;
  doc_type: string;
  urn: string;
  deleted: boolean;
  folder_type: string;
  is_root: boolean;
  permission_actions: FolderDMPermissionAction[];
  view_option: string;
  has_subfolders: boolean;
  path: string;
  hidden: boolean;
  permission_type: FolderDMPermissionType;
  is_system_folder: boolean;
  folders: ForgeDMProjectFolder[];
}

export interface ForgeDMProjectDetails {
  id: string;
  name: string;
  account_id: string;
  account_display_name: string;
}

export interface GetFoldersArgs {
  projectId: string;
  permissionFilter?: FolderDMPermissionAction;
}
export interface GetSubFoldersArgs extends GetFoldersArgs {
  folderUrn: string;
}

export type SelfLink = {
  self: {
    href: string;
  };
};

export type WebviewLink = {
  webview: { href: string };
};

export type RelatedLink = {
  related: { href: string };
};

export type JSONAPIBase = {
  jsonapi: {
    version: string;
  };
};

export const statusCodes = {
  CREATED: '201',
  BAD_REQUEST: '400',
  FORBIDDEN: '403',
  NOT_FOUND: '404',
  CONFLICT: '409',
  LOCKED: '423',
} as const;

export type StatusCode = (typeof statusCodes)[keyof typeof statusCodes];

export type JSONAPIErrors = {
  // The "errors" key is only present when
  // the api successfully validates a request
  // and is not able to return data
  errors?: {
    id: string;
    status: StatusCode;
    code?: string;
    title?: string;
    detail: string;
  }[];
};

type PaginationLinks = {
  first?: { href: string };
  prev?: { href: string };
  next?: { href: string };
};

export type JSONAPIResponseBase = JSONAPIBase &
  JSONAPIErrors & {
    // When there are errors, the "links" key is not present
    links?: SelfLink & PaginationLinks;
  };

export type DataAndMeta = {
  data: {
    type: string;
    id: string;
  };
  meta: {
    link: {
      href: string;
    };
  };
};

export type ForgeJsonApiListResponse<T = any> = JSONAPIResponseBase & {
  data: T[];
};
