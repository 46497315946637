export const RFAExportHistoryItemStatuses = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
} as const;

export type RFAExportHistoryItemStatus = keyof typeof RFAExportHistoryItemStatuses;

export type RFAExportHistoryItem = {
  id: string;
  createdAt: string;
  updatedAt: string;
  rfaFileName: string;
  revitVersion: string;
  status: RFAExportHistoryItemStatus;
  logFilePath?: string;
  workItemId?: string;
};

export type UpdateRFAExportHistoryItemPayload = {
  status: RFAExportHistoryItemStatus;
  logFilePath?: string;
  workItemId?: string;
};
