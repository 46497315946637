import { PostVariantOutputWithVirtualTypes } from 'mid-types';
import { StateCreator } from 'zustand';

export interface FileTypesStepSlice {
  // MODEL 3D
  isIamSelected: boolean;
  setIsIamSelected: (isIamSelected: boolean) => void;

  // DRAWING 2D
  idwOutputs: string[];
  setIdwOutputs: (idwOutputs: string[]) => void;

  dwgOutputs: string[];
  setDwgOutputs: (dwgOutputs: string[]) => void;

  pdfOutputs: string[];
  setPdfOutputs: (pdfOutputs: string[]) => void;

  // BILL OF MATERIALS
  bomOutputs: string[];
  setBomOutputs: (bomOutputs: string[]) => void;

  aggregatedBomOutputs: string[];
  setAggregatedBomOutputs: (aggregatedBomOutputs: string[]) => void;

  // NEUTRAL FORMAT
  satOutputs: string[];
  setSatOutputs: (satOutputs: string[]) => void;

  stepOutputs: string[];
  setStepOutputs: (stepOutputs: string[]) => void;

  glbOutputs: string[];
  setGlbOutputs: (glbOutputs: string[]) => void;

  stlOutputs: string[];
  setStlOutputs: (stlOutputs: string[]) => void;

  allOutputs: PostVariantOutputWithVirtualTypes[];
  setAllOutputs: (allOutputs: PostVariantOutputWithVirtualTypes[]) => void;
  resetAllOutputs: () => void;
}

export const emptyOutputs = {
  isIamSelected: false,
  idwOutputs: [],
  dwgOutputs: [],
  pdfOutputs: [],
  bomOutputs: [],
  aggregatedBomOutputs: [],
  satOutputs: [],
  stepOutputs: [],
  glbOutputs: [],
  stlOutputs: [],
  allOutputs: [],
};

export const createFileTypesStepSlice: StateCreator<FileTypesStepSlice> = (set) => ({
  // MODEL 3D
  isIamSelected: false,
  setIsIamSelected: (isIamSelected: boolean) => set({ isIamSelected }),

  // DRAWINGS 2D
  idwOutputs: [],
  setIdwOutputs: (idwOutputs: string[]) => set({ idwOutputs }),

  dwgOutputs: [],
  setDwgOutputs: (dwgOutputs: string[]) => set({ dwgOutputs }),

  pdfOutputs: [],
  setPdfOutputs: (pdfOutputs: string[]) => set({ pdfOutputs }),

  // BILL OF MATERIALS
  bomOutputs: [],
  setBomOutputs: (bomOutputs: string[]) => set({ bomOutputs }),

  aggregatedBomOutputs: [],
  setAggregatedBomOutputs: (aggregatedBomOutputs: string[]) => set({ aggregatedBomOutputs }),

  // NEUTRAL FORMAT
  satOutputs: [],
  setSatOutputs: (satOutputs: string[]) => set({ satOutputs }),

  stepOutputs: [],
  setStepOutputs: (stepOutputs: string[]) => set({ stepOutputs }),

  glbOutputs: [],
  setGlbOutputs: (glbOutputs: string[]) => set({ glbOutputs }),

  stlOutputs: [],
  setStlOutputs: (stlOutputs: string[]) => set({ stlOutputs }),

  // All outputs
  allOutputs: [],
  setAllOutputs: (allOutputs: PostVariantOutputWithVirtualTypes[]) => set({ allOutputs }),
  resetAllOutputs: () => {
    set(emptyOutputs);
  },
});
