import { http, HttpResponse } from 'msw';
import { ApiPaths } from '../constants/forgeApiPaths';
import { ServiceConfigMap, ServiceTypes } from '../constants/serviceAPIConfig';
import {
  amplitudeHandler,
  bypassRequest,
  checkHubProjectFolderPermissionHandler,
  createNewfolder,
  deleteProductTemplateHandler,
  getAccBridgeFoldersHandler,
  getAccBridgeProjectsHandler,
  getAccountsHandler,
  getAllProductsInProjectHandler,
  getAnalyticsId,
  getDownloadUrlHandler,
  getFolderContentHandler,
  getFoldersHandler,
  getFolderTreeHandler,
  getGainsightRequest,
  getLDApp,
  getLDClientStream,
  getLDEvents,
  getManifestHandler,
  getMSIMetadata,
  getProductHandler,
  getProductReleaseHandler,
  getProductReleasesHandler,
  getProjectByIdHandler,
  getProjectsHandler,
  getRulesObject,
  getRulesObjectWithError,
  getSubFoldersHandler,
  getThumbnailHandler,
  getUserProfile,
  getVariantHandler,
  getVariantLog,
  getVariantOutputsHandler,
  getVariantsByProductHandler,
  postCompleteUpload,
  postLDServerHash,
  postProduct,
  postUploadUrls,
  postVariantHandler,
  putProductUserConfigurationHandler,
  putUploadUrl,
} from '../mocks/handlers';
import { getReactAppEnvironment } from '../web-package/utility';

const reactAppEnvironment = getReactAppEnvironment();
export const mswForgeApiHandlers = [
  // **********************
  // FORGE API
  // **********************
  http.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.BIM360_API_PATH}/${ApiPaths.ACCOUNTS}`,
    getAccountsHandler,
  ),
  http.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.ACC_API_PATH}/${
      ApiPaths.ACCOUNTS
    }/:accountId/${ApiPaths.PROJECTS}`,
    getProjectsHandler,
  ),
  http.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.ACC_API_PATH}/${
      ApiPaths.PROJECTS
    }/:projectId`,
    getProjectByIdHandler,
  ),
  http.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.DM_STG_API_PATH}/${
      ApiPaths.PROJECTS
    }/:projectId/folders`,
    getFoldersHandler,
  ),
  http.post(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.DM_STG_API_PATH}/${
      ApiPaths.PROJECTS
    }/:projectId/folders`,
    createNewfolder,
  ),
  http.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.DM_STG_API_PATH}/${
      ApiPaths.PROJECTS
    }/:projectId/folders/:folderUrn`,
    getSubFoldersHandler,
  ),
  http.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.DM_STG_API_PATH}/${
      ApiPaths.PROJECTS
    }/:projectId/folders/:folderId/folder_tree`,
    getFolderTreeHandler,
  ),
  http.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.DM_STG_API_PATH}/${
      ApiPaths.PROJECTS
    }/:projectId/folders/:folder/documents`,
    getFolderContentHandler,
  ),
  http.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${
      ApiPaths.MODEL_DERIVATIVE_PATH
    }/:documentId/manifest`,
    getManifestHandler,
  ),
  http.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.DOCUMENT_THUMBNAIL_PATH}/:documentId`,
    getThumbnailHandler,
  ),
  http.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${ApiPaths.USERPROFILE_API_PATH}/users/@me`,
    getUserProfile,
  ),
  http.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${
      ApiPaths.IDENTITY_API_PATH
    }/users/:userId/analytics`,
    getAnalyticsId,
  ),
  // Check Hub Project Folder Permissions
  http.post(
    `${ServiceConfigMap[ServiceTypes.FORGE_API][reactAppEnvironment].api}/${
      ApiPaths.DM_V2_DATA_PATH
    }/projects/:projectId/commands`,
    checkHubProjectFolderPermissionHandler,
  ),
];

export const mswDcApiHandlers = [
  // **********************
  // DC API
  // **********************
  // POST product
  http.post(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api}/projects/:projectId/products`,
    postProduct,
  ),
  // PUT product
  http.put(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api}/projects/:projectId/products/:productId`,
    postProduct,
  ),
  // GET product
  http.get(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api}/projects/:projectId/products/:productId`,
    getProductHandler,
  ),
  // GET product releases
  http.get(
    `${
      ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api
    }/projects/:projectId/products/:productId/releases`,
    getProductReleasesHandler,
  ),
  // GET product release
  http.get(
    `${
      ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api
    }/projects/:projectId/products/:productId/releases/:releaseId`,
    getProductReleaseHandler,
  ),
  // GET products list in project
  http.get(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api}/projects/:projectId/products`,
    getAllProductsInProjectHandler,
  ),
  // DELETE product
  http.delete(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api}/projects/:projectId/products/:productId`,
    deleteProductTemplateHandler,
  ),
  // POST variant
  http.post(
    `${
      ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api
    }/projects/:projectId/products/:productId/variants`,
    postVariantHandler,
  ),
  // GET variant
  http.get(
    `${
      ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api
    }/projects/:projectId/products/:productId/variants/:variantId`,
    getVariantHandler,
  ),
  // Get variants list
  http.get(
    `${
      ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api
    }/projects/:projectId/products/:productId/variants`,
    getVariantsByProductHandler,
  ),
  // GET variant log
  http.get(
    `${
      ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api
    }/projects/:projectId/products/:productId/variants/:variantId/logs`,
    getVariantLog,
  ),
  // GET variant output
  http.get(
    `${
      ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api
    }/projects/:projectId/products/:productId/variants/:variantId/outputs`,
    getVariantOutputsHandler,
  ),
  // PUT product user config
  http.put(
    `${
      ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api
    }/userconfigs/projects/:projectId/products/:productId`,
    putProductUserConfigurationHandler,
  ),
  // Download Url
  http.post(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api}/projects/:tenancyId/data/downloadurl`,
    getDownloadUrlHandler,
  ),
  // Upload Url
  http.post(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api}/projects/:projectId/data/uploadurls`,
    postUploadUrls,
  ),
  // Complete Upload
  http.post(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api}/projects/:projectId/data/completeupload`,
    postCompleteUpload,
  ),
  // LaunchDarkly Hash
  http.post(`${ServiceConfigMap[ServiceTypes.OFFSITE_API][reactAppEnvironment].api}/ld_server_hash`, postLDServerHash),
];

export const mswAccBridgeHandlers = [
  http.get(
    `${ServiceConfigMap[ServiceTypes.ACC_BRIDGE][reactAppEnvironment].api}/proxy/bridge/v1/automations`,
    getAccBridgeFoldersHandler,
  ),
  http.get(
    `${ServiceConfigMap[ServiceTypes.ACC_BRIDGE][reactAppEnvironment].api}/proxy/bridge/v1/bridges`,
    getAccBridgeProjectsHandler,
  ),
];

export const mswMiscellaneousWebHandlers = [
  // **********************
  // OTHERS
  // **********************
  // GET metadata
  http.get(`${ServiceConfigMap[ServiceTypes.MID_ARTIFACTS][reactAppEnvironment].api}/metadata.json`, getMSIMetadata),

  // Local SVG and PNG
  http.get('http://localhost:3000/static/media/filing_cabinet_grey.*.svg', bypassRequest),
  http.put('https://s3.aws.com/path/to/thumbnail.png', putUploadUrl),
  // Rules
  http.get('https://s3.aws.com/path/to/rules.json', getRulesObject),
  http.get('https://s3.aws.com/path/to/rulesWithError.json', getRulesObjectWithError),
  http.all('https://api2.amplitude.com/*', amplitudeHandler),
  // LD requests
  http.get('https://clientstream.launchdarkly.com/*', getLDClientStream),
  http.post('https://events.launchdarkly.com/*', getLDEvents),
  http.get('https://app.launchdarkly.com/*', getLDApp),
  http.all('https://esp.aptrinsic.com/*', getGainsightRequest),
]; // block outgoing http calls by default

export const mswUnhandledPathHandler = [
  http.all('*', ({ request }) => {
    const url = new URL(request.url);

    console.error(`Please add request handler for ${url.toString()}`);
    return HttpResponse.json({ error: 'Please add request handler.' }, { status: 500 });
  }),
];
