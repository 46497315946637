import { Section } from '../RFOModal.styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import text from 'global/text.json';
import Button from '@mui/material/Button';
import { LocationInfo, Spacing } from '@mid-react-common/common';
import useRFOModalStore from '../../../../../context/RFOModalStore/useRFOModalStore';
import { useShallow } from 'zustand/react/shallow';
import { useMemo } from 'react';
import { rfoModalTestIds } from 'tests/helpers/dataTestIds';
import { getFolderNameAndFullPath } from '../utils';

const checkoutStepText = text.rfoModal.checkoutStep;
const checkoutStepTestIds = rfoModalTestIds.checkoutStep;

const UploadLocationInfo: React.FC = () => {
  const {
    uploadLocationProject,
    uploadFolderUrn,
    uploadLocationTree,
    editPermissions,
    isGeneratingOutputs,
    setUploadLocationStepActive,
  } = useRFOModalStore(
    useShallow((state) => ({
      uploadLocationProject: state.uploadLocationProject,
      uploadLocationTree: state.uploadLocationTree,
      uploadFolderUrn: state.uploadFolderUrn,
      editPermissions: state.editPermissions,
      isGeneratingOutputs: state.isGeneratingOutputs,
      setUploadLocationStepActive: state.setUploadLocationStepActive,
    })),
  );

  const { folderName: uploadFolderName, fullPath: uploadFolderBreadcrumbs } = useMemo(
    () => getFolderNameAndFullPath(uploadFolderUrn, uploadLocationTree),
    [uploadFolderUrn, uploadLocationTree],
  );
  return (
    <Section>
      <Box display="flex" alignItems="baseline" justifyContent="space-between">
        <Typography fontWeight="bold" variant="h3">
          {checkoutStepText.uploadLocation}
        </Typography>
        <Button
          data-testid={checkoutStepTestIds.uploadLocationStepChangeButton}
          onClick={setUploadLocationStepActive}
          disabled={isGeneratingOutputs}
        >
          {checkoutStepText.change}
        </Button>
      </Box>
      <Spacing />
      {uploadLocationProject && (
        <LocationInfo
          accountName={uploadLocationProject.accountName}
          projectVendor={uploadLocationProject.vendor}
          projectName={uploadLocationProject.name}
          selectedItemName={uploadFolderName}
          fullPath={uploadFolderBreadcrumbs}
          editPermissions={editPermissions}
        />
      )}
    </Section>
  );
};

export default UploadLocationInfo;
