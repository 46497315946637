/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import stubsAPI from '../tests/helpers/stubsAPI';

export default {
  Viewing: {
    theExtensionManager: {
      registerExtension: stubsAPI.stubFn(),
    },
    UI: {
      DockingPanel: class DockingPanel {},
    },
    Extension: class Extension {},
    Document: {
      load: stubsAPI.stubFn(),
    },
    GuiViewer3D: class GuiViewer3D {
      setTheme = stubsAPI.stubFn();
      addEventListener = stubsAPI.stubFn();
      registerContextMenuCallback = stubsAPI.stubFn();
      getAggregateSelection = stubsAPI.stubFn();
      getAggregateIsolation = stubsAPI.stubFn();
      select = stubsAPI.stubFn();
      toggleSelect = stubsAPI.stubFn();
      toggleVisibility = stubsAPI.stubFn();
      isNodeVisible = stubsAPI.stubFn();
      clearSelection = stubsAPI.stubFn();
      resize = stubsAPI.stubFn();
      modelstructure = {
        scrollToSelection: stubsAPI.stubFn(),
        tree: stubsAPI.stubFn(),
      };
      model = {
        getInstanceTree: () => ({
          enumNodeChildren: stubsAPI.stubFn(),
          getNodeParentId: stubsAPI.stubFn(),
          getRootId: stubsAPI.stubFn(),
        }),
        getPropertyDb: () => ({
          getProperties2: stubsAPI.stubFn(),
          getBulkProperties2: stubsAPI.stubFn(),
          executeUserFunction: stubsAPI.stubFn(),
        }),
        getFragmentList: stubsAPI.stubFn(),
        visibilityManager: {
          isolateMultiple: stubsAPI.stubFn(),
          isolateNone: stubsAPI.stubFn(),
        },
      };
      impl = {
        model: this.model,
        api: { dispatchEvent: stubsAPI.stubFn() },
        invalidate: stubsAPI.stubFn(),
      };
      unloadModel = stubsAPI.stubFn();
    },
    SELECTION_CHANGED_EVENT: 'selection',
    AGGREGATE_ISOLATION_CHANGED_EVENT: 'aggregateIsolation',
    SHOW_ALL_EVENT: 'showAll',
    OBJECT_TREE_CREATED_EVENT: 'propertyDbLoaded',
    Private: {
      VertexEnumerator: {
        enumMeshEdges: stubsAPI.stubFn(),
      },
    },
  },
};
