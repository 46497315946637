import { CustomError } from './customError';
import { ErrorCode } from './errorCode';

export class VersionInfoLoadError extends CustomError {
  errorCode = ErrorCode.VersionInfoLoadError;

  constructor(message: string) {
    super(message);
  }
}
