import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { AccProject } from 'mid-types';
import text from '../../../../global/text.json';
import { accountProjectSelectorTestIds } from '../../../../tests/helpers/dataTestIds';
import { AccountDetails } from '../AccountDetails/AccountDetails';
import {
  ProjectListContainer,
  ProjectsListTitle,
  ProjectsMenuList,
  ProjectsMenuListItem,
  SearchBarContainer,
  SearchIconWrapper,
  SearchInputBase,
} from './ProjectsList.style';

interface ProjectsListProps {
  projects: AccProject[];
  visible: boolean;
  accountName: string | undefined;
  accountImageURL: string | undefined;
  handleProjectSelection: (project: AccProject) => void;
  handleChangeAccount: () => void;
  handleSearchProjects: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const accountProjectSelectorText = text.accountProjectSelector;

const ProjectsList: React.FC<ProjectsListProps> = ({
  projects,
  visible,
  accountName,
  accountImageURL,
  handleProjectSelection,
  handleChangeAccount,
  handleSearchProjects,
}): JSX.Element => (
  <ProjectListContainer visible={visible}>
    <SearchBarContainer>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <SearchInputBase
        data-testid={accountProjectSelectorTestIds.searchInput}
        placeholder={accountProjectSelectorText.search}
        onChange={handleSearchProjects}
      />
    </SearchBarContainer>
    <Divider />
    <AccountDetails
      projectsQty={projects?.length}
      accountName={accountName}
      accountImageURL={accountImageURL}
      handleChangeAccount={handleChangeAccount}
    />
    <Divider />
    <ProjectsListTitle variant="h6">{accountProjectSelectorText.projects}</ProjectsListTitle>
    <ProjectsMenuList data-testid={accountProjectSelectorTestIds.projectList}>
      {projects.map((project) => (
        <ProjectsMenuListItem key={`${project.accountId}${project.id}`} onClick={() => handleProjectSelection(project)}>
          <Typography variant="body2">{project.name}</Typography>
        </ProjectsMenuListItem>
      ))}
    </ProjectsMenuList>
  </ProjectListContainer>
);

export default ProjectsList;
