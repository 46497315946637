import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';

type ProjectListContainerProps = {
  visible: boolean;
};

export const ProjectListContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'visible',
})<ProjectListContainerProps>(({ visible }) => ({
  maxWidth: visible ? '400px' : '0px',
  maxHeight: !visible ? '0px' : '60vh',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all .3s ease',
  overflow: 'hidden',
}));

export const ProjectsListTitle = styled(Typography)`
  margin: 8px 12px 0px 12px;
`;

export const ProjectsMenuList = styled(MenuList)(() => ({
  maxHeight: '600px',
  flexGrow: 10,
  overflow: 'auto',
}));

export const ProjectsMenuListItem = styled(MenuItem)(() => ({
  padding: '10px 10px 10px 12px',
}));

export const SearchBarContainer = styled('div')`
  position: relative;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  background-color: ${({ theme }) => alpha(theme.palette.common.white, 0.15)};
  &:hover {
    background-color: ${({ theme }) => alpha(theme.palette.common.white, 0.25)};
  }
`;

export const SearchIconWrapper = styled('div')`
  padding: ${({ theme }) => `0 ${theme.var.paddingBase}px`};
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
`;

export const SearchInputBase = styled(InputBase)`
  width: 100%;
  padding-inline: ${({ theme }) => `${theme.var.paddingBase * 4}px ${theme.var.paddingBase}px`};
`;
