import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { TooltipBodyTypography } from '@mid-react-common/common';

export const UploadLocationStepWrapper = styled('div')`
  padding: ${({ theme }) => theme.var.paddingBase * 2}px 0;
`;

export const FolderTreeDivider = styled(Divider)`
  margin: ${({ theme }) => theme.var.marginBase}px 0;
`;

export const FolderTooltipBodyTypography = styled(TooltipBodyTypography)`
  margin-bottom: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const FolderTooltipContainer = styled(Box)`
  padding: ${({ theme }) => `${theme.var.paddingBase}px`};
`;
