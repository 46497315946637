export const mockCodeBlockWorkspaceWithSimpleAssignment = {
  blocks: {
    languageVersion: 0,
    blocks: [
      {
        type: 'universal_input_block',
        id: '}3FOr||gvoMbdXGBuj=~',
        x: 17,
        y: 21,
        extraState: {
          inputsDropdown: '{"name":"EqualCheck","type":"boolean"}',
          functionsDropdown: 'value',
        },
        fields: {
          dropdown: '{"name":"EqualCheck","type":"boolean"}',
          functions_dropdown: 'value',
        },
        inputs: {
          connecting_block: {
            block: {
              type: 'logic_boolean',
              id: 'Z#Jou_(TjagKgBtGSaG,',
              fields: {
                BOOL: 'TRUE',
              },
            },
          },
        },
      },
    ],
  },
};

export const mockCodeBlockWorkspaceWithNumericValue = {
  blocks: {
    languageVersion: 0,
    blocks: [
      {
        type: 'universal_input_block',
        id: '}3FOr||gvoMbdXGBuj=~',
        x: 17,
        y: 21,
        extraState: {
          inputsDropdown: '{"name":"Width","type":"numeric"}',
          functionsDropdown: 'min',
        },
        fields: {
          dropdown: '{"name":"Width","type":"numeric"}',
          functions_dropdown: 'min',
        },
        inputs: {
          connecting_block: {
            block: {
              type: 'math_number',
              id: 'H63[2o(BoGRsP+sBzF#Q',
              fields: {
                NUM: 10,
              },
            },
          },
        },
      },
    ],
  },
};

export const mockCodeBlockWorkspaceWithMultipleInvalidNumericValues = {
  blocks: {
    languageVersion: 0,
    blocks: [
      {
        type: 'universal_input_block',
        id: '}3FOr||gvoMbdXGBuj=~',
        x: 17,
        y: 21,
        extraState: {
          inputsDropdown: '{"name":"Width","type":"numeric"}',
          functionsDropdown: 'min',
        },
        fields: {
          dropdown: '{"name":"Width","type":"numeric"}',
          functions_dropdown: 'min',
        },
        inputs: {
          connecting_block: {
            block: {
              type: 'math_number',
              id: 'H63[2o(BoGRsP+sBzF#Q',
              fields: {
                NUM: 10,
              },
            },
          },
        },
        next: {
          block: {
            type: 'universal_input_block',
            id: 'bK1*!7t{;D_bI-(_PQ)7',
            extraState: {
              inputsDropdown: '{"name":"Height","type":"numeric"}',
              functionsDropdown: 'min',
            },
            fields: {
              dropdown: '{"name":"Height","type":"numeric"}',
              functions_dropdown: 'min',
            },
            inputs: {
              connecting_block: {
                block: {
                  type: 'math_number',
                  id: 'v(?|04{@Cyix(W3M#o-A',
                  fields: {
                    NUM: 20,
                  },
                },
              },
            },
            next: {
              block: {
                type: 'universal_input_block',
                id: '[45vvWIwD8R#mB1sl4~m',
                extraState: {
                  inputsDropdown: '{"name":"Depth","type":"numeric"}',
                  functionsDropdown: 'min',
                },
                fields: {
                  dropdown: '{"name":"Depth","type":"numeric"}',
                  functions_dropdown: 'min',
                },
                inputs: {
                  connecting_block: {
                    block: {
                      type: 'math_number',
                      id: 'IOc2TUD,l8T.jc6rmi5h',
                      fields: {
                        NUM: 30,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
};

export const mockCodeBlocksWorkspaceMockWithConditionalMultiValueAssignments = {
  blocks: {
    languageVersion: 0,
    blocks: [
      {
        type: 'universal_input_block',
        id: '{sQ7b,0]RgfuX$0vbFnQ',
        x: 9,
        y: 35,
        extraState: {
          inputsDropdown: '{"name":"Height","type":"numeric"}',
          functionsDropdown: 'values',
        },
        fields: {
          functions_dropdown: 'values',
        },
        inputs: {
          connecting_block: {
            block: {
              type: 'lists_create_with',
              id: '+~y]iyZLk+NGEn4=Blh4',
              extraState: {
                itemCount: 4,
              },
              inputs: {
                ADD0: {
                  block: {
                    type: 'math_number',
                    id: '09l}]Vp]g0^XvcJo@?R8',
                    fields: {
                      NUM: 30,
                    },
                  },
                },
                ADD1: {
                  block: {
                    type: 'math_number',
                    id: 'Z)rbS.D;{UfSSoW%xbH7',
                    fields: {
                      NUM: 42,
                    },
                  },
                },
                ADD2: {
                  block: {
                    type: 'math_number',
                    id: 'gab}+~.gBIsw7#%u1nl[',
                    fields: {
                      NUM: 54,
                    },
                  },
                },
                ADD3: {
                  block: {
                    type: 'math_number',
                    id: 'kp#S1cfP#BJUX`(6cm@^',
                    fields: {
                      NUM: 66,
                    },
                  },
                },
              },
            },
          },
        },
        next: {
          block: {
            type: 'universal_input_block',
            id: '*(kn/+paspa$2EfjWSE-',
            extraState: {
              inputsDropdown: '{"name":"Material","type":"text"}',
              functionsDropdown: 'values',
            },
            fields: {
              functions_dropdown: 'values',
            },
            inputs: {
              connecting_block: {
                block: {
                  type: 'lists_create_with',
                  id: 'X$r6@CTTS:0Bgo)F5|@5',
                  extraState: {
                    itemCount: 11,
                  },
                  inputs: {
                    ADD0: {
                      block: {
                        type: 'text',
                        id: '!:x;)PX0E(r4U|{%ufEN',
                        fields: {
                          TEXT: 'Laminated Veneer Lumber',
                        },
                      },
                    },
                    ADD1: {
                      block: {
                        type: 'text',
                        id: 'au9o:DY=|w+-(hEBJG$t',
                        fields: {
                          TEXT: 'Particleboard',
                        },
                      },
                    },
                    ADD2: {
                      block: {
                        type: 'text',
                        id: 'FV:M|$q!FeN!GiWhzL@x',
                        fields: {
                          TEXT: 'Ash',
                        },
                      },
                    },
                    ADD3: {
                      block: {
                        type: 'text',
                        id: 'WAq3Q11m1,FXSbNCw]{U',
                        fields: {
                          TEXT: 'Birch',
                        },
                      },
                    },
                    ADD4: {
                      block: {
                        type: 'text',
                        id: 'q.cDVhbu`oOoL~0HG1!q',
                        fields: {
                          TEXT: 'Mahogany',
                        },
                      },
                    },
                    ADD5: {
                      block: {
                        type: 'text',
                        id: 'wOODBnA%i`oll7u^ofjw',
                        fields: {
                          TEXT: 'Maple',
                        },
                      },
                    },
                    ADD6: {
                      block: {
                        type: 'text',
                        id: 'I_ir44xAT3CkZk=6sE~;',
                        fields: {
                          TEXT: 'Oak, Red',
                        },
                      },
                    },
                    ADD7: {
                      block: {
                        type: 'text',
                        id: 'RT0n$VkeIxQZ`3k)z|h`',
                        fields: {
                          TEXT: 'Pine',
                        },
                      },
                    },
                    ADD8: {
                      block: {
                        type: 'text',
                        id: '2TGjO-eQf:UFqsL/kV`T',
                        fields: {
                          TEXT: 'Plywood, Finish',
                        },
                      },
                    },
                    ADD9: {
                      block: {
                        type: 'text',
                        id: 'ciyqXVkG@-;.Y~-Y}@_L',
                        fields: {
                          TEXT: 'Spruce',
                        },
                      },
                    },
                    ADD10: {
                      block: {
                        type: 'text',
                        id: '|K(M0ftV`|,#f|hnf(lb',
                        fields: {
                          TEXT: 'Walnut',
                        },
                      },
                    },
                  },
                },
              },
            },
            next: {
              block: {
                type: 'universal_input_block',
                id: '2_g.fqb]jCX1xdX8x7Xh',
                extraState: {
                  inputsDropdown: '{"name":"Shelves_Qty","type":"numeric"}',
                  functionsDropdown: 'values',
                },
                fields: {
                  functions_dropdown: 'values',
                },
                inputs: {
                  connecting_block: {
                    block: {
                      type: 'lists_create_with',
                      id: 'X$7V9-W0h~|BJ7B,hNI8',
                      extraState: {
                        itemCount: 4,
                      },
                      inputs: {
                        ADD0: {
                          block: {
                            type: 'math_number',
                            id: '0S_7I,,xrsxuv:mWv97G',
                            fields: {
                              NUM: 1,
                            },
                          },
                        },
                        ADD1: {
                          block: {
                            type: 'math_number',
                            id: '3H9IpXTG3X/2:A0F*Lg9',
                            fields: {
                              NUM: 2,
                            },
                          },
                        },
                        ADD2: {
                          block: {
                            type: 'math_number',
                            id: 'a{2}ZHRl$i.F,y]K;9jc',
                            fields: {
                              NUM: 3,
                            },
                          },
                        },
                        ADD3: {
                          block: {
                            type: 'math_number',
                            id: '$7Dfy4}pFuWX`Ic5O;VU',
                            fields: {
                              NUM: 4,
                            },
                          },
                        },
                      },
                    },
                  },
                },
                next: {
                  block: {
                    type: 'controls_if',
                    id: '|I4#uoto7lEwbB;XnN$!',
                    extraState: {
                      elseIfCount: 3,
                    },
                    inputs: {
                      IF0: {
                        block: {
                          type: 'logic_compare',
                          id: '{CUH(JTZpt4aP*/lA@/e',
                          fields: {
                            OP: 'EQ',
                          },
                          inputs: {
                            A: {
                              block: {
                                type: 'universal_output_block',
                                id: 'qLcr4jZ(%-f5*xDv?W,`',
                                extraState: {
                                  inputsDropdown: '{"name":"Height","type":"numeric"}',
                                  functionsDropdown: 'value',
                                },
                                fields: {
                                  functions_dropdown: 'value',
                                },
                              },
                            },
                            B: {
                              block: {
                                type: 'math_number',
                                id: 'Km8BpAW`B(27tEDn.$]]',
                                fields: {
                                  NUM: 30,
                                },
                              },
                            },
                          },
                        },
                      },
                      DO0: {
                        block: {
                          type: 'universal_input_block',
                          id: ':n|O.izE|R-pg!f2Od12',
                          extraState: {
                            inputsDropdown: '{"name":"Shelves_Qty","type":"numeric"}',
                            functionsDropdown: 'values',
                          },
                          fields: {
                            functions_dropdown: 'values',
                          },
                          inputs: {
                            connecting_block: {
                              block: {
                                type: 'lists_create_with',
                                id: ':?OyDYn:8gKbJu(KgP`i',
                                extraState: {
                                  itemCount: 1,
                                },
                                inputs: {
                                  ADD0: {
                                    block: {
                                      type: 'math_number',
                                      id: '.%!{GUmL2s?,Ho{w|%Q3',
                                      fields: {
                                        NUM: 1,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      IF1: {
                        block: {
                          type: 'logic_compare',
                          id: 'A]Y8L#r68tK_+6pPl.$C',
                          fields: {
                            OP: 'EQ',
                          },
                          inputs: {
                            A: {
                              block: {
                                type: 'universal_output_block',
                                id: 'iMe]l~^pl)}Q_6Myv5V!',
                                extraState: {
                                  inputsDropdown: '{"name":"Height","type":"numeric"}',
                                  functionsDropdown: 'value',
                                },
                                fields: {
                                  functions_dropdown: 'value',
                                },
                              },
                            },
                            B: {
                              block: {
                                type: 'math_number',
                                id: '+}1z5%[-Gn9:iteQ.+-Z',
                                fields: {
                                  NUM: 42,
                                },
                              },
                            },
                          },
                        },
                      },
                      DO1: {
                        block: {
                          type: 'universal_input_block',
                          id: '5,cW$U|%PVceM4_|Z|4)',
                          extraState: {
                            inputsDropdown: '{"name":"Shelves_Qty","type":"numeric"}',
                            functionsDropdown: 'values',
                          },
                          fields: {
                            functions_dropdown: 'values',
                          },
                          inputs: {
                            connecting_block: {
                              block: {
                                type: 'lists_create_with',
                                id: 'D;)8h-6B|LD3p=g`|t!c',
                                extraState: {
                                  itemCount: 2,
                                },
                                inputs: {
                                  ADD0: {
                                    block: {
                                      type: 'math_number',
                                      id: 'iy(Y:G-Cz]:![Sh~yu0X',
                                      fields: {
                                        NUM: 1,
                                      },
                                    },
                                  },
                                  ADD1: {
                                    block: {
                                      type: 'math_number',
                                      id: 'Pnh,l8hBx:|*PbUd(Krl',
                                      fields: {
                                        NUM: 2,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      IF2: {
                        block: {
                          type: 'logic_compare',
                          id: 'aduE#e9U*zbHwAQ;{-{c',
                          fields: {
                            OP: 'EQ',
                          },
                          inputs: {
                            A: {
                              block: {
                                type: 'universal_output_block',
                                id: 'PV?D8_N%EBSy7$CW!q4d',
                                extraState: {
                                  inputsDropdown: '{"name":"Height","type":"numeric"}',
                                  functionsDropdown: 'value',
                                },
                                fields: {
                                  functions_dropdown: 'value',
                                },
                              },
                            },
                            B: {
                              block: {
                                type: 'math_number',
                                id: 'xp5rWHV7`XbQ2$(j@K7H',
                                fields: {
                                  NUM: 54,
                                },
                              },
                            },
                          },
                        },
                      },
                      DO2: {
                        block: {
                          type: 'universal_input_block',
                          id: '9w!.^alBxS8]P_4iHv_q',
                          extraState: {
                            inputsDropdown: '{"name":"Shelves_Qty","type":"numeric"}',
                            functionsDropdown: 'values',
                          },
                          fields: {
                            functions_dropdown: 'values',
                          },
                          inputs: {
                            connecting_block: {
                              block: {
                                type: 'lists_create_with',
                                id: 'zdV1tA}h*xlN#V8H-SaF',
                                extraState: {
                                  itemCount: 3,
                                },
                                inputs: {
                                  ADD0: {
                                    block: {
                                      type: 'math_number',
                                      id: '-x?fqQgCYoCBEpMBt2x/',
                                      fields: {
                                        NUM: 1,
                                      },
                                    },
                                  },
                                  ADD1: {
                                    block: {
                                      type: 'math_number',
                                      id: '(+r{aTD^=,4/K#`8kHA#',
                                      fields: {
                                        NUM: 2,
                                      },
                                    },
                                  },
                                  ADD2: {
                                    block: {
                                      type: 'math_number',
                                      id: 'gYwiQ1OLF?fNNJ*JkdC=',
                                      fields: {
                                        NUM: 3,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      IF3: {
                        block: {
                          type: 'logic_compare',
                          id: '@)3~i/Jg/NxyFvFTTKlt',
                          fields: {
                            OP: 'EQ',
                          },
                          inputs: {
                            A: {
                              block: {
                                type: 'universal_output_block',
                                id: 'rDv6N_-Ge3bY5y(#mn81',
                                extraState: {
                                  inputsDropdown: '{"name":"Height","type":"numeric"}',
                                  functionsDropdown: 'value',
                                },
                                fields: {
                                  functions_dropdown: 'value',
                                },
                              },
                            },
                            B: {
                              block: {
                                type: 'math_number',
                                id: '.5DP=^V?k.#DPaX(OmYB',
                                fields: {
                                  NUM: 66,
                                },
                              },
                            },
                          },
                        },
                      },
                      DO3: {
                        block: {
                          type: 'universal_input_block',
                          id: '[#iLu?=uXa2F?8Kt]f!t',
                          extraState: {
                            inputsDropdown: '{"name":"Shelves_Qty","type":"numeric"}',
                            functionsDropdown: 'values',
                          },
                          fields: {
                            functions_dropdown: 'values',
                          },
                          inputs: {
                            connecting_block: {
                              block: {
                                type: 'lists_create_with',
                                id: 'rus[JI{1Wpi8iDxuy#Dl',
                                extraState: {
                                  itemCount: 4,
                                },
                                inputs: {
                                  ADD0: {
                                    block: {
                                      type: 'math_number',
                                      id: '!E!f]1F1^{K`aVr{C3!Z',
                                      fields: {
                                        NUM: 1,
                                      },
                                    },
                                  },
                                  ADD1: {
                                    block: {
                                      type: 'math_number',
                                      id: ']u)UHO{u*J1S:YWyi,2C',
                                      fields: {
                                        NUM: 2,
                                      },
                                    },
                                  },
                                  ADD2: {
                                    block: {
                                      type: 'math_number',
                                      id: 'A2Kx3|`m*.uJoF=EZdN*',
                                      fields: {
                                        NUM: 3,
                                      },
                                    },
                                  },
                                  ADD3: {
                                    block: {
                                      type: 'math_number',
                                      id: '[Yp5+g!fbwBU5*q?V0n]',
                                      fields: {
                                        NUM: 4,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                          next: {
                            block: {
                              type: 'universal_input_block',
                              id: 'W8hc*:u+6,8EQqvP0hMN',
                              extraState: {
                                inputsDropdown: '{"name":"Material","type":"text"}',
                                functionsDropdown: 'values',
                              },
                              fields: {
                                functions_dropdown: 'values',
                              },
                              inputs: {
                                connecting_block: {
                                  block: {
                                    type: 'lists_create_with',
                                    id: ';[MeJA?%uemKEnF*(Jj#',
                                    extraState: {
                                      itemCount: 4,
                                    },
                                    inputs: {
                                      ADD0: {
                                        block: {
                                          type: 'text',
                                          id: 'nBrpX$WcdpsL3k32a`cJ',
                                          fields: {
                                            TEXT: 'Oak, Red',
                                          },
                                        },
                                      },
                                      ADD1: {
                                        block: {
                                          type: 'text',
                                          id: 'IF=vU1h8ZHV=`1VD^f{4',
                                          fields: {
                                            TEXT: 'Maple',
                                          },
                                        },
                                      },
                                      ADD2: {
                                        block: {
                                          type: 'text',
                                          id: '1fQCdaqbC*nx5Qnk-8#8',
                                          fields: {
                                            TEXT: 'Mahogany',
                                          },
                                        },
                                      },
                                      ADD3: {
                                        block: {
                                          type: 'text',
                                          id: '96h1b8znB#|:kL*^3:ek',
                                          fields: {
                                            TEXT: 'Birch',
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
};

export const mockCodeBlocksWorkspaceMockWithConditionalMultiValueMigrated = {
  blocks: {
    languageVersion: 0,
    blocks: [
      {
        type: 'universal_input_block',
        id: '{sQ7b,0]RgfuX$0vbFnQ',
        x: 9,
        y: 35,
        extraState: {
          inputsDropdown: '{"name":"Height","type":"numeric"}',
          functionsDropdown: 'values',
        },
        fields: {
          functions_dropdown: 'values',
        },
        inputs: {
          connecting_block: {
            block: {
              type: 'lists_create_with',
              id: '+~y]iyZLk+NGEn4=Blh4',
              extraState: {
                itemCount: 4,
              },
              inputs: {
                ADD0: {
                  block: {
                    type: 'math_number',
                    id: '09l}]Vp]g0^XvcJo@?R8',
                    fields: {
                      NUM: 30,
                    },
                  },
                },
                ADD1: {
                  block: {
                    type: 'math_number',
                    id: 'Z)rbS.D;{UfSSoW%xbH7',
                    fields: {
                      NUM: 42,
                    },
                  },
                },
                ADD2: {
                  block: {
                    type: 'math_number',
                    id: 'gab}+~.gBIsw7#%u1nl[',
                    fields: {
                      NUM: 54,
                    },
                  },
                },
                ADD3: {
                  block: {
                    type: 'math_number',
                    id: 'kp#S1cfP#BJUX`(6cm@^',
                    fields: {
                      NUM: 66,
                    },
                  },
                },
              },
            },
          },
        },
        next: {
          block: {
            type: 'universal_input_block',
            id: '*(kn/+paspa$2EfjWSE-',
            extraState: {
              inputsDropdown: '{"name":"Material","type":"text"}',
              functionsDropdown: 'values',
            },
            fields: {
              functions_dropdown: 'values',
            },
            inputs: {
              connecting_block: {
                block: {
                  type: 'lists_create_with',
                  id: 'X$r6@CTTS:0Bgo)F5|@5',
                  extraState: {
                    itemCount: 11,
                  },
                  inputs: {
                    ADD0: {
                      block: {
                        type: 'text',
                        id: '!:x;)PX0E(r4U|{%ufEN',
                        fields: {
                          TEXT: 'Laminated Veneer Lumber',
                        },
                      },
                    },
                    ADD1: {
                      block: {
                        type: 'text',
                        id: 'au9o:DY=|w+-(hEBJG$t',
                        fields: {
                          TEXT: 'Particleboard',
                        },
                      },
                    },
                    ADD2: {
                      block: {
                        type: 'text',
                        id: 'FV:M|$q!FeN!GiWhzL@x',
                        fields: {
                          TEXT: 'Ash',
                        },
                      },
                    },
                    ADD3: {
                      block: {
                        type: 'text',
                        id: 'WAq3Q11m1,FXSbNCw]{U',
                        fields: {
                          TEXT: 'Birch',
                        },
                      },
                    },
                    ADD4: {
                      block: {
                        type: 'text',
                        id: 'q.cDVhbu`oOoL~0HG1!q',
                        fields: {
                          TEXT: 'Mahogany',
                        },
                      },
                    },
                    ADD5: {
                      block: {
                        type: 'text',
                        id: 'wOODBnA%i`oll7u^ofjw',
                        fields: {
                          TEXT: 'Maple',
                        },
                      },
                    },
                    ADD6: {
                      block: {
                        type: 'text',
                        id: 'I_ir44xAT3CkZk=6sE~;',
                        fields: {
                          TEXT: 'Oak, Red',
                        },
                      },
                    },
                    ADD7: {
                      block: {
                        type: 'text',
                        id: 'RT0n$VkeIxQZ`3k)z|h`',
                        fields: {
                          TEXT: 'Pine',
                        },
                      },
                    },
                    ADD8: {
                      block: {
                        type: 'text',
                        id: '2TGjO-eQf:UFqsL/kV`T',
                        fields: {
                          TEXT: 'Plywood, Finish',
                        },
                      },
                    },
                    ADD9: {
                      block: {
                        type: 'text',
                        id: 'ciyqXVkG@-;.Y~-Y}@_L',
                        fields: {
                          TEXT: 'Spruce',
                        },
                      },
                    },
                    ADD10: {
                      block: {
                        type: 'text',
                        id: '|K(M0ftV`|,#f|hnf(lb',
                        fields: {
                          TEXT: 'Walnut',
                        },
                      },
                    },
                  },
                },
              },
            },
            next: {
              block: {
                type: 'universal_input_block',
                id: '2_g.fqb]jCX1xdX8x7Xh',
                extraState: {
                  inputsDropdown: '{"name":"Shelves_Qty","type":"numeric"}',
                  functionsDropdown: 'values',
                },
                fields: {
                  functions_dropdown: 'values',
                },
                inputs: {
                  connecting_block: {
                    block: {
                      type: 'lists_create_with',
                      id: 'X$7V9-W0h~|BJ7B,hNI8',
                      extraState: {
                        itemCount: 4,
                      },
                      inputs: {
                        ADD0: {
                          block: {
                            type: 'math_number',
                            id: '0S_7I,,xrsxuv:mWv97G',
                            fields: {
                              NUM: 1,
                            },
                          },
                        },
                        ADD1: {
                          block: {
                            type: 'math_number',
                            id: '3H9IpXTG3X/2:A0F*Lg9',
                            fields: {
                              NUM: 2,
                            },
                          },
                        },
                        ADD2: {
                          block: {
                            type: 'math_number',
                            id: 'a{2}ZHRl$i.F,y]K;9jc',
                            fields: {
                              NUM: 3,
                            },
                          },
                        },
                        ADD3: {
                          block: {
                            type: 'math_number',
                            id: '$7Dfy4}pFuWX`Ic5O;VU',
                            fields: {
                              NUM: 4,
                            },
                          },
                        },
                      },
                    },
                  },
                },
                next: {
                  block: {
                    type: 'controls_if',
                    id: '|I4#uoto7lEwbB;XnN$!',
                    extraState: {
                      elseIfCount: 3,
                    },
                    inputs: {
                      IF0: {
                        block: {
                          type: 'logic_compare',
                          id: '{CUH(JTZpt4aP*/lA@/e',
                          fields: {
                            OP: 'EQ',
                          },
                          inputs: {
                            A: {
                              block: {
                                type: 'universal_output_block',
                                id: 'qLcr4jZ(%-f5*xDv?W,`',
                                extraState: {
                                  inputsDropdown: '{"name":"Height","type":"numeric"}',
                                  functionsDropdown: 'value',
                                },
                                fields: {
                                  functions_dropdown: 'value',
                                },
                              },
                            },
                            B: {
                              block: {
                                type: 'math_number',
                                id: 'Km8BpAW`B(27tEDn.$]]',
                                fields: {
                                  NUM: 30,
                                },
                              },
                            },
                          },
                        },
                      },
                      DO0: {
                        block: {
                          type: 'universal_input_block',
                          id: ':n|O.izE|R-pg!f2Od12',
                          extraState: {
                            inputsDropdown: '{"name":"Shelves_Qty","type":"numeric"}',
                            functionsDropdown: 'values',
                          },
                          fields: {
                            functions_dropdown: 'values',
                          },
                          inputs: {
                            connecting_block: {
                              block: {
                                type: 'lists_create_with',
                                id: ':?OyDYn:8gKbJu(KgP`i',
                                extraState: {
                                  itemCount: 1,
                                },
                                inputs: {
                                  ADD0: {
                                    block: {
                                      type: 'math_number',
                                      id: '.%!{GUmL2s?,Ho{w|%Q3',
                                      fields: {
                                        NUM: 1,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      IF1: {
                        block: {
                          type: 'logic_compare',
                          id: 'A]Y8L#r68tK_+6pPl.$C',
                          fields: {
                            OP: 'EQ',
                          },
                          inputs: {
                            A: {
                              block: {
                                type: 'universal_output_block',
                                id: 'iMe]l~^pl)}Q_6Myv5V!',
                                extraState: {
                                  inputsDropdown: '{"name":"Height","type":"numeric"}',
                                  functionsDropdown: 'value',
                                },
                                fields: {
                                  functions_dropdown: 'value',
                                },
                              },
                            },
                            B: {
                              block: {
                                type: 'math_number',
                                id: '+}1z5%[-Gn9:iteQ.+-Z',
                                fields: {
                                  NUM: 42,
                                },
                              },
                            },
                          },
                        },
                      },
                      DO1: {
                        block: {
                          type: 'universal_input_block',
                          id: '5,cW$U|%PVceM4_|Z|4)',
                          extraState: {
                            inputsDropdown: '{"name":"Shelves_Qty","type":"numeric"}',
                            functionsDropdown: 'values',
                          },
                          fields: {
                            functions_dropdown: 'values',
                          },
                          inputs: {
                            connecting_block: {
                              block: {
                                type: 'lists_create_with',
                                id: 'D;)8h-6B|LD3p=g`|t!c',
                                extraState: {
                                  itemCount: 2,
                                },
                                inputs: {
                                  ADD0: {
                                    block: {
                                      type: 'math_number',
                                      id: 'iy(Y:G-Cz]:![Sh~yu0X',
                                      fields: {
                                        NUM: 1,
                                      },
                                    },
                                  },
                                  ADD1: {
                                    block: {
                                      type: 'math_number',
                                      id: 'Pnh,l8hBx:|*PbUd(Krl',
                                      fields: {
                                        NUM: 2,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      IF2: {
                        block: {
                          type: 'logic_compare',
                          id: 'aduE#e9U*zbHwAQ;{-{c',
                          fields: {
                            OP: 'EQ',
                          },
                          inputs: {
                            A: {
                              block: {
                                type: 'universal_output_block',
                                id: 'PV?D8_N%EBSy7$CW!q4d',
                                extraState: {
                                  inputsDropdown: '{"name":"Height","type":"numeric"}',
                                  functionsDropdown: 'value',
                                },
                                fields: {
                                  functions_dropdown: 'value',
                                },
                              },
                            },
                            B: {
                              block: {
                                type: 'math_number',
                                id: 'xp5rWHV7`XbQ2$(j@K7H',
                                fields: {
                                  NUM: 54,
                                },
                              },
                            },
                          },
                        },
                      },
                      DO2: {
                        block: {
                          type: 'universal_input_block',
                          id: '9w!.^alBxS8]P_4iHv_q',
                          extraState: {
                            inputsDropdown: '{"name":"Shelves_Qty","type":"numeric"}',
                            functionsDropdown: 'values',
                          },
                          fields: {
                            functions_dropdown: 'values',
                          },
                          inputs: {
                            connecting_block: {
                              block: {
                                type: 'lists_create_with',
                                id: 'zdV1tA}h*xlN#V8H-SaF',
                                extraState: {
                                  itemCount: 3,
                                },
                                inputs: {
                                  ADD0: {
                                    block: {
                                      type: 'math_number',
                                      id: '-x?fqQgCYoCBEpMBt2x/',
                                      fields: {
                                        NUM: 1,
                                      },
                                    },
                                  },
                                  ADD1: {
                                    block: {
                                      type: 'math_number',
                                      id: '(+r{aTD^=,4/K#`8kHA#',
                                      fields: {
                                        NUM: 2,
                                      },
                                    },
                                  },
                                  ADD2: {
                                    block: {
                                      type: 'math_number',
                                      id: 'gYwiQ1OLF?fNNJ*JkdC=',
                                      fields: {
                                        NUM: 3,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      IF3: {
                        block: {
                          type: 'logic_compare',
                          id: '@)3~i/Jg/NxyFvFTTKlt',
                          fields: {
                            OP: 'EQ',
                          },
                          inputs: {
                            A: {
                              block: {
                                type: 'universal_output_block',
                                id: 'rDv6N_-Ge3bY5y(#mn81',
                                extraState: {
                                  inputsDropdown: '{"name":"Height","type":"numeric"}',
                                  functionsDropdown: 'value',
                                },
                                fields: {
                                  functions_dropdown: 'value',
                                },
                              },
                            },
                            B: {
                              block: {
                                type: 'math_number',
                                id: '.5DP=^V?k.#DPaX(OmYB',
                                fields: {
                                  NUM: 66,
                                },
                              },
                            },
                          },
                        },
                      },
                      DO3: {
                        block: {
                          type: 'universal_input_block',
                          id: '[#iLu?=uXa2F?8Kt]f!t',
                          extraState: {
                            inputsDropdown: '{"name":"Shelves_Qty","type":"numeric"}',
                            functionsDropdown: 'values',
                          },
                          fields: {
                            functions_dropdown: 'values',
                          },
                          inputs: {
                            connecting_block: {
                              block: {
                                type: 'lists_create_with',
                                id: 'rus[JI{1Wpi8iDxuy#Dl',
                                extraState: {
                                  itemCount: 4,
                                },
                                inputs: {
                                  ADD0: {
                                    block: {
                                      type: 'math_number',
                                      id: '!E!f]1F1^{K`aVr{C3!Z',
                                      fields: {
                                        NUM: 1,
                                      },
                                    },
                                  },
                                  ADD1: {
                                    block: {
                                      type: 'math_number',
                                      id: ']u)UHO{u*J1S:YWyi,2C',
                                      fields: {
                                        NUM: 2,
                                      },
                                    },
                                  },
                                  ADD2: {
                                    block: {
                                      type: 'math_number',
                                      id: 'A2Kx3|`m*.uJoF=EZdN*',
                                      fields: {
                                        NUM: 3,
                                      },
                                    },
                                  },
                                  ADD3: {
                                    block: {
                                      type: 'math_number',
                                      id: '[Yp5+g!fbwBU5*q?V0n]',
                                      fields: {
                                        NUM: 4,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                          next: {
                            block: {
                              type: 'universal_input_block',
                              id: 'W8hc*:u+6,8EQqvP0hMN',
                              extraState: {
                                inputsDropdown: '{"name":"Material","type":"text"}',
                                functionsDropdown: 'values',
                              },
                              fields: {
                                functions_dropdown: 'values',
                              },
                              inputs: {
                                connecting_block: {
                                  block: {
                                    type: 'lists_create_with',
                                    id: ';[MeJA?%uemKEnF*(Jj#',
                                    extraState: {
                                      itemCount: 4,
                                    },
                                    inputs: {
                                      ADD0: {
                                        block: {
                                          type: 'text',
                                          id: 'nBrpX$WcdpsL3k32a`cJ',
                                          fields: {
                                            TEXT: 'Oak, Red',
                                          },
                                        },
                                      },
                                      ADD1: {
                                        block: {
                                          type: 'text',
                                          id: 'IF=vU1h8ZHV=`1VD^f{4',
                                          fields: {
                                            TEXT: 'Maple',
                                          },
                                        },
                                      },
                                      ADD2: {
                                        block: {
                                          type: 'text',
                                          id: '1fQCdaqbC*nx5Qnk-8#8',
                                          fields: {
                                            TEXT: 'Mahogany',
                                          },
                                        },
                                      },
                                      ADD3: {
                                        block: {
                                          type: 'text',
                                          id: '96h1b8znB#|:kL*^3:ek',
                                          fields: {
                                            TEXT: 'Birch',
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
};
