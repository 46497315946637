//import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

export const RowLabel = styled(TableCell)`
  width: 30%;
  vertical-align: top;
  background-color: ${({ theme }) => theme.colors.surface.lightGray[10]};
`;

export const RowValueWrapper = styled('div')`
  white-space: nowrap;
  & ul {
    list-style-type: none;
  }
`;

export const RowValue = styled(TableCell)`
  width: 70%;
  background-color: ${({ theme }) => theme.colors.surface.lightGray[10]};
`;
