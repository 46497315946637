import text from '../../../global/text.json';

const uploadLocationText = text.uploadLocation;

// The official API sends back error messages that are grammatically
// incorrect, so we transform accordingly

// Actual messages from the API:
// https://aps.autodesk.com/en/docs/data/v2/reference/http/projects-project_id-folders-POST/
// Folder already exists (409)?  => Folder my-folder-name already
// exist under urn:adsk.wipstg:fs.folder:co.CfhwABNCTM2w-Qaytzfkhw (Invalid grammar & we do not want to show the urn)
// Invalid Characters (400)? "< > : \" / \\ | ? * ` are not allowed in folder/file names." (Message ok)
// Folder name too long (400)?  => "The folder/file name length should between 1-255." (Invalid grammar)
export const improveErrorDetailFromSubFolderCreationRequest = (statusCode: string, errorDetail: string): string => {
  const parsedStatusCode: number = parseInt(statusCode);
  if (parsedStatusCode === 409) {
    return uploadLocationText.folderAlreadyExistsError;
  }

  if (errorDetail.includes('1-') && errorDetail.includes('between') && errorDetail.includes('length')) {
    return uploadLocationText.folderNameLengthError;
  }

  // All other error messages can be returned as is.
  return errorDetail;
};
