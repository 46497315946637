import TableHead from '@mui/material/TableHead';
import { RFOTableRowWrapper, RFOTableHeadWrapper } from '../CheckoutStep.styles';
import text from 'global/text.json';
import Typography from '@mui/material/Typography';
const checkoutStepText = text.rfoModal.checkoutStep;

const OutputsTableHeader: React.FC = () => (
  <TableHead>
    <RFOTableRowWrapper>
      <RFOTableHeadWrapper align="left">
        <Typography fontWeight="bold">{checkoutStepText.fileTypeTableHeader}</Typography>
      </RFOTableHeadWrapper>
      <RFOTableHeadWrapper align="right">
        <Typography fontWeight="bold">{checkoutStepText.quantityTableHeader}</Typography>
      </RFOTableHeadWrapper>
      <RFOTableHeadWrapper align="right">
        <Typography fontWeight="bold">{checkoutStepText.tokenCostTableHeader}</Typography>
      </RFOTableHeadWrapper>
    </RFOTableRowWrapper>
  </TableHead>
);

export default OutputsTableHeader;
