import { PostVariantOutput, ProductRelease, PutProductUserConfigurationPayload, Vendors } from '@adsk/offsite-dc-sdk';
import { uniq } from 'lodash';
import { getDcApiServiceInstance } from 'mid-api-services';
import { OutputTypeWithVirtualTypes, PostVariantOutputWithVirtualTypes } from 'mid-types';
import text from 'global/text.json';
import { Instance } from 'types/product';

const productsText = text.productsStore;

export type VariantIdProductMap = { [key: string]: ProductRelease };

const getCurrentProductRelease = (productId: string, productRelease: number, products: ProductRelease[]): ProductRelease => {
  const currentProductRelease = products.find(
    (product) => product.contentId === productId && product.release === productRelease,
  );
  if (!currentProductRelease) {
    throw new Error(productsText.failedToFetchAssociateProducts);
  }
  return currentProductRelease;
};

export const getVariantIdProductMap = (
  selectedVariantInstances: Instance[],
  products: ProductRelease[],
  selectedProductId: string,
): VariantIdProductMap =>
  selectedVariantInstances.reduce<VariantIdProductMap>((prev, current) => {
    if (!prev[current.variantId] && current.contentId === selectedProductId) {
      prev[current.variantId] = getCurrentProductRelease(selectedProductId, current.release, products);
    }
    return prev;
  }, {});

type SaveUserConfigProps = {
  selectedOutputs: PostVariantOutputWithVirtualTypes[];
  uploadFolderUrn: string;
  uploadLocationAccountId: string;
  uploadLocationProjectId: string;
  selectedProjectId: string;
  selectedProductId: string;
  vendor: Vendors;
  incomingAccBridgeData: {
    targetProjcetId?: string;
    sourceProjectId?: string;
    sourceFolderUrn?: string;
  };
};

export const updateProductUserConfiguration = async ({
  selectedOutputs,
  uploadFolderUrn,
  uploadLocationAccountId,
  uploadLocationProjectId,
  selectedProjectId,
  selectedProductId,
  vendor,
  incomingAccBridgeData,
}: SaveUserConfigProps): Promise<void> => {
  const outputTypes = uniq(
    selectedOutputs
      // filter out the virtual output types
      .filter<PostVariantOutput>(
        (selectedOutput): selectedOutput is PostVariantOutput =>
          selectedOutput.type !== OutputTypeWithVirtualTypes.BOMAGGREGATED,
      )
      .map((output) => output.type),
  );

  const productUserConfigurationPayload = outputTypes.reduce<PutProductUserConfigurationPayload>(
    (payload, outputType) => ({
      outputLocations: {
        ...payload.outputLocations,
        [outputType]: {
          vendor,
          accountId: uploadLocationAccountId,
          projectId: uploadLocationProjectId,
          outputLocation: uploadFolderUrn,
        },
      },
    }),
    { outputLocations: {} },
  );
  await getDcApiServiceInstance().updateProductUserConfiguration({
    projectId: selectedProjectId,
    productId: selectedProductId,
    productUserConfigurationPayload,
    incomingAccBridgeData,
  });
};
