import { StateCreator } from 'zustand';

export interface StepperSlice {
  activeStep: number;
  setFileTypesStepActive: () => void;
  setUploadLocationStepActive: () => void;
  setCheckoutStepActive: () => void;
  isFileTypesStepCompleted: boolean;
  setIsFileTypesStepCompleted: (isFileTypesStepCompleted: boolean) => void;
  isUploadLocationStepCompleted: boolean;
  setIsUploadLocationStepCompleted: (isUploadLocationStepCompleted: boolean) => void;
  isCheckoutValidationCompleted: boolean;
  setIsCheckoutValidationCompleted: (isCheckoutValidationCompleted: boolean) => void;
  isCheckoutStepLoading: boolean;
  setIsCheckoutStepLoading: (isCheckoutStepLoading: boolean) => void;
}

export const stepsMap = {
  fileTypes: 0,
  uploadLocation: 1,
  checkout: 2,
};

export const createStepperSlice: StateCreator<StepperSlice> = (set) => ({
  activeStep: stepsMap.fileTypes,
  setFileTypesStepActive: () => set({ activeStep: stepsMap.fileTypes }),
  setUploadLocationStepActive: () => set({ activeStep: stepsMap.uploadLocation }),
  setCheckoutStepActive: () => set({ activeStep: stepsMap.checkout }),
  isFileTypesStepCompleted: false,
  setIsFileTypesStepCompleted: (isFileTypesStepCompleted: boolean) => set({ isFileTypesStepCompleted }),
  isUploadLocationStepCompleted: false,
  setIsUploadLocationStepCompleted: (isUploadLocationStepCompleted: boolean) => set({ isUploadLocationStepCompleted }),
  isCheckoutValidationCompleted: false,
  setIsCheckoutValidationCompleted: (isCheckoutValidationCompleted: boolean) => set({ isCheckoutValidationCompleted }),
  isCheckoutStepLoading: true,
  setIsCheckoutStepLoading: (isCheckoutStepLoading: boolean) => set({ isCheckoutStepLoading }),
});
