import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TitleContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  minHeight: '60px',
  maxHeight: '60px',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: '8px',
}));

export const FolderContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '2',
  minWidth: '200px',
  maxWidth: '250px',
  alignItems: 'stretch',
  paddingLeft: '4px',
  backgroundColor: 'white',
}));

export const ProjectFoldersTreeWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
