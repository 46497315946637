import { CloseIconButton, CustomDialog } from '@mid-react-common/common';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import React from 'react';
import text from '../../../../global/text.json';
import { outputsReviewPanel } from '../../../../tests/helpers/dataTestIds';
import LogDataWithNumbers from './LogDataWithNumbers';

interface LogDialogProps {
  fileData: string;
  isDialogOpen: boolean;
  setIsDialogOpen: (isOpen: boolean) => void;
  handleDownloadFile: () => void;
}

const reviewPanelText = text.reviewPanel;

const LogDialog: React.FC<LogDialogProps> = ({
  fileData,
  isDialogOpen,
  setIsDialogOpen,
  handleDownloadFile,
}: LogDialogProps) => {
  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <CustomDialog
      maxWidth="lg"
      dividers
      open={isDialogOpen}
      title={
        <>
          {reviewPanelText.failureLog}
          <CloseIconButton onClick={handleClose}>
            <CloseIcon />
          </CloseIconButton>
        </>
      }
      customActions={[
        <Button
          key={outputsReviewPanel.logDownload}
          data-testid={outputsReviewPanel.logDataClose}
          variant="outlined"
          onClick={handleClose}
        >
          {reviewPanelText.close}
        </Button>,
        <Button
          key={outputsReviewPanel.logDownload}
          data-testid={outputsReviewPanel.logDownload}
          variant="contained"
          onClick={handleDownloadFile}
          startIcon={<DownloadIcon />}
        >
          {reviewPanelText.download}
        </Button>,
      ]}
    >
      <LogDataWithNumbers logData={fileData} />
    </CustomDialog>
  );
};

export default LogDialog;
