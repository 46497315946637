import { CustomError } from './customError';
import { ErrorCode } from './errorCode';

type CauseType = {
  error?: any;
};

export class UserInfoError extends CustomError {
  errorCode = ErrorCode.UserInfoError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
