export const closeReasons = {
  TIMEOUT: 'timeout',
  CLICKAWAY: 'clickaway',
  ESCAPE_KEY_DOWN: 'escapeKeyDown',
} as const;

export type HandleCloseReason = (typeof closeReasons)[keyof typeof closeReasons];

export enum NOTIFICATION_STATUSES {
  NONE = 'none',
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}
