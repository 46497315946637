import { OutputType, OutputTypes } from '@adsk/offsite-dc-sdk';
import { CloseIconButton, CustomDialog } from '@mid-react-common/common';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { GridRenderCellParams } from '@weave-mui/data-grid';
import React, { ReactNode, useState } from 'react';
import text from '../../../../global/text.json';
import { outputsReviewPanel } from '../../../../tests/helpers/dataTestIds';
import {
  InstancesReviewOpener,
  ResultDescription,
  ResultInfo,
  ResultName,
  ResultsBoldSpan,
  ResultsRow,
  ResultsTable,
} from '../ReviewPanel.styles';
import { OutputsReviewTableDataModel } from '../Types';

const reviewPanelText = text.reviewPanel;

interface ResultData {
  name: string;
  info: string | number | undefined;
}

const exportFileHeader = "'text/csv;charset=utf-8;'";
const maxElementIdsDisplay = 6;
const newLine = '\n';

const InstancesResultDetails: React.FC<GridRenderCellParams<any, OutputsReviewTableDataModel>> = (params) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const { elementId, modifiedAt, fileType, modelState, productName, release, name, instanceList } = params.row;

  if (instanceList.length === 1) {
    return <Typography data-testid={outputsReviewPanel.instancesReviewElementId}>{elementId}</Typography>;
  }

  const resultDataList: ResultData[] = [
    {
      name: reviewPanelText.jobStartedOn,
      info: modifiedAt,
    },
    {
      name: reviewPanelText.fileType,
      info: humanFriendlyFileTypeDescription(fileType),
    },
    {
      name: reviewPanelText.modelState,
      info: modelState,
    },
    {
      name: reviewPanelText.productName,
      info: productName,
    },
    {
      name: reviewPanelText.release,
      info: release,
    },
    {
      name: reviewPanelText.variantName,
      info: name,
    },
    {
      name: reviewPanelText.instancesAssigned,
      info: instanceList.length,
    },
  ];

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);
  const handleDownloadFile = () => dataToCsv(resultDataList, instanceList, name, fileType);

  return (
    <>
      <InstancesReviewOpener data-testid={outputsReviewPanel.instancesReviewLink} color="primary" onClick={handleOpenDialog}>
        {instanceList.length} {reviewPanelText.instances}
      </InstancesReviewOpener>
      <CustomDialog
        data-testid={outputsReviewPanel.instancesResultDialog}
        dividers
        open={dialogOpen}
        title={
          <>
            {reviewPanelText.resultFileDialogTitle}
            <CloseIconButton onClick={handleCloseDialog}>
              <CloseIcon />
            </CloseIconButton>
          </>
        }
        customActions={[
          <Button
            key={outputsReviewPanel.downloadButton}
            data-testid={outputsReviewPanel.downloadButton}
            onClick={handleDownloadFile}
            startIcon={<FileDownloadIcon />}
          >
            {reviewPanelText.resultDetails}
          </Button>,
          <Button
            key={outputsReviewPanel.okButton}
            data-testid={outputsReviewPanel.okButton}
            variant="contained"
            onClick={handleCloseDialog}
          >
            {reviewPanelText.ok}
          </Button>,
        ]}
      >
        <ResultDescription>{reviewPanelText.resultFileDescription}</ResultDescription>
        <ResultsTable>
          {resultDataList.map((dataEntry, index) => (
            <ResultsRow key={`${dataEntry.name} ${index}`}>
              <ResultName>
                <Typography>{dataEntry.name}</Typography>
              </ResultName>
              <ResultInfo>
                <Typography>{dataEntry.info}</Typography>
              </ResultInfo>
            </ResultsRow>
          ))}
          {revitElementIdsDisplay(instanceList)}
        </ResultsTable>
      </CustomDialog>
    </>
  );
};

const revitElementIdsDisplay = (instanceList: string[]): ReactNode => {
  const instanceListToDisplay = Array.from(instanceList);

  const idsShouldBeTruncated = instanceList.length > maxElementIdsDisplay;

  if (idsShouldBeTruncated) {
    instanceListToDisplay.splice(maxElementIdsDisplay - instanceList.length);
  }

  const extraCount = instanceList.length - instanceListToDisplay.length;

  const hiddenIdsMessage = () => (
    <Typography>
      {`${reviewPanelText.plus} ${extraCount} ${reviewPanelText.moreIn} `}
      <ResultsBoldSpan>{reviewPanelText.resultDetails}</ResultsBoldSpan>
    </Typography>
  );

  return (
    <ResultsRow>
      <ResultName>
        <Typography>{reviewPanelText.revitElementIds}</Typography>
      </ResultName>
      <ResultInfo data-testid={outputsReviewPanel.elementIds}>
        <Typography>
          {instanceListToDisplay.join(newLine)}
          {idsShouldBeTruncated ? hiddenIdsMessage() : null}
        </Typography>
      </ResultInfo>
    </ResultsRow>
  );
};

const dataToCsv = (data: ResultData[], instanceList: string[], variantName: string, fileType?: string): void => {
  let csv = data.map((item) => `"${item.name}","${item.info}"`).join(newLine);

  // Append Element ID's to file
  csv += `${newLine}"${reviewPanelText.revitElementIds}","${instanceList.join(newLine)}"`;

  const filename = `${variantName}_${fileType}_result_details.${reviewPanelText.csv}`;

  const blob = new Blob([csv], { type: exportFileHeader });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const humanFriendlyFileTypeDescription = (fileType: OutputTypes): string => {
  switch (fileType) {
    case OutputType.BOM:
      return reviewPanelText.bomDescription;
    case OutputType.IAM:
      return reviewPanelText.iamDescription;
    case OutputType.SAT:
      return reviewPanelText.satDescription;
    case OutputType.STEP:
      return reviewPanelText.stepDescription;
    default:
      return `${fileType} (.${fileType.toLowerCase()})`;
  }
};

export default InstancesResultDetails;
