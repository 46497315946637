import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import text from 'global/text.json';
import React from 'react';
import { licensingTestIds } from 'tests/helpers/dataTestIds';
import { ContentSectionWrapper } from '../Licensing.styles';
import { privacyStatementUrl } from '../constants';

const licensingText = text.licensing;

const Privacy: React.FC = () => (
  <ContentSectionWrapper data-testid={licensingTestIds.privacySection}>
    <Typography id={licensingTestIds.privacyId} variant="h2" gutterBottom>
      {licensingText.privacy}
    </Typography>
    <Typography>
      {licensingText.privacyInfo}{' '}
      <Link
        data-testid={licensingTestIds.privacyStatementPage}
        target="_blank"
        rel="noopener noreferrer"
        href={privacyStatementUrl}
        color="primary"
      >
        {licensingText.autodeskPrivacyStatement}
      </Link>
      .
    </Typography>
  </ContentSectionWrapper>
);

export default Privacy;
