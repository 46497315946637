import { ErrorCode } from '../errorCode';
import { CustomError } from '../customError';

export const normalizeError = (error: unknown): Error => (error instanceof Error ? error : Error(String(error)));

type CauseInputType = {
  error: unknown;
};

type CauseType = {
  error: Error;
};

export class ApiError extends CustomError {
  errorCode = ErrorCode.ApiError;

  constructor(message: string, cause: CauseInputType) {
    const normalizedCause: CauseType = { error: normalizeError(cause.error) };
    super(message, normalizedCause);
  }
}
