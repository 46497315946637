import { Navigate } from 'react-router-dom';
import { PropsWithChildren, useContext } from 'react';
import AccessControlContext from 'context/AccessControlStore/AccessControl.context';
import { AccessProps } from 'context/AccessControlStore/accessControlStore';

/**
 * TODO: Clean this up after createRFO initiative
 * https://jira.autodesk.com/browse/TRADES-6186
 *
 * This is a copy of previous routes, all the new changes will be in routes
 *
 */
type ProtectedRouteProps = {
  accessControlProperty: keyof AccessProps;
  redirectPath: string;
};
const ProtectedRoute: React.FC<PropsWithChildren<ProtectedRouteProps>> = ({
  children,
  accessControlProperty,
  redirectPath = '/',
}) => {
  const { initialized, accessProps } = useContext(AccessControlContext);

  if (!initialized) {
    return <>Loading</>;
  }

  if (accessProps[accessControlProperty]) {
    return children;
  }

  return <Navigate to={redirectPath} replace />;
};

export default ProtectedRoute;
