import { ThemeProvider } from '@mui/material/styles';
import { RenderResult, queries, render } from '@testing-library/react';
import React, { ReactElement } from 'react';
import ModalContext from '../../context/ModalStore/Modal.context';
import { ModalStore, initialModalState } from '../../context/ModalStore/modalStore';
import { NotificationsProvider } from '../../context/NotificationStore/Notification.context';
import { NotificationStore } from '../../context/NotificationStore/notificationStore';
import midTheme from '../../styles/midTheme';
import QueryClientProvider from '../../components/QueryClientProvider/QueryClientProvider';

export interface MockDataStore {
  [key: string]: any;
}

interface ConfigurableStores {
  modalStore?: Partial<ModalStore>;
  dataStore?: Partial<MockDataStore>;
  notificationStore?: Partial<NotificationStore>;
}

const MockDataContext = React.createContext({} as MockDataStore);

export const getMockDataStore = (): MockDataStore => ({});
export const getMockModalStore = (): ModalStore => ({
  ...initialModalState,
  isOpen: false,
  message: '',
  setModalState: () => undefined,
  handleCancel: () => undefined,
  handleConfirm: () => undefined,
});

export const getMockNotificationStore = (): NotificationStore => ({
  items: [],
  showNotification: () => undefined,
  logAndShowNotification: () => undefined,
});

export const renderWithTheme = (tree: ReactElement<any>, theme = midTheme): RenderResult<typeof queries, HTMLElement> =>
  render(<ThemeProvider theme={theme}>{tree}</ThemeProvider>);

export const renderDataContextWrapper = (children: ReactElement<any>, store = {}): JSX.Element => (
  <MockDataContext.Provider value={store}>{children}</MockDataContext.Provider>
);

export const renderDataAndNotificationContextWrapper = (
  children: ReactElement<any>,
  dataStore = getMockDataStore(),
  notificationStore = getMockNotificationStore(),
): JSX.Element => (
  <NotificationsProvider store={notificationStore}>
    <MockDataContext.Provider value={dataStore}>{children}</MockDataContext.Provider>
  </NotificationsProvider>
);

export const renderModalContextWrapper = (children: ReactElement<any>, store = {}): JSX.Element => (
  <MockDataContext.Provider value={store}>{children}</MockDataContext.Provider>
);

export const renderConfigurableStoreContextWrapper = (
  tree: ReactElement<any>,
  configurableStores: ConfigurableStores = {},
  theme = midTheme,
): JSX.Element => {
  const mergedStores = {
    modalStore: {
      ...getMockModalStore(),
      ...(configurableStores.modalStore || {}),
    },
    dataStore: {
      ...getMockDataStore(),
      ...(configurableStores.dataStore || {}),
    },
    notificationStore: {
      ...getMockNotificationStore(),
      ...(configurableStores.notificationStore || {}),
    },
  };
  return (
    <ThemeProvider theme={theme}>
      <ModalContext.Provider value={mergedStores.modalStore}>
        <NotificationsProvider store={mergedStores.notificationStore}>
          <QueryClientProvider>
            <MockDataContext.Provider value={mergedStores.dataStore}>{tree}</MockDataContext.Provider>
          </QueryClientProvider>
        </NotificationsProvider>
      </ModalContext.Provider>
    </ThemeProvider>
  );
};

export const renderWithThemeAndConfigurableContext = (
  tree: ReactElement<any>,
  configurableStores: ConfigurableStores = {},
  theme = midTheme,
): RenderResult<typeof queries, HTMLElement> => {
  const wrap = renderConfigurableStoreContextWrapper(tree, configurableStores, theme);

  return render(wrap);
};
