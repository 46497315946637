import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import { LoadingButton } from '@mui/lab';
import Typography from '@mui/material/Typography';
import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import text from '../../../global/text.json';
import { routes as legacyRoutes } from '../../../routes/legacy/legacyRoutes';
import { InstancesPanelIds, modelDetails } from '../../../tests/helpers/dataTestIds';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { StyledIconButton, StyledWarningAmberIcon } from './InstancePanelComponents.style';
import { Box, Link, useTheme } from '@mui/material';
import { DarkGreyTooltip } from '@mid-react-common/common';

const instancePanelText = text.instancePanel;

interface ExpandToggleButtonProps {
  expanded: boolean;
  onToggle: (event: React.MouseEvent<HTMLElement>) => void;
}

export const ExpandToggleButton = ({ expanded, onToggle }: ExpandToggleButtonProps): JSX.Element => (
  <StyledIconButton onClick={onToggle} data-testid={InstancesPanelIds.expandTableButton}>
    {expanded && <KeyboardDoubleArrowLeft />}
    {!expanded && <KeyboardDoubleArrowRight />}
  </StyledIconButton>
);

interface GenerateOutputsButtonProps {
  disabled: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement> | undefined;
  loading?: boolean;
}

export const GenerateOutputsButton: React.FC<GenerateOutputsButtonProps> = ({ disabled, onClick, loading }) => {
  const { enableRfoModal } = useFlags();
  const legacyOutputSettingPath = '../../' + legacyRoutes.outputs.path + '/' + legacyRoutes.settings.path;
  return enableRfoModal ? (
    <LoadingButton
      variant="outlined"
      disabled={disabled}
      data-testid={modelDetails.generateOutputsButton}
      onClick={onClick}
      loading={Boolean(loading)}
    >
      <Typography variant="inherit" noWrap>
        {instancePanelText.generateOutputs}
      </Typography>
    </LoadingButton>
  ) : (
    <LoadingButton
      variant="outlined"
      component={NavLink}
      to={legacyOutputSettingPath}
      disabled={disabled}
      data-testid={modelDetails.generateOutputsButton}
      onClick={onClick}
    >
      <Typography variant="inherit" noWrap>
        {instancePanelText.generateOutputs}
      </Typography>
    </LoadingButton>
  );
};

export interface OutputsGenerationDisabledIconWithTooltipProps {
  title: ReactNode;
  message?: ReactNode;
  footerText?: string;
  footerLink?: string;
}

export const OutputsGenerationDisabledIconWithTooltip: React.FC<OutputsGenerationDisabledIconWithTooltipProps> = ({
  title,
  message,
  footerText,
  footerLink,
}: OutputsGenerationDisabledIconWithTooltipProps) => {
  const theme = useTheme();
  return (
    <DarkGreyTooltip
      title={
        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          gap={`${theme.var.instancesPanelElementsBaseGap}px`}
          padding={`${theme.var.paddingBase}px`}
        >
          <Typography
            data-testid={modelDetails.outputsGenerationDisabledInfoTooltipTitle}
            fontWeight={theme.var.tooltipTitleFontWeight}
          >
            {title}
          </Typography>
          {message && (
            <Typography
              data-testid={modelDetails.outputsGenerationDisabledInfoTooltipBody}
              fontWeight={theme.var.tooltipBodyFontWeight}
              fontSize={theme.var.tooltipBodyFontSize}
            >
              {message}
            </Typography>
          )}
          {footerText && footerLink && (
            <Link href={footerLink} target="_blank" rel="noreferrer" underline="none">
              <Typography
                data-testid={modelDetails.outputsGenerationDisabledInfoTooltipFooter}
                fontWeight={theme.var.tooltipFooterFontWeight}
              >
                {footerText}
              </Typography>
            </Link>
          )}
        </Box>
      }
      arrow
    >
      <StyledWarningAmberIcon data-testid={modelDetails.outputsGenerationDisabledIcon} color="warning" />
    </DarkGreyTooltip>
  );
};
