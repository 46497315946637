import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

export const SpinnerOverlayContainer = styled(Box)<{ width?: number }>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
`;

export const SpinnerOverlayFiller = styled('div')<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.surface.lightGray['35']};
  opacity: 0.5;
  position: absolute;
`;

export const Spinner = styled(CircularProgress)`
  position: absolute;
  width: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
