import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { CustomAccordionContent, CustomAccordionHeader } from './CustomAccordion.style';

interface CustomAccordionProps {
  title: any;
  children?: any;
}
const CustomAccordion = ({ title, children }: CustomAccordionProps): JSX.Element => {
  const [expanded, setExpanded] = useState(true);

  const handleExpandToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Box>
      <CustomAccordionHeader>
        <IconButton data-testid="accordion-button" onClick={handleExpandToggle} size="small">
          {expanded ? <ArrowDropDown /> : <ArrowDropUp />}
        </IconButton>
        <Typography>{title}</Typography>
      </CustomAccordionHeader>
      <CustomAccordionContent expanded={expanded}>{children}</CustomAccordionContent>
    </Box>
  );
};

export default CustomAccordion;
