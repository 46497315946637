import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import NavigateNext from '@mui/icons-material/NavigateNext';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React from 'react';
import { customPagination } from 'tests/helpers/dataTestIds';
import text from '../../../global/text.json';
import { CustomPaginationContainer } from './CustomPagination.style';

interface CustomPaginationProps {
  count: number;
  currentIndex: number;
  onChange: (index: number, previousIndex?: number, event?: React.MouseEvent<HTMLElement>) => void;
}

const CustomPagination = ({ count, currentIndex, onChange }: CustomPaginationProps): JSX.Element => {
  if (currentIndex < 0) {
    onChange(0, currentIndex);
  }

  if (currentIndex > count) {
    onChange(count - 1, currentIndex);
  }

  const handleFirst = (event: React.MouseEvent<HTMLElement>) => {
    if (currentIndex > 0) {
      onChange(0, currentIndex, event);
    }
  };

  const handlePrevious = (event: React.MouseEvent<HTMLElement>) => {
    if (currentIndex > 0) {
      onChange(currentIndex - 1, currentIndex, event);
    }
  };

  const handleNext = (event: React.MouseEvent<HTMLElement>) => {
    if (currentIndex < count - 1) {
      onChange(currentIndex + 1, currentIndex, event);
    }
  };

  const handleLast = (event: React.MouseEvent<HTMLElement>) => {
    if (currentIndex < count) {
      onChange(count - 1, currentIndex, event);
    }
  };
  const paginationText = text.customPagination;

  return (
    <CustomPaginationContainer>
      <IconButton
        size="small"
        onClick={handleFirst}
        disabled={currentIndex === 0}
        data-testid={customPagination.firstPageButton}
      >
        <FirstPage fontSize="small" />
      </IconButton>
      <IconButton
        size="small"
        onClick={handlePrevious}
        disabled={currentIndex === 0}
        data-testid={customPagination.previousButton}
      >
        <NavigateBefore fontSize="small" />
      </IconButton>
      <Typography variant="body2">
        {currentIndex + 1} {paginationText.of} {count}
      </Typography>
      <IconButton
        size="small"
        onClick={handleNext}
        disabled={currentIndex === count - 1}
        data-testid={customPagination.nextButton}
      >
        <NavigateNext fontSize="small" />
      </IconButton>
      <IconButton
        size="small"
        onClick={handleLast}
        disabled={currentIndex === count - 1}
        data-testid={customPagination.lastPageButton}
      >
        <LastPage fontSize="small" />
      </IconButton>
    </CustomPaginationContainer>
  );
};

export default CustomPagination;
