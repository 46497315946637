import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const KeyValueStackItem = styled(Box)`
  margin-bottom: ${({ theme }) => theme.var.marginBase * 1.5}px;
`;

export const KeyValueStackLabel = styled(Typography)``;

KeyValueStackLabel.defaultProps = {
  color: 'text.secondary',
  noWrap: true,
  variant: 'body2',
};

export const KeyValueStackValue = styled(Box)`
  margin-top: ${({ theme }) => theme.var.marginBase / 2}px;
`;
