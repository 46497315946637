/**
 * TODO: Clean this up after createRFO initiative
 * https://jira.autodesk.com/browse/TRADES-6186
 *
 * This is a copy of previous routes, all the new changes will be in routes
 *
 */

export const routes = {
  index: {
    path: '/',
  },
  licensing: {
    path: '/licensing',
  },
  revitInstallers: {
    path: '/revit',
  },
  inventorInstallers: {
    path: '/inventor',
  },
  projectId: {
    path: '/projects/:projectId',
    id: 'projectId',
  },
  products: {
    path: 'products',
    id: 'productsId',
  },
  models: {
    path: 'models',
  },
  instances: {
    path: 'instances',
  },
  outputs: {
    path: 'outputs',
    id: 'outputsId',
  },
  review: {
    path: 'review',
  },
  settings: {
    path: 'settings',
  },
  releases: {
    path: 'releases',
  },
  folders: {
    path: 'folders/:folderUrn',
  },
  folderItems: {
    path: 'items/:itemUrn/:details?',
  },
};
