import { useQuery } from '@tanstack/react-query';
import { getAccBridgeApiServiceInstance } from 'mid-api-services';
import { MIDAccBridgeFolder } from 'mid-types';
import { guid2Urn } from 'mid-utils';
import { useEffect, useMemo } from 'react';

interface UseAccBridgeProps {
  projectId: string | null;
  // Both Acc project ID or BIM360 project ID will work with ACC Bridge
  // If BIM360 project ID is passed to ACC Bridge Endpoint, it will just return an empty array
  isAccProject: boolean;
}

interface UseAccIncomingBridgeState {
  incomingBridgeFoldersMap: Map<string, MIDAccBridgeFolder> | undefined;
  isLoadingBridgeFolders: boolean;
  hasErrors: boolean;
}

const INCOMING_BRIDGE_FOLDERS = 'incomingBridgeFolders';

export const useAccIncomingBridge = ({ projectId, isAccProject }: UseAccBridgeProps): UseAccIncomingBridgeState => {
  const accBridgeService = getAccBridgeApiServiceInstance();

  // We memoize the initial map, so the Map reference stays the same between renders.
  // Without this it causes an infinite loop if you set the entire map to the data store
  const incomingBridgeFoldersMap = useMemo(() => new Map<string, MIDAccBridgeFolder>(), []);

  useEffect(() => {
    // We clear the useMemo Map whenever the projectId changes,
    // so bridge data doesn't persist when changing projects
    if (projectId) {
      incomingBridgeFoldersMap.clear();
    }
  }, [incomingBridgeFoldersMap, projectId]);

  const { data, isLoading, isError } = useQuery({
    queryKey: [INCOMING_BRIDGE_FOLDERS, projectId],
    queryFn: async ({ signal }) => (projectId ? accBridgeService.getIncomingFolders(projectId, signal) : undefined),
    enabled: !!projectId && isAccProject,
    select: (data) =>
      data?.reduce((acc, folder) => {
        const targetFolderUrn = guid2Urn(folder.target_key_uid);
        const sourceFolderUrn = guid2Urn(folder.source_key_uid);

        acc.set(targetFolderUrn, {
          ...folder,
          targetFolderUrn,
          sourceFolderUrn,
          // If the ACC Bridge folder sync is deactivated by a source or target user
          // we receive OxygenId in those properties below and it means the sync automation is paused
          isSyncAutomationActive: folder.target_deactivated_by === null && folder.source_deactivated_by === null,
        });

        return acc;
      }, incomingBridgeFoldersMap),
  });

  return {
    incomingBridgeFoldersMap: data,
    isLoadingBridgeFolders: isLoading,
    hasErrors: isError,
  };
};
