import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import text from '../../../common.text.json';
import {
  DATETIME_FORMAT,
  InventorWebComponentMoniker,
  MIDEditFormAppMoniker,
  MidWebAppMoniker,
  RevitUpdateContentMoniker,
  RevitWebComponentMoniker,
} from '../../../global/constants';
import { CloseIconButton } from '../../../styles/Common.styles';
import CustomDialog from '../../CustomDialog/CustomDialog';
import { BorderlessTableCell } from '../AboutInfoMenu.styles';
import { format, isValid, parse } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { versionInformation } from '../../../global/dataTestIds';

interface VersionInfoDialogProps {
  isVersionInfoDialogOpen: boolean;
  handleVersionInfoDialogClose: () => void;
  webComponentVersion: string;
  addinVersion?: string;
  desktopApplicationVersion?: string;
  isAddin: boolean;
}

export const extractVersionNumber = (str: string): string | null => {
  const version = str.match(/\d+(?:\.\d+)+/);
  if (!version) {
    return null;
  }
  return `${version}`;
};

export const extractReleaseTime = (str: string): string | null => {
  const releaseTimeString = str.match(/\d{4}-\d{2}-\d{2}-\d{4}$/);

  const timezone = 'UTC';
  const releaseTime = fromZonedTime(parse(releaseTimeString?.toString() || '', 'yyyy-LL-dd-HHmm', new Date()), timezone);
  return isValid(releaseTime) ? format(releaseTime, DATETIME_FORMAT) : null;
};

export const getAppReleaseInfo = (
  webComponentVersion: string,
  addinVersion?: string,
  desktopApplicationVersion?: string,
): { [key: string]: string } => {
  // Deployment tag format: <env>/v*.*.*+<IVTW|RVTW|MIDW>/<timestamp>
  const releaseTime = extractReleaseTime(webComponentVersion) || '';

  if (webComponentVersion.includes(MidWebAppMoniker)) {
    return {
      [text.aboutInfoMenu.product]: text.aboutInfoMenu.webappProductName,
      [text.aboutInfoMenu.version]: extractVersionNumber(webComponentVersion) || '',
      [text.aboutInfoMenu.released]: releaseTime,
    };
  }

  if (
    webComponentVersion.includes(InventorWebComponentMoniker) ||
    webComponentVersion.includes(RevitWebComponentMoniker) ||
    webComponentVersion.includes(MIDEditFormAppMoniker) ||
    webComponentVersion.includes(RevitUpdateContentMoniker)
  ) {
    const addinProductName = webComponentVersion.includes(InventorWebComponentMoniker)
      ? text.aboutInfoMenu.inventorAddinName
      : text.aboutInfoMenu.revitAddinName;

    let dialogName = '';
    if (webComponentVersion.includes(InventorWebComponentMoniker)) {
      dialogName = text.aboutInfoMenu.productDefinitionDialog;
    } else if (webComponentVersion.includes(RevitWebComponentMoniker)) {
      dialogName = text.aboutInfoMenu.customizeAndInsertDialog;
    } else if (webComponentVersion.includes(MIDEditFormAppMoniker)) {
      dialogName = text.aboutInfoMenu.editInstanceDialog;
    } else if (webComponentVersion.includes(RevitUpdateContentMoniker)) {
      dialogName = text.aboutInfoMenu.updateContentDialog;
    }

    return {
      [text.aboutInfoMenu.product]: desktopApplicationVersion
        ? `${addinProductName} ${desktopApplicationVersion}`
        : addinProductName,
      [text.aboutInfoMenu.addinVersion]: addinVersion || '',
      [text.aboutInfoMenu.dialog]: dialogName,
      [text.aboutInfoMenu.uiVersion]: extractVersionNumber(webComponentVersion) || '',
    };
  }

  return {};
};

export const VersionInfoDialog: React.FC<VersionInfoDialogProps> = ({
  isVersionInfoDialogOpen,
  handleVersionInfoDialogClose,
  webComponentVersion,
  addinVersion,
  desktopApplicationVersion,
  isAddin,
}) => {
  const releaseInfo = getAppReleaseInfo(webComponentVersion, addinVersion, desktopApplicationVersion);

  return (
    <CustomDialog
      dividers
      open={isVersionInfoDialogOpen}
      title={
        <>
          <span>{text.aboutInfoMenu.versionInformation}</span>
          <CloseIconButton onClick={handleVersionInfoDialogClose} data-testid={versionInformation.closeButton}>
            <CloseIcon />
          </CloseIconButton>
        </>
      }
      showActions={false}
      isAddin={isAddin}
    >
      <Table>
        <tbody>
          {Object.keys(releaseInfo).map((releaseInfoLabel) => (
            <TableRow key={releaseInfoLabel}>
              <BorderlessTableCell>
                <strong>{releaseInfoLabel}</strong>
              </BorderlessTableCell>
              <BorderlessTableCell>{releaseInfo[releaseInfoLabel]}</BorderlessTableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </CustomDialog>
  );
};
