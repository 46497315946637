export default {
  modalCancelTestId: 'modal-cancel-button',
  modalConfirmTestId: 'modal-confirm-button',
  closeIcon: 'CloseIcon',
  expandableTextSection: {
    mainContent: 'expandable-main-content',
    expandCollapseContentButton: 'expand-collapse-content-button',
  },
  locationInfo: {
    uploadLocationAccountName: 'upload-location-account-name',
    uploadLocationProjectName: 'upload-location-project-name',
    uploadLocationFolderName: 'upload-location-folder-name',
    showHideBreadcrumbs: 'show-hide-breadcrumbs',
    folderIcon: 'folder-icon',
    lockedFolderIcon: 'locked-folder-icon',
    folderPathBreadcrumbs: 'folder-path-breadcrumbs',
    bim360ProjectIconType: 'upload-location-project-selector-bim360-icon-type',
    accProjectIconType: 'upload-location-project-selector-acc-icon-type',
    fusionProjectIconType: 'upload-location-project-selector-fusion-icon-type',
  },
};
