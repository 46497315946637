let stubFn;

// prevent error when mock data (stores) use stubs for their empty functions (since vi.fn()
// is not available under Cypress runtime)
if (typeof Cypress !== 'undefined') {
  stubFn = (): (() => void) => () => {};
} else if (typeof vi !== 'undefined') {
  stubFn = vi.fn;
} else {
  stubFn = (): (() => void) => () => {};
}

export default {
  stubFn,
};
