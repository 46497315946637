import { Spacing } from '@mid-react-common/common';
import { installersTestIds } from 'tests/helpers/dataTestIds';
import text from '../../../global/text.json';
import { ButtonsWrapper, InformationWrapper } from '../Installers.styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface SignUpSectionProps {
  onboardingCampaignUrl: string;
}

const SignUpSection: React.FC<SignUpSectionProps> = ({ onboardingCampaignUrl }) => (
  <InformationWrapper>
    <Typography gutterBottom fontWeight="bolder">
      {text.installers.onboardingTipsText}
    </Typography>
    <Typography>{text.installers.subscribeText}</Typography>
    <Spacing />
    <ButtonsWrapper>
      <Button
        data-testid={installersTestIds.productCampaignLink}
        variant="outlined"
        color="secondary"
        href={onboardingCampaignUrl}
        target="_blank"
      >
        {text.installers.signUpNowButton}
      </Button>
    </ButtonsWrapper>
  </InformationWrapper>
);

export default SignUpSection;
