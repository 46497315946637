import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import MIDNotification from '../../components/Notification/Notification';
import { NotificationStore } from './notificationStore';

const NotificationContext = React.createContext({} as NotificationStore);

interface NotificationsProviderProps {
  children: ReactNode;
  store: NotificationStore;
  portalNode?: HTMLElement;
}

export const NotificationsProvider: React.FC<NotificationsProviderProps> = ({ children, store, portalNode }) => {
  const items = store.items.map((item, index) => (
    <MIDNotification
      key={index}
      seqNumber={index}
      message={item.message}
      messageBody={item.messageBody}
      severity={item.severity}
      anchor={item.anchor}
      handleClose={item.handleClose}
      id={item.id}
    />
  ));

  return (
    <NotificationContext.Provider value={store}>
      {children}

      {portalNode ? createPortal(items, portalNode) : items}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
