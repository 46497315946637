import { Vendors } from '@adsk/offsite-dc-sdk';
import Link from '@mui/material/Link';
import { ProjectFolderPermissionContainer } from './ProjectFolderPermissionSection.styles';
import { AUTODESK_DOCS_PERMISSION_LINK, FUSION_PERMISSION_LINK } from 'global/constants/links';

import text from 'global/text.json';
import { TooltipBodyTypography, TooltipFooterTypography } from '@mid-react-common/common';

const uploadLocationText = text.uploadLocation;

interface ProjectFolderPermissionSectionProps {
  summary: string;
  vendor: Vendors;
}

export const ProjectFolderPermissionSection: React.FC<ProjectFolderPermissionSectionProps> = ({ summary, vendor }) => (
  <ProjectFolderPermissionContainer>
    <TooltipBodyTypography gutterBottom>{summary}</TooltipBodyTypography>
    <Link
      href={vendor === Vendors.FUSIONTEAM ? FUSION_PERMISSION_LINK : AUTODESK_DOCS_PERMISSION_LINK}
      target="_blank"
      underline="none"
      rel="noopener noreferrer"
    >
      <TooltipFooterTypography>
        {vendor === Vendors.FUSIONTEAM
          ? uploadLocationText.projectSecurityInFusion
          : uploadLocationText.folderPermissionsInAutodeskDocs}
      </TooltipFooterTypography>
    </Link>
  </ProjectFolderPermissionContainer>
);
