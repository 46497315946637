import { CustomError } from '../customError';
import { ErrorCode } from '../errorCode';

type CauseType = {
  projectId: string;
  folderUrn?: string;
};

export class BIM360FoldersFetchError extends CustomError {
  errorCode = ErrorCode.BIM360FoldersFetchError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
