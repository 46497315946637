import { useAccIncomingBridge } from '@mid-react-common/common';
import { useQuery } from '@tanstack/react-query';
import { getFolderUrnFromFolderPath } from 'components/ReleasesPage/Releases.utils';
import ProductContext from 'context/ProductStore/Product.context';
import { GET_FOLDER_KEY } from 'global/constants/reactQueryKeys';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { FOLDERS_PERMISSION_FILTER_OPTIONS, MIDAccBridgeFolder } from 'mid-types';
import { useContext, useMemo } from 'react';
import { Instance } from 'types/product';

interface UseProductReleasePermissionCheckState {
  hasPermissionsFromProductTargetFolder: boolean | undefined;
  isLoadingProductTargetFolderData: boolean;
  hasErrorLoadingProductTargetFolderData: boolean;
}

export const useProductReleasePermissionCheck = (): UseProductReleasePermissionCheckState => {
  const { selectedProductRelease, instances } = useContext(ProductContext);

  const instanceWithSameProductRelease: Instance | null = useMemo(() => {
    if (!instances || !selectedProductRelease) {
      return null;
    }

    return (
      instances.find(
        (instance) =>
          instance.release === selectedProductRelease.release && instance.contentId === selectedProductRelease.contentId,
      ) || null
    );
  }, [instances, selectedProductRelease]);

  // Check incoming bridge only if target project id is not the same as source project id
  const incomingBridgeTargetProjectId: string | null =
    instanceWithSameProductRelease?.targetProjectId &&
    instanceWithSameProductRelease.targetProjectId !== instanceWithSameProductRelease.projectId
      ? instanceWithSameProductRelease.targetProjectId
      : null;

  const { incomingBridgeFoldersMap } = useAccIncomingBridge({
    projectId: incomingBridgeTargetProjectId,
    isAccProject: true,
  });

  const incomingBridgeFolder: MIDAccBridgeFolder | null = useMemo(() => {
    if (!instanceWithSameProductRelease) {
      return null;
    }
    const incomingAccBridgeFolders = Array.from(incomingBridgeFoldersMap?.values() || []);

    return (
      incomingAccBridgeFolders.find(
        (accFolder) =>
          instanceWithSameProductRelease.folderUrn?.endsWith(accFolder.sourceFolderUrn) &&
          accFolder.target_project_uid === instanceWithSameProductRelease.targetProjectId &&
          accFolder.source_project_uid === instanceWithSameProductRelease.projectId &&
          accFolder.isSyncAutomationActive,
      ) || null
    );
  }, [instanceWithSameProductRelease, incomingBridgeFoldersMap]);

  const targetProjectIdOfProductRelease = incomingBridgeFolder
    ? incomingBridgeFolder.target_project_uid
    : selectedProductRelease
      ? selectedProductRelease.tenancyId
      : null;

  const targetFolderUrnOfProductRelease = incomingBridgeFolder
    ? incomingBridgeFolder.targetFolderUrn
    : selectedProductRelease
      ? getFolderUrnFromFolderPath(selectedProductRelease.context.workspace.folderPath)
      : null;

  const isTargetProjectInfoAvailable = !!targetProjectIdOfProductRelease && !!targetFolderUrnOfProductRelease;
  const shouldCheckPublishFolderPermission = incomingBridgeTargetProjectId
    ? !!incomingBridgeFoldersMap && isTargetProjectInfoAvailable
    : isTargetProjectInfoAvailable;

  const {
    data: hasPermissionsFromProductTargetFolder,
    isPending: isLoadingProductTargetFolderData,
    isError: hasErrorLoadingProductTargetFolderData,
  } = useQuery({
    queryKey: [GET_FOLDER_KEY, targetProjectIdOfProductRelease, targetFolderUrnOfProductRelease],
    queryFn: async ({ signal }) =>
      isTargetProjectInfoAvailable
        ? getForgeApiServiceInstance().getFolder(targetProjectIdOfProductRelease, targetFolderUrnOfProductRelease, signal)
        : undefined,
    enabled: shouldCheckPublishFolderPermission,
    select: (data) =>
      [
        FOLDERS_PERMISSION_FILTER_OPTIONS.publish,
        FOLDERS_PERMISSION_FILTER_OPTIONS.view,
        FOLDERS_PERMISSION_FILTER_OPTIONS.download,
      ].every((permission) => data?.permissionActions.includes(permission)),
  });

  return {
    hasPermissionsFromProductTargetFolder,
    isLoadingProductTargetFolderData,
    hasErrorLoadingProductTargetFolderData,
  };
};
