import { BIM360Document, BIM360CurrentVersion } from 'mid-types';
import { BIM360CurrentVersionProcessResult, BIM360CurrentVersionProcessState } from 'mid-utils';

export const mockBIM360Document: BIM360Document = {
  id: 'mock-item-id-1',
  urn: 'item-urn-1',
  create_time: '1970-01-01T00:00:00.123Z',
  create_user_id: 'mock create user id',
  create_user_name: 'mock create user name',
  last_modified_time: '1970-01-01T00:00:00.456Z',
  last_modified_user_id: 'mock last modified user id',
  last_modified_user_name: 'mock last modified user name',
  file_name: 'mock file 1.rvt',
  name: 'mock name',
  description: 'mock description',
  hidden: false,
  custom_attributes: [],
  current_version: {
    process_state: BIM360CurrentVersionProcessState.PROCESSING_COMPLETE,
    process_result: BIM360CurrentVersionProcessResult.PROCESSING_SUCCESS,
    urn: window.btoa('item-urn-1?version=1'),
    bubble_urn: 'item-urn-1?version=1',
  } as BIM360CurrentVersion,
  current_set_version: 0,
  reserved: false,
  latest_version: 0,
  latest_version_file_state: [],
  latest_version_create_time: '2023-01-05T21:10:50+0000',
  current_version_urn: 'mock current version urn',
  latest_storage_urn: 'mock latest storage urn',
  parent_folder_urn: 'mock-folder1b-urn',
  latest_versioned_file_urn: 'mock latest versioned file urn',
};

export const mockProcessingBIM360Document: BIM360Document = {
  id: 'mock-item-id-2',
  urn: 'item-urn-2',
  create_time: '1970-01-01T00:00:00.123Z',
  create_user_id: 'mock create user id',
  create_user_name: 'mock create user name',
  last_modified_time: '1970-01-01T00:00:00.456Z',
  last_modified_user_id: 'mock last modified user id',
  last_modified_user_name: 'mock last modified user name',
  file_name: 'mock file name.rvt',
  name: 'mock name',
  description: 'mock description',
  hidden: false,
  custom_attributes: [],
  current_version: {
    process_state: BIM360CurrentVersionProcessState.EXTRACTION_PENDING,
    process_result: null,
    urn: 'item-urn-2?version=1',
    bubble_urn: 'item-urn-2?version=1',
  } as BIM360CurrentVersion,
  current_set_version: 0,
  reserved: false,
  latest_version: 0,
  latest_version_file_state: [],
  latest_version_create_time: '2023-01-05T21:10:50+0000',
  current_version_urn: 'mock current version urn',
  latest_storage_urn: 'mock latest storage urn',
  parent_folder_urn: 'mock-folder1b-urn',
  latest_versioned_file_urn: 'mock latest versioned file urn',
};

export const mockBIM360DocumentRVTUppercase: BIM360Document = {
  id: 'mock-item-id-3',
  urn: 'item-urn-3',
  create_time: '1970-01-01T00:00:00.123Z',
  create_user_id: 'mock create user id',
  create_user_name: 'mock create user name',
  last_modified_time: '1970-01-01T00:00:00.456Z',
  last_modified_user_id: 'mock last modified user id',
  last_modified_user_name: 'mock last modified user name',
  file_name: 'mock-filename-uppercase.RVT',
  name: 'mock name uppercase',
  description: 'mock description uppercase',
  hidden: false,
  custom_attributes: [],
  current_version: {
    process_state: BIM360CurrentVersionProcessState.PROCESSING_COMPLETE,
    process_result: BIM360CurrentVersionProcessResult.PROCESSING_SUCCESS,
    urn: window.btoa('item-urn-1?version=1'),
    bubble_urn: 'item-urn-1?version=1',
  } as BIM360CurrentVersion,
  current_set_version: 0,
  reserved: false,
  latest_version: 0,
  latest_version_file_state: [],
  latest_version_create_time: '2023-01-05T21:10:50+0000',
  current_version_urn: 'mock current version urn',
  latest_storage_urn: 'mock latest storage urn',
  parent_folder_urn: 'mock-folder1b-urn',
  latest_versioned_file_urn: 'mock latest versioned file urn',
};
