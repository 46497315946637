import { Parameter, Property, RevitElement, SowElement } from 'types/scopeOfWork';
import { VIEWER_THEME, viewerServiceConstants } from '../../global/constants/viewerService';
import text from '../../global/text.json';
import { PropertyType } from '../../types/rowData';
import { ViewerInitializerOptions, ViewerOptions } from '../../types/viewerService';
import { viewerConfig } from '../lmvViewerConfig';
import { getPropertyAttributeValueByName } from './PropertyDB';
import { EmptyViewerModelLoader } from './models/emptyViewerModelLoader';

class ViewerService {
  /**
   * Initialized
   */
  public initialized = false;
  private isInitializing = false;

  public projectId?: string;
  public documentId?: string;

  /**
   * LMV viewer host node
   */
  public viewerNode: HTMLElement;

  /**
   * LMV viewer instance
   */
  public viewer: Autodesk.Viewing.GuiViewer3D;

  constructor(private options: ViewerOptions) {
    this.documentId = options.documentId;
    this.projectId = options.projectId;
    this.viewerNode = document.createElement('div');
    this.viewer = new Autodesk.Viewing.GuiViewer3D(this.viewerNode, this.options.config3d);
    this.viewer.setTheme(VIEWER_THEME);
    if (import.meta.env.DEV) {
      // @ts-ignore: noinspection TypeScriptUnresolvedVariable
      window.viewer = this.viewer;
    }
  }

  public recreateViewer() {
    this.viewer = new Autodesk.Viewing.GuiViewer3D(this.viewerNode, this.options.config3d);
    this.viewer.setTheme(VIEWER_THEME);
  }

  public finish() {
    this.viewer.finish();
  }

  public async initializeViewer(options: ViewerInitializerOptions): Promise<ViewerService> {
    return new Promise((resolve, reject) => {
      Object.assign(this.options, options);

      //Prevent the initializer being called twice
      if (!this.isInitializing) {
        this.isInitializing = true;
        // eslint-disable-next-line
        Autodesk.Viewing.Initializer(this.options, async () => {
          const errCode = this.viewer.start();
          if (errCode !== 0) {
            reject();
          } else {
            this.initialized = true;
            this.isInitializing = false;

            this.loadDocument(this.options.projectId, this.options.documentId);

            resolve(this);
          }
        });
      } else {
        resolve(this);
      }
    });
  }

  /**
   * Load main viewer model from given document id.
   * If no document id is provided, load empty viewer model
   */
  public loadDocument(projectId?: string, documentId?: string): void {
    if (this.documentId === documentId) {
      return;
    }
    this.projectId = projectId;
    this.documentId = documentId;

    this.viewer.unloadModel();
    // Unload existing viewer model
    if (!documentId) {
      this.viewer.loadModel(viewerServiceConstants.DEFAULT_EMPTY_MODEL, {
        ...this.options.config3d,
        fileLoader: EmptyViewerModelLoader,
      });
    } else {
      Autodesk.Viewing.Document.load(
        `${viewerServiceConstants.DOCUMENT_PREFIX}:${documentId}`,
        this.handleLoadFileSuccess,
        this.handleLoadFileError,
      );
    }
  }

  public resizeViewer(): void {
    this.viewer.resize();
  }

  public shutdown(): void {
    Autodesk.Viewing.shutdown();
  }

  /**
   * Load Main Model
   */

  private handleLoadFileSuccess = async (viewerDocument: Autodesk.Viewing.Document) => {
    // do not proceed with loading when the DOM node (viewerNode) does not exist anymore
    // this can happen when user quickly closed the page before the load
    if (!document.body.contains(this.viewerNode)) {
      return;
    }

    const defaultModel = viewerDocument.getRoot().getDefaultGeometry();

    if (defaultModel) {
      await this.viewer.loadDocumentNode(viewerDocument, defaultModel, this.options);
    } else {
      Autodesk.Viewing.Private.ErrorHandler.reportError(
        this.viewer.container,
        Autodesk.Viewing.ErrorCodes.BAD_DATA_NO_VIEWABLE_CONTENT,
      );
    }
  };

  /**
   * Display errors on the viewer
   */
  private handleLoadFileError = (
    errorCode: number,
    errorMsg: string,
    statusCode: number,
    statusText: string,
    errors: any,
  ) => {
    if (errors && errors.length) {
      if (Autodesk.Viewing.Private.ErrorHandler && Autodesk.Viewing.Private.ErrorHandler.reportErrors) {
        Autodesk.Viewing.Private.ErrorHandler.reportErrors(this.viewer.container, errors);
      }
    } else {
      if (Autodesk.Viewing.Private.ErrorHandler && Autodesk.Viewing.Private.ErrorHandler.reportError) {
        Autodesk.Viewing.Private.ErrorHandler.reportError(
          this.viewer.container,
          errorCode,
          errorMsg,
          statusCode,
          statusText,
          viewerServiceConstants.ERROR,
        );
      }
    }
  };

  public get propertyDb(): any {
    return this.viewer.model.getPropertyDb();
  }
  public get instanceTree(): any {
    return this.viewer.model.getInstanceTree();
  }
  public isViewerModelLoaded(): boolean {
    return this.viewer.isLoadDone() && !!this.viewer.model;
  }

  public async getAllMIDElements(): Promise<RevitElement[]> {
    if (this.isViewerModelLoaded()) {
      return new Promise<RevitElement[]>((resolve) => {
        this.propertyDb.getBulkProperties2(null, {}, async (model: any) => {
          const midElements: RevitElement[] = model.filter((e: any) =>
            e.properties.find(
              (prop: any) => prop.displayName === viewerServiceConstants.IS_MID_ELEMENT && prop.displayValue === 1,
            ),
          );
          const addableElements: RevitElement[] = [];
          midElements.forEach((element) => {
            const isAddable = this.isLeaf(element.dbId) && !!element.name;
            if (isAddable) {
              addableElements.push(element);
            }
          });
          resolve(addableElements);
        });
      });
    }
    return [];
  }
  public getElementChildrenIds(ids: number[]): number[] {
    const instanceIds: number[] = [];

    if (this.instanceTree) {
      for (const id of ids) {
        const childrenIds: number[] = [];
        this.instanceTree.enumNodeChildren(
          id,
          (childId: number) => {
            childrenIds.push(childId);
          },
          false,
        );
        instanceIds.push(...childrenIds);
      }
    }

    return instanceIds;
  }

  public isLeaf(id: number): boolean {
    return this.getElementChildrenIds([id]).length === 0;
  }
  public getElementParentId(id: number): any {
    return this.instanceTree.getNodeParentId(id);
  }
  public async getElementByDbId(dbId: number): Promise<RevitElement> {
    return new Promise<RevitElement>((resolve, reject) => {
      this.propertyDb.getProperties2(dbId, resolve, reject);
    });
  }

  public select(ids: number[]): void {
    this.viewer.select(ids);
  }

  public clearSelection(): void {
    this.viewer.clearSelection();
  }

  public async getParentElement(dbId: number): Promise<RevitElement> {
    const parentId = this.getElementParentId(dbId);
    const parentElement = await this.getElementByDbId(parentId);
    return parentElement;
  }
  public transformRevitElementToSowElement =
    (documentId: string) =>
    async (element: RevitElement): Promise<SowElement> => {
      const extractDataFromProps = element.properties.reduce(
        (
          init: {
            parentId: number;
            category: string;
            parameters: Parameter[];
          },
          ele: Property,
        ) => {
          if (ele.displayCategory === viewerServiceConstants.PROPERTY_PARENT) {
            init.parentId = Number(ele.displayValue);
          }
          if (ele.displayCategory === viewerServiceConstants.PROPERTY_CATEGORY) {
            init.category = ele.displayValue;
          }
          if (!ele.displayCategory.match(/^__/)) {
            init.parameters.push(this.transformPropertyToParameter(ele));
          }
          return init;
        },
        { parentId: 0, category: '', parameters: [] },
      );

      // Add parent info
      const parentElement = await this.getParentElement(element.dbId);
      const viewerServiceText = text.viewerService;
      if (!parentElement) {
        console.error(viewerServiceText.failedToGetParent + JSON.stringify(element));
        return Promise.reject(viewerServiceText.failedToGetParent + JSON.stringify(element));
      }
      const category = getPropertyAttributeValueByName(parentElement, viewerServiceConstants.ATTRIBUTE_CATEGORY);
      const family = getPropertyAttributeValueByName(parentElement, viewerServiceConstants.ATTRIBUTE_FAMILY);
      const familyType = parentElement.name;
      const familyTypeId = parentElement.externalId;

      return {
        externalId: element.externalId,
        name: element.name,
        dbId: element.dbId,
        documentId,
        parentId: extractDataFromProps.parentId,
        parameters: extractDataFromProps.parameters,
        category,
        familyTypeId,
        familyType,
        family,
      };
    };

  public transformRevitElementsToSowElements = async (
    elements: RevitElement[],
    documentId: string,
  ): Promise<SowElement[]> => {
    const transformElementsPromise: Promise<SowElement>[] = [];
    elements.forEach((element) => {
      transformElementsPromise.push(
        new Promise((resolve) => {
          const transformedElement = this.transformRevitElementToSowElement(documentId)(element);
          resolve(transformedElement);
        }),
      );
    });
    const result = await Promise.all(transformElementsPromise);
    return result;
  };
  /**
   * Transform revit property into parameter
   */
  public transformPropertyToParameter = (prop: Property): Parameter => ({
    name: prop.displayName,
    value: prop.displayValue,
    category: prop.displayCategory,
    type:
      prop.type === PropertyType.String && !Number.isNaN(parseFloat(prop.displayValue))
        ? PropertyType.Unknown // TRADES-2121: If value is number received as string, we change its type to Unknown
        : prop.type,
    units: prop.units,
    precision: prop.precision,
  });
}

export default new ViewerService(viewerConfig);
