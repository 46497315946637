import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { css, styled } from '@mui/material/styles';
import { GridStateColDef } from '@mui/x-data-grid';

export const SkeletonWrapper = styled(Skeleton)`
  width: 100%;
  margin-inline: ${({ theme }) => theme.var.marginBase * 1.5}px;
`;

export const SkeletonCell = styled(Box)<{ column?: GridStateColDef }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(props) =>
    props.column &&
    css`
      justify-content: ${props.column.align};
    `}
`;

export const SkeletonGridWrapper = styled('div')<{ rowHeight: number; columns: GridStateColDef[] }>`
  display: grid;
  grid-template-columns: ${({ columns }) => `${columns.map(({ computedWidth }) => `${computedWidth}px`).join(' ')} 1fr`};
  grid-auto-rows: ${(props) => `${props.rowHeight}px`};
`;
