import { useState } from 'react';
import { getVariantOutputModelState, getVariantOutputsDrawingPath } from 'mid-utils';
import { DCOutputWithVirtualTypes, OutputTypesWithVirtualTypes, PostVariantOutputWithVirtualTypes } from 'mid-types';
import { transformDCOutputsToPostVariantOutputs } from './outputSettings.utils';

interface UseSelectOutputsState {
  selectedOutputs: PostVariantOutputWithVirtualTypes[];
  addNewOutput: (newOutput: PostVariantOutputWithVirtualTypes) => void;
  removeOutput: (outputToRemove: PostVariantOutputWithVirtualTypes) => void;
  addAllOutputsFromSpecificType: (outputTypesList: OutputTypesWithVirtualTypes[]) => void;
  removeAllOutputsFromSpecificType: (outputTypesList: OutputTypesWithVirtualTypes[]) => void;
  addAllOutputs: () => void;
  removeAllOutputs: () => void;
}

interface UseSelectOutputsProps {
  outputs: DCOutputWithVirtualTypes[];
}

export const useSelectOutputs = ({ outputs }: UseSelectOutputsProps): UseSelectOutputsState => {
  const [selectedOutputs, setSelectedOutputs] = useState<PostVariantOutputWithVirtualTypes[]>([]);

  const addNewOutput = (newOutput: PostVariantOutputWithVirtualTypes) => setSelectedOutputs([...selectedOutputs, newOutput]);
  const removeOutput = (outputToRemove: PostVariantOutputWithVirtualTypes) =>
    setSelectedOutputs(
      selectedOutputs.filter(
        (value) =>
          !(
            outputToRemove.type === value.type &&
            getVariantOutputsDrawingPath(outputToRemove) === getVariantOutputsDrawingPath(value) &&
            getVariantOutputModelState(outputToRemove) === getVariantOutputModelState(value)
          ),
      ),
    );

  const addAllOutputsFromSpecificType = (outputTypesList: OutputTypesWithVirtualTypes[]) =>
    setSelectedOutputs(() => {
      const filteredList = selectedOutputs.filter((value) => !outputTypesList.includes(value.type));
      const outputsToAdd = outputs.filter((output) => outputTypesList.includes(output.type));
      const transformedOutputsToAdd = transformDCOutputsToPostVariantOutputs(outputsToAdd);

      return [...filteredList, ...transformedOutputsToAdd];
    });

  const removeAllOutputsFromSpecificType = (outputTypesList: OutputTypesWithVirtualTypes[]) =>
    setSelectedOutputs(selectedOutputs.filter((value) => !outputTypesList.includes(value.type)));

  const addAllOutputs = () => setSelectedOutputs(transformDCOutputsToPostVariantOutputs(outputs));
  const removeAllOutputs = () => setSelectedOutputs([]);

  return {
    selectedOutputs,
    addNewOutput,
    removeOutput,
    addAllOutputsFromSpecificType,
    removeAllOutputsFromSpecificType,
    addAllOutputs,
    removeAllOutputs,
  };
};
