import { GridRenderCellParams } from '@weave-mui/data-grid';
import ModelSelectionContext from 'context/ModelSelectionStore/ModelSelection.context';
import { useContext } from 'react';
import { FilenameCell, ModelsFileName } from './ModelsFolderContent.style';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { HigFile } from '@mid-react-common/common';
import text from '../../../global/text.json';

const modelsFolderText = text.modelsFolderContent;

const RenderFilenameCell: React.FC<GridRenderCellParams> = ({ id, value }): React.ReactNode => {
  const { currentlyOpenModel } = useContext(ModelSelectionContext);

  return (
    <FilenameCell>
      <HigFile />
      <ModelsFileName>
        <Typography variant="body2" noWrap>
          {value}
        </Typography>
      </ModelsFileName>
      {currentlyOpenModel?.id === id && (
        <Chip color="primary" variant="outlined" label={<strong>{modelsFolderText.opened}</strong>} size="small" />
      )}
    </FilenameCell>
  );
};

export default RenderFilenameCell;
