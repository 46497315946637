import { ProductRelease } from '@adsk/offsite-dc-sdk';
import ProductContext from 'context/ProductStore/Product.context';
import { MouseEvent, MutableRefObject, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { sortProductByName } from '../instancesPanel.utils';
import { ProductReleasesMap } from './ProductSelectDropdown';

interface UseProductSelectDropdownState {
  productSelectionRef: MutableRefObject<HTMLDivElement | undefined>;
  anchorEl: HTMLElement | null;
  open: boolean;
  selectedProductRelease: ProductRelease | undefined;
  productReleases: ProductRelease[];
  productReleasesMap: ProductReleasesMap;
  instancesProductsLoading: boolean;
  handleMenuClick: (event: MouseEvent<HTMLElement>) => void;
  handleMenuClose: () => void;
  handleProductReleaseClick: (productRelease: ProductRelease) => void;
}

export const useProductSelectDropdown = (): UseProductSelectDropdownState => {
  const { productReleases, selectedProductRelease, handleProductReleaseSelection, productsLoading, instances } =
    useContext(ProductContext);

  const productSelectionRef = useRef<HTMLDivElement | undefined>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const instancesProductsLoading = productsLoading || !instances || !instances.length || !selectedProductRelease;

  const handleMenuClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (!instancesProductsLoading) {
        setAnchorEl(event.currentTarget);
      }
    },
    [instancesProductsLoading, setAnchorEl],
  );

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProductReleaseClick = (productRelease: ProductRelease) => {
    handleProductReleaseSelection(productRelease);
    setAnchorEl(null);
  };

  const productReleasesMap = useMemo(
    () =>
      productReleases
        .sort(sortProductByName)
        .reduce<ProductReleasesMap>((prevProductAndReleasesMap, currentProductRelease) => {
          const previousProductReleases =
            currentProductRelease.contentId in prevProductAndReleasesMap
              ? prevProductAndReleasesMap[currentProductRelease.contentId]
              : [];

          return {
            ...prevProductAndReleasesMap,
            [currentProductRelease.contentId]: [...previousProductReleases, currentProductRelease],
          };
        }, {}),
    [productReleases],
  );

  return {
    productSelectionRef,
    anchorEl,
    open,
    selectedProductRelease,
    productReleases,
    productReleasesMap,
    instancesProductsLoading,
    handleMenuClick,
    handleMenuClose,
    handleProductReleaseClick,
  };
};
