import { OutputType } from '@adsk/offsite-dc-sdk';
import { FlexContainer } from '@mid-react-common/common';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { groupBy } from 'lodash';
import {
  DCOutputAggregatedBom,
  DCOutputWithVirtualTypes,
  OutputTypeWithVirtualTypes,
  OutputTypesWithVirtualTypes,
} from 'mid-types';
import { getDCOutputModelStates } from 'mid-utils';
import { useContext, useMemo } from 'react';
import ProductContext from '../../../context/ProductStore/Product.context';
import UploadLocationContext from '../../../context/UploadLocationStore/UploadLocation.context';
import { useUploadLocationStore } from '../../../context/UploadLocationStore/uploadLocationStore';
import text from '../../../global/text.json';
import { outputsSettingsIds } from '../../../tests/helpers/dataTestIds';
import OutputSettingsPanelHeader from './Components/OutputSettingsPanelHeader';
import SelectAllOutputsCheckbox from './Components/SelectAllOutputsCheckbox/SelectAllOutputsCheckbox';
import { OutputSettingsItem } from './OutputSettingsItem';
import { OutputSettingsWithTree } from './OutputSettingsWithTree';
import { Label, OutputTypesSettings, SettingsPanelBackground } from './SettingsPanel.styles';
import { UploadLocation } from './UploadLocation/UploadLocation';
import { drawingsOutputTypesList, neutralOutputTypesList } from './outputSettings.utils';
import { useSelectOutputs } from './useSelectOutputs';

const settingsPanelText = text.settingsPanel;

export type OutputItem = {
  outputLabel: string;
  chips: string[];
  checked: boolean;
  outputType?: OutputTypesWithVirtualTypes;
  modelState?: string;
  drawingTemplatePath?: string;
  numberOfBomsToAggregate?: number;
  uploadBomOutput?: boolean;
};

const SettingsPanel: React.FC = () => {
  const theme = useTheme();
  const { selectedProductRelease, selectedInstances } = useContext(ProductContext);

  const outputs: DCOutputWithVirtualTypes[] = useMemo(
    // Business requirement: Remove RFA from available options in the webapp
    // We also omit sending thumbnails to generate outputs.
    () => {
      const outputs: DCOutputWithVirtualTypes[] =
        selectedProductRelease?.outputs.filter(
          (output) => output.type !== OutputType.RFA && output.type !== OutputType.THUMBNAIL,
        ) || [];

      const bomOutput = outputs.find((output) => output.type === OutputType.BOM);

      if (!bomOutput) {
        return outputs;
      }

      const groupedInstances = Object.values(groupBy(selectedInstances, 'variantId'));

      // the BOMs can be aggregated if there are 2 or more instances of the same variant
      const canBeAggregated = groupedInstances.some((instances) => instances.length > 1);
      if (canBeAggregated) {
        // add a new virtual output type to render the proper tree structure
        const bomAggregatedOutput: DCOutputAggregatedBom = {
          options: { modelStates: getDCOutputModelStates(bomOutput) || [] },
          type: OutputTypeWithVirtualTypes.BOMAGGREGATED,
        };
        outputs.push(bomAggregatedOutput);
      }

      return outputs;
    },
    [selectedProductRelease?.outputs, selectedInstances],
  );

  const productName = selectedProductRelease?.name;
  const productRelease = selectedProductRelease?.release;

  const {
    selectedOutputs,
    removeOutput,
    addNewOutput,
    addAllOutputsFromSpecificType,
    removeAllOutputsFromSpecificType,
    addAllOutputs,
    removeAllOutputs,
  } = useSelectOutputs({ outputs });

  const totalOutputs = useMemo(
    () =>
      outputs.reduce<number>((acc, output) => {
        const modelStates = getDCOutputModelStates(output);
        if (modelStates) {
          return acc + modelStates.length;
        }
        return acc + 1;
      }, 0),
    [outputs],
  );

  const outputsTypeIAM = useMemo(() => outputs.filter((output) => output.type === OutputType.IAM), [outputs]);
  const outputsTypeBOM = useMemo(
    () =>
      outputs.filter(
        (output) =>
          output.type === OutputTypeWithVirtualTypes.BOM || output.type === OutputTypeWithVirtualTypes.BOMAGGREGATED,
      ),
    [outputs],
  );

  const outputsTypeDrawing = useMemo(
    () => outputs.filter((output) => drawingsOutputTypesList.includes(output.type)),
    [outputs],
  );
  const outputsTypeNeutral = useMemo(
    () => outputs.filter((output) => neutralOutputTypesList.includes(output.type)),
    [outputs],
  );

  const outputsOrderList = [
    { [settingsPanelText.model3d]: outputsTypeIAM },
    { [settingsPanelText.drawing2d]: outputsTypeDrawing },
    { [settingsPanelText.billOfMaterials]: outputsTypeBOM },
    { [settingsPanelText.neutralFormatTitle]: outputsTypeNeutral },
  ];

  const OutputsSections = outputsOrderList.map((outputSection, index) => {
    const outputs = Object.values(outputSection).flat();
    const outputTitle = Object.keys(outputSection)[0];

    if (outputs.length === 1) {
      const modelStates = getDCOutputModelStates(outputs[0]);
      const drawingTemplatePath = getDCOutputModelStates(outputs[0]);
      if (outputs[0].type === OutputType.IAM || modelStates?.length === 1 || drawingTemplatePath) {
        return (
          <OutputSettingsItem
            key={`${outputTitle}-${index}`}
            output={outputs[0]}
            outputSectionTitle={outputTitle}
            selectedOutputs={selectedOutputs}
            addOutput={addNewOutput}
            removeOutput={removeOutput}
          />
        );
      }
    }

    if (outputs.length >= 1) {
      const outputsTypesList = outputs.map((output) => output.type);

      return (
        <OutputSettingsWithTree
          key={`${outputTitle}-${index}`}
          outputs={outputs}
          outputTypesList={outputsTypesList}
          selectedOutputs={selectedOutputs}
          outputSectionTitle={outputTitle}
          addOutput={addNewOutput}
          removeOutput={removeOutput}
          addAllOutputs={addAllOutputsFromSpecificType}
          removeAllOutputs={removeAllOutputsFromSpecificType}
        />
      );
    }

    return null;
  });

  const uploadLocationStore = useUploadLocationStore();

  return (
    <UploadLocationContext.Provider value={uploadLocationStore}>
      <SettingsPanelBackground>
        <OutputSettingsPanelHeader
          productName={productName}
          productRelease={productRelease}
          selectedOutputs={selectedOutputs}
        />
        <FlexContainer gap={theme.var.marginBase * 2} overflow="auto">
          <OutputTypesSettings className="mid-bg-shadow">
            {selectedProductRelease && selectedInstances ? (
              <>
                <Typography variant="h2" gutterBottom>
                  {settingsPanelText.fileTypes}
                </Typography>
                <Typography variant="body1">{settingsPanelText.duplicateFilesUploaded}</Typography>
                <FormControlLabel
                  control={
                    <SelectAllOutputsCheckbox
                      outputsLength={totalOutputs}
                      selectedOutputsLength={selectedOutputs.length}
                      addAllOutputs={addAllOutputs}
                      removeAllOutputs={removeAllOutputs}
                    />
                  }
                  label={<Label>{settingsPanelText.allAvailable}</Label>}
                />
                {OutputsSections}
              </>
            ) : (
              <Typography variant="h2" data-testid={outputsSettingsIds.selectProductPrompt}>
                {settingsPanelText.selectProductPrompt}
              </Typography>
            )}
          </OutputTypesSettings>
          <UploadLocation />
        </FlexContainer>
      </SettingsPanelBackground>
    </UploadLocationContext.Provider>
  );
};

export default SettingsPanel;
