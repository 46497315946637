import { OutputStatus, ProductRelease, Variant, VariantOutput } from '@adsk/offsite-dc-sdk';
import { isDCRfaOutput, isVariantRfaOutput } from '../typeGuards';
import { DCOutputWithVirtualTypes, PostVariantOutputWithVirtualTypes } from 'mid-types';
import { ProductDefinition } from 'mid-addin-lib';

// check if any RFA output is pending in variant
export const isRfaOutputPending = (variant: Variant): boolean =>
  variant.outputs.filter((output) => isVariantRfaOutput(output)).some((output) => output.status === OutputStatus.PENDING);

export const isRfaOutputFailed = (variant: Variant): boolean =>
  variant.outputs.filter((output) => isVariantRfaOutput(output)).some((output) => output.status === OutputStatus.FAILED);

export const productHasRFAModelState = (product: ProductRelease, modelState: string): boolean =>
  product.outputs.some((output) => isDCRfaOutput(output) && output.options.modelStates.includes(modelState));

export const getDCOutputModelStates = (output: DCOutputWithVirtualTypes): string[] | undefined =>
  'options' in output && 'modelStates' in output.options ? output.options.modelStates : undefined;

export const getDCOutputDrawingPath = (output: DCOutputWithVirtualTypes): string | undefined =>
  'options' in output && 'drawingTemplatePath' in output.options ? output.options.drawingTemplatePath : undefined;

export const getVariantOutputModelState = (
  output: VariantOutput | PostVariantOutputWithVirtualTypes | DCOutputWithVirtualTypes,
): string | undefined => ('modelState' in output ? output.modelState : undefined);

export const getVariantOutputsDrawingPath = (
  output: VariantOutput | PostVariantOutputWithVirtualTypes | DCOutputWithVirtualTypes,
): string | undefined => ('drawingTemplatePath' in output ? output.drawingTemplatePath : undefined);

export const isProductStatic = (product: ProductDefinition | ProductRelease | Variant): boolean =>
  product.isConfigurable === false;

export const getProductConfigurableStatus = (isConfigurable?: boolean): boolean =>
  isConfigurable === true || isConfigurable === undefined;
