import { logError } from 'mid-utils';
import { useState } from 'react';
import text from '../../common.text.json';

export interface ModalState {
  isOpen: boolean;
  dontShowAgain?: boolean;
  dontShowAgainMessage?: string;
  title?: string | JSX.Element;
  message: string | JSX.Element;
  onConfirmCallback?: Function;
  isConfirmCallbackAsync?: boolean;
  onSecondaryConfirmCallback?: Function;
  confirmButtonLabel: string;
  cancelButtonLabel: string;
  disableConfirmButton?: boolean;
  secondaryConfirmButtonLabel?: string;
  disableSecondaryConfirmButton?: boolean;
}

export interface ModalStore {
  isOpen: boolean;
  dontShowAgain?: boolean;
  dontShowAgainMessage?: string;
  title?: string | JSX.Element;
  message: null | string | JSX.Element;
  confirmButtonLabel: string;
  cancelButtonLabel: string;
  disableConfirmButton?: boolean;
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>;
  handleConfirm: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void> | void;
  handleCancel: () => void;
  handleDontShowAgainClick?: () => void;
  handleSecondaryConfirm?: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void> | void;
  secondaryConfirmButtonLabel?: string;
  disableSecondaryConfirmButton?: boolean;
}

export const initialModalState: ModalState = {
  isOpen: false,
  dontShowAgain: false,
  message: '',
  confirmButtonLabel: text.buttonYes,
  cancelButtonLabel: text.buttonNo,
  disableConfirmButton: false,
};

export const useModalStore = (): ModalStore => {
  const [modalState, setModalState] = useState<ModalState>(initialModalState);

  const handleConfirm = async () => {
    const resetingState: Partial<ModalState> = { disableConfirmButton: false, isOpen: false, onConfirmCallback: undefined };

    if (!modalState.onConfirmCallback) {
      logError(text.noConfirmCallback);
      setModalState((prevState) => ({
        ...prevState,
        ...resetingState,
      }));
      throw new Error(text.noConfirmCallback);
    }
    if (modalState.isConfirmCallbackAsync) {
      await modalState.onConfirmCallback();
    } else {
      modalState.onConfirmCallback();
    }

    setModalState((prevState) => ({
      ...prevState,
      ...resetingState,
    }));
  };

  const handleSecondaryConfirm = async () => {
    const resetingState: Partial<ModalState> = {
      disableSecondaryConfirmButton: false,
      isOpen: false,
      onSecondaryConfirmCallback: undefined,
    };

    if (!modalState.onSecondaryConfirmCallback) {
      logError(text.noSecondaryConfirmCallback);
      setModalState((prevState) => ({
        ...prevState,
        ...resetingState,
      }));
      throw new Error(text.noSecondaryConfirmCallback);
    }
    modalState.onSecondaryConfirmCallback();

    setModalState((prevState) => ({
      ...prevState,
      ...resetingState,
    }));
  };

  const handleCancel = () => {
    setModalState((prevState) => ({
      ...prevState,
      isOpen: false,
      onConfirmCallback: undefined,
    }));
  };

  const handleDontShowAgainClick = (): void => {
    setModalState((prevState) => ({
      ...prevState,
      dontShowAgain: !prevState.dontShowAgain,
    }));
  };

  return {
    ...modalState,
    setModalState,
    handleConfirm,
    handleSecondaryConfirm,
    handleCancel,
    handleDontShowAgainClick,
  };
};
