import { midTheme, NotificationContext } from '@mid-react-common/common';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useContext, useState } from 'react';
import text from '../../../../global/text.json';
import { getVariantsLogs } from '../../../../services/variants';
import { outputsReviewPanel } from '../../../../tests/helpers/dataTestIds';
import LogDialog from './LogDialog';
import ProductContext from 'context/ProductStore/Product.context';
import { getBridgeDataFromInstance } from 'utils/bridge';

const reviewPanelText = text.reviewPanel;

interface LogPreviewProps {
  projectId: string | null;
  variantId?: string;
  productId?: string;
}

const LogPreview: React.FC<LogPreviewProps> = ({ variantId, productId, projectId }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const { logAndShowNotification } = useContext(NotificationContext);

  const { instances } = useContext(ProductContext);

  const [fileUrl, setFileUrl] = useState<string>('');
  const [fileData, setFileData] = useState<string>('');
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  if (!projectId || !variantId || !productId) {
    return null;
  }

  const handleOpenLogClick = async () => {
    setLoading(true);

    try {
      const bridgeInstance = instances?.find((instance) => instance.variantId === variantId);
      const incomingAccBridgeData = bridgeInstance ? getBridgeDataFromInstance(bridgeInstance) : {};

      const { fileUrl, logData } = await getVariantsLogs(projectId, productId, variantId, incomingAccBridgeData);

      setFileUrl(fileUrl);
      setFileData(logData);
      setIsDialogOpen(true);
    } catch (error) {
      logAndShowNotification({
        message: reviewPanelText.failToGetFile,
        error,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadFile = async () => {
    const anchor = document.createElement('a');
    anchor.href = fileUrl;
    document.body.appendChild(anchor);

    // Programmatically clicking to download file
    anchor.click();

    // Remove element from DOM after file has been programmatically downloaded
    document.body.removeChild(anchor);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOpenLogClick}
        data-testid={outputsReviewPanel.downloadLogsButton}
        disabled={isLoading}
        endIcon={
          isLoading && (
            <CircularProgress size={midTheme.var.buttonSpinnerSize} data-testid={outputsReviewPanel.circularProgress} />
          )
        }
      >
        {reviewPanelText.openFailureLog}
      </Button>
      <LogDialog
        fileData={fileData}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        handleDownloadFile={handleDownloadFile}
      />
    </>
  );
};

export default LogPreview;
