import { useTheme } from '@mui/material/styles';
import OutgoingAccBridge from '../../assets/svg/acc_bridge/outgoing_acc_bridge.svg?react';
import IncomingAccBridge from '../../assets/svg/acc_bridge/incoming_acc_bridge.svg?react';
import { accBridge } from '../../global/dataTestIds';
import React from 'react';
import { ACC_BRIDGE_STATUS, AccBridgeStatus } from 'mid-types';

export const AccBridgeIncomingIcon = React.forwardRef<HTMLDivElement>((props, ref) => {
  const theme = useTheme();

  return (
    <div ref={ref} {...props}>
      <IncomingAccBridge
        width={theme.var.accBridgeIconSize}
        height={theme.var.accBridgeIconSize}
        data-testid={accBridge.accBridgeIncomingIcon}
      />
    </div>
  );
});

export const AccBridgeOutgoingIcon = React.forwardRef<HTMLDivElement>((props, ref) => {
  const theme = useTheme();

  return (
    <div ref={ref} {...props}>
      <OutgoingAccBridge
        width={theme.var.accBridgeIconSize}
        height={theme.var.accBridgeIconSize}
        data-testid={accBridge.accBridgeOutgoingIcon}
      />
    </div>
  );
});

export const AccBridgeIcon: React.FC<{ accBridgeStatus?: AccBridgeStatus }> = ({ accBridgeStatus }) => {
  if (accBridgeStatus === ACC_BRIDGE_STATUS.OUTGOING) {
    return <AccBridgeOutgoingIcon />;
  }
  if (accBridgeStatus === ACC_BRIDGE_STATUS.INCOMING) {
    return <AccBridgeIncomingIcon />;
  }

  return null;
};
