export interface PropertyGroupOption {
  category: string;
  label: string;
  value: string;
  disabled?: boolean;
  type: number;
}

export interface PropertyGroup {
  category: string;
  options?: PropertyGroupOption[];
  value?: string;
}

export interface RowData {
  name: string;
  type: string;
  unit?: string;
  units?: string;
  id: string;
  key: string;
  selected: boolean;
  isProductSelected?: boolean;
  propertyList: PropertyGroup[];
  propertyName?: string;
  value: string;
  selectedValue: PropertyGroupOption;
}

export enum PropertyType {
  Unknown = 0,
  Boolean = 1,
  Integer = 2,
  Numeric = 2,
  Double = 3,
  BLOB = 10,
  DbKey = 11,
  String = 20,
  Text = 20,
  LocalizableString = 21,
  DateTime = 22,
  GeoLocation = 23,
  Position = 24,
}
