import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const SidebarThumbnailContainer = styled(Box)`
  display: flex;
  height: 200px;
  background-color: white;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
`;

export const SidebarThumbnailImage = styled('img')`
  height: 100%;
  width: auto;
  max-width: 100%;
`;
