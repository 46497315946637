import {
  OutputType,
  OutputTypes,
  DCOutput,
  PostVariantOutput,
  OutputStatus,
  DCDwgOutput,
  DCIdwOutput,
  DCPdfOutput,
  IdwOutputType,
  PdfOutputType,
  DwgOutputType,
  DCBomOutput,
  BomOutputType,
  DCStepOutput,
  DCGlbOutput,
  DCSatOutput,
  DCStlOutput,
  StlOutputType,
  StepOutputType,
  GlbOutputType,
  SatOutputType,
} from '@adsk/offsite-dc-sdk';

const BOMAggregated = 'BOMAGGREGATED' as const;
export type BOMAGGREGATED = typeof BOMAggregated;
export type DCOutputAggregatedBom = {
  type: BOMAGGREGATED;
  options: {
    modelStates: string[];
  };
};
export const OutputTypeWithVirtualTypes = { ...OutputType, BOMAGGREGATED: BOMAggregated };
export type PostVariantOutputAggregatedBom = {
  type: BOMAGGREGATED;
  modelState: string;
};
export type DCOutputWithVirtualTypes = DCOutput | DCOutputAggregatedBom;
export type OutputTypesWithVirtualTypes = OutputTypes | BOMAGGREGATED;

export type DCDrawingOutputs = DCDwgOutput | DCIdwOutput | DCPdfOutput;
export type DrawingOutputTypes = IdwOutputType | PdfOutputType | DwgOutputType;

export type DCBomOutputs = DCBomOutput | DCOutputAggregatedBom;
export type BomOutputTypes = BomOutputType | BOMAGGREGATED;

export type DCNeutralOutputs = DCStepOutput | DCSatOutput | DCGlbOutput | DCStlOutput;
export type NeutralOutputTypes = StlOutputType | StepOutputType | GlbOutputType | SatOutputType;

export const VirtualOutputTypes = [BOMAggregated];
export type PostVariantOutputWithVirtualTypes = PostVariantOutput | PostVariantOutputAggregatedBom;
const CancelledOutputStatus = 'CANCELLED';
export type CANCELLED = typeof CancelledOutputStatus;
export const VirtualOutputStatus = { ...OutputStatus, CANCELLED: CancelledOutputStatus };
