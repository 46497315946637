import { Hub, hubTypes, HubLinks } from 'mid-types';
import { mockAccount, mockAccount2 } from './mockAccounts';

const mockHubLinks: HubLinks = {
  links: { self: { href: '' } },
  relationships: {
    projects: {
      links: { related: { href: '' } },
    },
  },
};

// Hub that contains ACC projects
export const mockAccHub: Hub = {
  type: 'hubs',
  id: mockAccount.id,
  attributes: {
    name: mockAccount.name,
    extension: {
      type: hubTypes.BIM360_OR_ACC,
      version: '1.0',
      schema: {
        href: 'https://autodesk.com/schemalink',
      },
      data: {},
    },
    region: 'US',
  },
  ...mockHubLinks,
};

export const mockAccHubApiResponse: Hub = {
  ...mockAccHub,
  id: `b.${mockAccHub.id}`,
};

// Hub that contains Bim360 projects
export const mockBim360Hub: Hub = {
  type: 'hubs',
  id: mockAccount2.id,
  attributes: {
    name: mockAccount2.name,
    extension: {
      type: hubTypes.BIM360_OR_ACC,
      version: '1.0',
      schema: {
        href: 'https://autodesk.com/schemalink',
      },
      data: {},
    },
    region: 'US',
  },
  ...mockHubLinks,
};

export const mockBim360HubApiResponse: Hub = {
  ...mockAccHub,
  id: `b.${mockBim360Hub.id}`,
};

// Hub that contains Fusion projects
export const mockFusionHub: Hub = {
  type: 'hubs',
  id: 'fusion-account-id',
  attributes: {
    name: 'Fusion account name',
    extension: {
      type: hubTypes.FUSION,
      version: '1.0',
      schema: {
        href: 'https://autodesk.com/schemalink',
      },
      data: {},
    },
    region: 'US',
  },
  ...mockHubLinks,
};

export const mockFusionHubApiResponse: Hub = {
  ...mockFusionHub,
  id: `a.${mockFusionHub.id}`,
};
