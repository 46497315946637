import { ErrorCode } from './errorCode';

export abstract class CustomError extends Error {
  abstract errorCode: ErrorCode;

  constructor(message: string, cause?: any) {
    super(message, { cause });
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
