export const VIEWER_THEME: 'light-theme' | 'dark-theme' = 'light-theme';

export const viewerServiceConstants = {
  DEV: 'development',
  DEFAULT_EMPTY_MODEL: 'Dummy',
  DOCUMENT_PREFIX: 'urn:',
  ERROR: 'error',
  GEOMETRY: 'geometry',
  IS_MID_ELEMENT: 'MID/isMIDElement',
  CATEGORY: 'Category',
  REVIT_LEVEL: 'Revit Level',
  PROPERTY_PARENT: '__parent__',
  PROPERTY_CATEGORY: '__category__',
  ATTRIBUTE_CATEGORY: '_RC',
  ATTRIBUTE_FAMILY: '_RFN',
};
