import { createTableData, generateTokenCost, TableRowData } from '../../utils';
import useRFOModalStore from 'context/RFOModalStore/useRFOModalStore';
import { useShallow } from 'zustand/react/shallow';
import { useContext, useEffect } from 'react';
import ProductContext from 'context/ProductStore/Product.context';
import { OutputTypeWithVirtualTypes } from 'mid-types';
import { Variant } from '@adsk/offsite-dc-sdk';

interface UseOutputsTableState {
  tableData: TableRowData[];
  totalQuantity: number;
  totalCostInUnicodeValue: string;
  selectedVariants: Variant[];
  handleFileTypesChangeClick: () => void;
}

const useOutputsTable = (): UseOutputsTableState => {
  const { selectedInstances, variants } = useContext(ProductContext);
  const {
    isIamSelected,
    aggregatedBomOutputs,
    bomOutputs,
    dwgOutputs,
    glbOutputs,
    idwOutputs,
    pdfOutputs,
    satOutputs,
    stepOutputs,
    setFileTypesStepActive,
    setTotalTokenCost,
  } = useRFOModalStore(
    useShallow((state) => ({
      isIamSelected: state.isIamSelected,
      dwgOutputs: state.dwgOutputs,
      idwOutputs: state.idwOutputs,
      pdfOutputs: state.pdfOutputs,
      bomOutputs: state.bomOutputs,
      aggregatedBomOutputs: state.aggregatedBomOutputs,
      satOutputs: state.satOutputs,
      stepOutputs: state.stepOutputs,
      glbOutputs: state.glbOutputs,
      setFileTypesStepActive: state.setFileTypesStepActive,
      setTotalTokenCost: state.setTotalTokenCost,
    })),
  );

  const selectedVariants = variants.filter((variant) =>
    selectedInstances?.some((instance) => instance.variantId === variant.variantId),
  );

  const allOutputs = [
    { outputs: dwgOutputs, type: OutputTypeWithVirtualTypes.DWG },
    { outputs: idwOutputs, type: OutputTypeWithVirtualTypes.IDW },
    { outputs: pdfOutputs, type: OutputTypeWithVirtualTypes.PDF },
    { outputs: bomOutputs, type: OutputTypeWithVirtualTypes.BOM },
    { outputs: aggregatedBomOutputs, type: OutputTypeWithVirtualTypes.BOMAGGREGATED },
    { outputs: satOutputs, type: OutputTypeWithVirtualTypes.SAT },
    { outputs: glbOutputs, type: OutputTypeWithVirtualTypes.GLB },
    { outputs: stepOutputs, type: OutputTypeWithVirtualTypes.STEP },
  ];

  const tableData = createTableData(isIamSelected, allOutputs, selectedVariants);

  const totalQuantity = tableData.reduce((acc, output) => acc + output.fileTypeQuantity, 0);
  const { totalCost, totalCostInUnicodeValue } = generateTokenCost(totalQuantity);

  useEffect(() => {
    setTotalTokenCost(totalCost);
  }, [totalCost, setTotalTokenCost]);

  return {
    tableData,
    totalQuantity,
    totalCostInUnicodeValue,
    selectedVariants,
    handleFileTypesChangeClick: setFileTypesStepActive,
  };
};

export default useOutputsTable;
