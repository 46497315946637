import { DCDrawingOutputs, DrawingOutputTypes, OutputTypeWithVirtualTypes } from 'mid-types';
import { SelectChangeEvent } from '@mui/material/Select';
import useRFOModalStore from 'context/RFOModalStore/useRFOModalStore';
import { handleDrawing2DChange, handleDrawing2DDelete } from '../FileTypesStep.utils';
import { useShallow } from 'zustand/react/shallow';

interface UseDrawing2DProps {
  outputsTypeDrawing: DCDrawingOutputs[];
}

export interface UseDrawing2DState {
  idwOutputs: string[];
  dwgOutputs: string[];
  pdfOutputs: string[];
  drawingOutputsByType: { type: DrawingOutputTypes; drawings: string[] }[];
  handleIdwOutputsChange: (event: SelectChangeEvent<string[]>) => void;
  handleDwgOutputsChange: (event: SelectChangeEvent<string[]>) => void;
  handlePdfOutputsChange: (event: SelectChangeEvent<string[]>) => void;
  handleIdwOutputDelete: (event: any, value: string) => void;
  handleDwgOutputDelete: (event: any, value: string) => void;
  handlePdfOutputDelete: (event: any, value: string) => void;
}

const useDrawing2D = ({ outputsTypeDrawing }: UseDrawing2DProps): UseDrawing2DState => {
  const { allOutputs, dwgOutputs, idwOutputs, pdfOutputs, setAllOutputs, setDwgOutputs, setIdwOutputs, setPdfOutputs } =
    useRFOModalStore(
      useShallow((state) => ({
        idwOutputs: state.idwOutputs,
        setIdwOutputs: state.setIdwOutputs,
        dwgOutputs: state.dwgOutputs,
        setDwgOutputs: state.setDwgOutputs,
        pdfOutputs: state.pdfOutputs,
        setPdfOutputs: state.setPdfOutputs,
        allOutputs: state.allOutputs,
        setAllOutputs: state.setAllOutputs,
      })),
    );

  const drawingOutputsByType = outputsTypeDrawing.reduce(
    (acc: { type: DrawingOutputTypes; drawings: string[] }[], output) => {
      const type = output.type;
      const foundIndex = acc.findIndex((item) => item.type === type);

      if (foundIndex === -1) {
        acc.push({ type, drawings: [output.options.drawingTemplatePath] });
      } else {
        acc[foundIndex].drawings.push(output.options.drawingTemplatePath);
      }

      return acc;
    },
    [],
  );

  const handleIdwOutputDelete = (event: any, value: string) => {
    handleDrawing2DDelete(value, OutputTypeWithVirtualTypes.IDW, idwOutputs, allOutputs, setIdwOutputs, setAllOutputs);
  };

  const handleDwgOutputDelete = (event: any, value: string) => {
    handleDrawing2DDelete(value, OutputTypeWithVirtualTypes.DWG, dwgOutputs, allOutputs, setDwgOutputs, setAllOutputs);
  };

  const handlePdfOutputDelete = (event: any, value: string) => {
    handleDrawing2DDelete(value, OutputTypeWithVirtualTypes.PDF, pdfOutputs, allOutputs, setPdfOutputs, setAllOutputs);
  };

  const handleIdwOutputsChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    handleDrawing2DChange(
      value,
      OutputTypeWithVirtualTypes.IDW,
      idwOutputs,
      outputsTypeDrawing,
      allOutputs,
      setIdwOutputs,
      setAllOutputs,
    );
  };

  const handleDwgOutputsChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    handleDrawing2DChange(
      value,
      OutputTypeWithVirtualTypes.DWG,
      dwgOutputs,
      outputsTypeDrawing,
      allOutputs,
      setDwgOutputs,
      setAllOutputs,
    );
  };

  const handlePdfOutputsChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    handleDrawing2DChange(
      value,
      OutputTypeWithVirtualTypes.PDF,
      pdfOutputs,
      outputsTypeDrawing,
      allOutputs,
      setPdfOutputs,
      setAllOutputs,
    );
  };

  return {
    dwgOutputs,
    idwOutputs,
    pdfOutputs,
    drawingOutputsByType,
    handleIdwOutputsChange,
    handleDwgOutputsChange,
    handlePdfOutputsChange,
    handleIdwOutputDelete,
    handleDwgOutputDelete,
    handlePdfOutputDelete,
  };
};

export default useDrawing2D;
