import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';

export type CustomSvgIconProps = {
  disabled?: boolean;
};

export const CustomSvgIcon = styled(SvgIcon)<CustomSvgIconProps>`
  fill: ${({ disabled, theme }) => (disabled ? theme.colors.primary.charcoal['400'] : theme.colors.primary.black)};
`;
