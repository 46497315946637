import { AsyncProviderConfig } from 'launchdarkly-react-client-sdk';

const LDProviderConfig: AsyncProviderConfig = {
  clientSideID: import.meta.env.VITE_LD_CLIENT_SIDE_ID!,
  options: {
    streaming: import.meta.env.VITE_ENVIRONMENT !== 'mock',
  },
  reactOptions: {
    useCamelCaseFlagKeys: true,
  },
};

export const LD_ANALYTICS_KIND = 'user';

export const LDProviderConfigWithAnonymousAnalytics: AsyncProviderConfig = {
  ...LDProviderConfig,
  context: { kind: LD_ANALYTICS_KIND, anonymous: true },
};
