import { CustomIcon, ThemableCSSProp } from '@mid-react-common/common';
import Box from '@mui/material/Box';
import { css, styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import AdskIcon from './images/autodesk-collections-icon-dark-slate.png';

export const TabsWrapper = styled(Box)`
  border-bottom: ${({ theme }) => `${theme.var.borderHeight}px`} solid
    ${({ theme }) => theme.colors.primary.autodeskBlue[500]};
  border-color: ${({ theme }) => theme.colors.primary.charcoal[200]};
`;

const NavCommonCSS = ({ theme }: ThemableCSSProp) => css`
  :link {
    text-decoration: none;
  }

  :visited {
    text-decoration: none;
  }

  &.active {
    text-decoration: none;
    color: ${theme.colors.primary.autodeskBlue[600]};
  }
`;

export const NavHorizontal = styled(NavLink)`
  ${NavCommonCSS}

  padding: ${({ theme }) => `${theme.var.paddingBase}px 17px`};
  color: ${({ theme }) => theme.colors.primary.charcoal[700]};
  display: inline-block;

  &.active {
    border-bottom: ${({ theme }) => theme.var.navBorderWidth}px solid
      ${({ theme }) => theme.colors.primary.autodeskBlue[500]};
  }
`;

export const SidebarBox = styled(Box)`
  display: flex;
  flex-direction: column;
  min-width: 100px;
`;

export const EmptyStateWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${({ theme }) => theme.var.midwMainHeader}px);
  justify-content: center;
`;

export const NavIcon = styled(CustomIcon)`
  width: ${({ theme }) => theme.var.navIconSize}px;
  height: ${({ theme }) => theme.var.navIconSize}px;
  display: block;
  margin: 0 auto;
`;

NavIcon.defaultProps = {
  src: AdskIcon,
};
