import { Vendors } from '@adsk/offsite-dc-sdk';
import { FolderPermissionAction, FolderPermissionDetail, HubType } from 'mid-types';

export type GetHubProjectsArgs = {
  hubId: string;
  hubType: HubType;
  signal?: AbortSignal;
};

export type GetHubProjectFolderContentsArgs = {
  projectId: string;
  folderId: string;
  vendor?: Vendors;
};

export type GetHubProjectTopFoldersArgs = {
  hubId: string;
  projectId: string;
  vendor?: Vendors;
  signal?: AbortSignal;
};

export const DEFAULT_CHECK_PERMISSION_PAGE_SIZE = 50;

export type CheckHubProjectFolderPermissionsArgs = {
  projectId: string;
  vendor?: Vendors;
  folderUrns: string[];
  permissionActions: FolderPermissionAction[];
  pageSize?: number;
};

export type CheckHubProjectFolderPermissionsResult = {
  results: FolderPermissionDetail[];
  errors: PromiseRejectedResult[];
};

export type GetHubProjectArgs = {
  hubId: string;
  projectId: string;
  vendor?: Vendors;
  signal?: AbortSignal;
};

export type GetHubProjectFolderArgs = {
  projectId: string;
  folderId: string;
  vendor?: Vendors;
  signal?: AbortSignal;
};
