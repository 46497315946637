import { CustomError } from './customError';
import { ErrorCode } from './errorCode';

type CauseType = {
  error?: Error;
  context?: Record<string, any>;
};

export class UnexpectedError extends CustomError {
  errorCode = ErrorCode.UnexpectedError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
