import axios from 'axios';
import JSZip from 'jszip';
import { ApiUploadFileError } from 'mid-utils';
import { UploadURLsPayload, CompleteUploadPayload } from '@adsk/offsite-dc-sdk';
import { DcApiService, inversifyContainer, InversifyTypes } from 'mid-api-services';
import { UploadUrlsResponse } from '../../interfaces/cloudStorage';
import text from '../../mid-addin-lib.text.json';
import { toFileObject } from './filesystem';
import { logToFile } from './tools';
import { LogLevel } from '../../interfaces/log';
import { CompleteBcdUploadPayload, CompleteBcdUploadResponse, UploadBcdURLsPayload } from 'mid-types';

/**
 * Gets the chunk information for upload taking into account OSS/S3 limitations.
 */
const getChunksInfo = (blob: Blob): any => {
  const MAX_PARTS = 25;
  const MIN_CHUNKSIZE = 0x500000;

  // Just use the blob size if we can upload in a single call.
  // Otherwise, make the chunk size as large as needed but not smaller than the allowed minimum.
  const chunkSize = blob.size <= MIN_CHUNKSIZE ? blob.size : Math.max(MIN_CHUNKSIZE, Math.ceil(blob.size / MAX_PARTS));
  const numChunks = Math.ceil(blob.size / chunkSize);

  return {
    chunkSize,
    numChunks,
  };
};

/** Gets the filename portion of a fully qualyfied file path.
 *
 * @param filePath Fully qualified path to a file.
 * @returns The filename portion of the file path.
 */
// eslint-disable-next-line
const getFileNameFromPath = (filePath: string) => filePath.replace(/^.*(\\|\/|\:)/, '');

/**
 * Data categories
 */
export enum DataCategory {
  Inputs = 'Inputs',
  Outputs = 'Outputs',
  Logs = 'Logs',
  CodeBlocksWorkspace = 'CodeBlocksWorkspace',
  Rules = 'Rules',
}

/**
 * Uploads a file to the cloud.
 *
 * @param tenancyId The tenancy id.
 * @param category The data category.
 * @param filePath The full path of the file to upload.
 * @param contentType Optional content type; defaults to 'application/octet-stream'.
 * @returns The object key with which the file can be downloaded.
 */
export const uploadFile = async (
  tenancyId: string,
  filePath: string,
  category: DataCategory,
  contentType = 'application/octet-stream',
): Promise<string> => {
  try {
    logToFile(`Attempting to create a file object for ${filePath}`, LogLevel.Info);
    const file: File = await toFileObject(filePath);
    const fileSize = file.size;
    const fileName = getFileNameFromPath(filePath);
    logToFile(`Attempting to get chunks for ${fileName} with size ${fileSize}`, LogLevel.Info);
    const { chunkSize, numChunks } = getChunksInfo(file);

    const uploadURLsPayload: UploadURLsPayload = {
      fileName,
      numberOfParts: numChunks,
      category,
    };

    // get signed URLs for multi-part upload
    const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
    logToFile(`Attempting to get upload urls for ${fileName}`, LogLevel.Info);
    const uploadUrlsResponse: UploadUrlsResponse = await dcApiService.getUploadURLs(tenancyId, uploadURLsPayload);

    // upload the chunks
    const axiosInstance = axios.create({
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });

    logToFile(`Attempting to upload ${fileName}`, LogLevel.Info);
    for (let i = 0, start = 0; i < numChunks; ++i) {
      const end = Math.min(start + chunkSize, file.size);
      const data: Blob = file.slice(start, end);

      await axiosInstance.put(uploadUrlsResponse.urls[i], data);
      start = end;
    }

    const completeUploadPayload: CompleteUploadPayload = {
      contentType,
      fileName,
      fileSize,
      objectKey: uploadUrlsResponse.objectKey,
      uploadKey: uploadUrlsResponse.uploadKey,
    };

    logToFile(`Attempting to complete upload for ${fileName}`, LogLevel.Info);
    await dcApiService.completeUpload(tenancyId, completeUploadPayload);
    return uploadUrlsResponse.objectKey;
  } catch (error: unknown) {
    throw new ApiUploadFileError(text.apiRequestError, { error });
  }
};

export const uploadBcdFileAndGenerateRfa = async (
  bcdFilePath: string,
  revitVersion: string,
  fileName: string,
): Promise<CompleteBcdUploadResponse> => {
  try {
    const file: File = await toFileObject(bcdFilePath);
    const zip = new JSZip();
    zip.file(`${fileName}.bcd`, file);
    const bcdZipFile = await zip.generateAsync({ type: 'blob' });

    const { chunkSize, numChunks } = getChunksInfo(bcdZipFile);

    const uploadURLsPayload: UploadBcdURLsPayload = {
      fileName: `${fileName}.zip`,
      parts: numChunks,
    };

    // get BCD signed URLs for multi-part upload
    const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
    const bcdUploadUrlsResponse: UploadUrlsResponse = await dcApiService.getBcdUploadUrls(uploadURLsPayload);

    // upload the chunks
    const axiosInstance = axios.create({
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });

    for (let i = 0, start = 0; i < numChunks; ++i) {
      const end = Math.min(start + chunkSize, bcdZipFile.size);
      const data: Blob = bcdZipFile.slice(start, end);

      await axiosInstance.put(bcdUploadUrlsResponse.urls[i], data);
      start = end;
    }

    const completeUploadPayload: CompleteBcdUploadPayload = {
      engineVersion: revitVersion,
      objectKey: bcdUploadUrlsResponse.objectKey,
      uploadKey: bcdUploadUrlsResponse.uploadKey,
      fileName: `${fileName}.zip`,
      fileSize: bcdZipFile.size,
    };

    const rfaWorkItem = await dcApiService.completeBcdUploadAndGenerateRfa(completeUploadPayload);

    return rfaWorkItem;
  } catch (error: unknown) {
    throw new ApiUploadFileError(text.apiRequestError, { error });
  }
};
