import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { installersTestIds } from 'tests/helpers/dataTestIds';
import { AddinBadge, AllThumbnailsSection, LargeThumbnail, ThumbnailsContainer } from '../Installers.styles';
import ThumbnailSelector from './ThumbnailSelector';

interface BadgeSectionProps {
  badge: string;
  allThumbnails: { description: string; image: string }[];
}

const BadgeSection: React.FC<BadgeSectionProps> = ({ badge, allThumbnails }) => {
  const theme = useTheme();

  const [largeThumbnail, setLargeThumbnail] = useState({
    description: allThumbnails[0].description,
    image: allThumbnails[0].image,
  });

  return (
    <AddinBadge imageUrl={badge}>
      <ThumbnailsContainer>
        <LargeThumbnail data-testid={installersTestIds.largeThumbnail} src={largeThumbnail.image} />
        <Typography gutterBottom color={theme.colors.primary.white}>
          {largeThumbnail.description}
        </Typography>
        <AllThumbnailsSection data-testid={installersTestIds.smallThumbnailWrapper}>
          {allThumbnails.map((thumbnail) => (
            <ThumbnailSelector
              key={thumbnail.image}
              value={{ description: thumbnail.description, image: thumbnail.image }}
              handleThumbnailClick={setLargeThumbnail}
            />
          ))}
        </AllThumbnailsSection>
      </ThumbnailsContainer>
    </AddinBadge>
  );
};

export default BadgeSection;
