import { NavItem, Sidebar } from 'pages/Sidebar';
import { useCallback, useContext } from 'react';
import { Outlet, generatePath } from 'react-router-dom';
import ModelSelectionContext from '../../context/ModelSelectionStore/ModelSelection.context';
import { useDefaultNavigation } from '../../global/hooks/hooks';
import text from '../../global/text.json';
import InstancesIcon from '../../images/instances.svg?react';
import ModelsIcon from '../../images/models.svg?react';
import { routes } from '../../routes/routes';
import ReviewResultsIcon from '../../images/review results.svg?react';

const OutputsPage = (): JSX.Element => {
  const { currentlyOpenModel, selectedFolderUrn, selectedModelId } = useContext(ModelSelectionContext);

  useDefaultNavigation(routes.outputs.id, routes.models.path);

  // dynamically generate route to the Models page
  const getModelsRoute = useCallback(() => {
    if (selectedFolderUrn && selectedModelId) {
      return generatePath([routes.models.path, routes.folders.path, routes.folderItems.path].join('/'), {
        folderUrn: selectedFolderUrn,
        itemUrn: selectedModelId,
      });
    }

    return routes.models.path;
  }, [selectedFolderUrn, selectedModelId]);

  const navItems: NavItem[] = [
    { name: text.outputsSubNavs.models, path: getModelsRoute(), icon: <ModelsIcon /> },
    {
      name: text.outputsSubNavs.instances,
      path: routes.instances.path,
      icon: <InstancesIcon />,
      disabled: !currentlyOpenModel,
    },
    {
      name: text.outputsSubNavs.review,
      path: routes.review.path,
      icon: <ReviewResultsIcon />,
      disabled: !currentlyOpenModel,
    },
  ];

  return (
    <>
      <Sidebar navItems={navItems} />
      <Outlet />
    </>
  );
};

export default OutputsPage;
