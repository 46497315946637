import { ForgeDMProjectFolder, TreeNode } from 'mid-types';

export const flattenFolderTree = (
  folderTree: ForgeDMProjectFolder[],
  folderId: string,
): {
  result: Record<string, TreeNode[]>;
  expandedPath: string;
} => {
  const result: Record<string, TreeNode[]> = {};
  const toTreeNode = (item: ForgeDMProjectFolder): TreeNode => ({ id: item.urn, label: item.title });
  result.root = [toTreeNode(folderTree[0])];
  let expandedPath = '';

  const traverseTree = (treeNode: ForgeDMProjectFolder) => {
    if (treeNode.urn === folderId) {
      expandedPath = treeNode.path;
    }
    if (treeNode.folders.length) {
      result[treeNode.urn] = [];
      for (const subItem of treeNode.folders) {
        result[treeNode.urn].push(toTreeNode(subItem));
        traverseTree(subItem);
      }
    }
  };

  traverseTree(folderTree[0]);

  return {
    result,
    expandedPath,
  };
};
