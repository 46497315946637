import { Vendors } from '@adsk/offsite-dc-sdk';
import { ForgeApiService } from 'mid-api-services';
import { HubProject, HubProjectFolderContent, statusCodes } from 'mid-types';
import { HubProjectFolderFetchError, HubProjectsFetchError } from 'mid-utils';
import text from '../../../global/text.json';

const BAD_REQUEST = 'BadRequest';
const BIM360DM_ERROR = 'BIM360DM_ERROR';

const reviewPanel = text.reviewPanel;

export const handleGetHubProjectQuery = async (
  forgeApiService: ForgeApiService,
  outputLocationAccountId: string | null,
  outputLocationProjectId: string | null,
  outputProjectVendor: Vendors | null,
  signal: AbortSignal,
): Promise<{ data?: HubProject; showPermissionError: boolean } | undefined> => {
  if (!outputLocationAccountId || !outputLocationProjectId || !outputProjectVendor) {
    return;
  }

  const { data, errors } = await forgeApiService.getHubProject({
    hubId: outputLocationAccountId,
    projectId: outputLocationProjectId,
    vendor: outputProjectVendor,
    signal,
  });

  if (data) {
    return { data, showPermissionError: false };
  }

  // Need to make sure both status code is 400 and detail is bad request for checking permission error when fetching project.
  // There is a case with BAD_INPUTS also returning status code 400 when we pass in ids that are malformed
  if (
    errors?.length &&
    ((errors[0].status === statusCodes.BAD_REQUEST && errors[0].detail === BAD_REQUEST) ||
      (errors[0].status === statusCodes.NOT_FOUND && errors[0].code === BIM360DM_ERROR))
  ) {
    return { showPermissionError: true };
  }

  throw new HubProjectsFetchError(reviewPanel.hubProjectError, {
    hubId: outputLocationAccountId,
    projectId: outputLocationProjectId,
  });
};

export const handleGetHubProjectFolderQuery = async (
  forgeApiService: ForgeApiService,
  outputLocationProjectId: string | null,
  outputLocation: string | null,
  outputProjectVendor: Vendors | null,
  signal: AbortSignal,
): Promise<{ data?: HubProjectFolderContent; showPermissionError: boolean } | undefined> => {
  if (!outputLocationProjectId || !outputLocation || !outputProjectVendor) {
    return;
  }

  const { data, errors } = await forgeApiService.getHubProjectFolder({
    projectId: outputLocationProjectId,
    folderId: outputLocation,
    vendor: outputProjectVendor,
    signal,
  });

  if (data) {
    return { data, showPermissionError: false };
  }

  if (errors?.length && errors[0].status === statusCodes.FORBIDDEN) {
    return { showPermissionError: true };
  }

  throw new HubProjectFolderFetchError(reviewPanel.hubProjectFolderError, {
    projectId: outputLocationProjectId,
    folderUrn: outputLocation,
  });
};
