import { useCallback, useEffect, useState } from 'react';

type UseReleaseNotesProps = {
  onChange: (value: string, isValid: boolean) => void;
  defaultNotes?: string;
  focusOnMount?: boolean;
  releaseNotesRef: React.RefObject<HTMLTextAreaElement>;
  characterLimit: number;
};

type UseReleaseNotesState = {
  characterCount: number;
  isLimitExceeded: boolean;
  characterLimit: number;
  content: string;
  handleChange: React.ChangeEventHandler<HTMLTextAreaElement>;
};

const useReleaseNotes = ({
  onChange,
  releaseNotesRef,
  defaultNotes = '',
  focusOnMount = false,
  characterLimit,
}: UseReleaseNotesProps): UseReleaseNotesState => {
  const [content, setContent] = useState(defaultNotes);
  const [characterCount, setCharacterCount] = useState(content.length);
  const [isLimitExceeded, setIsLimitExceeded] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newContent = event.target.value;

      setContent(newContent);

      const isSizeLimitExceeded = newContent.length > characterLimit;

      setIsLimitExceeded(isSizeLimitExceeded);

      onChange(newContent, !isSizeLimitExceeded);

      setCharacterCount(newContent.length);
    },
    [characterLimit, onChange],
  );

  useEffect(() => {
    if (isMounted) {
      return;
    }
    setIsMounted(true);

    // just a one-time call on mount
    if (focusOnMount && releaseNotesRef.current) {
      releaseNotesRef.current.focus();
      releaseNotesRef.current.setSelectionRange(content.length, content.length);
    }
  }, [isMounted, content.length, focusOnMount, releaseNotesRef]);

  return {
    characterCount,
    isLimitExceeded,
    characterLimit,
    content,
    handleChange,
  };
};

export default useReleaseNotes;
