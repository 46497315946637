const productDetailsTableHeight = 200;
const thumbnailLoadingPositionTop = 200;
const updatePreviewScreenThresholdWidth = 700;
const previewThumbnailMaxWidth = 500;
const midefHeaderHeight = 46;
const midefHeaderWithTabsHeight = 60;
const midefFooterHeight = 60;
const releaseDropdownWidth = 160;
const midefTitleMinWidth = 240;

export const midefConstants = {
  productDetailsTableHeight,
  thumbnailLoadingPositionTop,
  updatePreviewScreenThresholdWidth,
  previewThumbnailMaxWidth,
  midefHeaderHeight,
  midefHeaderWithTabsHeight,
  midefFooterHeight,
  releaseDropdownWidth,
  midefTitleMinWidth,
};
