import { ThemeOptions } from '@mui/material/styles';

const globalTypography: ThemeOptions['typography'] = {
  fontFamily: '"Artifakt Element", sans-serif',
  fontWeightRegular: 400,
  fontWeightBold: 600,
  body1: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.42,
  },
  subtitle2: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1.2,
  },
  h1: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: 1.25,
  },
  h2: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: 1.3,
  },
  h3: {
    fontSize: '18px',
  },
  button: {
    textTransform: 'none',
  },
};

export default globalTypography;
