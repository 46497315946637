import { FocusEvent, forwardRef, ReactNode, useImperativeHandle, useRef, useState } from 'react';
import { MenuProps } from '@mui/material/Menu';
import { MenuItemProps } from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { EventlessMenu, StyledMenuItem, SubMenuWithEventWrapper } from './NestedMenuItem.styles';

export type NestedMenuItemProps = MenuItemProps & {
  parentMenuOpen: boolean;
  label?: string;
  children?: ReactNode;
  minWidth?: number;
  MenuProps?: Partial<Omit<MenuProps, 'children'>>;
};

/**
 * This component is stripped out from mui-nested-menu library.
 *
 * https://github.com/webzep/mui-nested-menu/blob/main/packages/mui-nested-menu/src/components/NestedMenuItem.tsx
 */
const NestedMenuItem = forwardRef<HTMLLIElement | null, NestedMenuItemProps>(function NestedMenuItem(props, ref) {
  const { parentMenuOpen, label, minWidth, children, MenuProps, ...MenuItemProps } = props;

  const menuItemRef = useRef<HTMLLIElement | null>(null);
  useImperativeHandle(ref, () => menuItemRef.current!);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsSubMenuOpen(true);
  };

  const handleSubMenuClose = () => {
    setIsSubMenuOpen(false);
  };

  const handleFocus = (e: FocusEvent<HTMLElement>) => {
    if (e.target === containerRef.current) {
      setIsSubMenuOpen(true);
    }
  };

  const open = isSubMenuOpen && parentMenuOpen;

  return (
    <div ref={containerRef} onFocus={handleFocus} onMouseEnter={handleMouseEnter} onMouseLeave={handleSubMenuClose}>
      <StyledMenuItem {...MenuItemProps} ref={menuItemRef} minWidth={minWidth}>
        <Typography variant="body1" noWrap>
          {label}
        </Typography>
        <ArrowRightIcon />
      </StyledMenuItem>
      <EventlessMenu
        anchorEl={menuItemRef.current}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        open={open}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        onClose={handleSubMenuClose}
        {...MenuProps}
      >
        <SubMenuWithEventWrapper>{children}</SubMenuWithEventWrapper>
      </EventlessMenu>
    </div>
  );
});

export default NestedMenuItem;
