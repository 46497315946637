import { useState } from 'react';

interface UseAboutInfoMenuState {
  anchorElement: HTMLElement | null;
  isVersionInfoDialogOpen: boolean;
  handleInfoIconClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleMenuClose: () => void;
  handleVersionInfoMenuItemClick: () => void;
  handleVersionInfoDialogClose: () => void;
}

export const useAboutInfoMenu = (): UseAboutInfoMenuState => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [isVersionInfoDialogOpen, setIsVersionInfoDialogOpen] = useState(false);

  const handleInfoIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const handleVersionInfoMenuItemClick = () => {
    setIsVersionInfoDialogOpen(true);
    setAnchorElement(null);
  };

  const handleVersionInfoDialogClose = () => {
    setIsVersionInfoDialogOpen(false);
  };

  return {
    anchorElement,
    isVersionInfoDialogOpen,
    handleInfoIconClick,
    handleMenuClose,
    handleVersionInfoMenuItemClick,
    handleVersionInfoDialogClose,
  };
};
