import { styled, css } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

type ExpandedProps = {
  expanded: boolean;
};

export const InstancesSidebar = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<ExpandedProps>`
  height: 100%;
  min-width: ${({ theme }) => theme.var.instanceSidebarWidth}px;
  width: ${({ theme }) => theme.var.instanceSidebarWidth}px;
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
`;

export const InstanceSidebarHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.var.paddingBase}px;
  gap: ${({ theme }) => theme.var.marginBase}px;
  justify-content: space-between;
`;

export const InstanceSidebarHeaderRow = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.var.marginBase}px;
  justify-content: space-between;
`;

export const InstanceSidebarHeaderGroup = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.var.marginBase}px;
  overflow: hidden;
`;

export const ModelViewContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<ExpandedProps>`
  width: 100%;
  ${(props) =>
    props.expanded &&
    css(`
        display: none;
    `)}
`;

export const NoOutputsTooltip = styled(Tooltip)`
  opacity: 1;
  align-self: center;
`;
