import IconButton from '@mui/material/IconButton';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { styled } from '@mui/material/styles';

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

export const StyledWarningAmberIcon = styled(WarningAmberIcon)`
  margin: ${({ theme }) => theme.var.marginBase / 2}px;
  align-self: center;
`;
