export const HUB_KEY = 'hubs';
export const HUB_PROJECT_KEY = 'hubProjects';
export const VARIANT_OUTPUTS_UPLOAD_RESULTS_KEY = 'variantOutputsUploadResultsKey';
export const ALL_VARIANTS_OUTPUTS_STATUS_KEY = 'allVariantsOutputsStatus';
export const PROJECT_ENTITLEMENT_KEY = 'projectEntitlements';
export const HUB_PROJECT_FOLDER_KEY = 'hubProjectFolders';
export const CAN_GENERATE_OUTPUTS_KEY = 'canGenerateOutputs';
export const ROOT_FOLERS = 'rootFolders';
export const FOLDER_TREE_NODES = 'folderTreeNodes';
export const GET_FOLDER_KEY = 'getFolder';

export const GET_ALL_PRODUCTS_IN_TARGET_PROJECT = 'getAllProductsInTargetProject';
export const GET_BRIDGE_PRODUCTS = 'getBridgeProducts';
export const GET_PRODUCT_RELEASES = 'getProductReleases';
