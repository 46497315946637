import { AnalyticsBrowser } from '@segment/analytics-next';

export const ANALYTICS_ACTIONS = {
  IVTW: {
    createProductDefinition: 'ivtw.productDefinition.create',
    publishProductDefinition: 'ivtw.productDefinition.publish',
    deleteProductDefinition: 'ivtw.productDefinition.delete',
  },
  RVTW: {
    openProduct: 'rvtw.product.open',
    generateVariants: 'rvtw.variants.generate',
    insertVariants: 'rvtw.variants.insert',
  },
  MIDW: {
    clickAddinDownload: 'midw.addinDownload.click',
    generateOutputs: 'midw.outputs.generate',
  },
  MIDEF: {
    openInstance: 'midef.instance.open',
    generateVariant: 'midef.variant.generate',
    replaceInstance: 'midef.instance.replace',
  },
};

export const analytics = new AnalyticsBrowser();
