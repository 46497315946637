import { BIMAccount } from 'mid-types';

export const mockAccount: BIMAccount = {
  id: 'acct-001',
  name: 'acct-001',
  thumbnailImageUrl: 'http://img-acct-001',
  region: 'US',
  isAccEligible: true,
};

export const mockAccount2: BIMAccount = {
  id: 'acct-002',
  name: 'acct-002',
  thumbnailImageUrl: 'http://img-acct-002',
  region: 'US',
  isAccEligible: true,
};
