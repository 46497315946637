import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { css, styled } from '@mui/material/styles';

type AccountArrowIconProps = {
  anchorElPopover: HTMLElement | null;
};

export const SelectedProjectHeaderContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})<{ maxWidth?: number }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-grow: 0;
  min-width: ${({ theme }) => theme.var.accountProjectSelectorMinWidth}px;
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `}
  overflow: hidden;
  justify-content: space-between;
`;

export const AccountLogoTitleContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '12px',
  flexGrow: '10',
  overflow: 'hidden',
}));

export const AccountLogo = styled('img')(() => ({
  height: '34px',
  width: 'auto',

  backgroundColor: '#666',
}));

export const ProjectSelectorPanelPopoverContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  minWidth: '400px',
  maxWidth: '400px',
  overflow: 'hidden',
  transition: 'all .5s ease-in-out',
}));

export const SelectorsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const AccountArrowIcon = styled(ArrowDropDownIcon, {
  shouldForwardProp: (props) => props !== 'anchorElPopover',
})<AccountArrowIconProps>((props) => ({
  transform: props.anchorElPopover ? 'rotate(180deg)' : '',
}));

export const AccountAndProjectLoading = styled(LinearProgress)(() => ({
  width: '100%',
}));
