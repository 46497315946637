import { Label, LabelContainer, OutputsChip } from '../SettingsPanel.styles';
import { OutputItem } from '../SettingsPanel';

interface OutputLabelsProps {
  outputLabel: OutputItem;
}
export const OutputSettingsLabel: React.FC<OutputLabelsProps> = ({ outputLabel }) => (
  <LabelContainer>
    <Label>{outputLabel.outputLabel}</Label>
    {outputLabel.chips.map((chipLabel, index) => (
      <OutputsChip key={`${chipLabel}-${index}`} label={chipLabel} size="small" />
    ))}
  </LabelContainer>
);
