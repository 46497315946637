import { MouseEvent, MutableRefObject, forwardRef } from 'react';
import Tooltip from '@mui/material/Tooltip';
import {
  DisabledArrowDropDown,
  EventlessArrowDropDown,
  EventlessArrowDropUp,
  ProductSelectionBox,
  ProductSelectionTypography,
} from './ProductSelectDropdown.styles';

interface ProductSelectionFieldProps {
  onClick: (event: MouseEvent<HTMLElement>) => void;
  ref: MutableRefObject<HTMLDivElement | undefined>;
  label: string;
  expanded: boolean;
  disabled: boolean;
  open: boolean;
}

export const ProductSelectionField = forwardRef<HTMLDivElement | undefined, ProductSelectionFieldProps>(
  function ProductSelectionField({ onClick, label, expanded, disabled, open }, ref) {
    if (disabled) {
      return (
        <ProductSelectionBox expanded={expanded} disabled>
          <DisabledArrowDropDown />
        </ProductSelectionBox>
      );
    }

    const productSelectionField = (
      <ProductSelectionBox onClick={onClick} expanded={expanded} ref={ref}>
        <ProductSelectionTypography variant="body1" noWrap>
          {label}
        </ProductSelectionTypography>
        {open ? <EventlessArrowDropUp /> : <EventlessArrowDropDown />}
      </ProductSelectionBox>
    );

    return expanded ? (
      productSelectionField
    ) : (
      <Tooltip title={label} placement="top" arrow>
        {productSelectionField}
      </Tooltip>
    );
  },
);
