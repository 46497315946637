import { inject, injectable } from 'inversify';
import type {
  AccBridgeFolder,
  AccBridgeProject,
  AccBridgeProjectsResponse,
  AccBridgeAutomationResponse,
  AuthHandler,
  Environment,
} from 'mid-types';
import { ENVIRONMENT } from 'mid-types';
import 'reflect-metadata';
import * as apiService from '../api.service';
import { InversifyTypes } from '../inversify/inversifyTypes';
import { ApiService } from '../api.service';
import axios, { AxiosResponse } from 'axios';
import { getReactAppEnvironment } from 'mid-utils';

type GetAllItemsFromApiParams = {
  apiService: ApiService;
  url: string;
  token: string;
  signal?: AbortSignal;
};

// This function exists to handle the CORS issue from ACC Bridge API
// using localhost as the origin
const getMiddleware = async <T>(
  apiService: apiService.ApiService,
  url: string,
  token: string,
  signal: AbortSignal | undefined,
): Promise<AxiosResponse<T, any>> => {
  const env = getReactAppEnvironment();
  let getResponse: AxiosResponse<T, any>;

  // List of environments that are allowed to proxy the ACC Bridge request from localhost
  // It enables run it locally but put `stg` or `prd` as well for API calls
  const envListEnableToProxy: Environment[] = [ENVIRONMENT.DEV, ENVIRONMENT.STG, ENVIRONMENT.PRD];

  if (envListEnableToProxy.includes(env) && import.meta.env.VITE_BRIDGE_PROXY === 'true') {
    getResponse = await axios.get<T>(url, {
      signal,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  } else {
    getResponse = await apiService.get<T>(url, {
      signal,
    });
  }
  return getResponse;
};

export const getAllItemsFromApiGenerator = async function* <T>({
  apiService,
  url,
  token,
  signal,
}: GetAllItemsFromApiParams): AsyncGenerator<T[], void> {
  const getResponse = await getMiddleware<AccBridgeAutomationResponse<T>>(apiService, url, token, signal);
  const data: AccBridgeAutomationResponse<T> = getResponse.data;

  yield data.data;

  let apiResult = data;

  while (apiResult.more_data && apiResult.next_sync_token) {
    const nextUrl = `${url}&sync_token=${apiResult.next_sync_token}`;
    const nextGetResponse = await getMiddleware<AccBridgeAutomationResponse<T>>(apiService, nextUrl, token, signal);

    const nextData = nextGetResponse.data;

    yield nextData.data;

    apiResult = nextData;
  }
};

@injectable()
export class AccBridgeApiService {
  private apiService: apiService.ApiService;
  private authHandler: AuthHandler;

  constructor(
    @inject(InversifyTypes.AccBridgeApiBaseURL) baseURL: string,
    @inject(InversifyTypes.AuthHandler) authHandler: AuthHandler,
    @inject(InversifyTypes.Env) env: Environment,
  ) {
    this.apiService = new apiService.ApiService(
      baseURL,
      '',
      env,
      {
        Accept: 'application/vnd.plangrid+json; version=1',
      },
      authHandler,
    );
    this.authHandler = authHandler;
  }

  getIncomingFolders = async (targetProjectId: string, signal?: AbortSignal): Promise<AccBridgeFolder[]> => {
    const url = `/proxy/bridge/v1/automations?target_project_id=${targetProjectId}&asset_type=acs_folder`;
    const token = await this.authHandler();

    const allFoldersGenerator = await getAllItemsFromApiGenerator<AccBridgeFolder>({
      apiService: this.apiService,
      url,
      token,
      signal,
    });

    const allFolders: AccBridgeFolder[] = [];

    for await (const folders of allFoldersGenerator) {
      allFolders.push(...folders);
    }

    return allFolders;
  };

  getOutgoingFolders = async (sourceProjectId: string, signal?: AbortSignal): Promise<AccBridgeFolder[]> => {
    const url = `/proxy/bridge/v1/automations?source_project_id=${sourceProjectId}&asset_type=acs_folder`;
    const token = await this.authHandler();

    const allFoldersGenerator = await getAllItemsFromApiGenerator<AccBridgeFolder>({
      apiService: this.apiService,
      url,
      token,
      signal,
    });

    const allFolders: AccBridgeFolder[] = [];

    for await (const folders of allFoldersGenerator) {
      allFolders.push(...folders);
    }

    return allFolders;
  };

  // Get all projects that are bridged to your ACC project (incoming or outgoing)
  // It is exactly what you see at the Bridge page:
  // https://acc-staging.autodesk.com/docs/bridge/projects/{your project id}/bridged
  getBridgeProjects = async (projectId: string, signal?: AbortSignal): Promise<AccBridgeProject[]> => {
    const queryFields = ['accountName', 'id', 'projectId', 'projectName', 'status'];
    const params = new URLSearchParams({
      fields: queryFields.join(','),
      'filter[projectId]': projectId,
      'filter[status]': 'ACTIVE',
    }).toString();
    let nextUrl: string | undefined = `/proxy/bridge/v1/bridges?${params}`;
    const token = await this.authHandler();

    const projectBridgesList = [];

    while (typeof nextUrl !== 'undefined') {
      const response: AxiosResponse<AccBridgeProjectsResponse> = await getMiddleware<AccBridgeProjectsResponse>(
        this.apiService,
        nextUrl,
        token,
        signal,
      );
      const { pagination, bridges } = response.data;
      projectBridgesList.push(...bridges);
      nextUrl = pagination.nextUrl;
    }

    return projectBridgesList;
  };
}
