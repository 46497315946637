export const InversifyTypes = {
  AuthHandler: Symbol.for('AuthHandler'),
  DcApiBaseURL: Symbol.for('DcApiBaseURL'),
  ForgeApiBaseURL: Symbol.for('ForgeApiBaseURL'),
  DcApiService: Symbol.for('DcApiService'),
  ForgeApiService: Symbol.for('ForgeApiService'),
  Env: Symbol.for('Env'),
  AccBridgeApiBaseURL: Symbol.for('AccBridgeApiBaseURL'),
  AccBridgeApiService: Symbol.for('AccBridgeApiService'),
};
