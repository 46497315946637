import { useRef } from 'react';
import useOpenModelURLHandling from './useOpenModelURLHandling';

export const InvisibleLmvContainer: React.FC = () => {
  const lmvContainer = useRef<HTMLDivElement>(null);

  useOpenModelURLHandling(lmvContainer);

  /*
   Container for the LMV to be rendered. This LMV is needed just to get some data form the model to render outputs.
      It should be invisible for the user.
  */
  return <div ref={lmvContainer} style={{ display: 'none' }} />;
};
