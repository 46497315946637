import { CustomError } from './customError';
import { ErrorCode } from './errorCode';

export class EmptyOutputFileError extends CustomError {
  errorCode = ErrorCode.EmptyFileError;

  constructor(message: string) {
    super(message);
  }
}
