import { DCNeutralOutputs, OutputTypeWithVirtualTypes } from 'mid-types';
import { SelectChangeEvent } from '@mui/material/Select';
import useRFOModalStore from 'context/RFOModalStore/useRFOModalStore';
import { handleModelStateChange, handleModelStateDelete } from '../FileTypesStep.utils';
import { useShallow } from 'zustand/react/shallow';

interface UseNeutralFormatProps {
  outputsTypeNeutralFormat: DCNeutralOutputs[];
}

export interface UseNeutralFormatState {
  glbOutputs: string[];
  satOutputs: string[];
  stepOutputs: string[];
  handleSatOutputsChange: (event: SelectChangeEvent<string[]>) => void;
  handleStepOutputsChange: (event: SelectChangeEvent<string[]>) => void;
  handleGlbOutputsChange: (event: SelectChangeEvent<string[]>) => void;
  handleSatOutputDelete: (event: any, value: string) => void;
  handleStepOutputDelete: (event: any, value: string) => void;
  handleGlbOutputDelete: (event: any, value: string) => void;
}

const useNeutralFormat = ({ outputsTypeNeutralFormat }: UseNeutralFormatProps): UseNeutralFormatState => {
  const { allOutputs, glbOutputs, satOutputs, setAllOutputs, setGlbOutputs, setSatOutputs, setStepOutputs, stepOutputs } =
    useRFOModalStore(
      useShallow((state) => ({
        satOutputs: state.satOutputs,
        setSatOutputs: state.setSatOutputs,
        stepOutputs: state.stepOutputs,
        setStepOutputs: state.setStepOutputs,
        glbOutputs: state.glbOutputs,
        setGlbOutputs: state.setGlbOutputs,
        allOutputs: state.allOutputs,
        setAllOutputs: state.setAllOutputs,
      })),
    );

  const handleSatOutputDelete = (event: any, value: string) => {
    handleModelStateDelete(value, OutputTypeWithVirtualTypes.SAT, satOutputs, allOutputs, setSatOutputs, setAllOutputs);
  };

  const handleStepOutputDelete = (event: any, value: string) => {
    handleModelStateDelete(value, OutputTypeWithVirtualTypes.STEP, stepOutputs, allOutputs, setStepOutputs, setAllOutputs);
  };

  const handleGlbOutputDelete = (event: any, value: string) => {
    handleModelStateDelete(value, OutputTypeWithVirtualTypes.GLB, glbOutputs, allOutputs, setGlbOutputs, setAllOutputs);
  };

  const handleSatOutputsChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    handleModelStateChange(
      value,
      OutputTypeWithVirtualTypes.SAT,
      satOutputs,
      outputsTypeNeutralFormat,
      allOutputs,
      setSatOutputs,
      setAllOutputs,
    );
  };

  const handleStepOutputsChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    handleModelStateChange(
      value,
      OutputTypeWithVirtualTypes.STEP,
      stepOutputs,
      outputsTypeNeutralFormat,
      allOutputs,
      setStepOutputs,
      setAllOutputs,
    );
  };

  const handleGlbOutputsChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    handleModelStateChange(
      value,
      OutputTypeWithVirtualTypes.GLB,
      glbOutputs,
      outputsTypeNeutralFormat,
      allOutputs,
      setGlbOutputs,
      setAllOutputs,
    );
  };

  return {
    glbOutputs,
    satOutputs,
    stepOutputs,
    handleSatOutputsChange,
    handleGlbOutputsChange,
    handleStepOutputsChange,
    handleSatOutputDelete,
    handleStepOutputDelete,
    handleGlbOutputDelete,
  };
};

export default useNeutralFormat;
