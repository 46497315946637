import { DarkGreyTooltip } from '@mid-react-common/common';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { ProjectFolderPermissionSection } from 'components/Commons/ProjectFolderPermissionSection/ProjectFolderPermissionSection';
import text from 'global/text.json';
import { MIDAccBridgeFolder } from 'mid-types';
import { rfoModalTestIds } from 'tests/helpers/dataTestIds';
import { useUploadLocationFooter } from './useUploadLocationFooter';
import { BridgeFolderWarningIcon } from './BridgeFolderWarningIcon';
import useRFOModalStore from 'context/RFOModalStore/useRFOModalStore';

const rfoModalText = text.rfoModal;

interface UploadLocationStepFooterProps {
  outgoingBridgeFoldersMap: Map<string, MIDAccBridgeFolder[]> | undefined;
}

const UploadLocationStepFooter: React.FC<UploadLocationStepFooterProps> = ({ outgoingBridgeFoldersMap }) => {
  const theme = useTheme();

  const {
    isUploadLocationStepCompleted,
    isFileTypesStepCompleted,
    editPermissions,
    setFileTypesStepActive,
    setCheckoutStepActive,
  } = useRFOModalStore((state) => ({
    isUploadLocationStepCompleted: state.isUploadLocationStepCompleted,
    isFileTypesStepCompleted: state.isFileTypesStepCompleted,
    editPermissions: state.editPermissions,
    setFileTypesStepActive: state.setFileTypesStepActive,
    setCheckoutStepActive: state.setCheckoutStepActive,
  }));

  const { uploadFolderUrn, handleSubfolderCreationDisplay, uploadLocationProject } = useUploadLocationFooter();

  const hasFolderPermissionError = !!uploadFolderUrn && !editPermissions[uploadFolderUrn];
  const isOutgoingAccBridgeFolder = !!uploadFolderUrn && !!outgoingBridgeFoldersMap?.has(uploadFolderUrn);

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginInline={`${theme.var.marginBase}px`}
    >
      <Box>
        <Button
          variant="outlined"
          startIcon={<CreateNewFolderOutlinedIcon />}
          onClick={handleSubfolderCreationDisplay}
          disabled={!uploadFolderUrn || hasFolderPermissionError}
          data-testid={rfoModalTestIds.uploadLocationStep.addSubFolderButton}
        >
          {rfoModalText.addSubfolder}
        </Button>
        {uploadLocationProject && hasFolderPermissionError && (
          <DarkGreyTooltip
            title={
              <ProjectFolderPermissionSection
                summary={rfoModalText.permissionTooltip}
                vendor={uploadLocationProject.vendor}
              />
            }
            arrow
            placement="top"
            data-testid={rfoModalTestIds.checkoutStep.canGenerateOutputsErrorTooltip}
          >
            <IconButton size="small" data-testid={rfoModalTestIds.uploadLocationStep.permissionTooltipIconButton}>
              <InfoOutlinedIcon />
            </IconButton>
          </DarkGreyTooltip>
        )}
      </Box>
      <Box display="flex" gap={`${theme.var.marginBase}px`}>
        <Button
          variant="text"
          startIcon={<WestOutlinedIcon />}
          onClick={setFileTypesStepActive}
          data-testid={rfoModalTestIds.uploadLocationStep.fileTypesButton}
        >
          {rfoModalText.fileTypes}
        </Button>
        <Button
          variant="contained"
          onClick={setCheckoutStepActive}
          endIcon={<ShoppingCartOutlinedIcon />}
          disabled={!isUploadLocationStepCompleted || !isFileTypesStepCompleted}
          data-testid={rfoModalTestIds.checkoutButton}
        >
          {rfoModalText.checkout}
        </Button>
        {isOutgoingAccBridgeFolder && (
          <BridgeFolderWarningIcon
            messages={[rfoModalText.outgoingBridgeFolderTooltip, rfoModalText.outgoingBridgeSubFoldersTooltip]}
          />
        )}
      </Box>
    </Box>
  );
};

export default UploadLocationStepFooter;
