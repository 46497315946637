import { ErrorCode } from './errorCode';
import { CustomError } from './customError';

type CauseType = {
  report?: string;
};

export class GenerateOutputError extends CustomError {
  errorCode = ErrorCode.GenerateOutputError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
