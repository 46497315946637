import { CustomError } from '../customError';
import { ErrorCode } from '../errorCode';

type CauseType = {
  projectId?: string;
  productId?: string;
  variantId?: string;
  fileUrl?: string;
};

export class VariantLogsFetchError extends CustomError {
  public errorCode: ErrorCode = ErrorCode.VariantLogsFetchError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
