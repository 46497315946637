import ListItemAvatar from '@mui/material/ListItemAvatar';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import text from '../../../../global/text.json';
import { AccountImage, AccountListContainer, AccountsListTitle, AccountsMenuList } from './AccountsList.style';
import { BIMAccount } from 'mid-types';

interface AccountsListProps {
  visible: boolean;
  accounts: BIMAccount[] | undefined;
  handleAccountSelection: (account: BIMAccount) => void;
}

const AccountsList: React.FC<AccountsListProps> = ({ visible, accounts, handleAccountSelection }) => {
  const accountsText = text.accountsList;

  return (
    <AccountListContainer visible={visible}>
      <AccountsListTitle>
        <Typography variant="h6">{accountsText.accounts}</Typography>
      </AccountsListTitle>
      {accounts && (
        <AccountsMenuList>
          {accounts.map((account) => (
            <MenuItem key={account.id} onClick={() => handleAccountSelection(account)}>
              <ListItemAvatar>
                <AccountImage src={account.thumbnailImageUrl} />
              </ListItemAvatar>
              <Typography variant="body2">{account.name}</Typography>
            </MenuItem>
          ))}
        </AccountsMenuList>
      )}
    </AccountListContainer>
  );
};

export default AccountsList;
