import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { DataGridPremium } from '@weave-mui/data-grid';

export const ReviewPanelContainer = styled(Box)`
  flex: 1 1 auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const ReviewPanelHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: ${({ theme }) => theme.var.paddingBase}px;
`;
export const ReviewPanelContent = styled(Box)`
  flex: 1;
`;

export const ReviewPanelStatusCell = styled('div')`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.var.paddingBase}px;
`;

export const FilterButtonText = styled('div')`
  padding: 0px ${({ theme }) => theme.var.paddingBase}px;
`;

export const ReviewPanelDataGrid = styled(DataGridPremium)`
  & .MuiDataGrid-columnHeaders {
    background-color: ${({ theme }) => theme.colors.surface.lightGray[10]};
  }
`;

export const InstancesReviewOpener = styled(Typography)`
  cursor: pointer;
`;

export const ResultDescription = styled(Typography)`
  font-weight: bold;
`;

export const ResultsTable = styled('table')`
  margin: ${({ theme }) => theme.var.marginBase}px 0px;
  border-spacing: 0px ${({ theme }) => theme.var.marginBase / 2}px;
`;

export const ResultsRow = styled('tr')``;

export const ResultName = styled('td')`
  width: ${({ theme }) => theme.var.instanceResultNameWidth}px;
  margin: ${({ theme }) => theme.var.marginBase};
  vertical-align: top;
  color: ${({ theme }) => theme.colors.surface.darkGray[10]};
`;
export const ResultInfo = styled('td')`
  width: ${({ theme }) => theme.var.instanceResultInfoWidth}px;
  white-space: pre-line;
  vertical-align: top;
`;

export const ResultsBoldSpan = styled('span')`
  font-weight: bold;
`;

export const UploadLocationIconContainer = styled('div')`
  margin-right: ${({ theme }) => theme.var.marginBase}px;
  display: flex;
  align-items: center;
`;

export const UploadLocationErrorContainer = styled('div')`
  gap: ${({ theme }) => theme.var.marginBase}px;
  display: flex;
  align-items: center;
`;
