import { isFulfilled, isRejected } from '../typeGuards';

export type AllSettledProcessedResults<T> = {
  results: (T extends readonly (infer InnerArr)[] ? InnerArr : T)[];
  errors: PromiseRejectedResult[];
};

export const processAllSettledResults = <T>(resultsPromise: PromiseSettledResult<T>[]): AllSettledProcessedResults<T> => {
  const results = resultsPromise
    .filter(isFulfilled)
    .map<T>((val) => val.value)
    .flat();

  const errors = resultsPromise.filter(isRejected);
  return { results, errors };
};
