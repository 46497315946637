import { OutputStatus } from '@adsk/offsite-dc-sdk';
import { midTheme } from '@mid-react-common/common';
import { LinearProgress, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { GridColumnVisibilityModel, GridFilterModel, GridRowParams } from '@weave-mui/data-grid';
import React, { useContext, useState } from 'react';
import AccountProjectContext from '../../../context/AccountProjectStore/AccountProject.context';
import ProductContext from '../../../context/ProductStore/Product.context';
import text from '../../../global/text.json';
import { outputsReviewPanel } from '../../../tests/helpers/dataTestIds';
import ActionsPanel from './ActionsPanel/ActionsPanel';
import {
  FilterButtonText,
  ReviewPanelContainer,
  ReviewPanelContent,
  ReviewPanelDataGrid,
  ReviewPanelHeader,
} from './ReviewPanel.styles';
import { columns } from './ReviewPanelColumns';
import { OutputsReviewTableDataModel } from './Types';
import { useVariantStatusPolling } from './useVariantStatusPolling';
import { InvisibleLmvContainer } from './InvisibleLmvContainer/InvisibleLmvContainer';
import { useFlags } from 'launchdarkly-react-client-sdk';

const ReviewPanel: React.FC = () => {
  const { enableRfoModal } = useFlags();

  const reviewPanelText = text.reviewPanel;
  const [status, setStatus] = useState(reviewPanelText.all);

  // Project ID needs to come from the product itself as it might be from another project
  const { selectedProductRelease, instances } = useContext(ProductContext);
  const projectId = selectedProductRelease?.tenancyId;

  // currently selected project id is required to access the download output
  const { projectId: currentProjectId } = useContext(AccountProjectContext);

  const { polledAllVariantsOutputs, variantOutputsLoading } = useVariantStatusPolling({
    instances,
    projectId,
  });

  const [selectedGridRow, setSelectedGridRow] = useState<GridRowParams<OutputsReviewTableDataModel>>();

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const handleStatusFilterChange = (_event: React.MouseEvent<HTMLElement>, value: string) => {
    setStatus(value);
    if (value === reviewPanelText.all) {
      setFilterModel({ items: [] });
    } else {
      setFilterModel({
        items: [
          {
            field: 'status',
            operator: 'equals',
            value,
          },
        ],
      });
    }
  };

  // Show column "status" only when filter "all" is selected
  const visibleColumns: GridColumnVisibilityModel = {
    status: status === reviewPanelText.all,
  };

  const handleRowClick = (row: GridRowParams<OutputsReviewTableDataModel>) => {
    setSelectedGridRow(row);
  };

  return (
    <ReviewPanelContainer>
      {enableRfoModal && <InvisibleLmvContainer />}
      <ReviewPanelHeader>
        <ToggleButtonGroup value={status} exclusive onChange={handleStatusFilterChange} size="small">
          <ToggleButton value={reviewPanelText.all} data-testid={outputsReviewPanel.allFilter}>
            <FilterButtonText>{reviewPanelText.all}</FilterButtonText>
          </ToggleButton>
          <ToggleButton value={OutputStatus.PENDING}>
            <FilterButtonText>{reviewPanelText.pending}</FilterButtonText>
          </ToggleButton>
          <ToggleButton value={OutputStatus.SUCCESS} data-testid={outputsReviewPanel.successFilter}>
            <FilterButtonText>{reviewPanelText.success}</FilterButtonText>
          </ToggleButton>
          <ToggleButton value={OutputStatus.FAILED} data-testid={outputsReviewPanel.failedFilter}>
            <FilterButtonText>{reviewPanelText.failed}</FilterButtonText>
          </ToggleButton>
        </ToggleButtonGroup>
        <ActionsPanel
          status={status}
          currentProjectId={currentProjectId}
          objectKey={selectedGridRow?.row.objectKey}
          outputUploadStatus={selectedGridRow?.row.outputUploadStatus || null}
          outputLocationProjectId={selectedGridRow?.row.outputLocationProjectId || null}
          outputLocation={selectedGridRow?.row.outputLocation || null}
          outputProjectVendor={selectedGridRow?.row.outputProjectVendor || null}
          productId={selectedGridRow?.row.contentId}
          variantId={selectedGridRow?.row.variantId}
        />
      </ReviewPanelHeader>
      <ReviewPanelContent data-testid={outputsReviewPanel.statusTable}>
        <ReviewPanelDataGrid
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={polledAllVariantsOutputs || []}
          columns={columns}
          rowHeight={midTheme.var.reviewPanelDataGridRowHeight}
          columnHeaderHeight={midTheme.var.reviewPanelDataGridHeaderHeight}
          loading={variantOutputsLoading}
          filterModel={filterModel}
          columnVisibilityModel={visibleColumns}
          onRowClick={handleRowClick}
          sx={{ cursor: 'pointer' }}
        />
      </ReviewPanelContent>
    </ReviewPanelContainer>
  );
};

export default ReviewPanel;
