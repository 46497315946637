import { ProductRelease } from '@adsk/offsite-dc-sdk';
import React from 'react';
import { SelectChangeEvent } from '@mui/material';
import Divider from '@mui/material/Divider';
import { ReleaseStatusLabel, ReleaseStatusRow, ReleaseStatusSelect } from '../Releases.styles';
import MenuItem from '@mui/material/MenuItem';
import text from '../../../global/text.json';
import Typography from '@mui/material/Typography';
import { releaseUpdateIds } from '../../../tests/helpers/dataTestIds';

interface ReleaseUpdateSelectNewDefaultProps {
  activeReleases: ProductRelease[];
  releaseCount: number;
  newDefaultRelease: number | undefined;
  setNewDefaultRelease: (newDefaultRelease: number | undefined) => void;
}

const releasesText = text.releases;
const ReleaseUpdateSelectNewDefault: React.FC<ReleaseUpdateSelectNewDefaultProps> = ({
  activeReleases,
  releaseCount,
  newDefaultRelease,
  setNewDefaultRelease,
}) => {
  const updateDefaultRelease = (event: SelectChangeEvent<number>) => {
    setNewDefaultRelease(Number(event.target.value));
  };
  return (
    <>
      <Divider />
      <ReleaseStatusRow>
        <Typography>{releasesText.updateReleaseStatusHeadline}</Typography>
      </ReleaseStatusRow>
      <ReleaseStatusRow data-testid={releaseUpdateIds.releaseUpdateSelectNewDefault}>
        <ReleaseStatusLabel>{releasesText.defaultRelease}</ReleaseStatusLabel>
        <ReleaseStatusSelect value={newDefaultRelease} onChange={updateDefaultRelease}>
          {activeReleases.map((release) => (
            <MenuItem key={release.name + release.release} value={release.release}>
              {release.name} - {release.release} {releasesText.of} {releaseCount}
            </MenuItem>
          ))}
        </ReleaseStatusSelect>
      </ReleaseStatusRow>
    </>
  );
};
export default ReleaseUpdateSelectNewDefault;
