export interface FileFilter {
  name: string;
  expression: string;
}

export interface Base64Info {
  name: string;
  base64: string;
}

export enum DialogStatus {
  success,
  cancel,
  error,
}

export interface SaveToFileUsingDialogResult {
  status: DialogStatus;
}

export interface ReadFromFileUsingDialogResult {
  status: DialogStatus;
  content: string;
}
