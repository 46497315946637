import { useTheme } from '@mui/material/styles';
import { useContext } from 'react';
import AccountProjectContext from '../../../context/AccountProjectStore/AccountProject.context';
import AccountProjectSelector from '../../Commons/AccountProjectSelector/AccountProjectSelector';
import { useAccountProjectSelector } from '../../Commons/AccountProjectSelector/useAccountProjectSelector';
import { ProjectHeaderContainer } from './ProjectSelector.styles';
import { useProjectSelectorPanel } from './useProjectSelectorPanel';

const ProjectSelectorPanel = (): JSX.Element => {
  const theme = useTheme();
  const { accountId } = useContext(AccountProjectContext);
  const {
    accounts,
    projects,
    loading,
    anchorElPopover,
    handleProjectMenuOpen,
    handleProjectMenuClose,
    handleSearchProjects,
  } = useAccountProjectSelector({
    accountId,
  });
  const {
    accountDisplayName,
    accountImageURL,
    projectId,
    projectName,
    handleAccountSelection,
    handleProjectSelection,
    handleChangeAccount,
  } = useProjectSelectorPanel({ accounts, handleProjectMenuClose });

  return (
    <ProjectHeaderContainer>
      <AccountProjectSelector
        anchorElPopover={anchorElPopover}
        accountId={accountId}
        accountImageURL={accountImageURL}
        accountDisplayName={accountDisplayName}
        accounts={accounts}
        projectId={projectId}
        projects={projects}
        projectName={projectName}
        loading={loading}
        maxWidth={theme.var.headerPanelAccountProjectSelectorMaxWidth}
        handleProjectMenuOpen={handleProjectMenuOpen}
        handleProjectMenuClose={handleProjectMenuClose}
        handleSearchProjects={handleSearchProjects}
        handleAccountSelection={handleAccountSelection}
        handleProjectSelection={handleProjectSelection}
        handleChangeAccount={handleChangeAccount}
      />
    </ProjectHeaderContainer>
  );
};

export default ProjectSelectorPanel;
