import { Spacing } from '@mid-react-common/common';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import text from 'global/text.json';
import React from 'react';
import { licensingTestIds } from 'tests/helpers/dataTestIds';
import { ContentSectionWrapper } from '../Licensing.styles';
import { generalTermsUrl } from '../constants';

const licensingText = text.licensing;

const Introduction: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const effectiveDate = 'February 5, 2024';
  return (
    <ContentSectionWrapper data-testid={licensingTestIds.introductionSection}>
      <Typography>
        {licensingText.effectiveDate}: {effectiveDate}
      </Typography>
      <Typography variant="caption">
        {/* TODO: Change copyright from Autodesk to Autodesk Informed Design https://jira.autodesk.com/browse/TRADES-6806 */}
        {licensingText.copyright} {currentYear}, {licensingText.inc} {licensingText.allRightsReserved}
      </Typography>
      <Spacing />
      <Typography>
        {licensingText.softwareUsage}{' '}
        <Link
          data-testid={licensingTestIds.generalTermsPage}
          target="_blank"
          rel="noopener noreferrer"
          href={generalTermsUrl}
          color="primary"
        >
          {licensingText.here}
        </Link>
        .
      </Typography>
    </ContentSectionWrapper>
  );
};

export default Introduction;
