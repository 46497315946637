import { ReactNode } from 'react';
import { detailsSidebarIds } from 'tests/helpers/dataTestIds';
import { DetailsSidebarHeaderWrapper } from './DetailsSidebar.style';

interface DetailsSidebarHeaderProps {
  children: ReactNode;
}

const DetailsSidebarHeader: React.FC<DetailsSidebarHeaderProps> = ({ children }) => (
  <DetailsSidebarHeaderWrapper data-testid={detailsSidebarIds.detailsSidebarHeader}>{children}</DetailsSidebarHeaderWrapper>
);

export default DetailsSidebarHeader;
