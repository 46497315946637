import { StateSetter } from '@mid-react-common/common';
import { SmallThumbnail, SmallThumbnailWrapper } from '../Installers.styles';

interface ThumbnailListProps {
  value: { description: string; image: string };
  handleThumbnailClick: StateSetter<{ description: string; image: string }>;
}

const ThumbnailSelector: React.FC<ThumbnailListProps> = ({ value, handleThumbnailClick }) => {
  const handleClick = () => {
    handleThumbnailClick({ description: value.description, image: value.image });
  };

  return (
    <SmallThumbnailWrapper onClick={handleClick}>
      <SmallThumbnail src={value.image}></SmallThumbnail>
    </SmallThumbnailWrapper>
  );
};

export default ThumbnailSelector;
