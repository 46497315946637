import Breadcrumbs from '@mui/material/Breadcrumbs';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const BreadcrumbsContainer = styled(Breadcrumbs)`
  display: flex;
  align-items: center;
`;

export const BreadcrumbsItem = styled(Box)`
  display: flex;
  align-items: center;
`;

export const UploadLocationWrapper = styled(Box)`
  flex: 1;
`;

export const LocationSelection = styled(Box)`
  display: flex;
  gap: ${({ theme }) => `${theme.var.marginBase}px`};
  width: 100%;
`;

export const BreadcrumbsWrapper = styled(Box)`
  width: 100%;
  display: flex;
  padding: ${({ theme }) => `${theme.var.paddingBase}px`};
  align-items: center;
`;

export const BreadcrumbsIconWrapper = styled(Box)`
  display: flex;
  margin-right: ${({ theme }) => `${theme.var.marginBase}px`};
`;
