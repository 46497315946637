import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import MuiListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { CSSObject, Theme, styled } from '@mui/material/styles';

export const PageContainer = styled(Box)`
  display: flex;
  height: calc(100vh - ${({ theme }) => theme.var.midwTopBars}px);
`;

const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: 'hidden',
  width: theme.spacing(7),
});

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })<{ open: boolean }>`
  white-space: nowrap;
  .MuiDrawer-paper {
    position: relative;
    background-color: ${({ theme }) => theme.colors.primary.charcoal[100]};
    border: none;
  }
  ${(props) => props.open && { ...openedMixin(props.theme), '.MuiDrawer-paper': { ...openedMixin(props.theme) } }}
  ${(props) => !props.open && { ...closedMixin(props.theme), '.MuiDrawer-paper': { ...closedMixin(props.theme) } }}
`;

export const SidebarListWrapper = styled(List)`
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

export const ShowHideButtonWrapper = styled('div')`
  margin-top: auto;
`;

// Component prop won't be present without this
// https://mui.com/material-ui/guides/composition/#with-typescript
const ListItemButton = <C extends React.ElementType>(props: ListItemButtonProps<C, { component?: C }>) => (
  <MuiListItemButton {...props}>{props.children}</MuiListItemButton>
);

export const ListItemButtonWrapper = styled(ListItemButton)`
  &.active {
    background: ${({ theme }) => theme.colors.brand.white};
    border-left: ${({ theme }) => `${theme.var.navBorderWidth}px`} solid
      ${({ theme }) => theme.colors.primary.autodeskBlue[500]};
    color: ${({ theme }) => theme.colors.primary.autodeskBlue[500]};
    path {
      fill: ${({ theme }) => theme.colors.primary.autodeskBlue[500]};
    }
  }
`;

export const ListItemTextWrapper = styled(ListItemText, { shouldForwardProp: (props) => props !== 'visible' })<{
  visible?: boolean;
}>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;
