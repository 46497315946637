import { NotificationContext, useCancellablePromise } from '@mid-react-common/common';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { useCallback, useContext, useState } from 'react';

export type AccessProps = {
  releasesTab: boolean;
};

export type AccessControlStore = {
  accessProps: AccessProps;
  initialized: boolean;
  requestAccessData: (projectId: string, userId: string) => void;
};

export const useAccessControlStore = (): AccessControlStore => {
  const { logAndShowNotification } = useContext(NotificationContext);
  const cancellablePromise = useCancellablePromise();

  const [initialized, setInitialized] = useState<boolean>(false);
  const [accessProps, setAccessProps] = useState<AccessProps>({
    releasesTab: false,
  });

  const requestAccessData = useCallback(
    async (projectId: string, userId: string) => {
      try {
        const {
          accessLevels: { projectAdmin },
        } = await cancellablePromise(getForgeApiServiceInstance().getUserInfoInProject(projectId, userId));

        setAccessProps((prevState) => ({ ...prevState, releasesTab: projectAdmin }));
      } catch (error) {
        logAndShowNotification({ error });
      } finally {
        setInitialized(true);
      }
    },
    [logAndShowNotification, cancellablePromise],
  );

  return {
    initialized,
    accessProps,
    requestAccessData,
  };
};
