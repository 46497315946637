import { ACC_BRIDGE_STATUS, AccBridgeDataForIconsWithTooltip } from 'mid-types';
import { FlexContainer } from '../../styles/Common.styles';
import { AccBridgeIncomingIcon, AccBridgeOutgoingIcon } from './AccBridgeIcons';
import { AccBridgeTooltipWrapper } from './AccBridgeTooltip.styles';
import { AccBridgeTooltipContent } from './AccBridgeTooltipContent';
import { getTooltipText } from './AccBridgeTooltip.utils';

interface AccBridgeTooltipProps {
  accBridgeData: AccBridgeDataForIconsWithTooltip;
  selectedProjectId: string | null;
}

export const AccBridgeIconWithTooltip: React.FC<AccBridgeTooltipProps> = ({ accBridgeData, selectedProjectId }) => {
  const { accBridgeStatus, accBridgeProjectsData } = accBridgeData;

  const accBridgeProjectsInfo = accBridgeProjectsData.map(
    ({ projectNameSynced, targetProjectId, targetFolderId, sourceFolderId, sourceProjectId }) => ({
      projectNameSynced,
      linkProjectId: accBridgeStatus === ACC_BRIDGE_STATUS.OUTGOING ? targetProjectId : sourceProjectId,
      linkFolderId: accBridgeStatus === ACC_BRIDGE_STATUS.OUTGOING ? targetFolderId : sourceFolderId,
    }),
  );
  const hasProjectNameSynced = accBridgeProjectsInfo.some(({ projectNameSynced }) => projectNameSynced);
  const tooltipText = getTooltipText({ accBridgeStatus, hasProjectNameSynced });

  if (accBridgeStatus === ACC_BRIDGE_STATUS.OUTGOING) {
    return (
      <FlexContainer alignItems="center">
        <AccBridgeTooltipWrapper
          title={
            <AccBridgeTooltipContent
              tooltipText={tooltipText}
              selectedProjectId={selectedProjectId}
              accBridgeProjectsData={accBridgeProjectsInfo}
            />
          }
        >
          <AccBridgeOutgoingIcon />
        </AccBridgeTooltipWrapper>
      </FlexContainer>
    );
  }
  if (accBridgeStatus === ACC_BRIDGE_STATUS.INCOMING) {
    return (
      <FlexContainer alignItems="center">
        <AccBridgeTooltipWrapper
          title={
            <AccBridgeTooltipContent
              tooltipText={tooltipText}
              selectedProjectId={selectedProjectId}
              accBridgeProjectsData={accBridgeProjectsInfo}
            />
          }
        >
          <AccBridgeIncomingIcon />
        </AccBridgeTooltipWrapper>
      </FlexContainer>
    );
  }

  return null;
};
