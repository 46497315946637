import { Spacing } from '@mid-react-common/common';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import text from 'global/text.json';
import React from 'react';
import { licensingTestIds } from 'tests/helpers/dataTestIds';
import { ContentSectionWrapper } from '../Licensing.styles';
import { cloudPlatformComponentsUrl, desktopPlatformComponentsUrl, patentsUrl } from '../constants';

const licensingText = text.licensing;

const Patents: React.FC = () => (
  <ContentSectionWrapper data-testid={licensingTestIds.patentsSection}>
    <Typography id={licensingTestIds.patentsId} variant="h2" gutterBottom>
      {licensingText.patents}
    </Typography>
    <Typography>
      <Typography>
        {licensingText.patentsInfo1}{' '}
        <Link
          data-testid={licensingTestIds.patentsPage}
          target="_blank"
          rel="noopener noreferrer"
          href={patentsUrl}
          color="primary"
        >
          {licensingText.autodeskPatents}
        </Link>{' '}
        {licensingText.page}.
      </Typography>
      <Spacing />
      <Typography fontWeight="bold" gutterBottom>
        {licensingText.autodeskCloudDesktopComponents}
      </Typography>
      {licensingText.adskComponentsInfo}{' '}
      <Link
        data-testid={licensingTestIds.cloudPlatformComponentsPage}
        target="_blank"
        rel="noopener noreferrer"
        href={cloudPlatformComponentsUrl}
        color="primary"
      >
        {licensingText.adskCloudPlatformComponents}
      </Link>{' '}
      {licensingText.and}{' '}
      <Link
        data-testid={licensingTestIds.desktopPlatformComponentsPage}
        target="_blank"
        rel="noopener noreferrer"
        href={desktopPlatformComponentsUrl}
        color="primary"
      >
        {licensingText.adskDesktopPlatformComponents}
      </Link>{' '}
      .
    </Typography>
  </ContentSectionWrapper>
);

export default Patents;
