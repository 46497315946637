import { FilledFolder, LockedFolder } from '@mid-react-common/common';
import Typography from '@mui/material/Typography';
import text from '../../common.text.json';
import HubIcon from '../ProjectIcon/images/hub.svg?react';
import Box from '@mui/material/Box';
import { ProjectIcon } from '../ProjectIcon/ProjectIcon';
import { useTheme } from '@mui/material/styles';
import { BreadcrumbsWrapper, LinkWithPointerCursor } from './LocationInfo.styles';
import { ReactNode, useState } from 'react';
import testIds from '../../tests/helpers/dataTestIds';
import { Vendors } from '@adsk/offsite-dc-sdk';
import { TreeNode } from 'mid-types';
import Tooltip from '@mui/material/Tooltip';

const checkoutStepText = text.locationInfo;
const locationInfoTestIds = testIds.locationInfo;

interface LocationInfoProps {
  accountName: string;
  projectVendor: Vendors;
  projectName: string;
  selectedItemIcon?: ReactNode;
  selectedItemName: string | null;
  fullPath: TreeNode[] | null;
  editPermissions?: Record<string, boolean>;

  accountNameTooltip?: string;
  projectNameTooltip?: string;
  selectedItemTooltip?: string;
}

const LocationInfo: React.FC<LocationInfoProps> = ({
  accountName,
  projectVendor,
  projectName,
  fullPath,
  selectedItemIcon,
  selectedItemName,
  editPermissions,
  accountNameTooltip,
  projectNameTooltip,
  selectedItemTooltip,
}) => {
  const theme = useTheme();

  const [showBreadcrumbs, setShowBreadcrumbs] = useState(false);

  const handleShowBreadcrumbs = () => {
    setShowBreadcrumbs((prev) => !prev);
  };

  return (
    <>
      <Box
        display="flex"
        data-testid={locationInfoTestIds.uploadLocationAccountName}
        gap={`${theme.var.gapBase}px`}
        marginBottom={`${theme.var.marginBase}px`}
      >
        <Tooltip title={accountNameTooltip} arrow placement="top">
          <Box>
            <HubIcon height={theme.var.projectIconSize} width={theme.var.projectIconSize} />
          </Box>
        </Tooltip>
        <Typography>{accountName}</Typography>
      </Box>
      <Box
        data-testid={locationInfoTestIds.uploadLocationProjectName}
        display="flex"
        gap={`${theme.var.gapBase}px`}
        marginBottom={`${theme.var.marginBase}px`}
      >
        <Tooltip title={projectNameTooltip} arrow placement="top">
          <Box>
            <ProjectIcon vendor={projectVendor} />
          </Box>
        </Tooltip>
        <Typography>{projectName}</Typography>
      </Box>
      {fullPath && fullPath.length > 0 && (
        <Box marginBottom={`${theme.var.marginBase}px`}>
          <LinkWithPointerCursor
            data-testid={locationInfoTestIds.showHideBreadcrumbs}
            underline="none"
            onClick={handleShowBreadcrumbs}
          >
            {showBreadcrumbs ? checkoutStepText.hidePathDetails : checkoutStepText.showPathDetails}
          </LinkWithPointerCursor>
        </Box>
      )}
      {showBreadcrumbs && fullPath && fullPath.length > 0 && (
        <BreadcrumbsWrapper data-testid={locationInfoTestIds.folderPathBreadcrumbs} maxItems={20}>
          {fullPath.map((item) => (
            <Box key={item.label} display="flex" gap={`${theme.var.gapBase}px`}>
              {editPermissions && editPermissions[item.id] ? (
                <Box>
                  <FilledFolder data-testid={locationInfoTestIds.folderIcon} />
                </Box>
              ) : (
                <Box>
                  <LockedFolder data-testid={locationInfoTestIds.lockedFolderIcon} />
                </Box>
              )}
              <Typography color={theme.palette.text.primary}>{item.label}</Typography>
            </Box>
          ))}
        </BreadcrumbsWrapper>
      )}
      <Box data-testid={locationInfoTestIds.uploadLocationFolderName} display="flex" gap={`${theme.var.gapBase}px`}>
        <Tooltip title={selectedItemTooltip} arrow placement="top">
          <Box>{selectedItemIcon ? selectedItemIcon : <FilledFolder />}</Box>
        </Tooltip>
        <Typography noWrap>{selectedItemName}</Typography>
      </Box>
    </>
  );
};

export default LocationInfo;
