import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';

const CheckoutFooterSkeleton: React.FC = () => {
  const theme = useTheme();

  return (
    <>
      <Skeleton variant="rectangular" width={`${theme.var.rfoSkeletonButtonWidth}%`} height={theme.var.rfoSkeletonHeight} />
      <Skeleton variant="rectangular" width={`${theme.var.rfoSkeletonButtonWidth}%`} height={theme.var.rfoSkeletonHeight} />
    </>
  );
};

export default CheckoutFooterSkeleton;
