import { ProductRelease, ReleaseStatus, ReleaseStatusValue } from '@adsk/offsite-dc-sdk';
import React from 'react';
import text from '../../../global/text.json';
import ReleaseStatusOptions from './ReleaseStatusOptions';

const releasesText = text.releases;

// We have multiple "states" for the edit release form since:
// 1. There must be at least 1 default release
//   1a. In the case of only one release, you can only switch between obsolete and default
// 2. There can be multiple active releases and only one default release
// 3. The only case where there are no default releases is when all of them are obsolete
export const getReleaseStatusOptions = (
  productReleases: ProductRelease[],
  currentRelease: ProductRelease | undefined,
  releaseCount: number,
  obsoleteReleasesCount: number,
  changeReleaseStatus: (event: React.ChangeEvent<HTMLInputElement>) => void,
  newReleaseStatus: ReleaseStatusValue,
): JSX.Element | null => {
  if (!currentRelease) {
    return null;
  }
  if (
    currentRelease.status === ReleaseStatus.DEFAULT &&
    (hasExactlyOneRelease(releaseCount) || hasOnlyOneNonObsoleteRelease(releaseCount, obsoleteReleasesCount))
  ) {
    return <ReleaseStatusOptions selectedReleaseStatus={newReleaseStatus} releaseStatusList={[]} />;
  }
  if (
    currentRelease.status === ReleaseStatus.OBSOLETE &&
    (hasExactlyOneRelease(releaseCount) || areAllReleasesObsolete(releaseCount, obsoleteReleasesCount))
  ) {
    return (
      <ReleaseStatusOptions
        selectedReleaseStatus={newReleaseStatus}
        releaseStatusList={[ReleaseStatus.DEFAULT]}
        defaultMessage={releasesText.toDefaultMessage}
      />
    );
  }

  if (currentRelease.status === ReleaseStatus.DEFAULT) {
    return (
      <ReleaseStatusOptions
        selectedReleaseStatus={newReleaseStatus}
        releaseStatusList={[ReleaseStatus.ACTIVE, ReleaseStatus.OBSOLETE]}
        changeReleaseStatus={changeReleaseStatus}
        activeMessage={releasesText.toActiveMessage}
        obsoleteMessage={releasesText.toObsoleteWarningMessage}
      />
    );
  }

  if (currentRelease.status === ReleaseStatus.ACTIVE) {
    return (
      <ReleaseStatusOptions
        selectedReleaseStatus={newReleaseStatus}
        releaseStatusList={[ReleaseStatus.DEFAULT, ReleaseStatus.OBSOLETE]}
        changeReleaseStatus={changeReleaseStatus}
        defaultMessage={releasesText.toDefaultMessage}
        obsoleteMessage={releasesText.toObsoleteWarningMessage}
      />
    );
  }

  if (currentRelease.status === ReleaseStatus.OBSOLETE) {
    return (
      <ReleaseStatusOptions
        selectedReleaseStatus={newReleaseStatus}
        releaseStatusList={[ReleaseStatus.DEFAULT, ReleaseStatus.ACTIVE]}
        changeReleaseStatus={changeReleaseStatus}
        defaultMessage={releasesText.toDefaultMessage}
        activeMessage={releasesText.toActiveMessage}
      />
    );
  }
  return null;
};

export const getInitialLocalReleaseStatus = (
  currentRelease: ProductRelease | undefined,
  releaseCount: number,
  obsoleteReleasesCount: number,
): ReleaseStatusValue => {
  if (
    currentRelease?.status === ReleaseStatus.DEFAULT &&
    (hasExactlyOneRelease(releaseCount) || hasOnlyOneNonObsoleteRelease(releaseCount, obsoleteReleasesCount))
  ) {
    return ReleaseStatus.OBSOLETE;
  }
  if (
    currentRelease?.status === ReleaseStatus.OBSOLETE &&
    (hasExactlyOneRelease(releaseCount) || areAllReleasesObsolete(releaseCount, obsoleteReleasesCount))
  ) {
    return ReleaseStatus.DEFAULT;
  }
  if (currentRelease?.status === ReleaseStatus.DEFAULT) {
    return ReleaseStatus.ACTIVE;
  }
  return ReleaseStatus.DEFAULT;
};

const hasOnlyOneNonObsoleteRelease = (releaseCount: number, obsoleteReleasesCount: number): boolean =>
  releaseCount - obsoleteReleasesCount === 1;

const areAllReleasesObsolete = (releaseCount: number, obsoleteReleasesCount: number): boolean =>
  releaseCount === obsoleteReleasesCount;

const hasExactlyOneRelease = (releaseCount: number): boolean => releaseCount === 1;
