import { Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';

export const StyledText = styled(Typography)`
  text-wrap: wrap;
  word-break: break-word;
`;

export const ActionsContainer = styled('div')`
  display: flex;
  margin-top: ${({ theme }) => theme.var.marginBase / 2}px;
`;
