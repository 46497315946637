import { CustomDialog, useAccBridge } from '@mid-react-common/common';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import text from 'global/text.json';
import FileTypesStep from './FileTypesStep/FileTypesStep';
import { Section } from './RFOModal.styles';
import RFOModalFooter from './RFOModalFooter';
import CheckoutStep from './CheckoutStep/CheckoutStep';
import UploadLocationStep from './UploadLocationStep/UploadLocationStep';
import { RFOStepper } from './RFOStepper/RFOStepper';
import useRFOModalStore from '../../../../context/RFOModalStore/useRFOModalStore';
import { Vendors } from '@adsk/offsite-dc-sdk';
import { useShallow } from 'zustand/react/shallow';
import { stepsMap } from 'context/RFOModalStore/StepperSlice';

type RFOModalProps = {
  isRFOModalOpen: boolean;
  handleCloseRFOModal: () => void;
};

const rfoModalText = text.rfoModal;

/**
 * RFO stands for Request for Outputs
 */
const RFOModal: React.FC<RFOModalProps> = ({ isRFOModalOpen, handleCloseRFOModal }) => {
  const theme = useTheme();

  const { uploadLocationProject, activeStep } = useRFOModalStore(
    useShallow((state) => ({
      uploadLocationProject: state.uploadLocationProject,
      activeStep: state.activeStep,
    })),
  );

  const { incomingBridgeFoldersMap, outgoingBridgeFoldersMap, bridgeProjectsList } = useAccBridge({
    projectId: uploadLocationProject?.id || null,
    isAccProject: uploadLocationProject?.vendor === Vendors.ACC,
  });

  return (
    <CustomDialog
      height={theme.var.RfoModalHeight}
      open={isRFOModalOpen}
      title={
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h2" fontWeight="bold">
            {rfoModalText.generateOutputsTitle}
          </Typography>
          <IconButton onClick={handleCloseRFOModal} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      }
      dividers
      customActions={[
        <RFOModalFooter key={activeStep} activeStep={activeStep} outgoingBridgeFoldersMap={outgoingBridgeFoldersMap} />,
      ]}
    >
      <Section>
        <RFOStepper activeStep={activeStep} />
      </Section>
      <Divider />
      {activeStep === stepsMap.fileTypes && <FileTypesStep />}
      {activeStep === stepsMap.uploadLocation && (
        <UploadLocationStep
          incomingBridgeFoldersMap={incomingBridgeFoldersMap}
          outgoingBridgeFoldersMap={outgoingBridgeFoldersMap}
          bridgeProjectsList={bridgeProjectsList}
        />
      )}
      {activeStep === stepsMap.checkout && <CheckoutStep />}
    </CustomDialog>
  );
};

export default RFOModal;
