import { NotificationContext, useCancellablePromise } from '@mid-react-common/common';
import { GridRowId, GridRowParams } from '@weave-mui/data-grid';
import { ENVIRONMENT, Environment } from 'mid-types';
import { debounce } from 'lodash';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { ServiceConfigMap } from 'mid-utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AccountProjectContext from '../../../context/AccountProjectStore/AccountProject.context';
import ModelSelectionContext from '../../../context/ModelSelectionStore/ModelSelection.context';
import text from '../../../global/text.json';
import { ModelsFolderContentProps } from './ModelsFolderContent';
import { FolderContentRow } from './ModelsFolderContent.types';
import { transformLMVModelFileListInFolderContent } from './ModelsFolderContent.utils';
const modelsFolderText = text.modelsFolderContent;

type UseModelsFolderContentState = {
  filteredProductFoldersContent: FolderContentRow[];
  selectedModelId: GridRowId | undefined;
  handleOpenFoldersInDocsClick: () => void;
  handleFilterFilesByName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRowClick: (rowParams: GridRowParams<FolderContentRow>) => Promise<void>;
};

const useModelsFolderContent = ({
  folderUrn,
  lmvModelFiles,
  selectedModelFolder,
  setIsPreviewLoading,
  setSelectedModelFolder,
}: ModelsFolderContentProps): UseModelsFolderContentState => {
  const { projectId } = useContext(AccountProjectContext);
  const { productFoldersContent, setProductFoldersContent, selectedModelId, setSelectedModelId } =
    useContext(ModelSelectionContext);
  const { logAndShowNotification } = useContext(NotificationContext);
  const cancellablePromise = useCancellablePromise();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredProductFoldersContent, setFilteredProductFoldersContent] = useState<FolderContentRow[]>([]);

  const { itemUrn, details } = useParams<{ itemUrn: string; details?: string }>();
  const navigate = useNavigate();

  const selectItem = useCallback(
    async (rowData: FolderContentRow) => {
      try {
        setIsPreviewLoading(true);
        setSelectedModelId(rowData.id);

        const thumbnail = await cancellablePromise(getForgeApiServiceInstance().getThumbnail(rowData.lmvModelFileId));

        const folderContentRow: FolderContentRow = {
          ...rowData,
          thumbnail,
        };

        setSelectedModelFolder(folderContentRow);
      } catch (error) {
        logAndShowNotification({
          message: modelsFolderText.failedToLoadThumbnail,
        });
      } finally {
        setIsPreviewLoading(false);
      }
    },
    [setIsPreviewLoading, setSelectedModelId, setSelectedModelFolder, logAndShowNotification, cancellablePromise],
  );

  const handleRowClick = async (rowParams: GridRowParams<FolderContentRow>) => {
    if (selectedModelFolder?.id === rowParams.id) {
      return;
    }
    selectItem(rowParams.row);
  };

  const handleOpenFoldersInDocsClick = () => {
    const currentEnv = (import.meta.env.VITE_ENVIRONMENT as Environment) || ENVIRONMENT.DEV;
    const url = ServiceConfigMap.DOCUMENTS_MANAGER_API[currentEnv].api + '/' + projectId + '/folders/' + folderUrn;
    window.open(url, '_blank', 'noopener noreferrer');
  };

  const handleFilterFilesByName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const debouncedHandleFilterFilesByName = debounce(handleFilterFilesByName, 250);

  const filterProductFoldersContentData = (query: string, productFoldersContent: FolderContentRow[]) => {
    if (!query) {
      return productFoldersContent;
    }
    return productFoldersContent.filter((folderContent) =>
      folderContent.fileName.toLowerCase().includes(query.toLowerCase()),
    );
  };

  useEffect(() => {
    setFilteredProductFoldersContent(filterProductFoldersContentData(searchQuery, productFoldersContent));
  }, [productFoldersContent, searchQuery]);

  useEffect(() => {
    if (lmvModelFiles) {
      const transformedFolderContent = transformLMVModelFileListInFolderContent(lmvModelFiles);
      setProductFoldersContent(transformedFolderContent);

      const itemToSelect = transformedFolderContent.find((item) => item.id === itemUrn);

      const isSettingSelectedModelFromUrl = itemToSelect && !selectedModelId;
      if (isSettingSelectedModelFromUrl) {
        setSelectedModelId(itemToSelect.id);

        if (details === 'details') {
          selectItem(itemToSelect);
        }
      }

      // itemUrn in URL is not valid (can't be found)
      if (itemUrn && !itemToSelect) {
        logAndShowNotification({
          message: text.modelsFolderContent.failedToFindSelectedItem,
          severity: 'error',
        });

        navigate('..');
      }
    }
  }, [
    lmvModelFiles,
    itemUrn,
    details,
    selectedModelId,
    setProductFoldersContent,
    setSelectedModelId,
    logAndShowNotification,
    navigate,
    selectItem,
  ]);

  return {
    filteredProductFoldersContent,
    selectedModelId,
    handleOpenFoldersInDocsClick,
    handleFilterFilesByName: debouncedHandleFilterFilesByName,
    handleRowClick,
  };
};

export default useModelsFolderContent;
