import { MIDAccBridgeFolder } from 'mid-types';
import CheckoutStepFooter from './CheckoutStep/CheckoutStepFooter';
import FileTypesStepFooter from './FileTypesStep/FileTypesStepFooter';
import UploadLocationStepFooter from './UploadLocationStep/UploadLocationStepFooter';
import { stepsMap } from 'context/RFOModalStore/StepperSlice';

interface RFOModalFooterProps {
  activeStep: number;
  outgoingBridgeFoldersMap: Map<string, MIDAccBridgeFolder[]> | undefined;
}

const RFOModalFooter: React.FC<RFOModalFooterProps> = ({ activeStep, outgoingBridgeFoldersMap }) => (
  <>
    {activeStep === stepsMap.fileTypes && <FileTypesStepFooter />}
    {activeStep === stepsMap.uploadLocation && (
      <UploadLocationStepFooter outgoingBridgeFoldersMap={outgoingBridgeFoldersMap} />
    )}
    {activeStep === stepsMap.checkout && <CheckoutStepFooter />}
  </>
);

export default RFOModalFooter;
