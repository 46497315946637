import { CustomError } from '../customError';
import { ErrorCode } from '../errorCode';

type CauseType = {
  documentId: string;
};

export class BIM360ManifestFetchError extends CustomError {
  errorCode = ErrorCode.BIM360ManifestFetchError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
