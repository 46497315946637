import React from 'react';
import Checkbox from '@mui/material/Checkbox';

interface SettingsCheckboxProps {
  checked: boolean;
  handleCheckboxUpdate: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const OutputSettingsCheckbox: React.FC<SettingsCheckboxProps> = ({ checked, handleCheckboxUpdate }) => (
  <>
    <Checkbox size="small" checked={checked} onChange={handleCheckboxUpdate} />
  </>
);

export default OutputSettingsCheckbox;
