import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const LogsWrapper = styled('div')`
  min-width: ${({ theme }) => theme.var.logsWrapperMinWidth}px;
  width: 100%;
  height: inherit;
`;

export const LogTable = styled('table')`
  border-spacing: 0;
`;

export const LogNumbersColumn = styled('col')`
  background-color: ${({ theme }) => theme.colors.brand.lightSlate};
  width: ${({ theme }) => theme.var.logPreviewNumbersWidth}px;
`;
export const LogTextLine = styled('td')`
  padding-left: ${({ theme }) => theme.var.paddingBase}px;
`;

export const LogTableRow = styled('tr')`
  vertical-align: top;
`;

export const LogLineNumber = styled(Typography)`
  text-align: right;
  padding: 0 ${({ theme }) => theme.var.paddingBase / 2}px;
  user-select: none;
`;
