import { AlertColor } from '@mui/material';
import { useCallback, useState } from 'react';
import { HandleCloseReason, NOTIFICATION_STATUSES, closeReasons } from '../../components/Notification/notification.types';

import { CustomError, extractErrorMessage, logError, logWarn, uuidService } from 'mid-utils';
import text from '../../common.text.json';
import {
  ANCHOR_HORIZONTAL_POSITIONS,
  ANCHOR_VERTICAL_POSITIONS,
  AnchorHorizontalPosition,
  AnchorVerticalPosition,
} from '../../components/Notification/notification.constants';

export interface ShowNotificationProps {
  severity: AlertColor;
  message: string;
  messageBody?: string | JSX.Element | null;
  autoDismiss?: boolean;
}

export interface LogAndShowNotificationProps extends Partial<ShowNotificationProps> {
  logExtraData?: any;
  error?: Error | CustomError | unknown;
}

interface NotificationItem {
  id: string;
  message: string;
  severity: AlertColor;
  messageBody?: string | JSX.Element | null;
  anchor: { vertical: AnchorVerticalPosition; horizontal: AnchorHorizontalPosition };
  autoDismiss?: boolean;
  handleClose: (event?: React.SyntheticEvent | Event, reason?: HandleCloseReason) => void;
}

/**
 * Message field act like a title in the notification, where messageBody is additional
 * information that can be added
 */
export interface NotificationStore {
  items: NotificationItem[];
  showNotification: (props: ShowNotificationProps) => void;
  logAndShowNotification: (props: LogAndShowNotificationProps) => void;
}

const autoDismissDuration = 6000;

const anchor = { vertical: ANCHOR_VERTICAL_POSITIONS.TOP, horizontal: ANCHOR_HORIZONTAL_POSITIONS.RIGHT };

export const useNotificationStore = (): NotificationStore => {
  const [items, setItems] = useState<NotificationItem[]>([]);

  const showNotification = useCallback(
    ({ severity, message, autoDismiss = true, messageBody = null }: ShowNotificationProps) => {
      const notificationId = uuidService.getUUID();

      const handleClose = (event?: React.SyntheticEvent | Event, reason?: HandleCloseReason) => {
        if (reason === closeReasons.CLICKAWAY) {
          return;
        }

        setItems((prevItems) => prevItems.filter((item) => item.id !== notificationId));
      };

      setItems((prevItems) => [
        ...prevItems,
        {
          id: notificationId,
          severity,
          message,
          messageBody,
          autoDismiss,
          anchor,
          handleClose,
        },
      ]);

      // Custom auto-dismiss logic due to issues with standard Material UI auto-dismiss.
      // standard auto-dismiss is not working properly when multiple notifications are shown
      if (autoDismiss) {
        setTimeout(() => {
          handleClose(undefined, closeReasons.TIMEOUT);
        }, autoDismissDuration);
      }
    },
    [],
  );

  const logAndShowNotification = useCallback(
    ({ severity = NOTIFICATION_STATUSES.ERROR, message, error, logExtraData }: LogAndShowNotificationProps) => {
      let messageToLog = '',
        messageToShow = '';

      if (!message && !error) {
        throw new Error(text.insufficientArguments);
      }

      // determine what to show to user and log based on passed parameters
      if (!message && error) {
        messageToLog = messageToShow = extractErrorMessage(error);
      } else if (message && !error) {
        messageToLog = messageToShow = message;
      } else if (message && error) {
        messageToShow = message;
        messageToLog = `${extractErrorMessage(error)},${message}`;
      }

      if (severity === NOTIFICATION_STATUSES.WARNING) {
        logWarn(messageToLog, logExtraData);
      } else {
        logError(messageToLog, logExtraData);
      }
      showNotification({ severity, message: messageToShow });
    },
    [showNotification],
  );

  return {
    showNotification,
    logAndShowNotification,
    items,
  };
};
