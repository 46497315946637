import { UploadLocationProject } from 'types/uploadLocation';
import useRFOModalStore from 'context/RFOModalStore/useRFOModalStore';
import { useShallow } from 'zustand/react/shallow';

interface UseUploadLocationFooterState {
  uploadFolderUrn: string | undefined;
  uploadLocationProject: UploadLocationProject | null;
  isCreatingSubfolder: boolean;
  handleSubfolderCreationDisplay: () => void;
}

export const useUploadLocationFooter = (): UseUploadLocationFooterState => {
  const {
    isCreatingSubfolder,
    setIsCreatingSubfolder,
    uploadFolderUrn,
    uploadLocationProject,
    expandedUploadLocationTreeNodeIdsSelection,
    setExpandedUploadLocationTreeNodeIdsSelection,
  } = useRFOModalStore(
    useShallow((state) => ({
      uploadFolderUrn: state.uploadFolderUrn,
      uploadLocationProject: state.uploadLocationProject,
      isCreatingSubfolder: state.isCreatingSubfolder,
      setIsCreatingSubfolder: state.setIsCreatingSubfolder,
      expandedUploadLocationTreeNodeIdsSelection: state.expandedUploadLocationTreeNodeIdsSelection,
      setExpandedUploadLocationTreeNodeIdsSelection: state.setExpandedUploadLocationTreeNodeIdsSelection,
    })),
  );

  const handleSubfolderCreationDisplay = () => {
    setIsCreatingSubfolder(true);

    // If add subfolder is clicked, then automatically expand folder tree
    if (uploadFolderUrn && !expandedUploadLocationTreeNodeIdsSelection.includes(uploadFolderUrn)) {
      setExpandedUploadLocationTreeNodeIdsSelection([...expandedUploadLocationTreeNodeIdsSelection, uploadFolderUrn]);
    }
  };

  return {
    uploadFolderUrn,
    uploadLocationProject,
    isCreatingSubfolder,
    handleSubfolderCreationDisplay,
  };
};
