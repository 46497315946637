import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

export const AccBridgeTooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} placement="bottom" arrow classes={{ popper: className }} />
))`
  & .${tooltipClasses.tooltip} {
    padding: ${({ theme }) => `${theme.var.paddingBase}px`};
    background-color: ${({ theme }) => theme.colors.brand.white};
    color: ${({ theme }) => theme.palette.text.primary};
    box-shadow: ${({ theme }) => theme.shadows[5]};
  }

  & .${tooltipClasses.arrow} {
    color: ${({ theme }) => theme.colors.brand.white};
  }
`;

export const AccBridgeTooltipText = styled('p')`
  margin-top: 0;
  margin-bottom: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const AccBridgeTooltipList = styled('ul')`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: ${({ theme }) => `${theme.var.paddingBase * 2}px`};
`;

export const AccBridgeTooltipLink = styled('a')`
  display: inline-block;
  color: ${({ theme }) => theme.colors.primary.autodeskBlue[600]};
  text-decoration: none;
  font-size: ${({ theme }) => theme.typography.subtitle2.fontSize};
  margin-bottom: ${({ theme }) => `${theme.var.marginBase}px`};

  &.mid-acc-bridge-tooltip-link-details {
    margin-top: ${({ theme }) => `${theme.var.marginBase}px`};
  }
`;
