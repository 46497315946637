import { DCInputBooleanType, DCInputNumericType, InputType } from '@adsk/offsite-dc-sdk';

export const mockNumericInputWithMinError: DCInputNumericType = {
  increment: 1,
  label: 'Door Width',
  max: 100,
  min: 10,
  name: 'Width',
  readOnly: false,
  type: InputType.NUMERIC,
  unit: 'in',
  value: 9,
  visible: true,
};

export const mockNumericInputWithMinValidValue: DCInputNumericType = {
  increment: 1,
  label: 'Door Width',
  max: 100,
  min: 10,
  name: 'Width',
  readOnly: false,
  type: InputType.NUMERIC,
  unit: 'in',
  value: 20,
  visible: true,
};

export const mockNumericInputWithWidthMinError: DCInputNumericType = {
  increment: 1,
  label: 'Door Width',
  min: 10,
  name: 'Width',
  readOnly: false,
  type: InputType.NUMERIC,
  unit: 'in',
  value: 7,
  visible: true,
};

export const mockNumericInputWithHeightMinError: DCInputNumericType = {
  increment: 1,
  label: 'Door Height',
  min: 20,
  name: 'Height',
  readOnly: false,
  type: InputType.NUMERIC,
  unit: 'in',
  value: 18,
  visible: true,
};

export const mockNumericInputWithDepthMinError: DCInputNumericType = {
  increment: 1,
  label: 'Door Depth',
  min: 30,
  name: 'Depth',
  readOnly: false,
  type: InputType.NUMERIC,
  unit: 'in',
  value: 29,
  visible: true,
};

export const mockNumericInputWithMaxError: DCInputNumericType = {
  increment: 1,
  label: 'Door Width',
  max: 100,
  min: 10,
  name: 'Width',
  readOnly: false,
  type: InputType.NUMERIC,
  unit: 'in',
  value: 101,
  visible: true,
};

export const mockNumericInputWithIncrementError: DCInputNumericType = {
  increment: 10,
  label: 'Door Width',
  max: 100,
  min: 10,
  name: 'Width',
  readOnly: false,
  type: InputType.NUMERIC,
  unit: 'in',
  value: 91,
  visible: true,
};

export const mockNumericInputWithTypeError: DCInputNumericType = {
  increment: 1,
  label: 'Door Width',
  max: 100,
  min: 10,
  name: 'Width',
  readOnly: false,
  type: InputType.NUMERIC,
  unit: 'in',
  value: '9' as any, // any to trick Typescript and allow the value to be a string in a numeric input
  visible: true,
};

export const mockBooleanInput: DCInputBooleanType = {
  label: 'Equal Check',
  name: 'EqualCheck',
  readOnly: false,
  type: InputType.BOOLEAN,
  value: true,
  visible: true,
};
