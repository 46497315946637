import { OutputType } from '@adsk/offsite-dc-sdk';

export type InventorInput = {
  name: string;
  value: string;
  isProperty: boolean;
};

const InventorOutputThumbnailType = 'Thumbnail';

export const inventorOutputTypes = {
  [OutputType.RFA]: OutputType.RFA,
  [OutputType.THUMBNAIL]: InventorOutputThumbnailType,
} as const;
export type InventorOutputType = (typeof inventorOutputTypes)[keyof typeof inventorOutputTypes];

export type InventorOutput = {
  type: InventorOutputType;
  modelStates?: string[];
};

export type InventorOutputFileInfo = {
  type: InventorOutputType;
  modelState: string;
  filePath: string;
};

export type GenerateOutputsResult = {
  success: boolean;
  outputFiles?: InventorOutputFileInfo[];
  report: string;
};

export type UploadContentResult = {
  filePath: string;
  objectKey: string;
};
