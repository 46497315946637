import { ErrorCode } from './errorCode';
import { CustomError } from './customError';

type CauseType = {
  error?: Error;
};

export class ThumbnailError extends CustomError {
  errorCode = ErrorCode.ThumbnailError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
