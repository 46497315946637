import React, { useEffect } from 'react';

export type ButtonProps = {
  text: string;
  onClick?: () => void;
  width?: number;
};

export const Button: React.FC<ButtonProps> = ({ text, onClick, width }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (!ref.current.parentElement) {
      return;
    }

    const lmvButton = ref.current.parentElement;

    if (!lmvButton) {
      return;
    }

    if (width) {
      lmvButton.style.width = `${width}px`;
    }

    // updating the parent styles to get text centered. The parent node is controlled by LMV itself
    lmvButton.style.display = 'flex';
    lmvButton.style.alignItems = 'center';
    lmvButton.style.alignContent = 'center';

    if (onClick) {
      lmvButton.addEventListener('click', onClick);
    }

    return () => {
      if (onClick) {
        lmvButton.removeEventListener('click', onClick);
      }
    };
  }, [onClick, width]);

  return <div ref={ref}>{text}</div>;
};
