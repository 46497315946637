import { CustomDialog, StateSetter } from '@mid-react-common/common';
import Close from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { DetailsSidebarDivider, DetailsSidebarWrapper } from 'components/Commons/DetailsSidebar/DetailsSidebar.style';
import DetailsSidebarContent from 'components/Commons/DetailsSidebar/DetailsSidebarContent';
import DetailsSidebarHeader from 'components/Commons/DetailsSidebar/DetailsSidebarHeader';
import React from 'react';
import MissingThumbnail from '../../../../src/images/missingThumbnail.jpg';
import text from '../../../global/text.json';
import { modelDetails } from '../../../tests/helpers/dataTestIds';
import CustomAccordion from '../../Commons/CustomAccordion/CustomAccordion';
import KeyValueTable from '../../Commons/KeyValueTable/KeyValueTable';
import SidebarThumbnail from '../../Commons/SidebarThumbnail/SidebarThumbnail';
import { FolderContentRow } from '../ModelsFolderContent/ModelsFolderContent.types';
import { ModelDetailsSpinner } from './ModelDetails.style';
import useModelDetails from './useModelDetails';

const modelDetailsText = text.modelDetails;
interface ModelDetailsProps {
  folderUrn: string;
  selectedModelFolder: FolderContentRow | null;
  isPreviewLoading: boolean;
  setSelectedModelFolder: StateSetter<FolderContentRow | null>;
  handleModelDetailsClose: () => void;
}

const ModelDetails: React.FC<ModelDetailsProps> = ({
  folderUrn,
  selectedModelFolder,
  isPreviewLoading,
  setSelectedModelFolder,
  handleModelDetailsClose,
}): JSX.Element => {
  const {
    fileInfo,
    currentlyOpenModel,
    openConfirmUnselect,
    openConfirmReplace,
    handleCloseButtonClick,
    handleOpenModelClick,
    handleShowCloseModelConfirmation,
    handleConfirmReplaceOpen,
    handleConfirmReplaceClose,
    handleDismissCloseModelConfirmation,
    handleCloseModel,
  } = useModelDetails(folderUrn, selectedModelFolder, setSelectedModelFolder, handleModelDetailsClose);

  return (
    <>
      <DetailsSidebarWrapper className="mid-bg-shadow">
        {isPreviewLoading ? (
          <ModelDetailsSpinner size={75} />
        ) : (
          <>
            <DetailsSidebarHeader>
              {!currentlyOpenModel && (
                <Button variant="contained" onClick={handleOpenModelClick} data-testid={modelDetails.modelDetailsOpenButton}>
                  {modelDetailsText.openModel}
                </Button>
              )}
              {currentlyOpenModel && currentlyOpenModel.id === selectedModelFolder?.id && (
                <Button variant="outlined" onClick={handleShowCloseModelConfirmation} data-testid="modelDetailsCloseButton">
                  {modelDetailsText.closeModel}
                </Button>
              )}
              {currentlyOpenModel && currentlyOpenModel.id !== selectedModelFolder?.id && (
                <Button variant="contained" onClick={handleConfirmReplaceOpen} data-testid="modelDetailsChangeButton">
                  {modelDetailsText.changeModel}
                </Button>
              )}
              <IconButton onClick={handleCloseButtonClick} data-testid="close-model-details-button">
                <Close />
              </IconButton>
            </DetailsSidebarHeader>
            <DetailsSidebarDivider />
            <DetailsSidebarContent>
              <CustomAccordion title={modelDetailsText.preview}>
                {selectedModelFolder && selectedModelFolder.thumbnail ? (
                  <SidebarThumbnail src={URL.createObjectURL(selectedModelFolder.thumbnail)} />
                ) : (
                  <SidebarThumbnail src={MissingThumbnail} />
                )}
              </CustomAccordion>
              <CustomAccordion title={modelDetailsText.modelDetails}>
                <KeyValueTable data={fileInfo} />
              </CustomAccordion>
            </DetailsSidebarContent>
          </>
        )}
      </DetailsSidebarWrapper>
      <CustomDialog
        title={modelDetailsText.confirmation}
        open={openConfirmReplace}
        onCancel={handleConfirmReplaceClose}
        onConfirm={() => {
          handleOpenModelClick();
          handleConfirmReplaceClose();
        }}
        data-testid="replaceModel"
      >
        <Typography>{modelDetailsText.replaceModel}</Typography>
      </CustomDialog>
      <CustomDialog
        title={modelDetailsText.confirmation}
        open={openConfirmUnselect}
        onCancel={handleDismissCloseModelConfirmation}
        onConfirm={() => {
          handleCloseModel();
          handleDismissCloseModelConfirmation();
        }}
        data-testid="unselectModel"
      >
        <Typography>{modelDetailsText.unselectModel}</Typography>
      </CustomDialog>
    </>
  );
};

export default ModelDetails;
