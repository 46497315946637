import { InputType, InputTypes } from '@adsk/offsite-dc-sdk';
import { floor, isNaN } from 'lodash';

export interface InventorParameter {
  name: string;
  parameterType: string;
  unitType: string;
  value: string;
  isKey: boolean;
  label?: string;
  options?: string[] | number[];
}

export interface InventorProperties {
  parameters: InventorParameter[];
}

export const isMultivaluedInventorParameter = (p: InventorParameter): boolean => !!p.options;
export const isBooleanInventorParameter = (p: InventorParameter): boolean => p.unitType.toLowerCase() === 'boolean';

// *** Both single value & multi-value text parameters ***
// are returned as type: "text" by Inventor
// We differentiate between the two by checking if the options key exists or not
export const isTextInventorParameter = (p: InventorParameter): boolean =>
  p.unitType.toLowerCase() === 'text' && !isMultivaluedInventorParameter(p);

export const getInputTypeFromInventorParameter = (param: InventorParameter): InputTypes => {
  if (isBooleanInventorParameter(param)) {
    return InputType.BOOLEAN;
  }
  if (isTextInventorParameter(param)) {
    return InputType.TEXT;
  }
  if (isMultivaluedInventorParameter(param)) {
    if (param.options && areOptionsNumeric(param.options)) {
      return InputType.NUMERIC;
    }
    return InputType.TEXT;
  }
  return InputType.NUMERIC;
};

export const areOptionsNumeric = (options: Array<string | number>): boolean => options.every(isNumeric);

export const isNumeric = (x: string | number): boolean =>
  (typeof x === 'number' || typeof x === 'string') && !isNaN(Number(x));

export const arrayToNumericArray = (array: string[] | number[]): number[] => array.map(eightDecimalPlacesMax);

export const eightDecimalPlacesMax = (number: string | number): number => floor(Number(number), 8);
