import { mockFolder1, mockFolder1a, mockFolder1b, mockFolder2, mockFolder2a } from './mockFolders';
import { mockProject } from './mockProjects';
import {
  ProductRelease,
  ReleaseStatus,
  DynamicContent,
  OutputType,
  DCInputNumericType,
  PostProductPayload,
  PutProductPayload,
  InputType,
} from '@adsk/offsite-dc-sdk';
import { DCProductUIExtension, FormRules } from 'mid-types';

export const mockProjectIdWithProducts = '50353b25-2037-4b6f-b4ef-72d70fe706eb';
export const mockProjectIdFromACCBridge = '50353b25-2037-4b6f-b4ef-accbridge';
export const mockProjectIdWithError = '50353b25-2037-4b6f-b4ef-error';
export const rulesObjectKey = 'mock-object-key-for-rules';
export const ruleObjectKeyWError = 'mock-rules-key-with-error';
export const mockProductWithRulesWidthNumericInput: DCInputNumericType = {
  increment: 2,
  label: 'Door Width',
  max: 48,
  min: 32,
  name: 'Width',
  readOnly: false,
  type: InputType.NUMERIC,
  unit: 'in',
  value: 34,
  visible: true,
  allowCustomValue: true,
};

export const mockJavascriptRules1 = `
  parameters['Width'].min = 32;
  parameters['Width'].max = 48;
  parameters['Width'].increment = 2;

  parameters['WidthReadOnly'].min = 32;
  parameters['WidthReadOnly'].max = 48;
  parameters['WidthReadOnly'].increment = 6;

  parameters['MockMultiValueNumeric'].values = [1, 2, 3, 4, 5, 6];
  parameters['MockMultiValueNumericReadOnly'].values = [1, 2, 3];
  parameters['MockMultiValueText'].values = ['text1', 'text2', 'text3'];
  parameters['MockMultiValueTextReadOnly'].values = ['text1', 'text2', 'text3'];

  if ((parameters['Width'].value) == 44) {
    parameters['HasDoor'].value = false;
  }
  if(parameters['MockMultiValueText'].value == 'text3') {
    parameters['MockMultiValueText'].error = true;
    parameters['MockMultiValueText'].message = 'mock error message';
  }
`;

export const mockJavascriptRules2 = `
  parameters['Width'].min = 1;
  parameters['Width'].max = 100;
  parameters['Width'].increment = 1;

  parameters['WidthReadOnly'].min = 32;
  parameters['WidthReadOnly'].max = 48;
  parameters['WidthReadOnly'].increment = 6;

  parameters['MockMultiValueNumericReadOnly'].values = [1, 2, 3];
  parameters['MockMultiValueText'].values = ['text1', 'text2', 'text3'];
  parameters['MockMultiValueTextReadOnly'].values = ['text1', 'text2', 'text3'];

  parameters['MockMultiValueNumeric'].values = [1, 2];
  if ((parameters['Width'].value) == 44) {
    parameters['HasDoor'].value = false;
  }
  if (parameters['Width'].value < 50) {
    parameters['MockMultiValueNumeric'].values = [1, 2, 3];
  } else {
    parameters['MockMultiValueNumeric'].values = [1, 2, 3, 4, 5, 6];
  }
`;

export const mockFormRules: FormRules = {
  formName: 'mockFormName',
  inputs: [
    {
      label: '',
      name: 'HasDoor',
    },
    {
      label: '',
      name: 'HasDoorControllingInput',
    },
    {
      label: '',
      name: 'Width',
    },
    {
      label: '',
      name: 'MockMultiValueNumeric',
    },
    {
      label: '',
      name: 'MockMultiValueText',
    },
    {
      groupName: 'Read Only Group',
      openByDefault: true,
      inputs: [
        {
          label: '',
          name: 'WidthReadOnly',
        },
        {
          label: '',
          name: 'HasDoorReadOnly',
        },
        {
          label: '',
          name: 'MockMultiValueNumericReadOnly',
        },
        {
          label: '',
          name: 'MockMultiValueTextReadOnly',
        },
      ],
    },
  ],
};

export const mockProductWithRules: DCProductUIExtension<ProductRelease> = {
  status: ReleaseStatus.DEFAULT,
  contentId: '22222222-cc9c-44a4-ad79-b509e1c3225f',
  thumbnail: '22222222cc9c44a4ad79b509e1c3225f',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  inputs: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      readOnly: false,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Has Door - Read Only',
      name: 'HasDoorReadOnly',
      readOnly: true,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    mockProductWithRulesWidthNumericInput,
    {
      increment: 6,
      label: 'Door Width - Read Only',
      max: 48,
      min: 32,
      name: 'WidthReadOnly',
      readOnly: true,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Numeric',
      name: 'MockMultiValueNumeric',
      readOnly: false,
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      values: [1, 2, 3, 4, 5, 6],
      value: 4,
      onChange: [],
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Numeric - Read Only',
      name: 'MockMultiValueNumericReadOnly',
      readOnly: true,
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      values: [1, 2, 3],
      value: 2,
      onChange: [],
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Text',
      name: 'MockMultiValueText',
      readOnly: false,
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text3',
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Text - Read Only',
      name: 'MockMultiValueTextReadOnly',
      readOnly: true,
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text2',
      allowCustomValue: true,
    },
  ],
  name: 'mockProductWithRules',
  notes: 'Notes for mockProductWithRules',
  outputs: [
    {
      options: {
        modelStates: ['Master'],
      },
      type: OutputType.BOM,
    },
    {
      options: {
        category: 'Balcony 1',
        family: 'Balcony 1',
        modelStates: ['BIM', 'Master'],
      },
      type: OutputType.RFA,
    },
  ],
  rules: {
    currentRule: {
      code: mockJavascriptRules1,
    },
  },
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  schemaVersion: 1,
  tenancyId: mockProjectIdWithProducts,
  release: 1,
  createdAt: '',
  updatedAt: '',
};

export const mockProductWithoutRules: DCProductUIExtension<DynamicContent> = {
  contentId: '3243243243-cc9c-44a4-ad79-b509e1c3225f',
  thumbnail: '234234342cc9c44a4ad79b509e1c3225f',
  notes: 'Notes for mockProductWithoutRules',
  context: {
    topLevelAssembly: '1 - AD - Balcony Assembly - Inventor 2022 (without rules)\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'mockProductWithoutRules',
    workspace: {
      folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  inputs: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      readOnly: false,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Has Door - Read Only',
      name: 'HasDoorReadOnly',
      readOnly: true,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Door Width',
      name: 'Width',
      readOnly: false,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 34,
      visible: true,
      allowCustomValue: true,
    },
    {
      increment: 6,
      label: 'Door Width - Read Only',
      max: 48,
      min: 32,
      name: 'WidthReadOnly',
      readOnly: true,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Numeric',
      name: 'MockMultiValueNumeric',
      readOnly: false,
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      values: [1, 2, 3, 4, 5, 6],
      value: 4,
      onChange: [],
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Numeric - Read Only',
      name: 'MockMultiValueNumericReadOnly',
      readOnly: true,
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      values: [1, 2, 3],
      value: 2,
      onChange: [],
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Text',
      name: 'MockMultiValueText',
      readOnly: false,
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text3',
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Text - Read Only',
      name: 'MockMultiValueTextReadOnly',
      readOnly: true,
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text2',
      allowCustomValue: true,
    },
  ],
  name: '1 - AD - Balcony Assembly - Inventor 2022 (without rules)',
  outputs: [
    {
      options: {
        modelStates: ['Master'],
      },
      type: OutputType.BOM,
    },
    {
      options: {
        category: 'Balcony 1',
        family: 'Balcony 1',
        modelStates: ['BIM', 'Master'],
      },
      type: OutputType.RFA,
    },
  ],
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  schemaVersion: 1,
  tenancyId: mockProject.id,
  release: 1,
  createdAt: '',
  updatedAt: '',
};

export const mockProductFromBridge: DCProductUIExtension<DynamicContent> = {
  contentId: '3243243243-cc9c-44a4-ad79-b509e1c3225f',
  thumbnail: '234234342cc9c44a4ad79b509e1c3225f',
  notes: '',
  context: {
    topLevelAssembly: 'Balcony Assembly from bridge.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'mockProductWithoutRules',
    workspace: {
      folderPath: `${mockFolder2.urn}/urn:adsk.wipstg:fs.folder:co.AAAAAAAAEACAAAAAAAABKw`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  inputs: [
    {
      label: 'Mock MultiValue Numeric',
      name: 'MockMultiValueNumeric',
      readOnly: false,
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      values: [1, 2, 3, 4, 5, 6],
      value: 4,
      onChange: [],
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Numeric - Read Only',
      name: 'MockMultiValueNumericReadOnly',
      readOnly: true,
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      values: [1, 2, 3],
      value: 2,
      onChange: [],
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Text',
      name: 'MockMultiValueText',
      readOnly: false,
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text3',
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Text - Read Only',
      name: 'MockMultiValueTextReadOnly',
      readOnly: true,
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text2',
      allowCustomValue: true,
    },
  ],
  name: 'Balcony Assembly from bridge',
  outputs: [
    {
      options: {
        modelStates: ['Master'],
      },
      type: OutputType.BOM,
    },
    {
      options: {
        category: 'Balcony 1',
        family: 'Balcony 1',
        modelStates: ['BIM', 'Master'],
      },
      type: OutputType.RFA,
    },
  ],
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  schemaVersion: 1,
  tenancyId: mockProject.id,
  release: 1,
  createdAt: '',
  updatedAt: '',
};

export const mockProductWithRulesError: DCProductUIExtension<DynamicContent> = {
  contentId: '777777777-cc9c-44a4-ad79-b509e1c3225f',
  thumbnail: '55555555cc9c44a4ad79b509e1c3225f',
  notes: 'Notes for mockProductWithRulesError',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder2.urn}/${mockFolder2a.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  inputs: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      readOnly: true,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      increment: 6,
      label: 'Door Width',
      max: 48,
      min: 32,
      name: 'Width',
      readOnly: true,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
      allowCustomValue: true,
    },
  ],
  name: 'mockProductWithRulesError',
  outputs: [
    {
      options: {
        category: 'Balcony 5',
        family: 'Balcony 5',
        modelStates: ['BIM', 'Master'],
      },
      type: OutputType.RFA,
    },
  ],
  rulesKey: ruleObjectKeyWError,
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  schemaVersion: 1,
  tenancyId: mockProject.id,
  release: 1,
  createdAt: '',
  updatedAt: '',
};

export const mockProductWithRules2: DCProductUIExtension<DynamicContent> = {
  tenancyId: mockProjectIdWithProducts,
  contentId: '1231232-cc9c-44a4-ad79-b509e1c3kjkj',
  thumbnail: '22221231212cc9c44a4ad79b509e1c3225f',
  notes: 'Notes for mockProductWithRules2',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  inputs: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      readOnly: false,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Has Door - Read Only',
      name: 'HasDoorReadOnly',
      readOnly: true,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      increment: 1,
      label: 'Door Width',
      max: 100,
      min: 1,
      name: 'Width',
      readOnly: false,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 34,
      visible: true,
      allowCustomValue: true,
    },
    {
      increment: 6,
      label: 'Door Width - Read Only',
      max: 48,
      min: 32,
      name: 'WidthReadOnly',
      readOnly: true,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Numeric',
      name: 'MockMultiValueNumeric',
      readOnly: false,
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      values: [1, 2, 3, 4, 5, 6],
      value: 4,
      onChange: [],
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Numeric - Read Only',
      name: 'MockMultiValueNumericReadOnly',
      readOnly: true,
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      values: [1, 2, 3],
      value: 2,
      onChange: [],
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Text',
      name: 'MockMultiValueText',
      readOnly: false,
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text3',
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Text - Read Only',
      name: 'MockMultiValueTextReadOnly',
      readOnly: true,
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text2',
      allowCustomValue: true,
    },
  ],
  name: 'mockProductWithRules2',
  outputs: [
    {
      options: {
        modelStates: ['Master'],
      },
      type: OutputType.BOM,
    },
    {
      options: {
        category: 'Balcony 1',
        family: 'Balcony 1',
        modelStates: ['BIM', 'Master'],
      },
      type: OutputType.RFA,
    },
  ],
  rules: {
    currentRule: {
      code: mockJavascriptRules2,
    },
  },
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  schemaVersion: 1,
  release: 1,
  createdAt: '',
  updatedAt: '',
};

export const mockMIDShelvingUnitProduct: DCProductUIExtension<DynamicContent> = {
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  name: 'MID Shelving Unit',
  notes: '',
  thumbnail: '55555555cc9c44a4ad79b509e1c3225f',
  rules: {
    currentRule: {
      ruleLabel: 'currentRule',
      code: "parameters['Height'].values = [30, 42, 54, 66];parameters['Material'].values = ['Laminated Veneer Lumber', 'Particleboard', 'Ash', 'Birch', 'Mahogany', 'Maple', 'Oak, Red', 'Pine', 'Plywood, Finish', 'Spruce', 'Walnut'];parameters['Shelves_Qty'].values = [1, 2, 3, 4];if ((parameters['Height'].value) == 30) {\n  parameters['Shelves_Qty'].values = [1];} else if ((parameters['Height'].value) == 42) {\n  parameters['Shelves_Qty'].values = [1, 2];} else if ((parameters['Height'].value) == 54) {\n  parameters['Shelves_Qty'].values = [1, 2, 3];} else if ((parameters['Height'].value) == 66) {\n  parameters['Shelves_Qty'].values = [1, 2, 3, 4];parameters['Material'].values = ['Oak, Red', 'Maple', 'Mahogany', 'Birch'];}\n",
    },
  },
  schemaVersion: 1,
  codeBlocksWorkspaceKey: 'mock-key',
  tenancyId: mockProject.id,
  contentId: 'c3e2ea91-6f4d-4462-9436-f8a183c8e510',
  inputs: [
    {
      name: 'Height',
      type: InputType.NUMERIC,
      visible: true,
      readOnly: false,
      label: 'Height',
      unit: 'in',
      value: 54,
      values: [30, 42, 54, 66],
      allowCustomValue: true,
      onChange: [],
    },
    {
      name: 'Material',
      type: InputType.TEXT,
      visible: true,
      readOnly: false,
      label: 'Material',
      unit: 'Text',
      value: '"Oak, Red"',
      values: [
        '"Laminated Veneer Lumber"',
        '"Particleboard"',
        '"Ash"',
        '"Birch"',
        '"Mahogany"',
        '"Maple"',
        '"Oak, Red"',
        '"Pine"',
        '"Plywood, Finish"',
        '"Spruce"',
        '"Walnut"',
      ],
      allowCustomValue: true,
    },
    {
      name: 'Shelves_Qty',
      type: InputType.NUMERIC,
      visible: true,
      readOnly: false,
      label: 'Shelves_Qty',
      unit: 'ul',
      value: 3,
      values: [1, 2, 3],
      allowCustomValue: true,
      onChange: [],
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['[Primary]'],
        category: 'Furniture',
        family: 'MID Shelving Unit',
      },
    },
    {
      type: OutputType.BOM,
      options: {
        modelStates: ['Primary'],
      },
    },
  ],
  context: {
    projectFile: '\\MID Shelving Unit.ipj',
    topLevelAssembly: 'MID Shelving Unit\\MID Shelving Unit.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    workspace: {
      location: 'BIMDOCS',
      folderPath: `${mockFolder2.urn}/${mockFolder2a.urn}`,
    },
  },
  release: 1,
  createdAt: '',
  updatedAt: '',
};

export const mockProductWithRules3: DCProductUIExtension<DynamicContent> = {
  contentId: '11111111-cc9c-44a4-ad79-b509e1c3225f',
  thumbnail: '11111111cc9c44a4ad79b509e1c3225f',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  inputs: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      readOnly: false,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Has Door - Controlling Input',
      name: 'HasDoorControllingInput',
      readOnly: false,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
      onChange: [
        {
          dependsOn: {
            bindings: {
              dependentValue: 'Width.visible',
              dependsOn: 'HasDoorControllingInput.value',
            },
          },
        },
      ],
    },
    {
      label: 'Has Door - Read Only',
      name: 'HasDoorReadOnly',
      readOnly: true,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      increment: 6,
      label: 'Door Width',
      max: 48,
      min: 32,
      name: 'Width',
      readOnly: false,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
      allowCustomValue: true,
    },
    {
      increment: 6,
      label: 'Door Width - Read Only',
      max: 48,
      min: 32,
      name: 'WidthReadOnly',
      readOnly: true,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Numeric',
      name: 'MockMultiValueNumeric',
      readOnly: false,
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      values: [1, 2, 3],
      value: 2,
      allowCustomValue: true,
      onChange: [],
    },
    {
      label: 'Mock MultiValue Numeric - Read Only',
      name: 'MockMultiValueNumericReadOnly',
      readOnly: true,
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      values: [1, 2, 3],
      value: 2,
      allowCustomValue: true,
      onChange: [],
    },
    {
      label: 'Mock MultiValue Text',
      name: 'MockMultiValueText',
      readOnly: false,
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text2',
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Text - Read Only',
      name: 'MockMultiValueTextReadOnly',
      readOnly: true,
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text2',
      allowCustomValue: true,
    },
  ],
  name: 'mockProductWithRules3',
  notes: 'Notes for mockProductWithRules3',
  outputs: [
    {
      options: {
        modelStates: ['Primary'],
      },
      type: OutputType.BOM,
    },
    {
      options: {
        category: 'Balcony 0',
        family: 'Balcony  0',
        modelStates: ['BIM', 'Master'],
      },
      type: OutputType.RFA,
    },
  ],
  rulesKey: rulesObjectKey,
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  schemaVersion: 1,
  tenancyId: mockProject.id,
  release: 1,
  createdAt: '',
  updatedAt: '',
};

export const mockProductWithRules3DynamicProduct: DCProductUIExtension<DynamicContent> = {
  contentId: '11111111-cc9c-44a4-ad79-b509e1c3225f',
  thumbnail: '11111111cc9c44a4ad79b509e1c3225f',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  inputs: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      readOnly: false,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Has Door - Controlling Input',
      name: 'HasDoorControllingInput',
      readOnly: false,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
      onChange: [
        {
          dependsOn: {
            bindings: {
              dependentValue: 'Width.visible',
              dependsOn: 'HasDoorControllingInput.value',
            },
          },
        },
      ],
    },
    {
      label: 'Has Door - Read Only',
      name: 'HasDoorReadOnly',
      readOnly: true,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      increment: 6,
      label: 'Door Width',
      max: 48,
      min: 32,
      name: 'Width',
      readOnly: false,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
      allowCustomValue: true,
    },
    {
      increment: 6,
      label: 'Door Width - Read Only',
      max: 48,
      min: 32,
      name: 'WidthReadOnly',
      readOnly: true,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Numeric',
      name: 'MockMultiValueNumeric',
      readOnly: false,
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      values: [1, 2, 3],
      value: 2,
      allowCustomValue: true,
      onChange: [],
    },
    {
      label: 'Mock MultiValue Numeric - Read Only',
      name: 'MockMultiValueNumericReadOnly',
      readOnly: true,
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      values: [1, 2, 3],
      value: 2,
      allowCustomValue: true,
      onChange: [],
    },
    {
      label: 'Mock MultiValue Text',
      name: 'MockMultiValueText',
      readOnly: false,
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text2',
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Text - Read Only',
      name: 'MockMultiValueTextReadOnly',
      readOnly: true,
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text2',
      allowCustomValue: true,
    },
  ],
  name: 'mockProductWithRules3',
  notes: 'Notes for mockProductWithRules3DynamicProduct',
  outputs: [
    {
      options: {
        category: 'Balcony 0',
        family: 'Balcony  0',
        modelStates: ['BIM', 'Master'],
      },
      type: OutputType.RFA,
    },
    {
      options: {
        modelStates: ['BIM', 'Master'],
      },
      type: OutputType.THUMBNAIL,
    },
    {
      options: {
        modelStates: ['Primary'],
      },
      type: OutputType.BOM,
    },
  ],
  rulesKey: rulesObjectKey,
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  schemaVersion: 1,
  tenancyId: mockProject.id,
  release: 1,
  createdAt: '',
  updatedAt: '',
};

export const mockProductWithOneNumericValueAndRules: DCProductUIExtension<DynamicContent> = {
  contentId: '11111111-cc9c-44a4-ad79-b509e1c32254',
  thumbnail: '11111111cc9c44a4ad79b509e1c32254',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e4?region=US',
  inputs: [
    {
      increment: 6,
      label: 'Door Width',
      max: 48,
      min: 32,
      name: 'Width',
      readOnly: false,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 30,
      visible: true,
      allowCustomValue: true,
    },
  ],
  name: 'mockProductWithOneNumericValueAndRules',
  notes: 'Notes for mockProductWithOneNumericValueAndRules',
  outputs: [
    {
      options: {
        modelStates: ['Primary'],
      },
      type: OutputType.BOM,
    },
    {
      options: {
        category: 'Balcony 0',
        family: 'Balcony  0',
        modelStates: ['BIM', 'Master'],
      },
      type: OutputType.RFA,
    },
  ],
  rules: {
    currentRule: {
      code: `
      parameters['Width'].min = 32;
      parameters['Width'].max = 48;
      parameters['Width'].increment = 2;
      `,
    },
  },
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  schemaVersion: 1,
  tenancyId: mockProject.id,
  release: 1,
  createdAt: '',
  updatedAt: '',
};

export const mockProductWithOneNumericValueAndRulesDynamicContent: DCProductUIExtension<DynamicContent> = {
  contentId: '11111111-cc9c-44a4-ad79-b509e1c32254',
  thumbnail: '11111111cc9c44a4ad79b509e1c32254',
  notes: 'Notes for mockProductWithOneNumericValueAndRulesDynamicContent',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e4?region=US',
  inputs: [
    {
      increment: 6,
      label: 'Door Width',
      max: 48,
      min: 32,
      name: 'Width',
      readOnly: false,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 30,
      visible: true,
      allowCustomValue: true,
    },
  ],
  name: 'mockProductWithOneNumericValueAndRules',
  outputs: [
    {
      options: {
        modelStates: ['Primary'],
      },
      type: OutputType.BOM,
    },
    {
      options: {
        category: 'Balcony 0',
        family: 'Balcony  0',
        modelStates: ['BIM', 'Master'],
      },
      type: OutputType.RFA,
    },
  ],
  rules: {
    currentRule: {
      code: `
      parameters['Width'].min = 32;
      parameters['Width'].max = 48;
      parameters['Width'].increment = 2;
      `,
    },
  },
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  schemaVersion: 1,
  tenancyId: mockProject.id,
  release: 1,
  createdAt: '',
  updatedAt: '',
};

export const mockProductWithOneNumericValueAndRulesForTestingCustomMessageAndError: DCProductUIExtension<DynamicContent> = {
  contentId: '11111111-cc9c-44a4-ad79-b509e1c322345',
  thumbnail: '11111111cc9c44a4ad79b509e1c32254',
  notes: '',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e4?region=US',
  inputs: [
    {
      increment: 2,
      label: 'Wall Width',
      max: 40,
      min: 20,
      name: 'WallWidth',
      readOnly: false,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 20,
      visible: true,
      allowCustomValue: true,
    },
  ],
  name: 'mock Product With On eNumeric Value And Rules For Testing Custom Message And Error',
  outputs: [
    {
      options: {
        modelStates: ['Primary'],
      },
      type: OutputType.BOM,
    },
    {
      options: {
        category: 'Balcony 0',
        family: 'Balcony  0',
        modelStates: ['BIM', 'Master'],
      },
      type: OutputType.RFA,
    },
  ],
  rules: {
    currentRule: {
      code: `
      parameters['WallWidth'].increment = 2;
      parameters['WallWidth'].min = 20;
      parameters['WallWidth'].max = 40;
      if ((parameters['WallWidth'].value) == 24) {
        parameters['WallWidth'].message = 'The value is 24 and it\\'s an error!';
        parameters['WallWidth'].error = true;
      } else if ((parameters['WallWidth'].value) == 28) {
        parameters['WallWidth'].message = 'The value is 28 and it\\'s an info!';
        parameters['WallWidth'].error = false;
      }
      `,
    },
  },
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  schemaVersion: 1,
  tenancyId: mockProject.id,
  release: 1,
  createdAt: '',
  updatedAt: '',
};

export const mockStaticProduct: DCProductUIExtension<ProductRelease> = {
  contentId: '2222222-cc9c-44a4-ad79-b509e1c3225f',
  thumbnail: '2222222cc9c44a4ad79b509e1c3225f',
  notes: '',
  context: {
    topLevelAssembly: 'Static_Balcony_Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2025',
    },
    projectFile: 'Static_Balcony_Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  inputs: [
    {
      label: 'Width',
      name: 'Width',
      readOnly: false,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 40,
      visible: true,
      allowCustomValue: true,
    },
  ],
  name: 'mockStaticProduct',
  outputs: [
    {
      options: {
        modelStates: ['Primary'],
      },
      type: OutputType.BOM,
    },
    {
      options: {
        category: 'Balcony',
        family: 'Balcony',
        modelStates: ['Primary'],
      },
      type: OutputType.RFA,
    },
    {
      options: {
        modelStates: ['Primary'],
      },
      type: OutputType.THUMBNAIL,
    },
  ],
  rulesKey: rulesObjectKey,
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  schemaVersion: 1,
  tenancyId: mockProject.id,
  release: 1,
  createdAt: '',
  updatedAt: '',
  isConfigurable: false,
  status: ReleaseStatus.DEFAULT,
};

export const mockProducts: DynamicContent[] = [
  mockProductWithRules3,
  mockProductWithOneNumericValueAndRules,
  mockProductWithRules,
  mockProductWithOneNumericValueAndRulesForTestingCustomMessageAndError,
  {
    contentId: '33333333-cc9c-44a4-ad79-b509e1c3225f',
    thumbnail: '33333333cc9c44a4ad79b509e1c3225f',
    context: {
      topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
      engine: {
        location: 'DA4I',
        version: '2023',
      },
      projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
      workspace: {
        folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
        location: 'BIMDOCS',
      },
    },
    notes: '',
    dataSetLocation:
      'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
    inputs: [
      {
        label: 'Has Door',
        name: 'HasDoor',
        readOnly: true,
        type: InputType.BOOLEAN,
        value: true,
        visible: true,
      },
      {
        increment: 6,
        label: 'Door Width',
        max: 48,
        min: 32,
        name: 'Width',
        readOnly: true,
        type: InputType.NUMERIC,
        unit: 'in',
        value: 38,
        visible: true,
        allowCustomValue: true,
      },
    ],
    name: '2 - AD - Balcony Assembly - Inventor 2022',
    outputs: [
      {
        options: {
          modelStates: ['Primary'],
        },
        type: OutputType.BOM,
      },
      {
        options: {
          category: 'Balcony 2',
          family: 'Balcony 2',
          modelStates: ['BIM', 'Master'],
        },
        type: OutputType.RFA,
      },
    ],
    rulesKey: rulesObjectKey,
    codeBlocksWorkspace: '{"test": "mock-code-block"}',
    schemaVersion: 1,
    tenancyId: mockProjectIdWithProducts,
    release: 1,
    createdAt: '',
    updatedAt: '',
  },
  {
    contentId: '44444444-cc9c-44a4-ad79-b509e1c3225f',
    thumbnail: '44444444cc9c44a4ad79b509e1c3225f',
    notes: '',
    context: {
      topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
      engine: {
        location: 'DA4I',
        version: '2023',
      },
      projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
      workspace: {
        folderPath: `${mockFolder2.urn}/${mockFolder2a.urn}`,
        location: 'BIMDOCS',
      },
    },
    dataSetLocation:
      'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
    inputs: [
      {
        label: 'Has Door',
        name: 'HasDoor',
        readOnly: true,
        type: InputType.BOOLEAN,
        value: true,
        visible: true,
      },
      {
        increment: 6,
        label: 'Door Width',
        max: 48,
        min: 32,
        name: 'Width',
        readOnly: true,
        type: InputType.NUMERIC,
        unit: 'in',
        value: 38,
        visible: true,
        allowCustomValue: true,
      },
    ],
    name: '3 - AD - Balcony Assembly - Inventor 2022',
    outputs: [
      {
        options: {
          modelStates: ['Master'],
        },
        type: OutputType.BOM,
      },
      {
        options: {
          category: 'Balcony 3',
          family: 'Balcony 3',
          modelStates: ['BIM', 'Master'],
        },
        type: OutputType.RFA,
      },
    ],
    rules: {},
    codeBlocksWorkspace: '{"test": "mock-code-block"}',
    schemaVersion: 1,
    tenancyId: mockProjectIdWithProducts,
    release: 1,
    createdAt: '',
    updatedAt: '',
  },
  {
    contentId: '55555555-cc9c-44a4-ad79-b509e1c3225f',
    thumbnail: '55555555cc9c44a4ad79b509e1c3225f',
    notes: '',
    context: {
      topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
      engine: {
        location: 'DA4I',
        version: '2023',
      },
      projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
      workspace: {
        folderPath: `${mockFolder2.urn}/${mockFolder2a.urn}`,
        location: 'BIMDOCS',
      },
    },
    dataSetLocation:
      'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
    inputs: [
      {
        label: 'Has Door',
        name: 'HasDoor',
        readOnly: true,
        type: InputType.BOOLEAN,
        value: true,
        visible: true,
      },
      {
        increment: 6,
        label: 'Door Width',
        max: 48,
        min: 32,
        name: 'Width',
        readOnly: true,
        type: InputType.NUMERIC,
        unit: 'in',
        value: 38,
        visible: true,
        allowCustomValue: true,
      },
    ],
    name: '4 - AD - Balcony Assembly - Inventor 2022',
    outputs: [
      {
        options: {
          category: 'Balcony 4',
          family: 'Balcony 4',
          modelStates: ['BIM', 'Master'],
        },
        type: OutputType.RFA,
      },
      {
        options: {
          modelStates: ['Master'],
        },
        type: OutputType.BOM,
      },
    ],
    rules: {},
    codeBlocksWorkspace: '{"test": "mock-code-block"}',
    schemaVersion: 1,
    tenancyId: mockProjectIdWithProducts,
    release: 1,
    createdAt: '',
    updatedAt: '',
  },
  mockProductWithRulesError,
  mockProductWithRules2,
  mockMIDShelvingUnitProduct,
  mockProductWithoutRules,
  mockStaticProduct,
];

export const mockProductsFromACCBridge: DynamicContent[] = mockProducts.map((p) => ({
  ...p,
  tenancyId: mockProjectIdFromACCBridge,
}));

export const mockDynamicContentProducts: DynamicContent[] = [
  mockProductWithRules3DynamicProduct,
  mockProductWithOneNumericValueAndRulesDynamicContent,
];

export const midShelfCacheKey = 'a0qjm9906t5mvd7febbgvpo6teo6v9io4u3bnkbknk64huti8lobqfmfc4bhj0f9q3q69k4m650ld';

export const mockPostProductPayload: PostProductPayload = {
  schemaVersion: 1,
  name: 'AD - Balcony Assembly - Inventor 2022',
  thumbnail: 'mock-thumbnail-object-key',
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  notes: '',
  context: {
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    workspace: {
      folderPath:
        'urn:adsk.wipstg:fs.folder:co.xc_QXPE1QpSRF2GTS9YUCA/urn:adsk.wipstg:fs.folder:co.hR1-lUPySo2lTolJISRKLQ/urn:adsk.wipstg:fs.folder:co.G3oFEmrUTJu0xynUxevCSA',
      location: 'BIMDOCS',
    },
  },
  inputs: [
    {
      name: 'HasDoor',
      type: InputType.BOOLEAN,
      label: 'Has Door',
      visible: true,
      readOnly: false,
      value: true,
    },
    {
      name: 'Width',
      type: InputType.NUMERIC,
      label: 'Door Width',
      unit: 'in',
      visible: true,
      readOnly: false,
      value: 38,
      min: 32,
      max: 48,
      increment: 6,
      allowCustomValue: true,
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master', 'BIM'],
        family: 'Balcony',
        category: 'Balcony',
      },
    },
  ],
  rulesKey: rulesObjectKey,
  isConfigurable: false,
};

export const mockPostProductResponse: DCProductUIExtension<DynamicContent> = {
  schemaVersion: 1,
  name: 'AD - Balcony Assembly - Inventor 2022',
  thumbnail: 'mock-thumbnail-for-post-product-response',
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  notes: '',
  context: {
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    workspace: {
      folderPath:
        'urn:adsk.wipstg:fs.folder:co.xc_QXPE1QpSRF2GTS9YUCA/urn:adsk.wipstg:fs.folder:co.hR1-lUPySo2lTolJISRKLQ/urn:adsk.wipstg:fs.folder:co.G3oFEmrUTJu0xynUxevCSA',
      location: 'BIMDOCS',
    },
  },
  inputs: [
    {
      name: 'HasDoor',
      type: InputType.BOOLEAN,
      label: 'Has Door',
      visible: true,
      readOnly: false,
      value: true,
    },
    {
      name: 'Width',
      type: InputType.NUMERIC,
      label: 'Door Width',
      unit: 'in',
      visible: true,
      readOnly: false,
      value: 38,
      min: 32,
      max: 48,
      increment: 6,
      allowCustomValue: true,
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master', 'BIM'],
        family: 'Balcony',
        category: 'Balcony',
      },
    },
  ],
  rules: {},
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  tenancyId: mockProjectIdWithProducts,
  contentId: 'cd4ada75-d634-4051-ae99-d63978fa2a69',
  release: 1,
  createdAt: '',
  updatedAt: '',
};

export const mockPutProductPayloadFull: PutProductPayload = {
  schemaVersion: 1,
  name: 'AD - Balcony Assembly - Inventor 2022 - Updated',
  thumbnail: 'mock-thumbnail-for-post-product-response',
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  notes: '',
  context: {
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    workspace: {
      folderPath:
        'urn:adsk.wipstg:fs.folder:co.xc_QXPE1QpSRF2GTS9YUCA/urn:adsk.wipstg:fs.folder:co.hR1-lUPySo2lTolJISRKLQ/urn:adsk.wipstg:fs.folder:co.G3oFEmrUTJu0xynUxevCSA',
      location: 'BIMDOCS',
    },
  },
  inputs: [
    {
      name: 'HasDoor',
      type: InputType.BOOLEAN,
      label: 'Has Door',
      visible: true,
      readOnly: false,
      value: true,
    },
    {
      name: 'Width',
      type: InputType.NUMERIC,
      label: 'Door Width',
      unit: 'in',
      visible: true,
      readOnly: false,
      value: 38,
      min: 32,
      max: 48,
      increment: 6,
      allowCustomValue: true,
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master', 'BIM'],
        family: 'Balcony',
        category: 'Balcony',
      },
    },
  ],
};

export const mockPutProductPayloadMinimal: PutProductPayload = {
  schemaVersion: 1,
  name: 'AD - Balcony Assembly - Inventor 2022 - Updated',
  thumbnail: 'mock-thumbnail-for-post-product-response',
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  notes: '',
  context: {
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    workspace: {
      folderPath:
        'urn:adsk.wipstg:fs.folder:co.xc_QXPE1QpSRF2GTS9YUCA/urn:adsk.wipstg:fs.folder:co.hR1-lUPySo2lTolJISRKLQ/urn:adsk.wipstg:fs.folder:co.G3oFEmrUTJu0xynUxevCSA',
      location: 'BIMDOCS',
    },
  },
  inputs: [
    {
      name: 'HasDoor',
      type: InputType.BOOLEAN,
      label: 'Has Door',
      visible: true,
      readOnly: false,
      value: true,
    },
    {
      name: 'Width',
      type: InputType.NUMERIC,
      label: 'Door Width',
      unit: 'in',
      visible: true,
      readOnly: false,
      value: 38,
      min: 32,
      max: 48,
      increment: 6,
      allowCustomValue: true,
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master', 'BIM'],
        family: 'Balcony',
        category: 'Balcony',
      },
    },
  ],
};

export const mockPutProductResponse: DCProductUIExtension<DynamicContent> = {
  schemaVersion: 1,
  name: 'AD - Balcony Assembly - Inventor 2022 - Updated',
  thumbnail: 'mock-thumbnail-for-post-product-response',
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  notes: '',
  context: {
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    workspace: {
      folderPath:
        'urn:adsk.wipstg:fs.folder:co.xc_QXPE1QpSRF2GTS9YUCA/urn:adsk.wipstg:fs.folder:co.hR1-lUPySo2lTolJISRKLQ/urn:adsk.wipstg:fs.folder:co.G3oFEmrUTJu0xynUxevCSA',
      location: 'BIMDOCS',
    },
  },
  inputs: [
    {
      name: 'HasDoor',
      type: InputType.BOOLEAN,
      label: 'Has Door',
      visible: true,
      readOnly: false,
      value: true,
    },
    {
      name: 'Width',
      type: InputType.NUMERIC,
      label: 'Door Width',
      unit: 'in',
      visible: true,
      readOnly: false,
      value: 38,
      min: 32,
      max: 48,
      increment: 6,
      allowCustomValue: true,
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master', 'BIM'],
        family: 'Balcony',
        category: 'Balcony',
      },
    },
  ],
  rules: {},
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  tenancyId: mockProjectIdWithProducts,
  contentId: 'cd4ada75-d634-4051-ae99-d63978fa2a69',
  release: 2,
  createdAt: '',
  updatedAt: '',
};

export const mockProductWithRuleKey: DynamicContent = {
  contentId: '55555555-cc9c-44a4-ad79-b509e1c3225f',
  thumbnail: '55555555cc9c44a4ad79b509e1c3225f',
  notes: '',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder2.urn}/${mockFolder2a.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  inputs: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      readOnly: true,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      increment: 6,
      label: 'Door Width',
      max: 48,
      min: 32,
      name: 'Width',
      readOnly: true,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
      allowCustomValue: true,
    },
  ],
  name: '4 - AD - Balcony Assembly - Inventor 2022',
  outputs: [
    {
      options: {
        category: 'Balcony 4',
        family: 'Balcony 4',
        modelStates: ['BIM', 'Master'],
      },
      type: OutputType.RFA,
    },
    {
      options: {
        modelStates: ['Master'],
      },
      type: OutputType.BOM,
    },
  ],
  rulesKey: 'mock-rules-key',
  codeBlocksWorkspaceKey: 'mock-rules-key',
  schemaVersion: 1,
  tenancyId: mockProjectIdWithProducts,
  release: 1,
  createdAt: '',
  updatedAt: '',
};

export const mockProductsMap = new Map<string, DynamicContent>(mockProducts.map((p) => [p.contentId + p.release, p]));

export const mockProductWithDynamicContentSDK: DCProductUIExtension<ProductRelease> = {
  contentId: '11111111-cc9c-44a4-ad79-b509e1c3225f',
  thumbnail: '11111111cc9c44a4ad79b509e1c3225f',
  notes: '',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  inputs: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      readOnly: false,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      label: 'Has Door - Controlling Input',
      name: 'HasDoorControllingInput',
      readOnly: false,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
      onChange: [
        {
          dependsOn: {
            bindings: {
              dependentValue: 'Width.visible',
              dependsOn: 'HasDoorControllingInput.value',
            },
          },
        },
      ],
    },
    {
      label: 'Has Door - Read Only',
      name: 'HasDoorReadOnly',
      readOnly: true,
      type: InputType.BOOLEAN,
      value: true,
      visible: true,
    },
    {
      increment: 6,
      label: 'Door Width',
      max: 48,
      min: 32,
      name: 'Width',
      readOnly: false,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
      allowCustomValue: true,
    },
    {
      increment: 6,
      label: 'Door Width - Read Only',
      max: 48,
      min: 32,
      name: 'WidthReadOnly',
      readOnly: true,
      type: InputType.NUMERIC,
      unit: 'in',
      value: 38,
      visible: true,
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Numeric',
      name: 'MockMultiValueNumeric',
      readOnly: false,
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      values: [1, 2, 3, 4, 5, 6],
      value: 2,
      allowCustomValue: true,
      onChange: [],
    },
    {
      label: 'Mock MultiValue Numeric - Read Only',
      name: 'MockMultiValueNumericReadOnly',
      readOnly: true,
      type: InputType.NUMERIC,
      visible: true,
      unit: 'in',
      values: [1, 2, 3],
      value: 2,
      allowCustomValue: true,
      onChange: [],
    },
    {
      label: 'Mock MultiValue Text',
      name: 'MockMultiValueText',
      readOnly: false,
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      values: ['"text1"', '"text2"', '"text3"'],
      value: '"text2"',
      allowCustomValue: true,
    },
    {
      label: 'Mock MultiValue Text - Read Only',
      name: 'MockMultiValueTextReadOnly',
      readOnly: true,
      type: InputType.TEXT,
      visible: true,
      unit: 'in',
      values: ['"text1"', '"text2"', '"text3"'],
      value: '"text2"',
      allowCustomValue: true,
    },
  ],
  name: 'mockProductWithRules3',
  outputs: [
    {
      options: {
        modelStates: ['Primary'],
      },
      type: OutputType.BOM,
    },
    {
      options: {
        category: 'Balcony 0',
        family: 'Balcony  0',
        modelStates: ['BIM', 'Master'],
      },
      type: OutputType.RFA,
    },
  ],
  rulesKey: rulesObjectKey,
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  schemaVersion: 1,
  tenancyId: mockProject.id,
  release: 1,
  createdAt: '',
  updatedAt: '',
  status: ReleaseStatus.DEFAULT,
};

export const mockProductReleases: ProductRelease[] = [mockProductWithDynamicContentSDK];
