import React, { useMemo } from 'react';
import { OutputItem } from './SettingsPanel';
import text from '../../../global/text.json';
import { OutputsTypesContainer, Label, SettingsOutputTitle } from './SettingsPanel.styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import FormGroup from '@mui/material/FormGroup';
import { OutputSettingsLabel } from './Components/OutputSettingsLabel';
import {
  generateModelStateInfoTree,
  removeExtension,
  removePath,
  transformDCOutputsToPostVariantOutputs,
  transformOutputItemToPostVariantOutput,
} from './outputSettings.utils';
import SelectAllOutputsCheckbox from './Components/SelectAllOutputsCheckbox/SelectAllOutputsCheckbox';
import CheckboxForOutputLabel from './Components/CheckboxForOutputLabel/CheckboxForOutputLabel';
import { DCOutputWithVirtualTypes, OutputTypesWithVirtualTypes, PostVariantOutputWithVirtualTypes } from 'mid-types';

export type OutputWithTreeTemplateLabels = { [key: string]: OutputItem[] };

interface OutputSettingsWithTreeProps {
  outputs: DCOutputWithVirtualTypes[];
  outputTypesList: OutputTypesWithVirtualTypes[];
  selectedOutputs: PostVariantOutputWithVirtualTypes[];
  outputSectionTitle: string;
  addOutput: (newOutput: PostVariantOutputWithVirtualTypes) => void;
  removeOutput: (outputToRemove: PostVariantOutputWithVirtualTypes) => void;
  addAllOutputs: (outputTypesList: OutputTypesWithVirtualTypes[]) => void;
  removeAllOutputs: (outputTypesList: OutputTypesWithVirtualTypes[]) => void;
}

const settingsPanelText = text.settingsPanel;

export const OutputSettingsWithTree: React.FC<OutputSettingsWithTreeProps> = ({
  outputs,
  outputTypesList,
  selectedOutputs,
  outputSectionTitle,
  addOutput,
  removeOutput,
  addAllOutputs,
  removeAllOutputs,
}) => {
  const totalOutputs = useMemo(
    () => transformDCOutputsToPostVariantOutputs(outputs).filter((output) => outputTypesList.includes(output.type)).length,
    [outputs, outputTypesList],
  );
  const numberOfSelectedOutputs = useMemo(
    () => selectedOutputs.filter((output) => outputTypesList.includes(output.type)).length,
    [selectedOutputs, outputTypesList],
  );
  const labelsForOutputs = generateModelStateInfoTree(outputs, selectedOutputs);

  const handleCheckboxUpdate = (event: React.ChangeEvent<HTMLInputElement>, label: OutputItem, drawingPath?: string) => {
    const { outputType } = label;
    if (!outputType) {
      throw new Error(`${settingsPanelText.failToSelectOutputType} ${outputType}`);
    }

    const output = transformOutputItemToPostVariantOutput(label, drawingPath);
    if (output) {
      if (event.target.checked) {
        addOutput(output);
      } else {
        removeOutput(output);
      }
    }
  };

  if (!labelsForOutputs || totalOutputs === 0) {
    return null;
  }

  // Generates Array to keep TreeView expanded by default
  const indexList = Array.from(Array(totalOutputs).keys()).map((index) => index.toString());

  const handleAddAllOutputs = () => {
    addAllOutputs(outputTypesList);
  };

  const handleRemoveAllOutputs = () => {
    removeAllOutputs(outputTypesList);
  };

  return (
    <>
      <SettingsOutputTitle>{outputSectionTitle}</SettingsOutputTitle>
      <FormControlLabel
        control={
          <SelectAllOutputsCheckbox
            outputSection={outputSectionTitle}
            selectedOutputsLength={numberOfSelectedOutputs}
            outputsLength={totalOutputs}
            addAllOutputs={handleAddAllOutputs}
            removeAllOutputs={handleRemoveAllOutputs}
          />
        }
        label={<Label>{settingsPanelText.allAvailable}</Label>}
      />
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultExpanded={indexList}
        disableSelection
      >
        {Object.entries(labelsForOutputs).map(([title, outputLabel], index) => (
          <TreeItem key={`${title}-${index}`} nodeId={index.toString()} label={removePath(removeExtension(title))}>
            <OutputsTypesContainer>
              {outputLabel.map((label, index) => (
                <FormGroup key={`${label.outputLabel}-${index}`}>
                  <FormControlLabel
                    control={
                      <CheckboxForOutputLabel
                        drawingTemplatePath={title}
                        label={label}
                        handleCheckboxUpdate={handleCheckboxUpdate}
                      />
                    }
                    label={<OutputSettingsLabel outputLabel={label} />}
                  />
                </FormGroup>
              ))}
            </OutputsTypesContainer>
          </TreeItem>
        ))}
      </TreeView>
    </>
  );
};
