import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { KeyValueTableProps } from '../../../types/KeyValueTable';
import { RowLabel, RowValue, RowValueWrapper } from './KeyValueTable.style';

const randomKey = (prefix: string): string => `${prefix}-${Math.random().toString(36).substring(2, 7)}`;

export const KeyValueTable = ({ data }: KeyValueTableProps): JSX.Element => (
  <Table>
    <TableBody>
      {data.map((row) => (
        <TableRow key={randomKey(row.value)}>
          <RowLabel>
            <Typography color="text.secondary" noWrap variant="body2">
              {row.label}
            </Typography>
          </RowLabel>
          <RowValue>
            <RowValueWrapper>{row.value}</RowValueWrapper>
          </RowValue>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default KeyValueTable;
