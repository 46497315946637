import { ReactNode } from 'react';
import { AppLoadingProgress } from '../../styles/Common.styles';
import { useAnalytics } from './useAnalytics';

interface UserAnalyticsProviderProps {
  children: ReactNode;
  webComponentMoniker: string;
  sessionId: string;
}

const UserAnalyticsProvider: React.FC<UserAnalyticsProviderProps> = ({ children, webComponentMoniker, sessionId }) => {
  // Set analytics for Bugsnag, Segment and LaunchDarkly
  const { isAnalyticsIdIdentifiedInLD } = useAnalytics(webComponentMoniker, sessionId);

  if (!isAnalyticsIdIdentifiedInLD) {
    return <AppLoadingProgress />;
  }

  return children;
};

export default UserAnalyticsProvider;
