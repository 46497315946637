import { ProductRelease } from '@adsk/offsite-dc-sdk';
import { ProductReleaseLocalStorageKeys } from 'global/constants/products';
import { logWarn } from 'mid-utils';

/**
 * Save selected product release to local storage
 */
export const setSelectedProductReleaseToLocalStorage = (productRelease: ProductRelease): void => {
  const { tenancyId, contentId, release } = productRelease;
  window.localStorage.setItem(
    ProductReleaseLocalStorageKeys.SELECTED_PRODUCT_RELEASE,
    // Save the properties needed to identify a selected product release in local storage
    JSON.stringify({ tenancyId, contentId, release }),
  );
};

/**
 * Remove selected product release from local storage
 */
export const removeSelectedProductReleaseFromLocalStorage = (): void => {
  window.localStorage.removeItem(ProductReleaseLocalStorageKeys.SELECTED_PRODUCT_RELEASE);
};

/**
 * Get selected product release information from local storage and find it from the list of product releases
 * @param productReleases
 * @returns selected product release if found
 */
export const getSelectedProductReleaseFromLocalStorage = (productReleases: ProductRelease[]): ProductRelease | undefined => {
  const selectedProductReleaseFromLocalStorage = window.localStorage.getItem(
    ProductReleaseLocalStorageKeys.SELECTED_PRODUCT_RELEASE,
  );
  if (!selectedProductReleaseFromLocalStorage) {
    return;
  }

  try {
    const parseSelectedProductRelease = JSON.parse(selectedProductReleaseFromLocalStorage);
    return productReleases.find(
      (productRelease) =>
        productRelease.tenancyId === parseSelectedProductRelease?.tenancyId &&
        productRelease.contentId === parseSelectedProductRelease?.contentId &&
        productRelease.release === parseSelectedProductRelease?.release,
    );
  } catch (err: unknown) {
    logWarn(`Failed to parse selected product release`);
    return;
  }
};
