import { ProductRelease, ReleaseStatus, ReleaseStatusValue } from '@adsk/offsite-dc-sdk';
import { NotificationContext } from '@mid-react-common/common';
import { getDcApiServiceInstance } from 'mid-api-services';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import text from '../../../global/text.json';
import { getInitialLocalReleaseStatus, getReleaseStatusOptions } from './ReleaseStatusUpdate.utils';
import { DCProductUIExtension } from 'mid-types';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';

interface UseReleaseStateUpdateProps {
  productReleases: DCProductUIExtension<ProductRelease>[];
  releaseNumber: number;
  refetchProductReleases: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<DCProductUIExtension<ProductRelease>[], Error>>;
}

interface UseReleaseStateUpdateState {
  dialogOpen: boolean;
  handleDialogClose: () => void;
  handleDialogOpen: () => void;
  activeReleases: DCProductUIExtension<ProductRelease>[];
  currentRelease: DCProductUIExtension<ProductRelease> | undefined;
  isStatusUpdating: boolean;
  newDefaultRelease: number | undefined;
  newReleaseStatus: ReleaseStatusValue;
  obsoleteReleasesCount: number;
  releaseCount: number;
  releaseStatusOptions: JSX.Element | null;
  setNewDefaultRelease: (newDefaultRelease: number | undefined) => void;
  updateReleaseStatus: () => Promise<void>;
}

const releasesText = text.releases;

const useReleaseStatusUpdate = ({
  productReleases,
  releaseNumber,
  refetchProductReleases,
}: UseReleaseStateUpdateProps): UseReleaseStateUpdateState => {
  const { logAndShowNotification } = useContext(NotificationContext);

  const [newReleaseStatus, setNewReleaseStatus] = useState<ReleaseStatusValue>(ReleaseStatus.DEFAULT);
  const [newDefaultRelease, setNewDefaultRelease] = useState<number | undefined>();
  const [isStatusUpdating, setIsStatusUpdating] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const dcApiService = getDcApiServiceInstance();

  const currentRelease = useMemo(
    () => productReleases.find((release) => release.release === releaseNumber),
    [productReleases, releaseNumber],
  );
  const activeReleases = useMemo(
    () => productReleases.filter((release) => release.status === ReleaseStatus.ACTIVE),
    [productReleases],
  );
  const obsoleteReleasesCount = useMemo(
    () => productReleases.filter((release) => release.status === ReleaseStatus.OBSOLETE).length,
    [productReleases],
  );
  const releaseCount = productReleases.length;

  // update the form default values when the release number changes or the release data is reloaded
  useEffect(() => {
    const initialReleaseStatus = getInitialLocalReleaseStatus(currentRelease, productReleases.length, obsoleteReleasesCount);
    const initialNewDefaultRelease = activeReleases.at(-1)?.release;
    setNewReleaseStatus(initialReleaseStatus);
    setNewDefaultRelease(initialNewDefaultRelease);
  }, [activeReleases, currentRelease, obsoleteReleasesCount, productReleases, releaseNumber]);

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const changeReleaseStatus = (event: React.ChangeEvent<HTMLInputElement>) =>
    setNewReleaseStatus(event.target.value as ReleaseStatusValue);

  const updateReleaseStatus = async () => {
    setIsStatusUpdating(true);
    try {
      if (!currentRelease) {
        throw new Error(releasesText.noReleaseFound);
      }

      // If there is a new default release, update the old default release to active
      if (newDefaultRelease && currentRelease && currentRelease.status === ReleaseStatus.DEFAULT) {
        await dcApiService.updateProductRelease(
          currentRelease.tenancyId,
          currentRelease.contentId,
          newDefaultRelease,
          ReleaseStatus.DEFAULT,
        );
      }
      await dcApiService.updateProductRelease(
        currentRelease.tenancyId,
        currentRelease.contentId,
        releaseNumber,
        newReleaseStatus,
      );
    } catch (error) {
      logAndShowNotification({
        message: releasesText.failedToUpdateReleaseStatus,
        error,
      });
    } finally {
      setIsStatusUpdating(false);
      handleDialogClose();
      refetchProductReleases();
    }
  };

  const releaseStatusOptions = getReleaseStatusOptions(
    productReleases,
    currentRelease,
    releaseCount,
    obsoleteReleasesCount,
    changeReleaseStatus,
    newReleaseStatus,
  );

  return {
    dialogOpen,
    handleDialogClose,
    handleDialogOpen,
    activeReleases,
    currentRelease,
    isStatusUpdating,
    newDefaultRelease,
    newReleaseStatus,
    obsoleteReleasesCount,
    releaseCount,
    releaseStatusOptions,
    setNewDefaultRelease,
    updateReleaseStatus,
  };
};

export default useReleaseStatusUpdate;
