import { getMSIVersionInfo } from '@mid-react-common/common';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AddinsVersionInfoByName, DefaultReleaseYear } from 'mid-types';
import { logError } from 'mid-utils';
import { useCallback, useEffect, useState } from 'react';

interface UseInstallersState {
  addinVersionInfoByName: AddinsVersionInfoByName | null;
}

export const useInstallers = (): UseInstallersState => {
  const [addinVersionInfoByName, setAddinVersionInfoByName] = useState<AddinsVersionInfoByName | null>(null);
  const { midWebappInstallerLinksReleaseYears } =
    useFlags<{ midWebappInstallerLinksReleaseYears: string[] | undefined }>() || {};

  const setAddinDownloadLinksByYear = useCallback(async () => {
    const latestVersionInfo = await getMSIVersionInfo(midWebappInstallerLinksReleaseYears ?? DefaultReleaseYear);
    setAddinVersionInfoByName(latestVersionInfo);
  }, [midWebappInstallerLinksReleaseYears]);

  useEffect(() => {
    try {
      setAddinDownloadLinksByYear();
    } catch (error) {
      logError(error);
    }
  }, [setAddinDownloadLinksByYear]);

  return {
    addinVersionInfoByName,
  };
};
