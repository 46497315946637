import React from 'react';
import Popover from '@mui/material/Popover';
import { uploadLocationProjectSelectorTestIds } from '../../../../tests/helpers/dataTestIds';
import { UploadLocationProject } from '../../../../types/uploadLocation';
import { ProjectHeaderContainer, ProjectListPopoverContainer } from './OutputProjectSelector.styles';
import ProjectListDropdown from './ProjectListDropdown';
import AccountProjectName from './AccountProjectName';

interface OutputProjectSelectorProps {
  selectedProject: UploadLocationProject | null;
  projects: UploadLocationProject[];
  anchorElPopover: null | HTMLElement;
  loading: boolean;
  handleProjectMenuOpen: (event: React.MouseEvent<HTMLElement>) => Promise<void>;
  handleProjectMenuClose: () => void;
  handleSearchProjects: (value: string) => void;
  handleProjectSelection: (project: UploadLocationProject) => void;
}

const OutputProjectSelector: React.FC<OutputProjectSelectorProps> = ({
  selectedProject,
  projects,
  anchorElPopover,
  loading,
  handleProjectMenuOpen,
  handleProjectMenuClose,
  handleSearchProjects,
  handleProjectSelection,
}) => (
  <>
    <ProjectHeaderContainer
      onClick={handleProjectMenuOpen}
      data-testid={uploadLocationProjectSelectorTestIds.projectListDropdown}
    >
      <AccountProjectName loading={loading} isPopoverOpen={Boolean(anchorElPopover)} selectedProject={selectedProject} />
    </ProjectHeaderContainer>
    <Popover
      open={Boolean(anchorElPopover)}
      anchorEl={anchorElPopover}
      onClose={handleProjectMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <ProjectListPopoverContainer>
        <ProjectListDropdown
          selectedProjectId={selectedProject?.id}
          projects={projects}
          handleProjectSelection={handleProjectSelection}
          handleSearchProjects={handleSearchProjects}
        />
      </ProjectListPopoverContainer>
    </Popover>
  </>
);

export default OutputProjectSelector;
