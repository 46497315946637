import { ErrorCode } from '../errorCode';
import { ApiError } from './apiError';

type CauseType = {
  error: unknown;
};
export class ApiUploadFileError extends ApiError {
  errorCode = ErrorCode.ApiUploadFileError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
