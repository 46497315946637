import { Vendors } from '@adsk/offsite-dc-sdk';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import text from '../../../../../global/text.json';
import { UploadLocationIds } from '../../../../../tests/helpers/dataTestIds';
import { ModalActionContainer, ModalButton } from './OutputFolderButtonAndModal.styles';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import { ProjectFolderPermissionSection } from 'components/Commons/ProjectFolderPermissionSection/ProjectFolderPermissionSection';

type ModalActionsProps = {
  handleSubfolderCreationDisplay: () => void;
  handleCancellation: () => void;
  handleConfirmation: () => void;
  isFolderActionDisabled: boolean;
  vendor?: Vendors;
};

const uploadLocationText = text.uploadLocation;

export const ModalActions: React.FC<ModalActionsProps> = ({
  handleSubfolderCreationDisplay,
  handleCancellation,
  handleConfirmation,
  isFolderActionDisabled,
  vendor,
}) => (
  <ModalActionContainer>
    <Box display="flex" alignItems="center">
      <Button
        variant="outlined"
        startIcon={<CreateNewFolderOutlinedIcon />}
        onClick={handleSubfolderCreationDisplay}
        disabled={isFolderActionDisabled}
        data-testid={UploadLocationIds.addSubFolderButton}
      >
        {uploadLocationText.addSubfolder}
      </Button>
      {vendor && (
        <Tooltip
          title={<ProjectFolderPermissionSection summary={uploadLocationText.permissionTooltip} vendor={vendor} />}
          placement="top"
          arrow
          disableFocusListener={!isFolderActionDisabled}
          disableHoverListener={!isFolderActionDisabled}
        >
          <IconButton
            disableRipple
            disableFocusRipple
            disabled={!isFolderActionDisabled}
            data-testid={UploadLocationIds.tooltipIconButton}
          >
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
    <div>
      <Button variant="outlined" onClick={handleCancellation}>
        {uploadLocationText.cancel}
      </Button>
      <ModalButton
        variant="contained"
        onClick={handleConfirmation}
        disabled={isFolderActionDisabled}
        data-testid={UploadLocationIds.selectButton}
      >
        {uploadLocationText.select}
      </ModalButton>
    </div>
  </ModalActionContainer>
);
