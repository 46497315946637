import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

export const HeaderAppBarContainer = styled(Box)(() => ({
  flexGrow: 0,
}));

export const HeaderToolbarContainer = styled(Toolbar)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  justifyContent: 'center',
}));

export const AppLogoContainer = styled(Box)(() => ({
  flexGrow: 10,
  verticalAlign: 'center',
  paddingTop: '14px',
}));

export const AppLogoImage = styled('img')(() => ({
  height: '24px',
}));
