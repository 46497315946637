import { Spacing } from '@mid-react-common/common';
import Typography from '@mui/material/Typography';
import useBillOfMaterials from './useBillOfMaterials';
import BillOfMaterialDropdown from './BillOfMaterialDropdown';
import Box from '@mui/material/Box';
import { DCBomOutputs, OutputTypeWithVirtualTypes } from 'mid-types';
import text from 'global/text.json';
import { Section } from '../../RFOModal.styles';
import Divider from '@mui/material/Divider';
import { rfoModalTestIds } from 'tests/helpers/dataTestIds';
const fileTypesStepText = text.rfoModal.fileTypesStep;

interface BillOfMaterialsProps {
  outputsTypeBom: DCBomOutputs[];
}

const BillOfMaterials: React.FC<BillOfMaterialsProps> = ({ outputsTypeBom }) => {
  const {
    aggregatedBomOutputs,
    bomOutputs,
    handleAggregatedBomOutputsChange,
    handleBomOutputsChange,
    handleAggregatedBomOutputDelete,
    handleBomOutputDelete,
  } = useBillOfMaterials({ outputsTypeBom });

  return (
    <>
      <Section data-testid={rfoModalTestIds.fileTypesStep.bomSection}>
        <Typography fontWeight="bold" variant="h3">
          {fileTypesStepText.billOfMaterials}
        </Typography>
        <Spacing />
        <Box>
          {outputsTypeBom.map((outputsByType, index) => {
            switch (outputsByType.type) {
              case OutputTypeWithVirtualTypes.BOM:
                return (
                  <BillOfMaterialDropdown
                    key={`${outputsByType.type}-${index}`}
                    label={fileTypesStepText.instanceLevelBOM}
                    modelStates={outputsByType.options.modelStates}
                    selectedModelStates={bomOutputs}
                    handleOnChange={handleBomOutputsChange}
                    handleDelete={handleBomOutputDelete}
                  />
                );
              case OutputTypeWithVirtualTypes.BOMAGGREGATED:
                return (
                  <BillOfMaterialDropdown
                    key={`${outputsByType.type}-${index}`}
                    label={fileTypesStepText.aggregatedBOM}
                    modelStates={outputsByType.options.modelStates}
                    selectedModelStates={aggregatedBomOutputs}
                    handleOnChange={handleAggregatedBomOutputsChange}
                    handleDelete={handleAggregatedBomOutputDelete}
                  />
                );
            }
          })}
        </Box>
      </Section>
      <Divider />
    </>
  );
};

export default BillOfMaterials;
