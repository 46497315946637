import AccountProjectContext from 'context/AccountProjectStore/AccountProject.context';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootNodeErrorReason, rootNodeErrorReasons } from '../../Commons/FoldersTree/useFoldersTree';

export interface UseModelFolderBrowser {
  errorReason: RootNodeErrorReason | null;
}

const useModelFolderBrowser = ({ errorReason }: UseModelFolderBrowser): void => {
  const { clearProjectData } = useContext(AccountProjectContext);
  const navigate = useNavigate();

  useEffect(() => {
    // If folder doesn't exists or returns an error, go back to 'root' folder
    if (errorReason === rootNodeErrorReasons.NOT_ROOT) {
      navigate('..');
    }

    if (errorReason === rootNodeErrorReasons.ROOT) {
      // If getting the 'root' folder of a project returns an error
      // ask the user to select a new project (fixes: TRADES-4141)
      clearProjectData({ updateLocalStorage: true });
      navigate('/');
    }
  }, [clearProjectData, errorReason, navigate]);
};

export default useModelFolderBrowser;
