import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';

export const ProjectListPopoverContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.var.paddingBase}`}px;
  transition: all 0.5s ease-in-out;
  max-height: ${({ theme }) => `${theme.var.outputProjectDropdownMaxViewHeight}`};
  width: ${({ theme }) => `${theme.var.outputProjectDropdownWidth}`}px;
`;

export const ProjectList = styled(List)`
  overflow: auto;
`;

export const ProjectHeaderContainer = styled(Box)`
  cursor: pointer;
  overflow: hidden;
`;

export const ProjectTitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => `${theme.var.outputAccountProjectGap}`}px;
  flex-grow: 10;
  overflow: hidden;
`;

export const ProjectListLoading = styled(LinearProgress)`
  width: 100%;
`;

export const ProjectListTitle = styled(Typography)`
  margin: ${({ theme }) => `${theme.var.marginBase}`}px;
`;
