import { MenuItem, css, styled } from '@mui/material';
import Menu from '@mui/material/Menu';

// Set pointer events to 'none' to prevent the invisible Popover div
// from capturing events for clicks and hovers
export const EventlessMenu = styled(Menu)`
  pointer-events: none;
`;

export const SubMenuWithEventWrapper = styled('div')`
  pointer-events: auto;
`;

export const StyledMenuItem = styled(MenuItem)<{ minWidth?: number }>`
  display: flex;
  justify-content: space-between;
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `}
`;
