import AppBar from '@mui/material/AppBar';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const TopHeader = styled(AppBar)`
  position: relative;
  padding: ${({ theme }) => `${theme.var.paddingBase / 2}px`} ${({ theme }) => `${theme.var.paddingBase * 1.5}px`};
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: none;
  height: ${({ theme }) => `${theme.var.headerHeight}px`};
  z-index: 0;
`;

export const Title = styled(Typography)`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LeftSection = styled('div')`
  display: flex;
  flex-direction: row;
  white-space: pre;
  gap: ${({ theme }) => `${theme.var.paddingBase}px`};
`;

export const RightSection = styled('div')`
  margin-left: auto;
`;

export const Hyperlink = styled(Link)<{ component: string }>`
  ${({ theme }) => theme.typography.h1}
  vertical-align: baseline;
`;
