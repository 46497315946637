import { SpinnerForButton } from '@mid-react-common/common';
import { WarningRounded } from '@mui/icons-material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import IconButton from '@mui/material/IconButton/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { useTheme } from '@mui/material/styles';
import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { foldersTreeTestIds } from '../../../tests/helpers/dataTestIds';
import {
  CreateFolderInput,
  InputButton,
  MainContainer,
  NewFolderInputContainer,
  SubFolderCreationInfoContainer,
} from './FoldersTree.styles';

interface CreateNewFolderProps {
  onNewFolderNameConfirmation: (folderName: string) => void;
  onNewFolderNameCancellation: () => void;
  folderCreationError?: string;
  folderCreationRequestLoading?: boolean;
  resetSubFolderCreationError?: () => void;
}

const CreateNewFolder: React.FC<CreateNewFolderProps> = ({
  onNewFolderNameConfirmation,
  onNewFolderNameCancellation,
  folderCreationRequestLoading,
  folderCreationError,
  resetSubFolderCreationError,
}) => {
  const [newFolderName, setNewFolderName] = useState<string>('');
  const theme = useTheme();

  const onFolderNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewFolderName(e.target.value);
    // We want to reset the folderName associated error
    // if the user updates the name in any way
    if (folderCreationError && resetSubFolderCreationError) {
      resetSubFolderCreationError();
    }
  };

  const onConfirmationButtonClick = () => {
    if (newFolderName) {
      onNewFolderNameConfirmation(newFolderName);
    }
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && newFolderName) {
      onNewFolderNameConfirmation(newFolderName);
    }
  };

  return (
    <MainContainer data-testid={foldersTreeTestIds.subFolderCreationMainContainer}>
      <NewFolderInputContainer>
        <CreateFolderInput
          autoFocus
          value={newFolderName}
          onChange={onFolderNameChange}
          data-testid={foldersTreeTestIds.newFolderInput}
          onKeyDown={onKeyDown}
        />
        <InputButton>
          <CloseOutlinedIcon fontSize="small" onClick={onNewFolderNameCancellation} />
        </InputButton>
        <InputButton
          data-testid={foldersTreeTestIds.confirmNewFolderButton}
          disabled={!newFolderName || folderCreationRequestLoading || !!folderCreationError}
        >
          <DoneOutlinedIcon fontSize="small" onClick={onConfirmationButtonClick} />
        </InputButton>
      </NewFolderInputContainer>
      <SubFolderCreationInfoContainer>
        {folderCreationRequestLoading && (
          <SpinnerForButton
            size={theme.var.smallButtonSpinnerSize}
            data-testid={foldersTreeTestIds.subFolderCreationLoadingSpinner}
            color="inherit"
          />
        )}
        {folderCreationError && (
          <Tooltip title={folderCreationError}>
            <IconButton data-testid={foldersTreeTestIds.subFolderCreationErrorIcon}>
              <WarningRounded color={'error'} />
            </IconButton>
          </Tooltip>
        )}
      </SubFolderCreationInfoContainer>
    </MainContainer>
  );
};

export default CreateNewFolder;
