import { Environment } from 'mid-types';
import { ServiceConfigMap } from 'mid-utils';

export const getFusionTeamUrl = (projectId: string, folderUrn: string, env: Environment): string => {
  // Base64 decoding Fusion project id gets you following format
  // business:<Fusion sub project domain>#<Fusion project numeric id>
  const DECODED_PROJECT_PREFIX = 'business:';

  const decodedProjectId = atob(projectId);
  const [projectSubDomain, projectNumericId] = decodedProjectId.replace(DECODED_PROJECT_PREFIX, '').split('#');

  // Folder urn is based64 encoded in the url
  const encodedUrn = btoa(folderUrn);

  return `https://${projectSubDomain}.${ServiceConfigMap.FUSION_TEAM_WEBAPP[env].api}/${projectNumericId}/data/${encodedUrn}`;
};
