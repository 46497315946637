import { AccBridgeIconWithTooltip, FlexContainer } from '@mid-react-common/common';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { getForgeApiServiceInstance } from 'mid-api-services';
import text from '../../global/text.json';
import { AccBridgeDataForIconsWithTooltip } from 'mid-types';
import { useTheme } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { GET_FOLDER_KEY } from 'global/constants/reactQueryKeys';

const releasesText = text.releases;

interface ReleaseFolderNameProps {
  tenancyId: string;
  folderUrn: string;
  accBridgeData: AccBridgeDataForIconsWithTooltip;
}

const ReleaseFolderName: React.FC<ReleaseFolderNameProps> = ({ tenancyId, folderUrn, accBridgeData }) => {
  const theme = useTheme();

  const {
    data: folderData,
    isPending: isLoadingFolder,
    isError: hasErrorLoadingFolder,
  } = useQuery({
    queryKey: [GET_FOLDER_KEY, tenancyId, folderUrn],
    queryFn: async ({ signal }) => getForgeApiServiceInstance().getFolder(tenancyId, folderUrn, signal),
  });

  if (isLoadingFolder) {
    return <Skeleton width="50%" />;
  }

  if (hasErrorLoadingFolder) {
    return <Typography color="error">{releasesText.failedToFetchFolder}</Typography>;
  }

  return (
    <FlexContainer>
      <Typography lineHeight={1.2} marginRight={`${theme.var.marginBase / 2}px`}>
        {folderData.title}
      </Typography>
      <AccBridgeIconWithTooltip accBridgeData={accBridgeData} selectedProjectId={tenancyId} />
    </FlexContainer>
  );
};

export default ReleaseFolderName;
