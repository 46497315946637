// taken from official docs, modified to match real world data

export interface AccProject {
  id: string;
  accountId: string;
  addressLine1?: string;
  addressLine2?: string;
  adminGroupId?: string;
  businessUnitId?: any;
  city?: string;
  classification?: string;
  companyCount?: number;
  constructionType?: string | null;
  contractType?: string | null;
  country: string;
  createdAt?: string;
  currentPhase?: any;
  deliveryMethod?: string | null;
  endDate?: string | null;
  imageUrl?: string;
  jobNumber?: string;
  lastSignIn?: string;
  latitude?: string;
  longitude?: string;
  memberCount?: number;
  memberGroupId?: string;
  name: string;
  platform: AccProjectPlatform;
  postalCode?: string;
  projectValue?: AccProjectValue;
  products?: AccProduct[];
  sheetCount?: any;
  startDate?: string | null;
  stateOrProvince?: string;
  status?: string;
  thumbnailImageUrl: string;
  timezone?: string;
  type?: string;
  updatedAt?: string;
}

export interface AccProjectValue {
  value?: number | null;
  currency: string;
}

export interface AccProduct {
  icon: string;
  key: string;
  language?: string;
  name: string;
  status: string;
}

export const accProjectPlatform = {
  bim360: 'bim360',
  acc: 'acc',
} as const;

export type AccProjectPlatform = (typeof accProjectPlatform)[keyof typeof accProjectPlatform];
export interface ProjectUser {
  id: string;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  accessLevels: {
    accountAdmin: boolean;
    projectAdmin: boolean;
    executive: boolean;
  };
}

export interface MetaInfo {
  id: string;
  name: string;
  platform?: AccProjectPlatform;
}

export type MetaInfoPath = MetaInfo & { parentPath: MetaInfo[] }; // ParentPath is the full path to the MetaInfo.ID from Root
