import Divider from '@mui/material/Divider';
import React from 'react';
import { ContentWrapper } from './Licensing.styles';
import Introduction from './Sections/Introduction';
import LicensedComponents from './Sections/LicensedComponents/LicensedComponents';
import Patents from './Sections/Patents';
import Privacy from './Sections/Privacy';
import Trademarks from './Sections/Trademarks';

const LicensingContent = React.forwardRef((_, ref) => (
  <ContentWrapper ref={ref}>
    <Introduction />
    <Divider />
    <Privacy />
    <Divider />
    <Trademarks />
    <Divider />
    <Patents />
    <Divider />
    <LicensedComponents />
    <Divider />
  </ContentWrapper>
));

export default LicensingContent;
