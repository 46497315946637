import lodash from 'lodash';
import {
  ProductRelease,
  PostVariantOutput,
  PostVariantPayload,
  OutputType,
  PostVariantInput,
  VariantInput,
  VariantBomOutput,
} from '@adsk/offsite-dc-sdk';
import { OutputTypeWithVirtualTypes, PostVariantOutputWithVirtualTypes } from 'mid-types';

// the list of BOM outputs may contain a virtual output type "BOMAGGREGATED", this is a UI-only BOM output type, so it has
// to be transformed into a normal BOM Post Variant Output adding a number of BOMs to aggregate
export const normalizeBomOutputs = (
  allOutputs: PostVariantOutputWithVirtualTypes[],
  numberOfInstancesPerCurrentVariant: number,
): PostVariantOutput[] => {
  const bomOutputsAcc: PostVariantOutputWithVirtualTypes[] = [];
  const nonBomOutputsAcc: PostVariantOutput[] = [];
  const [bomOutputs, nonBomOutputs] = allOutputs.reduce(
    (acc, output) => {
      const [bomOutputs, nonBomOutputs] = acc;

      if (OutputTypeWithVirtualTypes.BOM === output.type || OutputTypeWithVirtualTypes.BOMAGGREGATED === output.type) {
        bomOutputs.push(output);
      } else {
        nonBomOutputs.push(output);
      }
      return acc;
    },
    [bomOutputsAcc, nonBomOutputsAcc],
  );

  const bomOutputsByModelState = Object.values(lodash.groupBy(bomOutputs, 'modelState'));

  const normalizedBomOutputs = bomOutputsByModelState.map((bomsGroup) => {
    const instanceBomOutput = bomsGroup.find((bom) => bom.type === OutputType.BOM);
    const aggregatedBomOutput = bomsGroup.find((bom) => bom.type === OutputTypeWithVirtualTypes.BOMAGGREGATED);

    const normalizedBomOutput: PostVariantOutput = {
      ...bomsGroup[0],
      type: OutputType.BOM,
      uploadBomOutput: false,
    } as VariantBomOutput;

    if (instanceBomOutput) {
      // uploadBomOutput means that API will generate bom per instance
      normalizedBomOutput.uploadBomOutput = true;
      normalizedBomOutput.numberOfBomsToAggregate = 0;
    }

    if (aggregatedBomOutput) {
      normalizedBomOutput.numberOfBomsToAggregate = numberOfInstancesPerCurrentVariant;
    }

    return normalizedBomOutput;
  });

  return [...nonBomOutputs, ...normalizedBomOutputs];
};

export const transformToVariantPayload = (
  variantName: string,
  variantInputs: VariantInput[],
  selectedOutputs: PostVariantOutputWithVirtualTypes[],
  numberOfInstancesPerCurrentVariant: number,
  release: number,
): PostVariantPayload => {
  const inputPayload: PostVariantInput[] = variantInputs.map((input: VariantInput) => {
    const variantInput: PostVariantInput = {
      name: input.name,
      value: input.value,
    };

    return variantInput;
  });

  return {
    variantName,
    inputs: inputPayload,
    outputs: normalizeBomOutputs(selectedOutputs, numberOfInstancesPerCurrentVariant),
    release,
  };
};

export const sortProductByName = (productA: ProductRelease, productB: ProductRelease): number => {
  if (productA.name < productB.name) {
    return -1;
  }

  if (productA.name > productB.name) {
    return 1;
  }

  return 0;
};
