import { Variant } from '@adsk/offsite-dc-sdk';
import {
  analytics,
  ANALYTICS_ACTIONS,
  MidWebAppMoniker,
  NOTIFICATION_STATUSES,
  NotificationContext,
} from '@mid-react-common/common';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getDcApiServiceInstance } from 'mid-api-services';
import { PostVariantOutputWithVirtualTypes } from 'mid-types';
import { logError } from 'mid-utils';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/legacy/legacyRoutes';
import ProductContext from '../../../context/ProductStore/Product.context';
import UploadLocationContext from '../../../context/UploadLocationStore/UploadLocation.context';
import text from '../../../global/text.json';
import { VariantInstanceAccBridgeMetadata } from '../../../types/bridge';
import {
  getBridgeDataFromInstance,
  getBridgeAutomationFromVariantId,
  transformInstancesToVariantBridgeMap,
} from '../../../utils/bridge';
import { transformToVariantPayload } from '../../OutputsPage/InstancesPanel/instancesPanel.utils';
import {
  GeneratingState,
  calculateCost,
  getButtonTextByGeneratingState,
  getVariantIdProductMap,
  updateProductUserConfiguration,
} from './useGenerateOutputs.utils';
import GatekeeperContext from 'context/GatekeeperStore/Gatekeeper.context';

const outputsText = text.useGenerateOutputs;
const productsText = text.productsStore;
const commonText = text.common;

export interface UseGenerateOutputsState {
  isOutputGenerationDisabled: boolean;
  generateButtonText: string;
  handleGenerateOutputsButtonClick: (selectedOutputs: PostVariantOutputWithVirtualTypes[]) => Promise<void>;
  isUploadLocationSet: boolean;
}

const useGenerateOutputs = (): UseGenerateOutputsState => {
  const [generatingState, setGeneratingState] = useState<GeneratingState>(GeneratingState.NONE);
  const navigate = useNavigate();

  const { uploadFolderUrn, uploadLocationProject } = useContext(UploadLocationContext);
  const { productReleases, selectedInstances, selectedProductRelease, instances } = useContext(ProductContext);
  const { sessionId } = useContext(GatekeeperContext);
  const { showNotification, logAndShowNotification } = useContext(NotificationContext);
  const { enableTokenCharge, useJobProcessingV2 } = useFlags();

  const selectedProductId = selectedProductRelease?.contentId || '';
  const selectedProjectId = selectedProductRelease?.tenancyId;

  const isUploadLocationSet = !!(uploadFolderUrn && uploadLocationProject && selectedProductId && selectedProjectId);

  const selectedVariantBridgeMap: Map<string, VariantInstanceAccBridgeMetadata> | undefined = useMemo(() => {
    if (!selectedInstances) {
      return;
    }

    return transformInstancesToVariantBridgeMap(selectedInstances);
  }, [selectedInstances]);

  const saveUserConfig = useCallback(
    async (selectedOutputs: PostVariantOutputWithVirtualTypes[]) => {
      if (!isUploadLocationSet || !selectedInstances) {
        return;
      }

      const accBridgeMetadataFromInstance = selectedInstances.find((instance) => instance.contentId === selectedProductId);
      const accBridgeData = accBridgeMetadataFromInstance ? getBridgeDataFromInstance(accBridgeMetadataFromInstance) : {};

      try {
        await updateProductUserConfiguration({
          selectedOutputs,
          uploadFolderUrn,
          uploadLocationAccountId: uploadLocationProject.accountId,
          uploadLocationProjectId: uploadLocationProject.id,
          selectedProjectId,
          selectedProductId,
          vendor: uploadLocationProject.vendor,
          incomingAccBridgeData: accBridgeData,
        });
      } catch (error) {
        logAndShowNotification({
          message: outputsText.failSaveConfigurations,
          error,
          logExtraData: {
            uploadLocationAccountId: uploadLocationProject.accountId,
            uploadLocationProjectId: uploadLocationProject.id,
            uploadFolderUrn,
            productId: selectedProductId,
          },
        });

        throw new Error('Failed to save user config.');
      }
    },
    [
      isUploadLocationSet,
      selectedInstances,
      uploadFolderUrn,
      uploadLocationProject?.accountId,
      uploadLocationProject?.id,
      selectedProductId,
      selectedProjectId,
      uploadLocationProject?.vendor,
      logAndShowNotification,
    ],
  );

  const checkTokenBalance = useCallback(
    async (requestedOutputsCount: number) => {
      try {
        setGeneratingState(GeneratingState.CHECKING_BALANCE);

        const canGenerateOutputsResult = await getDcApiServiceInstance().canGenerateOutputs(requestedOutputsCount);

        if (!canGenerateOutputsResult.canGenerateOutputs) {
          throw new Error(canGenerateOutputsResult.errorMessage);
        }
      } catch (error) {
        logAndShowNotification({
          message: '',
          error,
          logExtraData: { selectedProjectId, uploadFolderUrn, productId: selectedProductId },
        });

        throw new Error('Failed to check tokens balance.');
      }
    },
    [logAndShowNotification, selectedProjectId, selectedProductId, uploadFolderUrn],
  );

  const generateOutputs = useCallback(
    async (selectedOutputs: PostVariantOutputWithVirtualTypes[]) => {
      try {
        const variantIdProductMap = selectedInstances
          ? getVariantIdProductMap(selectedInstances, productReleases, selectedProductId)
          : {};

        const allGenerateOutputsJobs: Promise<void>[] = Object.keys(variantIdProductMap).map(async (currentVariantId) => {
          const currentProduct = variantIdProductMap[currentVariantId];
          try {
            const accBridgeData = getBridgeAutomationFromVariantId(currentVariantId, selectedVariantBridgeMap);

            const currentVariant = await getDcApiServiceInstance().getVariant({
              projectId: currentProduct.tenancyId,
              productId: currentProduct.contentId,
              variantId: currentVariantId,
              incomingAccBridgeData: accBridgeData,
            });

            // info required for aggregated BOM
            const numberOfInstancesPerCurrentVariant = selectedInstances!.filter(
              (selectedInstance) => selectedInstance.variantId === currentVariantId,
            ).length;

            // the variant name has to be taken from LMV instances, not from the variant object itself (otherwise all
            // outputs will come under the same folder with the name equals to the product name) TRADES-4912
            const variantName = instances?.find((instance) => instance.variantId === currentVariantId)?.variantName;

            // Transform to variant payload
            const postVariantPayload = transformToVariantPayload(
              variantName || currentVariant.name,
              currentVariant.inputs,
              selectedOutputs,
              numberOfInstancesPerCurrentVariant,
              currentVariant.release,
            );

            // Trigger Post Variant job processing
            const postVariantResponse: Variant = useJobProcessingV2
              ? await getDcApiServiceInstance().uploadVariantOutputs({
                  projectId: currentProduct.tenancyId,
                  productId: currentProduct.contentId,
                  postVariantPayload,
                  incomingAccBridgeData: accBridgeData,
                })
              : await getDcApiServiceInstance().postVariant({
                  projectId: currentProduct.tenancyId,
                  productId: currentProduct.contentId,
                  postVariantPayload,
                  incomingAccBridgeData: accBridgeData,
                });

            // Segment event
            analytics.track(ANALYTICS_ACTIONS.MIDW.generateOutputs, {
              session_id: sessionId,
              sourceMoniker: MidWebAppMoniker,
              projectId: currentProduct.tenancyId,
              productId: currentProduct.contentId,
              variantId: currentVariantId,
              outputTypes: selectedOutputs.map((output) => output.type),
              instancesPerVariant: numberOfInstancesPerCurrentVariant,
              outputsCount: selectedOutputs.length,
              releaseNumber: currentVariant.release,
            });

            showNotification({
              severity: NOTIFICATION_STATUSES.SUCCESS,
              message: `${outputsText.successTriggerJob} ${postVariantResponse.name}`,
            });
          } catch (error) {
            logAndShowNotification({
              message: outputsText.failTriggerJob,
              error,
              logExtraData: { projectId: currentProduct.tenancyId, productId: currentProduct.contentId, currentVariantId },
            });
          }
        });

        await Promise.all(allGenerateOutputsJobs);

        navigate(`../${routes.review.path}`);
      } catch {
        logAndShowNotification({
          message: productsText.failedToFetchAssociateProducts,
        });

        throw new Error('Failed to generate outputs.');
      }
    },
    [
      selectedInstances,
      productReleases,
      selectedProductId,
      navigate,
      selectedVariantBridgeMap,
      instances,
      useJobProcessingV2,
      sessionId,
      showNotification,
      logAndShowNotification,
    ],
  );

  const handleGenerateOutputsButtonClick = useCallback(
    async (selectedOutputs: PostVariantOutputWithVirtualTypes[]) => {
      if (selectedInstances && selectedInstances.length <= 0) {
        return logAndShowNotification({ message: outputsText.selectAtLeastOneInstance });
      }

      if (!selectedProductRelease) {
        return logAndShowNotification({ message: productsText.failedToFetchAssociateProducts });
      }

      if (!uploadLocationProject?.id || !uploadLocationProject?.accountId) {
        return logAndShowNotification({ message: commonText.noProjectSelected });
      }

      try {
        if (enableTokenCharge) {
          const outputsToGenerateCount = calculateCost(
            selectedOutputs,
            selectedInstances,
            productReleases,
            selectedProductId,
          );

          setGeneratingState(GeneratingState.CHECKING_BALANCE);

          await checkTokenBalance(outputsToGenerateCount);
        }

        setGeneratingState(GeneratingState.GENERATING);

        await saveUserConfig(selectedOutputs);

        await generateOutputs(selectedOutputs);
      } catch (error) {
        logError(error);
      } finally {
        setGeneratingState(GeneratingState.NONE);
      }
    },
    [
      selectedInstances,
      selectedProductRelease,
      uploadLocationProject?.id,
      uploadLocationProject?.accountId,
      logAndShowNotification,
      enableTokenCharge,
      saveUserConfig,
      generateOutputs,
      productReleases,
      selectedProductId,
      checkTokenBalance,
    ],
  );

  return {
    isOutputGenerationDisabled: generatingState !== GeneratingState.NONE,
    generateButtonText: getButtonTextByGeneratingState(generatingState),
    handleGenerateOutputsButtonClick,
    isUploadLocationSet,
  };
};

export default useGenerateOutputs;
