import { Spacing } from '@mid-react-common/common';
import Typography from '@mui/material/Typography';
import NeutralFormatDropdown from './NeutralFormatDropdown';
import Box from '@mui/material/Box';
import { DCNeutralOutputs, OutputTypeWithVirtualTypes } from 'mid-types';
import text from 'global/text.json';
import useNeutralFormat from './useNeutralFormat';
import { Section } from '../../RFOModal.styles';
import { rfoModalTestIds } from 'tests/helpers/dataTestIds';
const fileTypesStepText = text.rfoModal.fileTypesStep;

interface NeutralFormatProps {
  outputsTypeNeutralFormat: DCNeutralOutputs[];
}

const NeutralFormat: React.FC<NeutralFormatProps> = ({ outputsTypeNeutralFormat }) => {
  const {
    glbOutputs,
    satOutputs,
    stepOutputs,
    handleGlbOutputsChange,
    handleSatOutputsChange,
    handleStepOutputsChange,
    handleSatOutputDelete,
    handleStepOutputDelete,
    handleGlbOutputDelete,
  } = useNeutralFormat({ outputsTypeNeutralFormat });

  return (
    <>
      {outputsTypeNeutralFormat.length > 0 && (
        <Section data-testid={rfoModalTestIds.fileTypesStep.neutralFormatSection}>
          <Typography fontWeight="bold" variant="h3">
            {fileTypesStepText.neutralFormat}
          </Typography>
          <Spacing />
          <Box>
            {outputsTypeNeutralFormat.map((outputsByType, index) => {
              switch (outputsByType.type) {
                case OutputTypeWithVirtualTypes.SAT:
                  return (
                    <NeutralFormatDropdown
                      key={`${outputsByType.type}-${index}`}
                      label={fileTypesStepText.neutralSAT}
                      modelStates={outputsByType.options.modelStates}
                      selectedModelStates={satOutputs}
                      handleOnChange={handleSatOutputsChange}
                      handleDelete={handleSatOutputDelete}
                    />
                  );
                case OutputTypeWithVirtualTypes.STEP:
                  return (
                    <NeutralFormatDropdown
                      key={`${outputsByType.type}-${index}`}
                      label={fileTypesStepText.neutralStep}
                      modelStates={outputsByType.options.modelStates}
                      selectedModelStates={stepOutputs}
                      handleOnChange={handleStepOutputsChange}
                      handleDelete={handleStepOutputDelete}
                    />
                  );
                case OutputTypeWithVirtualTypes.GLB:
                  return (
                    <NeutralFormatDropdown
                      key={`${outputsByType.type}-${index}`}
                      label={fileTypesStepText.neutralGLB}
                      modelStates={outputsByType.options.modelStates}
                      selectedModelStates={glbOutputs}
                      handleOnChange={handleGlbOutputsChange}
                      handleDelete={handleGlbOutputDelete}
                    />
                  );
              }
            })}
          </Box>
        </Section>
      )}
    </>
  );
};

export default NeutralFormat;
