import { Vendors } from '@adsk/offsite-dc-sdk';
import {
  DataAndMeta,
  ForgeJsonApiListResponse,
  JSONAPIResponseBase,
  RelatedLink,
  SelfLink,
  WebviewLink,
} from './dmApi.types';

export const hubProjectTypes = {
  BIM360_OR_ACC: 'projects:autodesk.bim360:Project',
  FUSION: 'projects:autodesk.core:Project',
} as const;

export type HubProjectType = (typeof hubProjectTypes)[keyof typeof hubProjectTypes];

export type HubProjectLinks = {
  links: (SelfLink & WebviewLink) | SelfLink;
  relationships: {
    hub: {
      data: {
        type: string;
        id: string;
      };
      links: RelatedLink;
    };
    rootFolder: DataAndMeta;
    topFolders: {
      links: RelatedLink;
    };
    issues?: DataAndMeta;
    submittals?: DataAndMeta;
    rfis?: DataAndMeta;
    markups?: DataAndMeta;
    checklists?: DataAndMeta;
    cost?: DataAndMeta;
    locations?: DataAndMeta;
  };
};

export type FusionProjectExtension = {
  type: typeof hubProjectTypes.FUSION;
  version: string;
  schema: {
    href: string;
  };
  data: object;
};

export type Bim360AccProjectExtension = {
  type: typeof hubProjectTypes.BIM360_OR_ACC;
  version: string;
  schema: {
    href: string;
  };
  data: { projectType: Vendors.ACC | Vendors.BIM360 };
};

export type HubProject = {
  type: 'projects';
  id: string;
  attributes: {
    name: string;
    scopes: string[];
    extension: FusionProjectExtension | Bim360AccProjectExtension;
  };
} & HubProjectLinks;

export type HubProjectListResponse = ForgeJsonApiListResponse<HubProject>;

export type HubProjectResponse = JSONAPIResponseBase & {
  data?: HubProject;
};
