import { FC, useContext, useEffect, useRef } from 'react';
import AccountProjectContext from '../../../context/AccountProjectStore/AccountProject.context';
import ModelSelectionContext from '../../../context/ModelSelectionStore/ModelSelection.context';
import { MODEL_VIEWER_TIMER } from '../../../global/constants/model';
import viewerService from '../../../services/viewer/viewerService';
import { ViewerWrapper } from './ModelViewer.style';
import GateKeeperContext from '../../../context/GatekeeperStore/Gatekeeper.context';

export const ModelViewer: FC = () => {
  const viewerRef = useRef<HTMLDivElement>(null);
  const { currentlyOpenModel: currentlyOpenModel } = useContext(ModelSelectionContext);
  const { getFreshToken } = useContext(GateKeeperContext);

  const { projectId } = useContext(AccountProjectContext);

  useEffect(() => {
    // prevent unnecessary initializing/destructuring of LMV
    if (!currentlyOpenModel?.lmvModelFileId || !projectId) {
      return;
    }

    async function initialize() {
      if (viewerRef.current) {
        viewerRef.current.appendChild(viewerService.viewerNode);
        viewerService.resizeViewer();
      }

      if (!viewerService.initialized) {
        const token = await getFreshToken();
        await viewerService.initializeViewer({
          documentId: currentlyOpenModel?.lmvModelFileId,
          projectId: projectId || '',
          getAccessToken: (get) => {
            get(token, MODEL_VIEWER_TIMER);
          },
        });
      }
    }

    initialize();

    // destructuring of the LMV before closing the page to prevent issue TRADES-4035
    return () => {
      // without this timeout 0 there is an issue with LMV infinite loop (LMV has its own internal timeout), so here
      // it's required to have a micro delay (execute the destruction process with the next event loop tick)
      if (!viewerService.initialized) {
        return;
      }
      setTimeout(() => {
        viewerService.initialized = false;

        viewerService.finish();
        viewerService.shutdown();

        viewerService.recreateViewer();
        // if the documentId is not reset, the LMV will think that it has to be loaded with the same model, so it won't start
        // the process of model loading
        viewerService.documentId = undefined;
      }, 0);
    };
  }, [currentlyOpenModel?.lmvModelFileId, projectId, getFreshToken]);

  useEffect(() => {
    if (projectId && currentlyOpenModel?.lmvModelFileId && viewerService.initialized) {
      // Load document
      viewerService.loadDocument(projectId, currentlyOpenModel?.lmvModelFileId);
    }
  }, [projectId, currentlyOpenModel?.lmvModelFileId]);
  return <ViewerWrapper ref={viewerRef} />;
};
