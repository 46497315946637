import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const PageWrapper = styled(Box)`
  height: calc(100vh - ${({ theme }) => theme.var.midwMainHeader}px);
  overflow: hidden;
`;

export const BodyWrapper = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.var.marginBase * 10}px;
  margin: ${({ theme }) => theme.var.marginBase * 5}px;
  height: calc(
    100vh - ${({ theme }) => theme.var.midwMainHeader + theme.var.licensingPageHeaderHeight + theme.var.marginBase * 5 * 2}px
  );
`;

export const HeaderWrapper = styled(Box)`
  height: ${({ theme }) => theme.var.licensingPageHeaderHeight}px;
  display: flex;
  padding-left: ${({ theme }) => theme.var.paddingBase * 5}px;
  align-items: center;
`;

export const LegendWrapper = styled(Box)`
  flex: 1;
`;

export const ContentWrapper = styled(Box)`
  flex: 4;
  overflow: auto;
`;

export const ContentSectionWrapper = styled(Box)`
  padding: ${({ theme }) => theme.var.paddingBase * 5}px 0 ${({ theme }) => theme.var.paddingBase * 2}px 0;
`;

export const PrintWrapper = styled(Box)`
  flex: 1;
`;

export const ThirdPartyLicenseBlock = styled(Box)`
  padding: ${({ theme }) => theme.var.paddingBase}px 0 ${({ theme }) => theme.var.paddingBase * 2}px;
`;

export const ThirdPartyComponentsList = styled(Box)`
  padding-left: ${({ theme }) => theme.var.paddingBase}px;
`;

export const LicensingTextWrapper = styled(Box)`
  padding: ${({ theme }) => theme.var.paddingBase}px;
  border-radius: ${({ theme }) => theme.var.borderRadius}px;
  width: 75%;
`;
