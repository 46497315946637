import {
  OutputType,
  PostProductPayload,
  PutProductPayload,
  DCInput,
  DCOutput,
  DCInputNumericType,
  DCInputBooleanType,
  DCInputTextType,
  InputType,
} from '@adsk/offsite-dc-sdk';
import {
  InventorParameter,
  arrayToNumericArray,
  getInputTypeFromInventorParameter,
} from '../../interfaces/inventorProperties';
import {
  ProductDefinition,
  ProductDefinitionInputParameter,
  ProductDefinitionOutput,
} from '../../interfaces/productDefinitions';
import { MetaInfoPath, ProductDefinitionEngine, productDefinitionEngines } from 'mid-types';
import text from '../../mid-addin-lib.text.json';
import { browserApiService } from '../../services';
import { ThumbnailError } from 'mid-utils';
import PublishUtils from './publishUtils';

export const getThumbnailImgPath = async (iamPath: string): Promise<string> => {
  const result = await browserApiService.getThumbnailImage(iamPath);
  if (result.value === null) {
    throw new ThumbnailError(text.notificationThumbnailFailed, {
      error: Error(result.errorMessage!),
    });
  }

  return result.value;
};

export const prependTopLevelFolderToPath = (productDefinitionTopLevelFolder: string, file: string): string => {
  const topLevelFolderPath = productDefinitionTopLevelFolder.replace(/\//g, '\\');
  const datasetFolderName = topLevelFolderPath.substring(topLevelFolderPath.lastIndexOf('\\') + 1);

  // Rely on the fact that the productDefinition's assembly path begins with a directory separator.
  return `${datasetFolderName}${file.replace(/\//g, '\\')}`;
};

export const getPathSeparator = (path: string): '\\' | '/' => (/\\/.test(path) ? '\\' : '/');

export const createFullPath = (topLevelFolder: string, relativePathToFile: string): string => {
  const separator = getPathSeparator(topLevelFolder);
  const rawJoinedPath = [topLevelFolder, relativePathToFile].join(separator);
  const normalizedPath = separator === '\\' ? rawJoinedPath.replace(/\\\\/g, '\\') : rawJoinedPath.replace(/\/\//g, '/');
  return normalizedPath;
};

export type DCProduct = PostProductPayload & { tenancyId: string };

export type ProductDefinitionToPostProductPayloadParams = {
  productDefinition: ProductDefinition;
  thumbnail: string;
  datasetUrn: string;
  engineVersion: string;
  codeBlocksWorkspaceKey?: string;
  rulesKey?: string;
  engine?: ProductDefinitionEngine;
  workspaceLocation?: string;
  isConfigurable?: boolean;
};

export const productDefinitionToPostOrPutProductPayload = ({
  productDefinition,
  thumbnail,
  datasetUrn,
  engineVersion,
  codeBlocksWorkspaceKey,
  rulesKey,
  engine = productDefinitionEngines.INVENTOR,
  workspaceLocation = 'BIMDOCS',
  isConfigurable,
}: ProductDefinitionToPostProductPayloadParams): PostProductPayload | PutProductPayload => ({
  name: productDefinition.name,
  schemaVersion: 1,
  dataSetLocation: datasetUrn,
  thumbnail,
  context: {
    projectFile: productDefinition.inventorProject,
    // The  DA4I plugin expects the assembly path to include the top-folder name,
    // e.g. "Wall w Door\\Wall w Door.iam".
    topLevelAssembly: prependTopLevelFolderToPath(productDefinition.topLevelFolder, productDefinition.assembly),
    engine: {
      location: engine,
      version: engineVersion,
    },
    workspace: {
      location: workspaceLocation,
      folderPath: getFullFolderPath(productDefinition.folder),
    },
  },
  rulesKey,
  codeBlocksWorkspaceKey,
  inputs: productDefinition.inputs.map(transformProductDefinitionInputToDCInput),
  outputs: productDefinition.outputs.map((output) =>
    transformProductDefinitionOutputToDCOutput(output, productDefinition.topLevelFolder),
  ),
  notes: productDefinition.notes,
  isConfigurable,
});

export const transformProductDefinitionInputToDCInput = (
  productDefinitionInput: ProductDefinitionInputParameter,
): DCInput => {
  try {
    switch (productDefinitionInput.type) {
      case InputType.BOOLEAN:
        return {
          type: productDefinitionInput.type,
          value: productDefinitionInput.value,
          trueLabel: productDefinitionInput.trueLabel,
          falseLabel: productDefinitionInput.falseLabel,
          name: productDefinitionInput.name,
          label: productDefinitionInput.label,
          readOnly: productDefinitionInput.readOnly,
          visible: productDefinitionInput.visible,
          onChange: productDefinitionInput.onChange,
          applicable: productDefinitionInput.applicable,
        };
      case InputType.NUMERIC:
        return {
          type: productDefinitionInput.type,
          value: productDefinitionInput.value,
          min: productDefinitionInput.min,
          max: productDefinitionInput.max,
          increment: productDefinitionInput.increment,
          values: productDefinitionInput.values,
          allowCustomValue: productDefinitionInput.allowCustomValue,
          unit: productDefinitionInput.unit,
          name: productDefinitionInput.name,
          label: productDefinitionInput.label,
          readOnly: productDefinitionInput.readOnly,
          visible: productDefinitionInput.visible,
          onChange: productDefinitionInput.onChange,
          applicable: productDefinitionInput.applicable,
        };
      case InputType.TEXT:
        return {
          type: productDefinitionInput.type,
          value: productDefinitionInput.value,
          values: productDefinitionInput.values,
          allowCustomValue: productDefinitionInput.allowCustomValue,
          unit: productDefinitionInput.unit,
          name: productDefinitionInput.name,
          label: productDefinitionInput.label,
          readOnly: productDefinitionInput.readOnly,
          visible: productDefinitionInput.visible,
          applicable: productDefinitionInput.applicable,
        };
      default:
        throw Error(`Unknown type = ${productDefinitionInput}`);
    }
  } catch (error: unknown) {
    throw Error('Failed in transformProductDefinitionInputToDCInput()');
  }
};

export const transformProductDefinitionOutputToDCOutput = (
  productDefinitionOutput: ProductDefinitionOutput,
  productDefinitionTopLevelFolder: string,
): DCOutput => {
  try {
    switch (productDefinitionOutput.type) {
      case OutputType.IAM:
        return {
          type: productDefinitionOutput.type,
        };
      case OutputType.BOM:
      case OutputType.GLB:
      case OutputType.STEP:
      case OutputType.STL:
      case OutputType.SAT:
      case OutputType.THUMBNAIL:
        return {
          type: productDefinitionOutput.type,
          options: {
            modelStates: productDefinitionOutput.options?.modelStates ?? [],
          },
        };
      case OutputType.DWG:
      case OutputType.IDW:
        return {
          type: productDefinitionOutput.type,
          options: {
            drawingTemplatePath: productDefinitionOutput.options?.drawingTemplatePath
              ? prependTopLevelFolderToPath(
                  productDefinitionTopLevelFolder,
                  productDefinitionOutput.options.drawingTemplatePath,
                )
              : '', // Align folder structure the same as topLevelAssembly field in DC Output
            excludeIntellectualProperty: true, // ToDo: add excludeIntellectualProperty support in productDefinitionOutput
          },
        };
      case OutputType.PDF:
        return {
          type: productDefinitionOutput.type,
          options: {
            drawingTemplatePath: productDefinitionOutput.options?.drawingTemplatePath
              ? prependTopLevelFolderToPath(
                  productDefinitionTopLevelFolder,
                  productDefinitionOutput.options.drawingTemplatePath,
                )
              : '', // Align folder structure the same as topLevelAssembly field in DC Output
          },
        };
      case OutputType.RFA:
        return {
          type: productDefinitionOutput.type,
          options: {
            modelStates: productDefinitionOutput.options?.modelStates ?? [],
            family: productDefinitionOutput.options?.family ?? '',
            category: productDefinitionOutput.options?.category ?? '',
          },
        };
      default:
        throw Error(`Unknow type = ${productDefinitionOutput.type}`);
    }
  } catch (error: unknown) {
    throw Error('Failed in transformProductDefinitionOutputToDCOutput()');
  }
};

export const toProductDefinitionInputParameter = (param: InventorParameter): ProductDefinitionInputParameter => {
  const type = getInputTypeFromInventorParameter(param);

  switch (type) {
    case InputType.BOOLEAN: {
      const input: DCInputBooleanType = {
        type,
        visible: true,
        readOnly: false,
        label: param.label ?? '',
        name: param.name,
        value: /true/i.test(param.value),
        onChange: [],
      };

      return input;
    }
    case InputType.TEXT: {
      const input: DCInputTextType = {
        type,
        value: param.value,
        values: param.options ? (param.options as string[]) : undefined,
        unit: param.unitType,
        name: param.name,
        label: param.label ?? '',
        visible: true,
        readOnly: false,
        allowCustomValue: true,
      };

      return input;
    }
    default: {
      const input: DCInputNumericType = {
        type: InputType.NUMERIC,
        visible: true,
        readOnly: false,
        label: param.label ?? '',
        name: param.name,
        value: PublishUtils.truncateDecimals(Number(param.value)),
        values: param.options ? arrayToNumericArray(param.options) : undefined,
        unit: param.unitType,
        onChange: [],
        allowCustomValue: true,
      };

      return input;
    }
  }
};

export const getFullFolderPath = (metaInfo: MetaInfoPath): string =>
  [...(metaInfo.parentPath?.map((p) => p.id) ?? []), metaInfo.id]?.join('/');

export const getFullFolderNamedPath = (metaInfo: MetaInfoPath): string =>
  [...(metaInfo.parentPath?.map((p) => p.name) ?? []), metaInfo.name]?.join('/');
