import TableFooter from '@mui/material/TableFooter';
import { RFOFooterTypography, RFOTableCellWrapper, RFOTableRowWrapper } from '../CheckoutStep.styles';
import text from 'global/text.json';
const checkoutStepText = text.rfoModal.checkoutStep;

interface OutputsTableFooterProps {
  totalQuantity: number;
  totalCostInUnicodeValue: string;
  webappTokenCostStrikethrough: boolean;
}

const OutputsTableFooter: React.FC<OutputsTableFooterProps> = ({
  totalQuantity,
  totalCostInUnicodeValue,
  webappTokenCostStrikethrough,
}) => (
  <TableFooter>
    <RFOTableRowWrapper>
      <RFOTableCellWrapper isFooter align="left">
        <RFOFooterTypography fontWeight="bold">{checkoutStepText.orderTotalFooter}</RFOFooterTypography>
      </RFOTableCellWrapper>
      <RFOTableCellWrapper isFooter align="right">
        <RFOFooterTypography>{totalQuantity}</RFOFooterTypography>
      </RFOTableCellWrapper>
      <RFOTableCellWrapper isFooter align="right">
        {webappTokenCostStrikethrough && (
          <RFOFooterTypography display="inline" sx={{ textDecoration: 'line-through' }}>
            {totalCostInUnicodeValue}
          </RFOFooterTypography>
        )}{' '}
        <RFOFooterTypography display="inline">0</RFOFooterTypography>
      </RFOTableCellWrapper>
    </RFOTableRowWrapper>
  </TableFooter>
);

export default OutputsTableFooter;
