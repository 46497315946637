import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import text from 'global/text.json';
import useModel3D from './useModel3D';
import { Section } from '../../RFOModal.styles';
import { Divider } from '@mui/material';
import { rfoModalTestIds } from 'tests/helpers/dataTestIds';
const fileTypesStepText = text.rfoModal.fileTypesStep;

const Model3D: React.FC = () => {
  const theme = useTheme();
  const { handleInventorDataSetChange, isIamSelected } = useModel3D();

  return (
    <>
      <Section data-testid={rfoModalTestIds.fileTypesStep.model3DSection}>
        <Typography fontWeight="bold" variant="h3" gutterBottom>
          {fileTypesStepText.model3d}
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={isIamSelected} onChange={handleInventorDataSetChange} />}
            label={<Typography color={theme.palette.text.secondary}>{fileTypesStepText.model3dCheckboxLabel}</Typography>}
          />
          <Typography color={theme.palette.text.secondary}>{fileTypesStepText.model3dInfo}</Typography>
        </FormGroup>
      </Section>
      <Divider />
    </>
  );
};

export default Model3D;
