import { DCBomOutputs, OutputTypeWithVirtualTypes } from 'mid-types';
import { useShallow } from 'zustand/react/shallow';
import { SelectChangeEvent } from '@mui/material/Select';
import useRFOModalStore from 'context/RFOModalStore/useRFOModalStore';
import { handleModelStateChange, handleModelStateDelete } from '../FileTypesStep.utils';
import { useEffect } from 'react';

interface UseBillOfMaterialsProps {
  outputsTypeBom: DCBomOutputs[];
}

interface UseBillOfMaterialsState {
  bomOutputs: string[];
  aggregatedBomOutputs: string[];
  handleBomOutputsChange: (event: SelectChangeEvent<string[]>) => void;
  handleAggregatedBomOutputsChange: (event: SelectChangeEvent<string[]>) => void;
  handleBomOutputDelete: (event: any, value: string) => void;
  handleAggregatedBomOutputDelete: (event: any, value: string) => void;
}

const useBillOfMaterials = ({ outputsTypeBom }: UseBillOfMaterialsProps): UseBillOfMaterialsState => {
  const { aggregatedBomOutputs, allOutputs, bomOutputs, setAggregatedBomOutputs, setAllOutputs, setBomOutputs } =
    useRFOModalStore(
      useShallow((state) => ({
        bomOutputs: state.bomOutputs,
        setBomOutputs: state.setBomOutputs,
        aggregatedBomOutputs: state.aggregatedBomOutputs,
        setAggregatedBomOutputs: state.setAggregatedBomOutputs,
        allOutputs: state.allOutputs,
        setAllOutputs: state.setAllOutputs,
      })),
    );

  // Need to clear the selected aggregated boms whenever it no longer applies
  useEffect(() => {
    const hasAggregatedBomOutputs = !!outputsTypeBom.find(
      (outputByType) => outputByType.type === OutputTypeWithVirtualTypes.BOMAGGREGATED,
    );
    const otherOutputs = allOutputs.filter((output) => output.type !== OutputTypeWithVirtualTypes.BOMAGGREGATED);
    if (aggregatedBomOutputs.length && !hasAggregatedBomOutputs) {
      setAggregatedBomOutputs([]);
      setAllOutputs(otherOutputs);
    }
  }, [aggregatedBomOutputs.length, allOutputs, outputsTypeBom, setAggregatedBomOutputs, setAllOutputs]);

  const handleBomOutputDelete = (event: any, value: string) => {
    handleModelStateDelete(value, OutputTypeWithVirtualTypes.BOM, bomOutputs, allOutputs, setBomOutputs, setAllOutputs);
  };

  const handleAggregatedBomOutputDelete = (event: any, value: string) => {
    handleModelStateDelete(
      value,
      OutputTypeWithVirtualTypes.BOMAGGREGATED,
      aggregatedBomOutputs,
      allOutputs,
      setAggregatedBomOutputs,
      setAllOutputs,
    );
  };

  const handleBomOutputsChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    handleModelStateChange(
      value,
      OutputTypeWithVirtualTypes.BOM,
      bomOutputs,
      outputsTypeBom,
      allOutputs,
      setBomOutputs,
      setAllOutputs,
    );
  };

  const handleAggregatedBomOutputsChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    handleModelStateChange(
      value,
      OutputTypeWithVirtualTypes.BOMAGGREGATED,
      aggregatedBomOutputs,
      outputsTypeBom,
      allOutputs,
      setAggregatedBomOutputs,
      setAllOutputs,
    );
  };

  return {
    bomOutputs,
    aggregatedBomOutputs,
    handleAggregatedBomOutputsChange,
    handleBomOutputsChange,
    handleAggregatedBomOutputDelete,
    handleBomOutputDelete,
  };
};

export default useBillOfMaterials;
