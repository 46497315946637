import { CustomError } from './customError';
import { ErrorCode } from './errorCode';

type CauseType = {
  url?: string;
  error?: Error;
};

export class InvalidURLError extends CustomError {
  errorCode = ErrorCode.InvalidURLError;

  constructor(message: string, cause?: CauseType) {
    super(message, cause);
  }
}
