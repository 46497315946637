import { ProductData } from 'utils/types';
import { extensionName } from 'utils/consts';
import { generateDomId } from 'utils/common';
import { useThemeStore } from 'stores/ThemeStore';
import { FormLmvElement } from 'components/Form/Form.lmvElement';
import { ReleaseDetailsLmvElement } from 'components/ReleaseDetails/ReleaseDetails.lmvElement';

export class InformedDesignExtension extends Autodesk.Viewing.Extension {
  productData: ProductData | undefined;
  subToolbar: Autodesk.Viewing.UI.ControlGroup | undefined;

  formLmvElement: FormLmvElement;
  releaseDetailsLmvElement: ReleaseDetailsLmvElement;

  constructor(viewer: any, options: any) {
    super(viewer, options);

    this.setTheme(viewer.theme);

    this.formLmvElement = new FormLmvElement(viewer);
    this.releaseDetailsLmvElement = new ReleaseDetailsLmvElement(viewer);
  }

  _validateProductData(productData: ProductData | undefined): void {
    if (
      !productData ||
      !productData.accountId ||
      !productData.projectId ||
      !productData.releaseId ||
      !productData.releaseVersion
    ) {
      throw new Error('Invalid product data.');
    }
  }

  _log(...args: any[]): void {
    console.log.apply(console, [`${extensionName}:`, ...args]);
  }

  // this lifecycle method is automatically called by LMV
  onToolbarCreated(toolbar: Autodesk.Viewing.UI.ToolBar): void {
    this.subToolbar = new Autodesk.Viewing.UI.ControlGroup(generateDomId('toolbarControlGroup'));

    // add all buttons here
    this.subToolbar.addControl(this.releaseDetailsLmvElement.getButton());
    this.subToolbar.addControl(this.formLmvElement.getButton());

    toolbar.addControl(this.subToolbar);
  }

  setProductData(productData: ProductData): void {
    this._validateProductData(productData);

    this.productData = { ...productData };
  }

  loadProduct(svfUrl: string): void {
    this._validateProductData(this.productData);

    // TODO: get the SVF url from the backend based ont the product data
    this.viewer.loadModel(
      svfUrl,
      {},
      (model: any) => {
        this._log('Model loaded.', model);
      },
      (error: any) => {
        this._log('Model loading failed.', error);
      },
    );
  }

  setTheme(theme: Autodesk.Viewing.GuiViewer3D['theme']): void {
    useThemeStore.setState({ theme });
  }

  load(): boolean {
    this._log('Extension is loaded.');
    return true;
  }

  unload(): boolean {
    this.releaseDetailsLmvElement.uninitialize();
    this.formLmvElement.uninitialize();

    this._log('Extension is now unloaded.');
    return true;
  }
}

Autodesk.Viewing.theExtensionManager.registerExtension(extensionName, InformedDesignExtension);
