import { AddinList } from 'mid-types';
import BadgeSection from './Components/BadgeSection';
import DownloadSection from './Components/DownloadSection';
import { ContentWrapper, PageWrapper } from './Installers.styles';
import { useInstallers } from './useInstallers';
import SignUpSection from './Components/SignUpSection';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Spacing } from '@mid-react-common/common';

interface InstallersProps {
  badge: string;
  allThumbnails: { description: string; image: string }[];
  title: string;
  information: string;
  productPageUrl: string;
  productName: AddinList;
  onboardingCampaignUrl: string;
}

const Installers: React.FC<InstallersProps> = ({
  badge,
  allThumbnails,
  title,
  information,
  productPageUrl,
  productName,
  onboardingCampaignUrl,
}) => {
  const { enableSignUpButton } = useFlags();
  const { addinVersionInfoByName } = useInstallers();
  return (
    <PageWrapper>
      <ContentWrapper>
        <BadgeSection badge={badge} allThumbnails={allThumbnails} />
        {addinVersionInfoByName && (
          <Box display="flex" flex={1} flexDirection="column" justifyContent="center">
            <DownloadSection
              title={title}
              information={information}
              addinUrl={addinVersionInfoByName[productName]}
              productPageUrl={productPageUrl}
              productName={productName}
            />
            {enableSignUpButton && (
              <>
                <Spacing />
                <Divider />
                <Spacing />
                <SignUpSection onboardingCampaignUrl={onboardingCampaignUrl} />
              </>
            )}
          </Box>
        )}
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Installers;
