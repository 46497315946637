import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Chip } from '@mui/material';

export const SettingsPanelBackground = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.var.paddingBase}px ${({ theme }) => theme.var.paddingBase * 2}px;
`;

export const OutputTypesSettings = styled(Box)`
  padding: ${({ theme }) => `${theme.var.paddingBase}px ${theme.var.paddingBase * 2}px`};
  width: ${({ theme }) => theme.var.settingsPanelWidth}px;
  overflow-y: auto;
`;

export const OutputsTypesContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const SettingsPanelHeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }) => theme.var.marginBase * 2}px ${({ theme }) => theme.var.marginBase}px;
`;

export const SettingsButtonContainer = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.var.paddingBase}px;
`;

export const SettingsOutputTitle = styled('div')`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  font-size: ${({ theme }) => theme.var.settingsPanelFontSize}px;
  margin-top: ${({ theme }) => theme.var.paddingBase * 2}px;
  text-transform: uppercase;
`;

export const Label = styled('span')`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  font-size: ${({ theme }) => theme.var.settingsPanelFontSize}px;
`;

export const LabelContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.var.paddingBase / 2}px;
  align-items: center;
`;

export const OutputsChip = styled(Chip)`
  background-color: ${({ theme }) => theme.colors.primary.charcoal['200']};
`;

export const ProductReleaseTitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.var.paddingBase * 1.5}px;
`;
