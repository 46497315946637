import { OutputItem } from '../../SettingsPanel';
import OutputSettingsCheckbox from '../OutputSettingsCheckbox';

interface CheckboxForOutputLabelProps {
  label: OutputItem;
  handleCheckboxUpdate: (
    event: React.ChangeEvent<HTMLInputElement>,
    label: OutputItem,
    drawingTemplatePath?: string,
  ) => void;
  drawingTemplatePath?: string;
}

const CheckboxForOutputLabel: React.FC<CheckboxForOutputLabelProps> = ({
  label,
  handleCheckboxUpdate,
  drawingTemplatePath,
}) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    handleCheckboxUpdate(event, label, drawingTemplatePath);

  return <OutputSettingsCheckbox checked={label.checked} handleCheckboxUpdate={handleCheckboxChange} />;
};

export default CheckboxForOutputLabel;
