import { CustomError } from './customError';
import midLogger from './midLogger';
import Bugsnag from '@bugsnag/js';

export const logError = (error: unknown, additionalObjects?: any): void => {
  if (error instanceof CustomError) {
    midLogger.error(`Code: ${error.errorCode} Error: ${error.message}`, {
      error,
      cause: error.cause,
    });
  } else {
    // This is used when some error handling does not use customError
    // or error handling is not handled to use customError
    midLogger.error(String(error), {
      error,
      ...additionalObjects,
    });
  }

  // Reporting handled error to bugsnag
  if (error instanceof Error) {
    Bugsnag.notify(error);
  } else {
    Bugsnag.notify({ name: 'Unknown handled error', message: String(error) });
  }
};

export const logWarn = (message: string, additionalObjects?: any): void => {
  midLogger.warn(message, { ...additionalObjects });
};

export const logInfo = (message: string, additionalObjects?: any): void => {
  midLogger.info(message, { ...additionalObjects });
};

export const extractErrorMessage = (error: unknown): string => {
  if (typeof error === 'string') {
    return error;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return 'Unknown error';
};
