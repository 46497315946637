import { Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

export const FolderTreeDivider = styled(Divider)`
  margin: ${({ theme }) => theme.var.marginBase}px 0;
`;

export const ModalActionContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ModalButton = styled(Button)`
  margin-left: ${({ theme }) => theme.var.marginBase}px;
`;
