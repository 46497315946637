import { CustomError } from '../customError';
import { ErrorCode } from '../errorCode';

export class HubsFetchError extends CustomError {
  errorCode = ErrorCode.HubsFetchError;

  constructor(message: string) {
    super(message);
  }
}
