import { styled } from '@mui/material/styles';
import MenuList from '@mui/material/MenuList';
import Box from '@mui/material/Box';

type AccountListContainerProps = {
  visible: boolean;
};

export const AccountListContainer = styled(Box, {
  shouldForwardProp: (props) => props !== 'visible',
})<AccountListContainerProps>(({ visible }) => ({
  maxWidth: visible ? '400px' : '0px',
  maxHeight: !visible ? '0px' : '60vh',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all .3s ease',
  overflow: 'hidden',
}));

export const AccountsListTitle = styled(Box)(() => ({
  margin: '8px 12px 0px 12px',
}));

export const AccountsMenuList = styled(MenuList)(() => ({
  flexGrow: 10,
  overflow: 'auto',
  minWidth: '400px',
}));

export const AccountImage = styled('img')(() => ({
  width: '38px',
  height: '30px',
}));
