import { RouterProvider } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { router } from './routes';
import { router as legacyRouter } from './legacy/legacyRouterConfig';

export const Router: React.FC = () => {
  const { enableRfoModal } = useFlags();

  return <RouterProvider router={enableRfoModal ? router : legacyRouter} />;
};
