import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const RFOTableHeadWrapper = styled(TableCell)`
  &.${tableCellClasses.head} {
    border-bottom: 1px solid ${({ theme }) => theme.palette.text.primary};
  }
`;

export const RFOTableRowWrapper = styled(TableRow)`
  background-color: ${({ theme }) => theme.palette.background.default};
`;

export const RFOTableCellWrapper = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isLastRow' && prop !== 'isFooter',
})<{ isLastRow?: boolean; isFooter?: boolean }>`
  ${(props) =>
    props.isLastRow
      ? `border-bottom: 1px solid ${props.theme.colors.brand.black};`
      : `border-bottom: 1px solid ${props.theme.palette.divider};`}

  ${(props) => props.isFooter && 'border-bottom: 0;'}

  padding: ${({ theme }) => theme.var.paddingBase}px;
`;

export const RFOFooterTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.brand.black};
  font-size: ${({ theme }) => theme.var.outputsTableFooterTextSize}px;
`;

export const TypographyWithDefaultCursor = styled(Typography)`
  cursor: default;
`;
