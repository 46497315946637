import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { DataGridPremium } from '@weave-mui/data-grid';
import { TextField } from '@mui/material';

export const ModelsFolderContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '10',
  alignItems: 'stretch',
  paddingLeft: '12px',
  backgroundColor: 'white',
  minWidth: `${theme.var.modelsFolderContentContainerMinWidth}px`,
}));

export const ModelsFolderContentHeader = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  minHeight: '60px',
  maxHeight: '60px',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const ModelsFolderContentDataGrid = styled(Box)(() => ({
  flexGrow: '10',
  overflow: 'auto',
}));

export const FilenameCell = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
}));

export const FolderContentDatagrid = styled(DataGridPremium)(() => ({
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#eee',
  },
  fontSize: '14px',
}));

export const ModelsSearchTextField = styled(TextField)(() => ({
  padding: '0px',
  minWidth: '300px',
  marginRight: '12px',
}));

export const ModelsFileName = styled(Box)(() => ({
  marginLeft: '6px',
  flexGrow: '10',
}));
