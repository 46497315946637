import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import text from '../../../global/text.json';
import noProjectImage from '../../../images/3d-settings-icon-light-slate.png';
import { accountProjectSelectorTestIds } from '../../../tests/helpers/dataTestIds';
import {
  AccountAndProjectLoading,
  AccountArrowIcon,
  AccountLogo,
  AccountLogoTitleContainer,
  ProjectSelectorPanelPopoverContainer,
  SelectedProjectHeaderContainer,
  SelectorsContainer,
} from './AccountProjectSelector.style';
import AccountsList from './AccountsList/AccountsList';
import ProjectsList from './ProjectsList/ProjectsList';
import { BIMAccount, AccProject } from 'mid-types';

interface AccountProjectSelectorProps {
  accountId: string | null;
  accountDisplayName: string | undefined;
  accountImageURL: string | undefined;
  accounts: BIMAccount[] | undefined;
  projectId: string | null;
  projectName: string | null;
  projects: AccProject[] | undefined;
  anchorElPopover: null | HTMLElement;
  loading: boolean;
  maxWidth?: number;
  handleProjectMenuOpen: (event: React.MouseEvent<HTMLElement>) => Promise<void>;
  handleProjectMenuClose: () => void;
  handleSearchProjects: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleAccountSelection: (account: BIMAccount) => void;
  handleProjectSelection: (project: AccProject) => void;
  handleChangeAccount: () => void;
}

const accountProjectSelectorText = text.accountProjectSelector;

const AccountProjectSelector: React.FC<AccountProjectSelectorProps> = ({
  accountId,
  accountDisplayName,
  accountImageURL,
  accounts,
  projectId,
  projectName,
  projects,
  anchorElPopover,
  loading,
  maxWidth,
  handleProjectMenuOpen,
  handleProjectMenuClose,
  handleSearchProjects,
  handleAccountSelection,
  handleProjectSelection,
  handleChangeAccount,
}) => {
  const renderAccountProjectName = (): JSX.Element => {
    if (loading) {
      return <AccountAndProjectLoading />;
    }

    if (accountDisplayName || projectName) {
      return (
        <Typography variant="body2" noWrap component="div">
          {accountDisplayName} {projectName && `- ${projectName}`}
        </Typography>
      );
    }

    return (
      <Typography variant="body2" noWrap component="div">
        <strong>{accountProjectSelectorText.title}</strong>
      </Typography>
    );
  };

  return (
    <>
      <SelectedProjectHeaderContainer
        onClick={handleProjectMenuOpen}
        data-testid={accountProjectSelectorTestIds.projectSelectorBanner}
        maxWidth={maxWidth}
      >
        <AccountLogoTitleContainer>
          <AccountLogo src={projectId && accountImageURL ? accountImageURL : noProjectImage} />
          {renderAccountProjectName()}
        </AccountLogoTitleContainer>
        <AccountArrowIcon anchorElPopover={anchorElPopover} />
      </SelectedProjectHeaderContainer>
      <Popover
        open={Boolean(anchorElPopover)}
        anchorEl={anchorElPopover}
        onClose={handleProjectMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ProjectSelectorPanelPopoverContainer>
          <SelectorsContainer>
            <AccountsList accounts={accounts} visible={!accountId} handleAccountSelection={handleAccountSelection} />
            {projects && (
              <ProjectsList
                projects={projects}
                visible={!!accountId}
                handleProjectSelection={handleProjectSelection}
                accountName={accountDisplayName}
                accountImageURL={accountImageURL}
                handleChangeAccount={handleChangeAccount}
                handleSearchProjects={handleSearchProjects}
              />
            )}
          </SelectorsContainer>
        </ProjectSelectorPanelPopoverContainer>
      </Popover>
    </>
  );
};

export default AccountProjectSelector;
