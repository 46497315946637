import { UserProfile } from 'mid-types';

export const mockUserProfile: UserProfile = {
  userId: 'OTTOUSERID',
  userName: 'otto.desc',
  emailId: 'otto.desc@autodesk.com',
  firstName: 'Otto',
  lastName: 'Desc',
  emailVerified: true,
  '2FaEnabled': false,
  countryCode: 'CA',
  language: 'en',
  optin: true,
  lastModified: '2022-11-18T18:35:28.254',
  profileImages: {
    sizeX20: 'https://images.profile-stg.autodesk.com/default/user_X20.png',
    sizeX40: 'https://images.profile-stg.autodesk.com/default/user_X40.png',
    sizeX50: 'https://images.profile-stg.autodesk.com/default/user_X50.png',
    sizeX58: 'https://images.profile-stg.autodesk.com/default/user_X58.png',
    sizeX80: 'https://images.profile-stg.autodesk.com/default/user_X80.png',
    sizeX120: 'https://images.profile-stg.autodesk.com/default/user_X120.png',
    sizeX160: 'https://images.profile-stg.autodesk.com/default/user_X160.png',
    sizeX176: 'https://images.profile-stg.autodesk.com/default/user_X176.png',
    sizeX240: 'https://images.profile-stg.autodesk.com/default/user_X240.png',
    sizeX360: 'https://images.profile-stg.autodesk.com/default/user_X360.png',
  },
  ldapInfo: { ldapEnabled: true, domainName: 'adsk' },
  socialUserInfoList: [],
  createdDate: '2022-11-18T18:35:28.254',
  lastLoginDate: '2022-11-18T18:35:28.254',
  eidmGuid: '12345',
};
