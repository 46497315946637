import Checkbox from '@mui/material/Checkbox';
import { outputsSettingsIds } from 'tests/helpers/dataTestIds';

const SelectAllOutputsCheckbox: React.FC<{
  outputsLength: number;
  selectedOutputsLength: number;
  addAllOutputs: () => void;
  removeAllOutputs: () => void;
  outputSection?: string;
}> = ({ outputsLength, selectedOutputsLength, addAllOutputs, removeAllOutputs, outputSection }) => {
  const isChecked = outputsLength === selectedOutputsLength;
  const isIndeterminate = outputsLength !== selectedOutputsLength && selectedOutputsLength !== 0;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      addAllOutputs();
    } else {
      removeAllOutputs();
    }
  };

  return (
    <Checkbox
      size="small"
      indeterminate={isIndeterminate}
      checked={isChecked}
      onChange={handleOnChange}
      data-testid={
        outputSection
          ? `${outputsSettingsIds.selectAllOutputs}-${outputSection.replaceAll(' ', '')}`
          : outputsSettingsIds.selectAllOutputs
      }
    />
  );
};

export default SelectAllOutputsCheckbox;
