import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import text from 'global/text.json';
import { licensingTestIds } from 'tests/helpers/dataTestIds';
import { LegendWrapper } from './Licensing.styles';
import React from 'react';

const licensingText = text.licensing;

const LicensingLegend: React.FC = (): JSX.Element => (
  <LegendWrapper data-testid={licensingTestIds.sectionsLegend}>
    <List>
      <ListItemButton href={`#${licensingTestIds.privacyId}`}>
        <ListItemText primary={licensingText.privacy} />
      </ListItemButton>
      <Divider />
    </List>
    <List>
      <ListItemButton href={`#${licensingTestIds.trademarksId}`}>
        <ListItemText primary={licensingText.autodeskTrademarks} />
      </ListItemButton>
      <Divider />
    </List>
    <List>
      <ListItemButton href={`#${licensingTestIds.patentsId}`}>
        <ListItemText primary={licensingText.patents} />
      </ListItemButton>
      <Divider />
    </List>
    <List>
      <ListItemButton href={`#${licensingTestIds.componentsId}`}>
        <ListItemText primary={licensingText.licensedComponents} />
      </ListItemButton>
      <Divider />
    </List>
  </LegendWrapper>
);

export default LicensingLegend;
