import { MutableRefObject, useEffect, useRef } from 'react';
import { InformedDesignExtension } from '../InformedDesignExtension';
import { ProductData } from '../utils/types';

type UseLmvInitProps = {
  token: string | undefined;
  lmvContainerRef: MutableRefObject<HTMLElement | null>;
  productData: ProductData;
  svfUrl: string;
};

export const useLmvInit = ({ token, lmvContainerRef, productData, svfUrl }: UseLmvInitProps): void => {
  const lmvInitializerStarted = useRef(false);
  const lmvRef = useRef<Autodesk.Viewing.GuiViewer3D>();

  useEffect(() => {
    if (!token || !lmvContainerRef.current) {
      return;
    }

    // prevent from double initialization in the development mode
    lmvInitializerStarted.current = true;

    // eslint-disable-next-line new-cap
    Autodesk.Viewing.Initializer(
      {
        env: 'Local',
        getAccessToken: async (onTokenReady) => {
          onTokenReady(token, 3600);
        },
      },
      () => {
        // dark theme is default
        const viewer = new Autodesk.Viewing.GuiViewer3D(lmvContainerRef.current!, {
          extensions: ['Autodesk.InformedDesign'],
        });

        const startedCode = viewer.start();

        if (startedCode > 0) {
          console.error('Failed to create a Viewer: WebGL not supported.');
          return;
        }

        viewer.fitToView();
        lmvRef.current = viewer;

        viewer.getExtensionAsync('Autodesk.InformedDesign').then((extension: InformedDesignExtension) => {
          extension.setProductData(productData);
          extension.loadProduct(svfUrl);
        });

        console.log('LMV initialization complete.');
      },
    );

    return () => {
      lmvRef.current?.finish();
      lmvRef.current = undefined;
      Autodesk.Viewing.shutdown();
    };
  }, [token, lmvContainerRef, productData, svfUrl]);
};
