import Typography from '@mui/material/Typography';
import text from 'global/text.json';
import Box from '@mui/material/Box';
import { Spacing } from '@mid-react-common/common';
import useDrawing2D from './useDrawing2D';
import { OutputType } from '@adsk/offsite-dc-sdk';
import Drawing2DDropdown from './Drawing2DDropwdown';
import { Section } from '../../RFOModal.styles';
import Divider from '@mui/material/Divider';
import { rfoModalTestIds } from 'tests/helpers/dataTestIds';
import { DCDrawingOutputs } from 'mid-types';
const fileTypesStepText = text.rfoModal.fileTypesStep;

interface Drawing2DProps {
  outputsTypeDrawing: DCDrawingOutputs[];
}

const Drawing2D: React.FC<Drawing2DProps> = ({ outputsTypeDrawing }) => {
  const {
    dwgOutputs,
    idwOutputs,
    pdfOutputs,
    drawingOutputsByType,
    handleIdwOutputsChange,
    handleDwgOutputsChange,
    handlePdfOutputsChange,
    handleDwgOutputDelete,
    handleIdwOutputDelete,
    handlePdfOutputDelete,
  } = useDrawing2D({ outputsTypeDrawing });

  return (
    <>
      {drawingOutputsByType.length > 0 && (
        <>
          <Section data-testid={rfoModalTestIds.fileTypesStep.drawing2DSection}>
            <Typography fontWeight="bold" variant="h3">
              {fileTypesStepText.drawing2d}
            </Typography>
            <Spacing />
            <Box>
              {drawingOutputsByType.map((outputsByType, index) => {
                switch (outputsByType.type) {
                  case OutputType.IDW:
                    return (
                      <Drawing2DDropdown
                        key={`${outputsByType.type}-${index}`}
                        label={fileTypesStepText.inventorNative}
                        drawings={outputsByType.drawings}
                        selectedDrawings={idwOutputs}
                        handleOnChange={handleIdwOutputsChange}
                        handleDelete={handleIdwOutputDelete}
                      />
                    );
                  case OutputType.DWG:
                    return (
                      <Drawing2DDropdown
                        key={`${outputsByType.type}-${index}`}
                        label={fileTypesStepText.autocadNative}
                        drawings={outputsByType.drawings}
                        selectedDrawings={dwgOutputs}
                        handleOnChange={handleDwgOutputsChange}
                        handleDelete={handleDwgOutputDelete}
                      />
                    );
                  case OutputType.PDF:
                    return (
                      <Drawing2DDropdown
                        key={`${outputsByType.type}-${index}`}
                        label={fileTypesStepText.neutralPDF}
                        drawings={outputsByType.drawings}
                        selectedDrawings={pdfOutputs}
                        handleOnChange={handlePdfOutputsChange}
                        handleDelete={handlePdfOutputDelete}
                      />
                    );
                }
              })}
            </Box>
          </Section>
          <Divider />
        </>
      )}
    </>
  );
};

export default Drawing2D;
