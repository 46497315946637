/* eslint-disable monorepo-imports/no-relative-imports */
import { FC, useContext, useEffect, useRef, useState } from 'react';

import GatekeeperContext from 'context/GatekeeperStore/Gatekeeper.context';
import { LmvWrapperContainer } from './ViewIn3d.styles';

// this import is needed to make the extension available in the viewer
import 'lmv-ext-ind/src/InformedDesignExtension';

import { ProductData } from 'lmv-ext-ind/src/utils/types';
import { useLmvInit } from 'lmv-ext-ind/src/hooks/useLmvInit';

type LmvWrapperProps = {
  productData: ProductData;
};

export const LmvWrapper: FC<LmvWrapperProps> = ({ productData }) => {
  const { getFreshToken } = useContext(GatekeeperContext);
  const lmvContainerRef = useRef<HTMLDivElement | null>(null);

  const [token, setToken] = useState<string>();

  useLmvInit({
    token,
    // the lmv-ext local app has to be run
    svfUrl: `${location.origin}/proxy/wall_svf/output/1/result.svf`,
    lmvContainerRef,
    productData,
  });

  useEffect(() => {
    const _setToken = async () => {
      if (token) {
        return;
      }

      setToken(await getFreshToken());
    };

    _setToken();
  }, [token, getFreshToken]);

  return <LmvWrapperContainer ref={lmvContainerRef} />;
};
