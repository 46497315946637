import { DownloadURLPayload, DownloadUrlResponse } from '@adsk/offsite-dc-sdk';
import { NotificationContext, midTheme } from '@mid-react-common/common';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { getDcApiServiceInstance } from 'mid-api-services';
import { EmptyOutputFileError } from 'mid-utils';
import React, { useCallback, useContext, useState } from 'react';
import text from '../../../../global/text.json';
import { outputsReviewPanel } from '../../../../tests/helpers/dataTestIds';

interface DownloadOutputsFileProps {
  objectKey?: string;
  projectId: string | null;
}

const reviewPanelText = text.reviewPanel;

// value is the objectKey used to fetch BOM file URL
const DownloadOutputsFile: React.FC<DownloadOutputsFileProps> = ({ objectKey, projectId }) => {
  const [isLoading, setLoading] = useState(false);
  const { logAndShowNotification } = useContext(NotificationContext);

  const fetchFile = useCallback(async () => {
    if (!projectId || !objectKey) {
      return;
    }

    const downloadURLPayload: DownloadURLPayload = {
      objectKey,
    };
    const downloadUrlResponse: DownloadUrlResponse = await getDcApiServiceInstance().downloadURL({
      projectId,
      downloadURLPayload,
    });

    if (!downloadUrlResponse) {
      throw new EmptyOutputFileError(text.reviewPanel.emptyFileUrlFromAPI);
    }

    const anchor = document.createElement('a');
    anchor.href = downloadUrlResponse.signedUrl;
    document.body.appendChild(anchor);

    // Programmatically clicking to download file
    anchor.click();

    // Remove element from DOM after file has been programmatically downloaded
    document.body.removeChild(anchor);
  }, [projectId, objectKey]);

  const handleDownloadFileClick = useCallback(async () => {
    setLoading(true);

    try {
      await fetchFile();
    } catch (error) {
      logAndShowNotification({
        message: reviewPanelText.failToGetFile,
        error,
      });
    } finally {
      setLoading(false);
    }
  }, [fetchFile, setLoading, logAndShowNotification]);

  // If no objectKey or projectId, can't download the file
  const cannotDownload = !projectId || !objectKey;

  return (
    <Button
      variant="outlined"
      onClick={handleDownloadFileClick}
      data-testid={outputsReviewPanel.downloadButton}
      disabled={isLoading || cannotDownload}
      endIcon={
        isLoading && (
          <CircularProgress size={midTheme.var.buttonSpinnerSize} data-testid={outputsReviewPanel.circularProgress} />
        )
      }
    >
      {reviewPanelText.downloadFile}
    </Button>
  );
};

export default DownloadOutputsFile;
