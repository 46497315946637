import { styled } from '@mui/material/styles';
import { FlexContainer } from '../../styles/Common.styles';

export const ReleaseNotesTextarea = styled('textarea')`
  min-height: 100px;
  width: 100%;
  resize: vertical;
  padding: ${({ theme }) => theme.var.paddingBase}px;
  background-color: ${({ theme }) => theme.palette.background.default};
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  :focus {
    outline: none;
  }
`;

export const ReleaseNotesFooter = styled(FlexContainer)`
  justify-content: space-between;
`;
