import { CustomDialog, MIDEmptyState, useAccBridge } from '@mid-react-common/common';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { PERMISSION_REQUIREMENT_LINK } from 'global/constants/links';
import React, { useCallback } from 'react';
import { outputsSettingsIds } from 'tests/helpers/dataTestIds';
import text from '../../../../../global/text.json';
import { UploadLocationProject } from '../../../../../types/uploadLocation';
import { FoldersTree } from '../../../../Commons/FoldersTree/FoldersTree';
import useFoldersTree from '../../../../Commons/FoldersTree/useFoldersTree';
import { ModalActions } from './ModalActions';
import { FolderTreeDivider } from './OutputFolderButtonAndModal.styles';
import OutputProjectSelector from '../../../../Commons/Legacy/OutputProjectSelector/OutputProjectSelector';
import { useOutputProjectSelector } from '../../../../Commons/Legacy/OutputProjectSelector/useOutputProjectSelector';
import { useSelectionModal } from './useSelectionModal';
import { Vendors } from '@adsk/offsite-dc-sdk';

const uploadLocationText = text.uploadLocation;

const BrowseButtonAndModal: React.FC = () => {
  const theme = useTheme();

  const {
    uploadFolderUrnModalSelection,
    uploadLocationTreeModalSelection,
    expandedUploadLocationTreeNodeIdsModalSelection,
    uploadLocationProject,
    dialogOpen,
    isSubfolderCreationInputVisible,
    isFolderBreadcrumbSet,
    editPermissionLookup,
    setUploadLocationTreeModalSelection,
    setExpandedUploadLocationTreeNodeIdsModalSelection,
    handleProjectSelection,
    handleDialogOpen,
    handleDialogClose,
    handleFolderSelection,
    handleUploadLocationConfirmation,
    handleSubfolderCreationDisplay,
    dismissSubfolderCreationDisplay,
    handleEditPermissions,
  } = useSelectionModal();

  const { projects, loading, anchorElPopover, handleProjectMenuOpen, handleProjectMenuClose, handleSearchProjects } =
    useOutputProjectSelector();

  const {
    errorReason,
    hasEmptyRootNodes,
    handleFolderToggle,
    handleSubFolderCreation,
    subFolderCreationError,
    subFolderCreationRequestLoading,
    resetSubFolderCreationError,
  } = useFoldersTree({
    accountId: uploadLocationProject?.accountId || null,
    projectId: uploadLocationProject?.id || null,
    foldersTree: uploadLocationTreeModalSelection,
    folderUrn: uploadFolderUrnModalSelection || '',
    vendor: uploadLocationProject?.vendor,
    isSubfolderCreationInputVisible,
    setFoldersTree: setUploadLocationTreeModalSelection,
    setExpandedTreeNodeIds: setExpandedUploadLocationTreeNodeIdsModalSelection,
    selectFolder: handleFolderSelection,
    handleEditPermissions,
  });

  const { incomingBridgeFoldersMap, outgoingBridgeFoldersMap, bridgeProjectsList } = useAccBridge({
    projectId: uploadLocationProject?.id || null,
    isAccProject: uploadLocationProject?.vendor === Vendors.ACC,
  });

  const handleProjectSelectionAndClosePopover = useCallback(
    (project: UploadLocationProject) => {
      handleProjectSelection(project);
      handleProjectMenuClose();
    },
    [handleProjectSelection, handleProjectMenuClose],
  );

  const handleSubFolderCreationInModal = useCallback(
    async (folderName: string) => {
      const folderCreated = await handleSubFolderCreation(folderName);
      // Only dismiss sub folder creation display on success
      // (show display while loading & if there is an error)
      if (folderCreated) {
        dismissSubfolderCreationDisplay();
      }
    },
    [dismissSubfolderCreationDisplay, handleSubFolderCreation],
  );

  const permissionLink = (
    <Link href={PERMISSION_REQUIREMENT_LINK} target="_blank" rel="noopener noreferrer">
      {uploadLocationText.permissionRequirements}
    </Link>
  );

  return (
    <Box alignItems="center" display="flex">
      <Button
        variant={isFolderBreadcrumbSet ? 'outlined' : 'contained'}
        onClick={handleDialogOpen}
        data-testid={outputsSettingsIds.outputFolderButton}
      >
        {isFolderBreadcrumbSet ? uploadLocationText.buttonChangeFolder : uploadLocationText.buttonBrowseFolder}
      </Button>
      <CustomDialog
        height={theme.var.dialogHeight}
        open={dialogOpen}
        title={uploadLocationText.selectUploadLocation}
        customActions={[
          <ModalActions
            key="modal-actions"
            handleSubfolderCreationDisplay={handleSubfolderCreationDisplay}
            handleCancellation={handleDialogClose}
            handleConfirmation={handleUploadLocationConfirmation}
            isFolderActionDisabled={!uploadFolderUrnModalSelection || !editPermissionLookup[uploadFolderUrnModalSelection]}
            vendor={uploadLocationProject?.vendor}
          />,
        ]}
      >
        <OutputProjectSelector
          anchorElPopover={anchorElPopover}
          selectedProject={uploadLocationProject}
          projects={projects}
          loading={loading}
          handleProjectMenuOpen={handleProjectMenuOpen}
          handleProjectMenuClose={handleProjectMenuClose}
          handleSearchProjects={handleSearchProjects}
          handleProjectSelection={handleProjectSelectionAndClosePopover}
        />
        <FolderTreeDivider />
        {errorReason ? (
          <MIDEmptyState title={uploadLocationText.projectPermissionError} description={permissionLink} />
        ) : hasEmptyRootNodes ? (
          <MIDEmptyState title={uploadLocationText.emptyRootNodeError} description={permissionLink} />
        ) : (
          <FoldersTree
            folderUrn={uploadFolderUrnModalSelection || ''}
            foldersTree={uploadLocationTreeModalSelection}
            expandedTreeNodeIds={expandedUploadLocationTreeNodeIdsModalSelection}
            showNewFolderCreation={isSubfolderCreationInputVisible}
            handleFolderToggle={handleFolderToggle}
            handleFolderClick={handleFolderSelection}
            folderCreationRequestLoading={subFolderCreationRequestLoading}
            folderCreationError={subFolderCreationError}
            handleNewFolderNameCancellation={dismissSubfolderCreationDisplay}
            handleNewFolderNameConfirmation={handleSubFolderCreationInModal}
            resetSubFolderCreationError={resetSubFolderCreationError}
            editPermissionLookup={editPermissionLookup}
            bridgeIncomingProjectFoldersMap={incomingBridgeFoldersMap}
            bridgeOutgoingProjectFoldersMap={outgoingBridgeFoldersMap}
            bridgeProjectsList={bridgeProjectsList}
            selectedProjectId={uploadLocationProject?.id}
          />
        )}
      </CustomDialog>
    </Box>
  );
};

export default BrowseButtonAndModal;
