import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const CustomAccordionHeader = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

export const CustomAccordionContent = styled(Box, {
  shouldForwardProp: (props) => props !== 'expanded',
})<{ expanded: boolean }>`
  height: ${({ theme, expanded }) => (expanded ? `calc(100% - ${theme.var.customAccordionContentHeader}px)` : '0')};
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  padding: 0 10px;
`;
