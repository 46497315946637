import { FC } from 'react';

type ViewIn3dHeaderProps = {
  accountName: string;
  projectName: string;
  productReleaseName: string;
  productReleaseVersion: number;
};

export const ViewIn3dHeader: FC<ViewIn3dHeaderProps> = ({
  accountName,
  projectName,
  productReleaseName,
  productReleaseVersion,
}) => {
  return (
    <div>
      {accountName} / {projectName} / {productReleaseName} ({productReleaseVersion})
    </div>
  );
};
