import { Navigate, createBrowserRouter } from 'react-router-dom';
import App from 'App';
import ReviewPanel from 'components/OutputsPage/ReviewPanel/ReviewPanel';
import SettingsPanel from 'components/LegacyOutputsPage/LegacySettingsPanel/SettingsPanel';
import InstancesPanel from 'components/OutputsPage/InstancesPanel/InstancesPanel';
import ModelsPanel from 'components/OutputsPage/ModelsPanel/ModelsPanel';
import InventorInstallersPage from 'pages/Installers/InventorInstallers';
import RevitInstallersPage from 'pages/Installers/RevitInstallers';
import LicensesPage from 'pages/Licencing/LicensingPage';
import { PageContainer } from 'pages/pages.styles';
import LegacyOutputsPage from '../../pages/LegacyOutputsPage/LegacyOutputsPage';
import LegacyProductsPage from '../../pages/LegacyProductsPage/LegacyProductsPage';
import ReleasesPage from '../../pages/ReleasesPage/ReleasesPage';
import { routes } from './legacyRoutes';
import ProtectedRoute from 'routes/ProtectedRoute';

/**
 * TODO: Clean this up after createRFO initiative
 * https://jira.autodesk.com/browse/TRADES-6186
 *
 * This is a copy of previous routes, all the new changes will be in routes
 *
 */
export const nonProjectRoutes = [
  {
    path: routes.licensing.path,
    element: <LicensesPage />,
  },
  {
    path: routes.inventorInstallers.path,
    element: <InventorInstallersPage />,
  },
  {
    path: routes.revitInstallers.path,
    element: <RevitInstallersPage />,
  },
];

export const routerConfig = [
  {
    path: routes.index.path,
    element: <App />,
    children: nonProjectRoutes,
  },
  {
    path: routes.projectId.path,
    element: <App />,
    id: routes.projectId.id,
    children: [
      {
        path: routes.products.path,
        element: (
          <PageContainer>
            <LegacyProductsPage />
          </PageContainer>
        ),
        id: routes.products.id,
        children: [
          {
            path: routes.models.path,
            element: <ModelsPanel />,
            children: [
              {
                path: routes.folders.path,
                element: <ModelsPanel />,
                children: [
                  {
                    path: routes.folderItems.path,
                    element: <ModelsPanel />,
                  },
                ],
              },
            ],
          },
          {
            path: routes.instances.path,
            element: <InstancesPanel />,
          },
        ],
      },
      {
        path: routes.outputs.path,
        element: (
          <PageContainer>
            <LegacyOutputsPage />
          </PageContainer>
        ),
        id: routes.outputs.id,
        children: [
          {
            path: routes.review.path,
            element: <ReviewPanel />,
          },
          {
            path: routes.settings.path,
            element: <SettingsPanel />,
          },
          /**
           * Need to keep a copy of new routes here.
           * Due to how the new routes are set up with enableRfoModal flag, when user refreshes the page, app will first
           * pick up old routes config, then use the new routes config.
           * If this route config is not here, the app will redirect to main page if browser is refreshed while on LMV model
           * page when enableRfoModal is on.
           */
          {
            path: routes.models.path,
            element: <ModelsPanel />,
            children: [
              {
                path: routes.folders.path,
                element: <ModelsPanel />,
                children: [
                  {
                    path: routes.folderItems.path,
                    element: <ModelsPanel />,
                  },
                ],
              },
            ],
          },
          {
            path: routes.instances.path,
            element: <InstancesPanel />,
          },
        ],
      },
      {
        path: routes.releases.path,
        element: (
          <ProtectedRoute accessControlProperty="releasesTab" redirectPath={routes.products.id}>
            <PageContainer>
              <ReleasesPage />
            </PageContainer>
          </ProtectedRoute>
        ),
      },
    ],
  },
  // handle the unknown routes, just navigate to the root (404 case)
  {
    path: '*',
    element: <Navigate to={routes.index.path} replace />,
  },
];

export const router = createBrowserRouter(routerConfig);
