import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const Section = styled(Box)`
  padding: ${({ theme }) => theme.var.marginBase * 2}px 0 ${({ theme }) => theme.var.marginBase * 2}px 0;
`;

export const CanGenerateOutputsErrorContainer = styled(Box)`
  display: flex;
  margin-right: auto;
  gap: ${({ theme }) => `${theme.var.marginBase}px`};
  align-items: center;
`;
