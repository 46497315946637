import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Environment } from 'mid-types';
import { ServiceConfigMap, ServiceTypes } from 'mid-utils';
import { NavLink } from 'react-router-dom';
import text from '../../common.text.json';
import { inventorInstallersPath, revitInstallersPath } from '../../global/constants';
import { aboutInfoMenu } from '../../global/dataTestIds';

interface InstallerLinksProps {
  isTargetBlank?: boolean;
  isAddin: boolean;
  currentEnv: Environment;
  handleMenuClose: () => void;
}

export const InstallerLinks: React.FC<InstallerLinksProps> = ({ isAddin, isTargetBlank, currentEnv, handleMenuClose }) =>
  !isAddin && (
    <>
      <ListItemButton
        component={isTargetBlank ? 'a' : NavLink}
        to={
          isTargetBlank
            ? `${ServiceConfigMap[ServiceTypes.MID_WEBAPP][currentEnv].api}${inventorInstallersPath}`
            : inventorInstallersPath
        }
        target={isTargetBlank ? '_blank' : undefined}
        disableRipple
        onClick={handleMenuClose}
        data-testid={aboutInfoMenu.inventorInstallersPageLink}
      >
        <ListItemText>{text.aboutInfoMenu.inventorAddinLinkLabel}</ListItemText>
      </ListItemButton>
      <ListItemButton
        component={isTargetBlank ? 'a' : NavLink}
        to={
          isTargetBlank
            ? `${ServiceConfigMap[ServiceTypes.MID_WEBAPP][currentEnv].api}${revitInstallersPath}`
            : revitInstallersPath
        }
        target={isTargetBlank ? '_blank' : undefined}
        disableRipple
        onClick={handleMenuClose}
        data-testid={aboutInfoMenu.revitInstallersPageLink}
      >
        <ListItemText>{text.aboutInfoMenu.revitAddinLinkLabel}</ListItemText>
      </ListItemButton>
      <Divider />
    </>
  );
