import Divider from '@mui/material/Divider';
import ProductContext from 'context/ProductStore/Product.context';
import React, { useCallback, useContext } from 'react';
import InstanceDetailsPanel from '../InstanceDetailsPanel/InstanceDetailsPanel';
import { ModelViewer } from '../ModelViewer/ModelViewer';
import ProductsInstancesDataGrid from '../ProductsInstancesDataGrid/ProductsInstancesDataGrid';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  ExpandToggleButton,
  GenerateOutputsButton,
  OutputsGenerationDisabledIconWithTooltip,
} from './InstancePanelComponents';
import {
  InstanceSidebarHeader,
  InstanceSidebarHeaderGroup,
  InstanceSidebarHeaderRow,
  InstancesSidebar,
  ModelViewContainer,
  NoOutputsTooltip,
} from './InstancesPanel.style';
import { ProductSelectDropdown } from './ProductSelectDropdown/ProductSelectDropdown';
import { useInstancesPanel } from './useInstancesPanel';
import SelectionFilter from './InstanceSidebarHeader/SelectionFilter/SelectionFilter';
import { useOutputGenerationCheck } from './useOutputGenerationCheck';
import RFOModal from './RFOModal/RFOModal';
import text from 'global/text.json';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useProductReleasePermissionCheck } from './useProductReleasePermissionCheck';
import { accBridgeLink } from 'mid-utils';
import { modelDetails } from 'tests/helpers/dataTestIds';

const instancePanelText = text.instancePanel;
const rfoModalText = text.rfoModal;

const InstancesPanel: React.FC = () => {
  const { enableRfoModal } = useFlags();
  const { dataGridInstances } = useContext(ProductContext);
  const {
    instancesSidebarExpanded,
    selectedProductRelease,
    outputsGenerationDisabledDueToInstances,
    instancesOutputCount,
    localSelectedInstances,
    selectedInstancesIds,
    dataGridColumns,
    productsLoading,
    hasDataGridInstancesBeenInitialized,
    showInstanceDetailsPanel,
    variantsList,
    selectionFilter,
    setSelectionFilter,
    handleInstancesSelection,
    handleToggleExpanded,
    handleInstanceDetailsPanelVisibility,
    handleSelectedInstances,
  } = useInstancesPanel();

  const {
    isRFOModalOpen,
    handleOpenRFOModal,
    handleCloseRFOModal,
    isCanGenerateOutputsRequestLoading,
    outputsGenerationDisabledDueToTokenIssue,
    humanReadableErrorForOutputsGenerationUsingTokens,
  } = useOutputGenerationCheck();

  /**
   * TODO: Clean up enableRfoModal after createRFO initiative
   * https://jira.autodesk.com/browse/TRADES-6186
   *
   */
  const handleGenerateOutputsButtonClick = useCallback(() => {
    handleSelectedInstances();
    if (enableRfoModal) {
      handleOpenRFOModal();
    }
  }, [enableRfoModal, handleSelectedInstances, handleOpenRFOModal]);

  const { hasPermissionsFromProductTargetFolder, isLoadingProductTargetFolderData, hasErrorLoadingProductTargetFolderData } =
    useProductReleasePermissionCheck();

  const outputsButtonDisabled =
    outputsGenerationDisabledDueToInstances ||
    outputsGenerationDisabledDueToTokenIssue ||
    instancesOutputCount === 0 ||
    isLoadingProductTargetFolderData ||
    hasErrorLoadingProductTargetFolderData ||
    hasPermissionsFromProductTargetFolder === false;

  return (
    <>
      <InstancesSidebar expanded={instancesSidebarExpanded}>
        <InstanceSidebarHeader>
          <InstanceSidebarHeaderRow>
            <InstanceSidebarHeaderGroup>
              <ProductSelectDropdown expanded={instancesSidebarExpanded} />
              <GenerateOutputsButton
                onClick={handleGenerateOutputsButtonClick}
                disabled={outputsButtonDisabled}
                loading={isCanGenerateOutputsRequestLoading}
              />
              {outputsGenerationDisabledDueToTokenIssue && humanReadableErrorForOutputsGenerationUsingTokens && (
                <OutputsGenerationDisabledIconWithTooltip
                  title={humanReadableErrorForOutputsGenerationUsingTokens.title}
                  message={humanReadableErrorForOutputsGenerationUsingTokens.message}
                  footerText={humanReadableErrorForOutputsGenerationUsingTokens.footerText}
                  footerLink={humanReadableErrorForOutputsGenerationUsingTokens.footerLink}
                />
              )}
              {!outputsGenerationDisabledDueToTokenIssue &&
                !!localSelectedInstances?.length &&
                instancesOutputCount === 0 && (
                  <NoOutputsTooltip title={text.instancePanel.noOutputsTooltip} arrow>
                    <WarningAmberIcon color="error" data-testid={modelDetails.outputsGenerationNoRfaIcon} />
                  </NoOutputsTooltip>
                )}
              {!isLoadingProductTargetFolderData &&
                (hasErrorLoadingProductTargetFolderData || hasPermissionsFromProductTargetFolder === false) && (
                  <OutputsGenerationDisabledIconWithTooltip
                    title={
                      <>
                        {instancePanelText.permissionCheck.createRequestPrefix}{' '}
                        <strong>{instancePanelText.permissionCheck.minimumOutputsGenerationPermissions}</strong>{' '}
                        {instancePanelText.permissionCheck.createRequestSuffix}
                      </>
                    }
                    footerText={rfoModalText.informedDesignAndAccBridge}
                    footerLink={accBridgeLink}
                  />
                )}
              <ExpandToggleButton expanded={instancesSidebarExpanded} onToggle={handleToggleExpanded} />
            </InstanceSidebarHeaderGroup>
          </InstanceSidebarHeaderRow>
          <SelectionFilter
            selectedInstances={localSelectedInstances}
            setSelectionFilter={setSelectionFilter}
            selectionFilter={selectionFilter}
          />
        </InstanceSidebarHeader>
        <ProductsInstancesDataGrid
          midModelInstances={dataGridInstances}
          selectedInstancesIds={selectedInstancesIds}
          handleInstancesSelection={handleInstancesSelection}
          columns={dataGridColumns}
          tableLoading={productsLoading || !hasDataGridInstancesBeenInitialized}
          selectionFilter={selectionFilter}
        />
      </InstancesSidebar>
      <Divider orientation="vertical" />
      {showInstanceDetailsPanel && selectedProductRelease && (
        <InstanceDetailsPanel
          visibleInstances={dataGridInstances}
          selectedInstancesIds={selectedInstancesIds || []}
          variants={variantsList}
          currentProductRelease={selectedProductRelease}
          handleInstanceDetailsPanelVisibility={handleInstanceDetailsPanelVisibility}
        />
      )}
      <ModelViewContainer expanded={instancesSidebarExpanded}>
        <ModelViewer />
      </ModelViewContainer>
      <RFOModal isRFOModalOpen={isRFOModalOpen} handleCloseRFOModal={handleCloseRFOModal} />
    </>
  );
};

export default InstancesPanel;
