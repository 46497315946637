import { CustomError } from '../customError';
import { ErrorCode } from '../errorCode';

type CauseType = {
  hubId?: string;
  projectId: string;
  folderUrn?: string;
};

export class HubProjectFolderFetchError extends CustomError {
  errorCode = ErrorCode.HubProjectFolderFetchError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
