import { useCallback, useContext, useMemo, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getDcApiServiceInstance } from 'mid-api-services';
import { CAN_GENERATE_OUTPUTS_KEY } from 'global/constants/reactQueryKeys';
import { useQuery } from '@tanstack/react-query';
import { CanGenerateOutputsErrorTypes } from '@adsk/offsite-dc-sdk';
import { isUndefined } from 'lodash';
import { instancePanel } from '../../../global/text.json';
import AccountProjectContext from 'context/AccountProjectStore/AccountProject.context';
import { UploadLocationProject } from 'types/uploadLocation';
import { vendorPlatforms } from 'global/constants/bim360';
import useRFOModalStore from 'context/RFOModalStore/useRFOModalStore';
import { useShallow } from 'zustand/react/shallow';

const MINIMUM_OUTPUT_AMOUNT = 1;

type UseOutputGenerationCheckState = {
  isRFOModalOpen: boolean;
  outputsGenerationDisabledDueToTokenIssue: boolean;
  humanReadableErrorForOutputsGenerationUsingTokens?: {
    title: string;
    message: string;
    footerText?: string;
    footerLink?: string;
  };
  isCanGenerateOutputsRequestLoading: boolean;
  handleOpenRFOModal: () => void;
  handleCloseRFOModal: () => void;
};

const canGenerateOutputsCustomErrorMessage: {
  [key in CanGenerateOutputsErrorTypes]: { title: string; message: string; footerText?: string; footerLink?: string };
} = instancePanel.canGenerateOutputsDcApiErrorMessages;

export const useOutputGenerationCheck = (): UseOutputGenerationCheckState => {
  const { enableRfoModal, enableTokenCharge } = useFlags();
  const [isRFOModalOpen, setIsRFOModalOpen] = useState<boolean>(false);
  const { currentAccount, currentProject } = useContext(AccountProjectContext);
  const { initializeFolderTreeState, setUploadLocationProject, resetModal } = useRFOModalStore(
    useShallow((state) => ({
      initializeFolderTreeState: state.initializeFolderTreeState,
      setUploadLocationProject: state.setUploadLocationProject,
      resetModal: state.resetModal,
    })),
  );

  const defaultUploadLocationProject: UploadLocationProject | null = useMemo(
    () =>
      currentAccount && currentProject
        ? {
            id: currentProject.id,
            name: currentProject.name,
            accountId: currentAccount.id,
            accountName: currentAccount.name,
            vendor: vendorPlatforms[currentProject.platform],
          }
        : null,
    [currentAccount, currentProject],
  );

  const resetUploadState = useCallback(() => {
    initializeFolderTreeState();
    setUploadLocationProject(defaultUploadLocationProject);
  }, [defaultUploadLocationProject, initializeFolderTreeState, setUploadLocationProject]);

  const handleOpenRFOModal = useCallback((): void => {
    resetUploadState();
    setIsRFOModalOpen(true);
  }, [resetUploadState]);

  const handleCloseRFOModal = (): void => {
    setIsRFOModalOpen(false);
    resetModal();
  };

  const { data: canGenerateOutputsResponse, isLoading: isCanGenerateOutputsRequestLoading } = useQuery({
    queryKey: [CAN_GENERATE_OUTPUTS_KEY],
    queryFn: async ({ signal }) => {
      const requestResponse = await getDcApiServiceInstance().canGenerateOutputs(MINIMUM_OUTPUT_AMOUNT, signal);
      return requestResponse;
    },
    // We don't need to put the enableTokenCharge ff in the "enabled" logic,
    // as the api will always return true if the enableTokenCharge
    // feature flag is not enabled for a user.
    enabled: enableRfoModal,
  });

  const outputsGenerationDisabledDueToTokenIssue: boolean =
    enableRfoModal && enableTokenCharge
      ? isCanGenerateOutputsRequestLoading ||
        isUndefined(canGenerateOutputsResponse) ||
        !canGenerateOutputsResponse.canGenerateOutputs
      : false;

  return {
    isRFOModalOpen,
    outputsGenerationDisabledDueToTokenIssue,
    humanReadableErrorForOutputsGenerationUsingTokens: canGenerateOutputsResponse?.errorType
      ? canGenerateOutputsCustomErrorMessage[canGenerateOutputsResponse?.errorType]
      : undefined,
    // loading should only be considered if enableTokenCharge is enabled
    isCanGenerateOutputsRequestLoading: enableTokenCharge && isCanGenerateOutputsRequestLoading,
    handleOpenRFOModal,
    handleCloseRFOModal,
  };
};
