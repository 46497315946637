import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import text from '../../../global/text.json';
import { releaseNotesEditIds } from 'tests/helpers/dataTestIds';
import { ReleaseStatusUpdateSpinner, ReleaseProductName, ReleaseCount } from '../Releases.styles';
import {
  CustomDialog,
  FlexContainer,
  ReleaseNotes,
  ReleaseStatusWithColorBar,
  LinkButton,
  midTheme,
} from '@mid-react-common/common';
import useReleaseNotesEdit from './useReleaseNotedEdit';
import { DCProductUIExtension } from 'mid-types';
import { ProductRelease } from '@adsk/offsite-dc-sdk';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';

interface ReleaseNotesEditProps {
  currentRelease: DCProductUIExtension<ProductRelease>;
  releasesCount: number;
  refetchProductReleases: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<DCProductUIExtension<ProductRelease>[], Error>>;
}

const ReleaseNotesEdit: React.FC<ReleaseNotesEditProps> = ({ currentRelease, releasesCount, refetchProductReleases }) => {
  const {
    dialogOpen,
    isNotesUpdating,
    notesValid,
    handleDialogClose,
    handleDialogOpen,
    handleNotesChange,
    handleNotesSave,
  } = useReleaseNotesEdit({
    currentRelease,
    refetchProductReleases,
  });

  return (
    <>
      <LinkButton onClick={handleDialogOpen} data-testid={releaseNotesEditIds.releaseNotesEditButton}>
        {text.releases.editReleaseNotes}
      </LinkButton>
      <CustomDialog
        title={text.releases.editReleaseNotes}
        open={dialogOpen}
        dividers
        maxWidth="md"
        customActions={[
          <Button
            key={releaseNotesEditIds.releaseNotesEditCancelButton}
            data-testid={releaseNotesEditIds.releaseNotesEditCancelButton}
            variant="outlined"
            onClick={handleDialogClose}
          >
            {text.releases.cancel}
          </Button>,
          <Button
            key={releaseNotesEditIds.releaseNotesSaveButton}
            data-testid={releaseNotesEditIds.releaseNotesSaveButton}
            variant="contained"
            startIcon={isNotesUpdating && <ReleaseStatusUpdateSpinner size={midTheme.var.buttonSpinnerSize} />}
            onClick={handleNotesSave}
            disabled={isNotesUpdating || !notesValid}
          >
            {text.releases.save}
          </Button>,
        ]}
      >
        <Box>
          <FlexContainer>
            <ReleaseProductName noBold>{currentRelease.name}</ReleaseProductName>
            <ReleaseCount className="mid-status-secondary">{`${currentRelease.release} ${text.releases.of} ${releasesCount}`}</ReleaseCount>
            <ReleaseStatusWithColorBar status={currentRelease.status} noBold />
          </FlexContainer>

          <br />

          <ReleaseNotes onChange={handleNotesChange} defaultNotes={currentRelease.notes} focusOnMount />
        </Box>
      </CustomDialog>
    </>
  );
};

export default ReleaseNotesEdit;
