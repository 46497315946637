import { Vendors } from '@adsk/offsite-dc-sdk';
import { HubProject, HubProjectLinks, hubProjectTypes } from 'mid-types';
import { mockBim360Project, mockProject } from './mockProjects';
import { mockAccHub, mockBim360Hub, mockFusionHub } from './mockHubs';

const mockHubProjectLinks: HubProjectLinks = {
  links: { self: { href: '' } },
  relationships: {
    hub: {
      data: {
        type: 'hub',
        id: 'hub-id',
      },
      links: { related: { href: '' } },
    },
    rootFolder: {
      data: {
        type: 'folders',
        id: 'root-folder-id',
      },
      meta: {
        link: {
          href: '',
        },
      },
    },
    topFolders: {
      links: { related: { href: '' } },
    },
  },
};

export const mockAccHubProject: HubProject = {
  type: 'projects',
  id: mockProject.id,
  attributes: {
    name: mockProject.name,
    scopes: [],
    extension: {
      type: hubProjectTypes.BIM360_OR_ACC,
      version: '1.0',
      schema: {
        href: 'https://autodesk.com/schemalink',
      },
      data: { projectType: Vendors.ACC },
    },
  },
  ...mockHubProjectLinks,
};

export const mockAccHubProjectApiResponse: HubProject = {
  ...mockAccHubProject,
  id: `b.${mockAccHubProject.id}`,
};

export const mockBim360HubProject: HubProject = {
  type: 'projects',
  id: mockBim360Project.id,
  attributes: {
    name: mockBim360Project.name,
    scopes: [],
    extension: {
      type: hubProjectTypes.BIM360_OR_ACC,
      version: '1.0',
      schema: {
        href: 'https://autodesk.com/schemalink',
      },
      data: { projectType: Vendors.BIM360 },
    },
  },
  ...mockHubProjectLinks,
};

export const mockBim360HubProjectApiResponse: HubProject = {
  ...mockBim360HubProject,
  id: `b.${mockBim360HubProject.id}`,
};

export const mockFusionHubProject: HubProject = {
  type: 'projects',
  id: 'fusion-project-id',
  attributes: {
    name: 'Fusion project name',
    scopes: [],
    extension: {
      type: hubProjectTypes.FUSION,
      version: '1.0',
      schema: {
        href: 'https://autodesk.com/schemalink',
      },
      data: {},
    },
  },
  ...mockHubProjectLinks,
};

export const mockFusionHubProjectApiResponse: HubProject = {
  ...mockFusionHubProject,
  id: `a.${mockFusionHubProject.id}`,
};

export const getMockHubProjectList = (hubId: string): HubProject[] => {
  const hubProjectListMap = {
    [mockAccHub.id]: [mockAccHubProject],
    [mockBim360Hub.id]: [mockBim360HubProject],
    [mockFusionHub.id]: [mockFusionHubProject],
  };

  return hubProjectListMap[hubId] || [];
};
