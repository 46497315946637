import Link, { LinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';

// have to manually add 'component' prop, MUI bug: https://github.com/mui/material-ui/issues/29875
const LinkButton = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'leftMargin' && prop !== 'topMargin',
})<LinkProps & { component?: string; leftMargin?: boolean; topMargin?: boolean }>`
  ${({ leftMargin, theme }) => leftMargin && `margin-left: ${theme.var.marginBase}px;`}
  ${({ topMargin, theme }) => topMargin && `margin-top: ${theme.var.marginBase}px;`}
`;
LinkButton.defaultProps = {
  component: 'button',
  underline: 'none',
  variant: 'body2',
};

export default LinkButton;
