import { ReleaseNotesFooter, ReleaseNotesTextarea } from './ReleaseNotes.styles';
import text from '../../common.text.json';
import useReleaseNotes from './useReleaseNotes';
import { useRef } from 'react';
import testIds from '../../global/dataTestIds';
import Typography from '@mui/material/Typography';

type ReleaseNotesProps = {
  onChange: (value: string, isValid: boolean) => void;
  defaultNotes?: string;
  focusOnMount?: boolean;
  characterLimit?: number;
  characterLimitVisibilityOffset?: number;
};

const DEFAULT_CHARACTER_LIMIT = 500;
const DEFAULT_CHARACTER_LIMIT_VISIBILITY_OFFSET = 15;

const ReleaseNotes: React.FC<ReleaseNotesProps> = ({
  onChange,
  defaultNotes = '',
  focusOnMount = false,
  characterLimit = DEFAULT_CHARACTER_LIMIT,
  characterLimitVisibilityOffset = DEFAULT_CHARACTER_LIMIT_VISIBILITY_OFFSET,
}) => {
  const releaseNotesRef = useRef<HTMLTextAreaElement>(null);

  const { characterCount, isLimitExceeded, handleChange, content } = useReleaseNotes({
    onChange,
    defaultNotes,
    focusOnMount,
    releaseNotesRef,
    characterLimit,
  });

  return (
    <>
      <ReleaseNotesTextarea
        placeholder={text.releaseNotes.placeholder}
        ref={releaseNotesRef}
        value={content}
        onChange={handleChange}
        className={isLimitExceeded ? 'mid-release-notes-border-error' : 'mid-release-notes-border-normal'}
        data-testid={testIds.releaseNotesTextArea}
      />

      <ReleaseNotesFooter className={isLimitExceeded ? 'mid-release-notes-footer-text-error' : 'mid-status-secondary'}>
        <Typography>{isLimitExceeded ? text.releaseNotes.characterLimitExceeded : text.releaseNotes.description}</Typography>

        {characterCount >= characterLimit - characterLimitVisibilityOffset && (
          <Typography data-testid={testIds.releaseNotesCharactersCount}>
            {characterCount}/{characterLimit}
          </Typography>
        )}
      </ReleaseNotesFooter>
    </>
  );
};

export default ReleaseNotes;
