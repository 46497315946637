import { CustomError } from '../customError';
import { ErrorCode } from '../errorCode';

type CauseType = {
  hubId: string;
  projectId?: string;
};

export class HubProjectsFetchError extends CustomError {
  errorCode = ErrorCode.HubProjectsFetchError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
