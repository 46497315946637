import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { uploadLocationProjectSelectorTestIds } from '../../../tests/helpers/dataTestIds';
import { UploadLocationProject } from '../../../types/uploadLocation';
import { ProjectListLoading, ProjectTitleContainer } from './OutputProjectSelector.styles';
import { ProjectIcon } from '@mid-react-common/common';
import text from '../../../global/text.json';

const outputProjectSelectorText = text.outputProjectSelector;

const ACCOUNT_PROJECT_LENGTH_LIMIT = 70;

interface AccountProjectNameProps {
  isPopoverOpen: boolean;
  selectedProject: UploadLocationProject | null;
  loading: boolean;
}

const AccountProjectName: React.FC<AccountProjectNameProps> = ({ isPopoverOpen, loading, selectedProject }) => {
  const dropdownIcon = isPopoverOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;

  if (loading) {
    return <ProjectListLoading data-testid={uploadLocationProjectSelectorTestIds.loading} />;
  }

  if (!selectedProject) {
    return (
      <Typography variant="body2" noWrap component="div">
        <strong>{outputProjectSelectorText.selectProjectTitle}</strong>
      </Typography>
    );
  }

  // If there is not enough length for both project name and account name in one line, put account name as a caption.
  if (selectedProject.name.length + selectedProject.accountName.length >= ACCOUNT_PROJECT_LENGTH_LIMIT) {
    return (
      <>
        <Typography variant="caption" noWrap component="div" textAlign="start">
          {selectedProject.accountName}
        </Typography>
        <Box display="flex">
          <ProjectTitleContainer>
            <Typography variant="body2" noWrap component="div">
              <strong>{selectedProject.name}</strong>
            </Typography>
            <ProjectIcon vendor={selectedProject.vendor} />
          </ProjectTitleContainer>
          {dropdownIcon}
        </Box>
      </>
    );
  }

  return (
    <Box display="flex" justifyContent="flex-end">
      <ProjectTitleContainer>
        {/* The line height=normal fixes the issue with the vertical alignment between the account name and
            the project name in the project selection bar  */}
        <Typography variant="caption" noWrap component="div" lineHeight="normal">
          {selectedProject.accountName}
        </Typography>
        <Typography variant="body2" noWrap component="div">
          <strong>{selectedProject.name}</strong>
        </Typography>
        <ProjectIcon vendor={selectedProject.vendor} />
      </ProjectTitleContainer>
      {dropdownIcon}
    </Box>
  );
};

export default AccountProjectName;
