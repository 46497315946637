import Typography from '@mui/material/Typography';
import text from 'global/text.json';
import React from 'react';
import { licensingTestIds } from 'tests/helpers/dataTestIds';
import { ContentSectionWrapper } from '../../Licensing.styles';
import ThirdPartyAttributions from './ThirdPartyAttributions';

const licensingText = text.licensing;

const LicensedComponents: React.FC = () => (
  <ContentSectionWrapper data-testid={licensingTestIds.componentsSection}>
    <Typography id="components" variant="h2" gutterBottom>
      {licensingText.licensedComponents}
    </Typography>
    <ThirdPartyAttributions />
  </ContentSectionWrapper>
);

export default LicensedComponents;
