import { useMemo } from 'react';
import { LogsWrapper, LogTable, LogNumbersColumn, LogTableRow, LogLineNumber, LogTextLine } from './LogPreview.styles';
import Typography from '@mui/material/Typography';
import { outputsReviewPanel } from '../../../../tests/helpers/dataTestIds';

interface LogDataWithNumbersProps {
  logData: string;
}

const newLine = '\n';
const logPreviewKey = 'logPreviewKey';
const LogDataWithNumbers: React.FC<LogDataWithNumbersProps> = ({ logData }: LogDataWithNumbersProps) => {
  const logDataArray = useMemo(() => logData.split(newLine), [logData]);

  return (
    <LogsWrapper data-testid={outputsReviewPanel.logData}>
      <LogTable>
        <colgroup>
          <LogNumbersColumn />
        </colgroup>
        {logDataArray.map((logLine, index) => (
          <LogTableRow key={logPreviewKey + index}>
            <td>
              <LogLineNumber>{index + 1}</LogLineNumber>
            </td>
            <LogTextLine>
              <Typography variant="body2">{logLine}</Typography>
            </LogTextLine>
          </LogTableRow>
        ))}
      </LogTable>
    </LogsWrapper>
  );
};

export default LogDataWithNumbers;
