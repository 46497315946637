import { ProductRelease } from '@adsk/offsite-dc-sdk';
import { useTheme } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PopoverOrigin } from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import text from '../../../../global/text.json';
import NestedMenuItem from './NestedMenuItem';
import { ProductSelectDropDownContainer, ProductSelectionMenuItem } from './ProductSelectDropdown.styles';
import { ProductSelectionField } from './ProductSelectionField';
import { useProductSelectDropdown } from './useProductSelectDropdown';

const instancePanelText = text.instancePanel;

interface ProductSelectDropdownProps {
  expanded: boolean;
}

// Use product id as key to group multiple releases
export type ProductReleasesMap = {
  [key: string]: ProductRelease[];
};

export const getProductNameInDropdown = (
  productReleases: ProductRelease[],
  selectedProductRelease: ProductRelease | undefined,
  productReleasesMap: ProductReleasesMap,
): string => {
  if (
    !selectedProductRelease ||
    !productReleases.find(
      (productRelease) =>
        productRelease.contentId === selectedProductRelease.contentId &&
        productRelease.release === selectedProductRelease.release,
    )
  ) {
    return '';
  }

  const releasesByProduct = productReleasesMap[selectedProductRelease.contentId];

  return releasesByProduct.length === 1
    ? selectedProductRelease.name
    : `${selectedProductRelease.name} - ${instancePanelText.release} ${selectedProductRelease.release}`;
};

export const ProductSelectDropdown: React.FC<ProductSelectDropdownProps> = ({ expanded }) => {
  const {
    productSelectionRef,
    anchorEl,
    open,
    selectedProductRelease,
    productReleases,
    productReleasesMap,
    instancesProductsLoading,
    handleMenuClick,
    handleMenuClose,
    handleProductReleaseClick,
  } = useProductSelectDropdown();

  const anchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  };

  const transformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'center',
  };

  const theme = useTheme();

  const minWidthOfMenuItem =
    expanded &&
    !!productSelectionRef.current &&
    productSelectionRef.current.clientWidth > theme.var.productSelectDropdownWidth
      ? productSelectionRef.current.clientWidth
      : theme.var.productSelectDropdownWidth;

  return (
    <ProductSelectDropDownContainer>
      <Typography variant="body2">{instancePanelText.product}</Typography>
      <ProductSelectionField
        onClick={handleMenuClick}
        expanded={expanded}
        disabled={instancesProductsLoading}
        ref={productSelectionRef}
        open={open}
        label={getProductNameInDropdown(productReleases, selectedProductRelease, productReleasesMap)}
      />
      {!instancesProductsLoading && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          {Object.values(productReleasesMap).map((productReleases) => {
            if (productReleases.length === 1) {
              return (
                <ProductSelectionMenuItem
                  onClick={() => handleProductReleaseClick(productReleases[0])}
                  key={`product-${productReleases[0].contentId}-release-${productReleases[0].release}`}
                  data-id={`product-${productReleases[0].contentId}-release-${productReleases[0].release}`}
                  selected={selectedProductRelease?.contentId === productReleases[0].contentId}
                  minWidth={minWidthOfMenuItem}
                >
                  <Typography variant="body1" noWrap>
                    {productReleases[0].name}
                  </Typography>
                </ProductSelectionMenuItem>
              );
            }

            return (
              <NestedMenuItem
                label={productReleases[0].name}
                parentMenuOpen={!!anchorEl}
                key={`product-${productReleases[0].contentId}`}
                data-id={`product-${productReleases[0].contentId}`}
                selected={selectedProductRelease?.contentId === productReleases[0].contentId}
                minWidth={minWidthOfMenuItem}
              >
                {productReleases.map((productRelease) => (
                  <MenuItem
                    onClick={() => handleProductReleaseClick(productRelease)}
                    key={`product-${productRelease.contentId}-release-${productRelease.release}`}
                    data-id={`product-${productRelease.contentId}-release-${productRelease.release}`}
                    selected={
                      selectedProductRelease?.contentId === productRelease.contentId &&
                      selectedProductRelease?.release === productRelease.release
                    }
                  >
                    <Typography variant="body1" noWrap>
                      {`${instancePanelText.release} ${productRelease.release}`}
                    </Typography>
                  </MenuItem>
                ))}
              </NestedMenuItem>
            );
          })}
        </Menu>
      )}
    </ProductSelectDropDownContainer>
  );
};
