import React, { ReactNode, Component, ErrorInfo } from 'react';
import { logError } from 'mid-utils';
import ErrorFallback from './ErrorFallback';

interface ErrorBoundaryProps {
  children: ReactNode;
  handleResetAppState?: () => void;
  secondaryMessage?: string;
  resetButtonText?: string;
}

class ErrorBoundary extends Component<ErrorBoundaryProps> {
  state: Readonly<{ error: Error | null }>;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error): { error: Error } {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    logError(error.message, errorInfo);
  }

  handleErrorBoundaryReset = (): void => {
    const { handleResetAppState } = this.props;
    if (handleResetAppState) {
      handleResetAppState();
      this.setState({ error: null });
    }
  };

  render(): React.ReactNode {
    const { error } = this.state;
    const { secondaryMessage, resetButtonText, children } = this.props;

    if (error) {
      return (
        <ErrorFallback
          error={error}
          clearError={this.handleErrorBoundaryReset}
          {...(secondaryMessage && { secondaryMessage })}
          {...(resetButtonText && { resetButtonText })}
        />
      );
    }

    return children;
  }
}

export default ErrorBoundary;
