import text from '../../common.text.json';
import { Divider } from '@mui/material';
import { getReactAppEnvironment, ServiceConfigMap, ServiceTypes } from 'mid-utils';
import { accBridge } from '../../global/dataTestIds';
import { AccBridgeTooltipLink, AccBridgeTooltipList, AccBridgeTooltipText } from './AccBridgeTooltip.styles';

const { accBridge: accBridgeText } = text;

interface AccBridgeTooltipContentProps {
  tooltipText: string;
  selectedProjectId: string | null;
  accBridgeProjectsData: {
    projectNameSynced?: string;
    linkProjectId?: string;
    linkFolderId?: string;
  }[];
}

export const AccBridgeTooltipContent: React.FC<AccBridgeTooltipContentProps> = ({
  tooltipText,
  selectedProjectId,
  accBridgeProjectsData,
}) => {
  const reactAppEnvironment = getReactAppEnvironment();

  return (
    <div data-testid={accBridge.accBridgeTooltip}>
      <AccBridgeTooltipText>{tooltipText}</AccBridgeTooltipText>
      {accBridgeProjectsData.length > 0 && (
        <AccBridgeTooltipList>
          {accBridgeProjectsData.map(({ projectNameSynced, linkProjectId, linkFolderId }, index) => (
            <li key={`${projectNameSynced}-${index}`}>
              <AccBridgeTooltipLink
                data-testid={accBridge.accBridgeTooltipLink}
                href={`${
                  ServiceConfigMap[ServiceTypes.ACC][reactAppEnvironment].api
                }/docs/files/projects/${linkProjectId}?folderId=${linkFolderId}&viewModel=detail&moduleId=folders`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {projectNameSynced}
              </AccBridgeTooltipLink>
            </li>
          ))}
        </AccBridgeTooltipList>
      )}
      {selectedProjectId && (
        <>
          <Divider />
          <AccBridgeTooltipLink
            href={`${
              ServiceConfigMap[ServiceTypes.ACC][reactAppEnvironment].api
            }/docs/bridge/projects/${selectedProjectId}/bridged`}
            target="_blank"
            rel="noreferrer noopener"
            className="mid-acc-bridge-tooltip-link-details"
            data-testid={accBridge.accBridgeTooltipLinkSeeDetails}
          >
            {accBridgeText.tooltipSeeDetails}
          </AccBridgeTooltipLink>
        </>
      )}
    </div>
  );
};
