import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import SvgIcon from '@mui/material/SvgIcon';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { sidebarNavTestIds } from 'tests/helpers/dataTestIds';
import {
  Drawer,
  ListItemButtonWrapper,
  ListItemTextWrapper,
  ShowHideButtonWrapper,
  SidebarListWrapper,
} from './pages.styles';

export type NavItem = { name: string; path: string; icon: JSX.Element; disabled?: boolean };

export const Sidebar: React.FC<{ navItems: NavItem[] }> = ({ navItems }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerChange = () => {
    setDrawerOpen((drawerOpenState) => !drawerOpenState);
  };

  return (
    <Drawer variant="permanent" open={drawerOpen}>
      <SidebarListWrapper>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButtonWrapper
              data-testid={`${sidebarNavTestIds.sidebarItemButton}-${item.name}`}
              component={NavLink}
              to={item.path}
              disabled={item.disabled}
            >
              <ListItemIcon>
                <SvgIcon>{item.icon}</SvgIcon>
              </ListItemIcon>
              <ListItemTextWrapper
                primary={item.name}
                data-testid={`${sidebarNavTestIds.sidebarItemText}-${item.name}`}
                visible={drawerOpen}
              />
            </ListItemButtonWrapper>
          </ListItem>
        ))}
        <ShowHideButtonWrapper>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton onClick={handleDrawerChange} data-testid={sidebarNavTestIds.showHideSidebar}>
              <ListItemIcon>{drawerOpen ? <FirstPageIcon /> : <LastPageIcon />}</ListItemIcon>
            </ListItemButton>
          </ListItem>
        </ShowHideButtonWrapper>
      </SidebarListWrapper>
    </Drawer>
  );
};
