import { OutputType, ProductRelease } from '@adsk/offsite-dc-sdk';
import { groupBy } from 'lodash';
import {
  DCOutputWithVirtualTypes,
  DCOutputAggregatedBom,
  OutputTypeWithVirtualTypes,
  NeutralOutputTypes,
  PostVariantOutputWithVirtualTypes,
  DCNeutralOutputs,
  DCBomOutputs,
  DCDrawingOutputs,
  DrawingOutputTypes,
  BomOutputTypes,
} from 'mid-types';
import { getDCOutputModelStates } from 'mid-utils';
import { Instance } from 'types/product';
import text from 'global/text.json';
const fileTypesStepText = text.rfoModal.fileTypesStep;

export const splitStringIntoArray = (value: string | string[]): string[] =>
  typeof value === 'string' ? value.split(',') : value;

export const handleModelStateChange = (
  value: string | string[],
  type: NeutralOutputTypes | BomOutputTypes,
  modelStatesOfTypeAlreadySelected: string[],
  allOutputsOfCategory: DCNeutralOutputs[] | DCBomOutputs[],
  allOutputs: PostVariantOutputWithVirtualTypes[],
  setModelStatesOfType: (outputs: string[]) => void,
  setAllOutputs: (outputs: PostVariantOutputWithVirtualTypes[]) => void,
): void => {
  const modelStatesArray = splitStringIntoArray(value);
  const otherOutputs = allOutputs.filter((output) => output.type !== type);

  // ALL SELECTION
  if (modelStatesArray[modelStatesArray.length - 1] === fileTypesStepText.all) {
    const allModelStatesOfType = allOutputsOfCategory
      .filter((output) => output.type === type)
      .map((output) => output.options.modelStates)
      .flat();
    const allPostVariantOutputsOfType: PostVariantOutputWithVirtualTypes[] = allModelStatesOfType.map((modelState) => ({
      type,
      modelState,
    }));

    const areAllModelStatesAlreadySelected = modelStatesOfTypeAlreadySelected.length === allModelStatesOfType.length;
    setModelStatesOfType(areAllModelStatesAlreadySelected ? [] : allModelStatesOfType);
    setAllOutputs(areAllModelStatesAlreadySelected ? otherOutputs : [...otherOutputs, ...allPostVariantOutputsOfType]);
  } else {
    // INDIVIDUAL SELECTION
    const newPostVariantOutputsOfType: PostVariantOutputWithVirtualTypes[] = modelStatesArray.map((modelState) => ({
      type,
      modelState,
    }));

    setModelStatesOfType(modelStatesArray);
    setAllOutputs([...otherOutputs, ...newPostVariantOutputsOfType]);
  }
};

export const handleModelStateDelete = (
  value: string | string[],
  type: NeutralOutputTypes | BomOutputTypes,
  modelStatesOfTypeAlreadySelected: string[],
  allOutputs: PostVariantOutputWithVirtualTypes[],
  setModelStatesOfType: (outputs: string[]) => void,
  setAllOutputs: (outputs: PostVariantOutputWithVirtualTypes[]) => void,
): void => {
  const remainingOutputs = modelStatesOfTypeAlreadySelected.filter((modelState) => modelState !== value);
  const otherOutputs = allOutputs.filter((output) => output.type !== type);

  const newPostVariantOutputsOfType: PostVariantOutputWithVirtualTypes[] = remainingOutputs.map((modelState) => ({
    type,
    modelState,
  }));

  setModelStatesOfType(remainingOutputs);
  setAllOutputs([...otherOutputs, ...newPostVariantOutputsOfType]);

  (document.activeElement as HTMLElement)?.blur();
};

export const handleDrawing2DChange = (
  value: string | string[],
  type: DrawingOutputTypes,
  outputsOfTypeAlreadySelected: string[],
  allOutputsOfCategory: DCDrawingOutputs[],
  allOutputs: PostVariantOutputWithVirtualTypes[],
  setOutputsOfType: (outputs: string[]) => void,
  setAllOutputs: (outputs: PostVariantOutputWithVirtualTypes[]) => void,
): void => {
  const drawingsArray = splitStringIntoArray(value);
  const otherOutputs = allOutputs.filter((output) => output.type !== type);

  // ALL SELECTION
  if (drawingsArray[drawingsArray.length - 1] === fileTypesStepText.all) {
    const allDrawingsOfType = allOutputsOfCategory
      .filter((output) => output.type === type)
      .map((output) => output.options.drawingTemplatePath)
      .flat();
    const allPostVariantOutputsOfType: PostVariantOutputWithVirtualTypes[] = allDrawingsOfType.map((drawing) => ({
      type,
      drawingTemplatePath: drawing,
    }));

    const areAllOutputsAlreadySelected = outputsOfTypeAlreadySelected.length === allDrawingsOfType.length;

    setOutputsOfType(areAllOutputsAlreadySelected ? [] : allDrawingsOfType);
    setAllOutputs(areAllOutputsAlreadySelected ? otherOutputs : [...otherOutputs, ...allPostVariantOutputsOfType]);
  } else {
    // INDIVIDUAL SELECTION
    const newPostVariantOutputsOfType: PostVariantOutputWithVirtualTypes[] = drawingsArray.map((drawing) => ({
      type,
      drawingTemplatePath: drawing,
    }));

    setOutputsOfType(drawingsArray);
    setAllOutputs([...otherOutputs, ...newPostVariantOutputsOfType]);
  }
};

export const handleDrawing2DDelete = (
  value: string,
  type: DrawingOutputTypes,
  drawingsOfTypeAlreadySelected: string[],
  allOutputs: PostVariantOutputWithVirtualTypes[],
  setOutputsOfType: (outputs: string[]) => void,
  setAllOutputs: (outputs: PostVariantOutputWithVirtualTypes[]) => void,
): void => {
  const remainingOutputs = drawingsOfTypeAlreadySelected.filter((drawing) => drawing !== value);
  const otherOutputs = allOutputs.filter((output) => output.type !== type);

  const newPostVariantOutputsOfType: PostVariantOutputWithVirtualTypes[] = remainingOutputs.map((drawing) => ({
    type,
    drawingTemplatePath: drawing,
  }));

  setOutputsOfType(remainingOutputs);
  setAllOutputs([...otherOutputs, ...newPostVariantOutputsOfType]);

  (document.activeElement as HTMLElement)?.blur();
};

export const getAllOutputs = (
  selectedProductRelease: ProductRelease | undefined,
  selectedInstances: Instance[] | undefined,
): DCOutputWithVirtualTypes[] => {
  const outputs: DCOutputWithVirtualTypes[] =
    selectedProductRelease?.outputs.filter(
      (output) => output.type !== OutputType.RFA && output.type !== OutputType.THUMBNAIL,
    ) || [];

  const bomOutput = outputs.find((output) => output.type === OutputType.BOM);

  if (!bomOutput) {
    return outputs;
  }

  const groupedInstances = Object.values(groupBy(selectedInstances, 'variantId'));

  // the BOMs can be aggregated if there are 2 or more instances of the same variant
  const canBeAggregated = groupedInstances.some((instances) => instances.length > 1);
  if (canBeAggregated) {
    // add a new virtual output type to render the proper tree structure
    const bomAggregatedOutput: DCOutputAggregatedBom = {
      options: { modelStates: getDCOutputModelStates(bomOutput) || [] },
      type: OutputTypeWithVirtualTypes.BOMAGGREGATED,
    };
    outputs.push(bomAggregatedOutput);
  }
  return outputs;
};
