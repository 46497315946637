import { Spacing } from '@mid-react-common/common';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import text from 'global/text.json';
import React from 'react';
import { licensingTestIds } from 'tests/helpers/dataTestIds';
import { ContentSectionWrapper } from '../Licensing.styles';
import { trademarksUrl } from '../constants';

const licensingText = text.licensing;

const Trademarks: React.FC = () => (
  <ContentSectionWrapper data-testid={licensingTestIds.trademarksSection}>
    <Typography id={licensingTestIds.trademarksId} variant="h2" gutterBottom>
      {licensingText.autodeskTrademarks}
    </Typography>
    <Typography>
      {licensingText.trademarksInfo1}{' '}
      <Link
        data-testid={licensingTestIds.trademarksPage}
        target="_blank"
        rel="noopener noreferrer"
        href={trademarksUrl}
        color="primary"
      >
        {licensingText.autodeskTrademarks}
      </Link>{' '}
      {licensingText.trademarksInfo2}
      <Spacing />
      {licensingText.trademarksInfo3}
    </Typography>
  </ContentSectionWrapper>
);

export default Trademarks;
