import { useVariantCanvasConstants } from '../global/constants/products';
import text from '../global/text.json';

//Downloads an image from the url and returns base64 encoded string
export const getBase64Image = (url: string): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const img = new Image();
    img.setAttribute(useVariantCanvasConstants.ATTRIBUTE_NAME, useVariantCanvasConstants.ATTRIBUTE_VALUE);
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL(useVariantCanvasConstants.IMAGE_TYPE);
        resolve(dataURL);
      } else {
        const variantText = text.useThumbnail;
        reject(variantText.canvasError);
      }
    };
    img.src = url;
  }).then((dataURL) => dataURL);
