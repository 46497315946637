import { NotificationContext } from '@mid-react-common/common';
import { QueryCache, QueryClient, QueryClientProvider as ReactQueryClientProvider } from '@tanstack/react-query';
import { ReactNode, useContext } from 'react';

interface QueryClientProviderProps {
  children: ReactNode;
}

const QueryClientProvider: React.FC<QueryClientProviderProps> = ({ children }) => {
  const { logAndShowNotification } = useContext(NotificationContext);

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error) => {
        logAndShowNotification({
          error,
        });
      },
    }),
    defaultOptions: { queries: { gcTime: 0, retry: false } },
  });

  return <ReactQueryClientProvider client={queryClient}>{children}</ReactQueryClientProvider>;
};

export default QueryClientProvider;
